import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { ToastContainer } from 'react-toastify';
import { CheckPermission } from '../../../modules/CheckPermission';
import { Link } from 'react-router-dom';
import { ApiCall } from '../../../modules/ApiCall';
import axios from 'axios';
import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../../Loader/BeatLoader";
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';



class CreateGroup extends Component {
    constructor() {
        super();
        this.state = {
            users: [],
            groups: [],
            selectedUsers: [],
            groupName: "",
            groupDescription: "",
            status: true,
            isGroupsLoaded: true,
            companyName: "",
            phone: "",
            createUserGroup: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    async componentDidMount() {
        document.title = "Tenant App - Settings";

        await this.getGroups();
        await this.getUsers();
    }



    async getGroups() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles == "TenantAdmin") {
                let tenantId = parseJwt(bearer).tenantid;

                let url = process.env.REACT_APP_IDENTITY_API + "/TenantUserGroup/GetTenantUserGroupsList?tenantId=" + tenantId;
                await axios(url,
                    {
                        method: 'GET',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        }
                    }).then(async (response) => {
                        this.setState({
                            groups: response.data,
                            isGroupsLoaded: false
                        })
                    })
                    .catch(err => {
                        console.error("Unable to get user groups == >" + err);
                    });


            }
            else
                console.error("You do not have permission on user groups, only tenant admin s allowed");

        }
    }


    async getUsers() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsersWithRoles";

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        users: result
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching users!', "top-center");

                });
        }
    }


    async handleSubmit() {
        
            if (this.state.selectedUsers.length <= 0 || this.state.selectedUsers == null && this.state.selectedUsers == []) {
                displayWarningMessage('Please select atleast 1 user from the bottom table!', "top-center");

                return;
            }
            if (this.state.selectedUsers.length > 0 && this.state.selectedUsers != null && this.state.selectedUsers != [] && this.state.groupName != "" && this.state.groupDescription != undefined && this.state.groupName != "" && this.state.groupDescription != undefined) {
                let bearer = validateAndGetFormatedToken();
                if (bearer != "" || bearer != null) {
                    let tenantId = parseJwt(bearer).tenantid;
                    let url = process.env.REACT_APP_CORE_API + '/api/tenantUserGroup';

                    let temparr = [];
                    this.state.selectedUsers.forEach(
                        x => temparr.push({ applicationUserId: x.userId, username: x.email })
                    )
                    let inputModel = {
                        TenantUserGroup: {
                            name: this.state.groupName,
                            description: this.state.groupDescription,
                            IsActive: this.state.status,
                            TenantId: tenantId
                        },

                        TenantUserInGroups: temparr
                    }

                    var model = JSON.stringify(inputModel);

                    const requestOptions =
                    {
                        method: 'POST',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        },
                        body: model
                    };
                    await fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(async (result) => {
                            await this.getUsers();
                            this.setState({
                                groupName: "",
                                groupDescription: "",
                                status: false,
                                createUserGroup: false
                            });
                            displaySuccessMessage(' Tenant Group Created!', "top-center");

                        })
                        .catch((err) => {
                            console.log("Error During Tenant Group Creation!", err);
                            displayErrorMessage('Error During Tenant Group Creation!', "top-center");
                        });

                }
                else {
                    displayWarningMessage('Please fill all required fields!', "top-center");

                    return;

                }

                setTimeout(function () {
                    window.location.replace('/usergroup');
                }, 5000);

            }
            else {
                displayWarningMessage('Please add required field !', "top-center");

                return;
            }
        
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    render() {
        const addSpacesToCamelCase = (str) => {
            // Using regex to find where a lowercase letter is followed by an uppercase letter
            return str.replace(/([a-z])([A-Z])/g, '$1 $2');
        }

        const onRowSelectforusers = (row, isSelected) => {
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedUsers;


                selectedsets.push(row);

                this.setState({ selectedUsers: selectedsets })
            }
            else {
                let selectedsets = this.state.selectedUsers;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.userId === selectedsets[i].userId) {



                        selectedsets.splice(i, 1);

                        this.setState({ selectedUsers: selectedsets })
                        return;
                    }
                }

            }
        };

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>
                                   

                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Create Group</h4>


                            </div>
                            <div className="card-body">
                                <form>


                                    <div className="row mb-4">
                                        <label for="groupName" className="col-sm-3 col-form-label form-label">Name <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className="form-control" name="groupName" id="groupName" placeholder="Group Name" aria-label="Clarice" value={this.state.groupName} onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label for="groupDescription" className="col-sm-3 col-form-label form-label">Description <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="groupDescription" id="groupDescription" placeholder="Group Description" value={this.state.groupDescription} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-sm-3 col-form-label form-label">Status                                                     <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                           
                                            <div className="col-lg form-check" >

                                                <input type="radio" value={true} name="status" defaultChecked onChange={() => {
                                                    this.setState({ status: true })
                                                }} /> <span className="form-check-label mx-2">Active</span>
                                                <input type="radio" value={false} name="status"
                                                    onChange={() => {
                                                        this.setState({ status: false })
                                                    }}
                                                /> <span className="form-check-label mx-2">Inactive</span>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="table-responsive datatable-custom position-relative">
                                        <div id="datatable_wrapper" className="dataTables_wrapper no-footer">

                                            <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                                <thead className="thead-light">
                                                    <tr role="row">
                                                        <th className="table-column-pe-0 sorting_disabled" rowspan="1" colspan="1" aria-label=" " style={{ width: '24px;' }}>
                                                            Select
                                                        </th>
                                                        <th className="table-column-ps-0 " tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Name & Email</th>
                                                        <th tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" >Role</th>
                                                        <th tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending" >Status</th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.users.map((user, index) => (
                                                        <tr role="row" className="odd" key={index}>
                                                            <td className="table-column-pe-0">
                                                                <div>
                                                                    <input type="checkbox" value={user.userId}
                                                                        onClick={(e) => {
                                                                            onRowSelectforusers(user, e)
                                                                        }} />
                                                                </div>
                                                            </td>

                                                            <td className="table-column-ps-0">
                                                                <a className="d-flex align-items-center">

                                                                    <div className="ms-0">
                                                                        <span className="d-block h5 text-inherit mb-0">{user.firstName} {user.lastName}</span>
                                                                        <span className="d-block fs-5 text-body">  {user.email}</span>
                                                                    </div>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <span className="d-block h5 mb-0"> {addSpacesToCamelCase(user.role)}</span>
                                                            </td>

                                                            <td>
                                                                <span className={(user.status == "Active"
                                                                    ? "legend-indicator bg-success" :
                                                                    "legend-indicator bg-danger")}></span> {user.status}
                                                            </td>



                                                        </tr>


                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="card-footer">

                                    </div>



                                    <div className="d-flex justify-content-end">
                                        <div className="d-flex gap-3">
                                            <Link type="button" className="btn btn-white" to="/usergroup">Cancel</Link>
                                            <button type="button" className="btn btn-primary" onClick={this.handleSubmit}
                                            >Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}
export default CreateGroup;