import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseJwt, parseToken } from '../../modules/TokenParser';
import axios from "axios";
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import CompanyLogo from '../../assets/svg/brands/codingkey-icon.svg';
import { CheckPermission } from '../../modules/CheckPermission';
import Moment from 'moment';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'

class CandidateDetailsComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state =
        {
            candidateDetails: [],
            isloadedgetVideos: false,
            isloadedgetCandidateDetails: true,
            isloadedgetTenantUserGroups: true,
            isloadedgetQuestionSets: true,
            editJobPermission: true,
            createJobPermission: true,
            createInterviewPermission: true,
            candidateFiles: [],
            candidateName: "",
            candidatePhone: "",
            candidateEmail: "",




        };



    }
    componentDidMount() {
        //document.title = "Tenant App - Candidates";
        console.log("view props", this.props.userId)
        this.getCandidateFiles(this.props.userId);
    }

    /**
     * Fetch Candidate files
     * @param {any} userId
     */
    async getCandidateFiles(userId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/CandidateDocuments/" + userId;
            await axios(
                url,

                {
                    method: 'GET',
                    headers: {
                        'Authorization': bearer,
                        'accept': '*/*',
                        'Content-Type': 'application/json-patch+json'
                    }
                }
            )
                .then(response => {
                    this.setState({
                        candidateName: response.data.candidateName,
                        candidatePhone: response.data.candidatePhone,
                        candidateEmail: response.data.candidateEmail,
                        candidateFiles: response.data.documents,
                        
                    })
                    console.log("response.data.documents", response.data.documents)

                })

                .catch(err => {
                    if (err.response.data !== "No data found") {
                        console.log(err);
                        displayErrorMessage('Error while fetching Candidate Files!', "top-center");
                       

                    }
                    

                });
        }
    }


    render() {

        return (
            <>
                    {/* <!-- Content --> */}
                    <div className="">
                        
                        {/* <!-- Start Job Details--> */}

                   
                    {this.state.candidateFiles.length !==0 ? 
                        <div className="card m-0 d-none d-lg-block">

                           
                        {/* <!-- Table --> */}
                        <div className="table-responsive datatable-custom w-100  mb-4" >
                                <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                    <thead className="thead-light">
                                        <tr className="f-weight-600">

                                            <th style={{ width: "0px" }}
                                            >
                                                <strong>Document Category </strong>
                                            </th>
                                            <th style={{ width: "0px" }}
                                            >
                                                <strong>Document Type </strong>

                                            </th>
                                            <th style={{ width: "0px" }}>
                                                <strong>Download Link </strong>
                                            </th>

                                            

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            this.state.candidateFiles.map((file, index) => {
                                                return (

                                                    <tr >

                                                        <td className="f-size-14 inter-ff">
                                                            {file.docCategoryType === "personal" ? "Personal Informational Documents" : ""}
                                                            {file.docCategoryType === "education" ? "Educational Documents" : ""}
                                                            {file.docCategoryType === "work_experience" ? "Work Experience" : ""}
                                                            {file.docCategoryType === "other" ? "Others" : ""}
                                                        </td>
                                                        <td className="f-size-14 inter-ff">
                                                            {file.docTitle}
                                                        </td>
                                                        <td >
                                                            {(file.docFileUrl != null && file.docFileUrl != "") &&
                                                                <a className="btn-link" type="button" style={{ marginLeft: '1%' }}
                                                                    href={file.docFileUrl} target="_blank"><span class="material-symbols-outlined f-size-26">
                                                                        cloud_download
                                                                    </span></a>} 
                                                        </td>

                                                       

                                                       

                                                    </tr>

                                                )

                                            })
                                        }

                                    </tbody>
                                </table>

                            </div>

                            {/*<!-- End Table --> */}

                                                    
                        </div>
                        : 
                        <div className=" card-centered mb-3 mb-lg-5">
                            <div className="card-body py-10">
                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                <div className="text-center">

                                    <h1>No documents uploaded.</h1>




                                </div>

                            </div>
                        </div>
                    }
                        {/*Mobile Start Here*/}
                        <div className="row justify-content-center align-items-center g-0 p-0 mt-0 d-lg-none">
                            <div className="col-md-12 d-flex d-lg-none mb-2" style={{ paddingRight: '8px' }}>

                            </div>
                            <div className=" col-md-12">
                                <div className="card border-0 jc-radius pb-3">
                                    <table className="table jobs-overview-table">
                                        <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                            <tr>
                                                <th scope="col " className="p-3" colSpan={3}>
                                                    <p className="f-size-16 f-weight-600 mb-0">  {this.state.candidateName}</p>
                                                    <p className="f-size-12 f-weight-600 mb-0 f-gray">{this.state.candidateEmail} | {this.state.candidatePhone}</p>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {this.state.candidateFiles.map((file, index) => (
                                                <tr key={index}>
                                                    <td className="p-2">
                                                        <div className="d-flex align-items-center mb-0">
                                                            <div className="col d-flex">
                                                                <div>
                                                                    <div className="d-block ms-1">

                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div>
                                                                        <h2 className="inter-ff f-size-14 f-weight-500 mb-0">
                                                                           
                                                                            {
                                                                            file.docTitle === "SSN Front" ? "Social Security Number (Card front)" :
                                                                            file.docTitle === "SSN Back" ? "Social Security Number (Card back)" : 
                                                                            file.docTitle === "Photo" ? "Candidate’s Recent Photo" : 
                                                                            file.docTitle === "Passport" ? "Passport" :
                                                                            file.docTitle === "HSS" ? "HSS" :
                                                                            file.docTitle === "Bachelor" ? "Bachelor’s Degree" : 
                                                                            file.docTitle === "Master" ? "Master’s Degree" : 
                                                                            file.docTitle === "Doctoral" ? "Doctoral’s Degree" : file.docTitle
                                                                            }
                                                                           
                                                                 
                                                                        </h2>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-1  align-middle d-flex gap-0 justify-content-end">


                                                                <div>
                                                                    <div className="btn btn-outline-primary btn-icon btn-sm me-2" >
                                                                    {(file.docFileUrl != null && file.docFileUrl != "") &&
                                                                            <a className="btn-link" type="button" style={{ display: 'flex', alignItems: 'center' }}
                                                                            href={file.docFileUrl} target="_blank"><span class="material-symbols-outlined f-size-18">
                                                                                cloud_download
                                                                            </span></a>} 

                                                                     </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  ">
                                                            <div className="col d-flex align-items-center ">
                                                                
                                                                <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter mx-1">
                                                                    {file.docCategoryType === "personal" ? "Personal Informational Documents" : ""}
                                                                    {file.docCategoryType === "education" ? "Educational Documents" : ""}
                                                                    {file.docCategoryType === "work_experience" ? "Work Experience" : ""}
                                                                    {file.docCategoryType === "other" ? "Others" : ""}                                                                </p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                        {/*Mobile End Here*/}

                        {/* <!-- Content --> */}


                        {/* <!-- End Job Details --> */}


                    </div>
                    {/* <!-- End Content --> */}


                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}

export default CandidateDetailsComponent;

