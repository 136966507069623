import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import BarChart from "../Charts/BarChart.js";
import PieChart from "../Charts/PieChart.js";
import { CheckPermission } from '../../modules/CheckPermission';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage } from '../../modules/ToastMessage';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';




class Dashboard extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            filter: "",
            openJobs: "",
            interviewCounts: "",
            openOnewayInterview: "",
            closeOnewayInterview: "",
            liveInterview: "",
            candidateInterviews: [],
            pieChartData: {},
            pieChartDataForAvgHiring: {},
            jobWithNoApplicants: {},
            jobApplicants: null,
            isloadedgetDashboardStats: true,
            jobCount: "",
            totalJobCount: "",
            activeUserCount: "",
            totalUserCount: "",
            activeUserCountPer: "",
            candidateHiredCount: "",
            interviewCount: "",
            jobCountPer: "",
            averageHiring: "",
            openOnewayInterviewCount: "",
            closeOnewayInterviewCount: "",
            liveInterviewCount: "",
            editReviewPermission: true,
            recentInterviewsHeaders: [
                { label: "Full Name", key: "fullName" },
                { label: "Inteview date", key: "interviewDate" },
                { label: "Time Zone Name", key: "timeZoneName" },
                { label: "Status", key: "status" },
                { label: "Type", key: "type" },

            ],
            itemOffset: 0

        }
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    async componentDidMount() {
        if (verifyTokenOnLocal()) {
            await isActiveAccessToken();

            document.title = "Tenant App - Dashboard";
            await this.getDashboardStats();
            await this.getTenantdetials();
            this.interviewReviewPermissions();

        }
    }
    /** Get list of videos */
    async getDashboardStats() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();

        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Dashboard";

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let onewayOpenCount = null;
                    let onewayCloseCount = null;
                    let liveCount = null;
                    let jobApplicants = result.jobApplicants;
                    let jobAppliantsCount = []
                    let interviewCount = result.interviewCount;
                    let candidateHiredCount = result.interviewEvaluations.candidateHiredCount;
                    let totalJobCount = result.totalJobCount;
                    let candidateRejectedCount = result.interviewEvaluations.candidateRejectedCount;
                    let inProgressCount = result.interviewEvaluations.inProgressCount;
                    onewayOpenCount = (result.interviewTypes.onewayOpenCount / result.interviewTypes.totalInterviews) * 100;
                    onewayCloseCount = (result.interviewTypes.onewayCloseCount / result.interviewTypes.totalInterviews) * 100;
                    liveCount = (result.interviewTypes.liveCount / result.interviewTypes.totalInterviews) * 100;


                    const temp = jobApplicants.reduce((a, {
                        title,
                        applicantCount
                    }) => (a[title] = (a[title] || 0) + +applicantCount, a), {});


                    Object.keys(temp).forEach(function (key) {


                        jobAppliantsCount.push({
                            name: key,
                            y: temp[key],
                            drilldown: key
                        })
                    });


                    this.setState({
                        openJobs: result.jobCount,
                        interviewCounts: result.interviewTypes.totalInterviews,
                        openOnewayInterview: onewayOpenCount,
                        closeOnewayInterview: onewayCloseCount,
                        liveInterview: liveCount,
                        jobApplicants: result.jobApplicants,
                        totalJobCount: result.totalJobCount,
                        jobCountPer: ((result.jobCount / result.totalJobCount) * 100).toFixed(2),
                        averageHiring: ((result.candidateHiredCount / result.interviewCount) * 100).toFixed(2),
                        candidateHiredCount: result.candidateHiredCount,
                        activeUserCount: result.tenantUserStatus.activeUserCount,
                        totalUserCount: result.tenantUserStatus.totalUserCount,
                        activeUserCountPer: ((result.tenantUserStatus.activeUserCount / result.tenantUserStatus.totalUserCount) * 100).toFixed(2),
                        openOnewayInterviewCount: result.interviewTypes.onewayOpenCount,
                        closeOnewayInterviewCount: result.interviewTypes.onewayCloseCount,
                        liveInterviewCount: result.interviewTypes.liveCount,

                        pieChartData: {
                            series: [{
                                name: 'Jobs',
                                colorByPoint: true,
                                data: [{
                                    name: 'One Way Open',
                                    y: onewayOpenCount,
                                    sliced: true,
                                    selected: true
                                }, {
                                    name: 'One Way Close',
                                    y: onewayCloseCount,
                                },
                                {
                                    name: 'Live',
                                    y: liveCount,
                                }
                                ]
                            }],
                            title: "Percentage of the Open One Way, Close One Way, and live interviews",
                        },
                        pieChartDataForAvgHiring: {
                            series: [{
                                name: 'Avg Hiring',
                                colorByPoint: true,
                                data: [{
                                    name: 'Candidates Hired',
                                    y: candidateHiredCount,
                                    sliced: true,
                                    selected: true
                                },

                                {
                                    name: 'Interviews in Progress',
                                    y: inProgressCount,
                                },
                                {
                                    name: 'Candidate Rejected',
                                    y: candidateRejectedCount,
                                }
                                ]
                            }],
                            title: "Percentage of the Candidates Hired, Candidates Rejected and Interviews in Progress",
                        },
                        jobWithNoApplicants: {
                            series: [{
                                name: "Jobs",
                                colorByPoint: true,
                                data: jobAppliantsCount

                            }],

                            title: "Jobs with number of applicants count",
                            xaxistitle: "Jobs",
                            yaxistitle: "Total Number of Applicants"
                        },
                        isloadedgetDashboardStats: false
                    });
                    let tempInterviews = [];
                    (result.candidateInterviews != [] || result.candidateInterviews != undefined) && result.candidateInterviews.forEach((interview) => {
                        interview.inviteRequestedDate = convertUTCDateToBrowserLocale(interview.inviteRequestedDate);
                        interview.inviteExpiryDate = convertUTCDateToBrowserLocale(interview.inviteExpiryDate);
                        tempInterviews.push(interview);
                    })
                    this.setState({ candidateInterviews: tempInterviews });

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Dashboard stats!', "top-center")

                });

        }

        else
            return;
    }


    async getTenantdetials() {
        var url = null;
        var tenantId = null;

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_IDENTITY_API + "/Tenant/GetTenant?tenantId=" + tenantId;

            if (localStorage.getItem("Tenant") == null) {

                fetch(url,
                    {
                        method: 'GET',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => response.json())
                    .then(result => {

                        //console.log("Dashboard get tenant details", result);
                        //localStorage.setItem('Tenant', JSON.stringify(result));


                    })
                    .catch(err => {
                        console.log(err);
                        displayErrorMessage('Error while Fetching Get Tenant Details', "top-center")

                    });
            }
        }
        else
            return;
    };

    interviewReviewPermissions() {
        let bearer = validateAndGetFormatedToken();
        var viewReviewPermission = false;
        var editReviewPermission = false;
        if (bearer !== "" || bearer != null) {
            if (parseToken(bearer).roles !== "TenantAdmin") {

                viewReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.View", bearer);
                this.setState({ viewReviewPermission: viewReviewPermission });

                editReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.Edit", bearer);
                this.setState({ editReviewPermission: editReviewPermission });
            }

        }
    }


    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    handleRowClick = (jobId, interviewId, interviewInviteId) => {
        sessionStorage.setItem('activeTabLink', "interviews");
        //sessionStorage.setItem('interviewId', interviewId);

        this.props.history.push(`/viewjob/${jobId}?interviewId=${interviewId}&interviewInviteId=${interviewInviteId}`);
    }

    render() {
        const { filter, candidateInterviews } = this.state;
        const lowercasedFilter = filter.toLowerCase();

        const filteredData = candidateInterviews.filter(
            (item) => typeof item.email === 'string' && item.email.toLowerCase().indexOf(lowercasedFilter) > -1
        );

        //for pagination
        const endOffset = this.state.itemOffset + 10;
        const currentItems = this.state.candidateInterviews.slice(this.state.itemOffset, endOffset);
        const pageCount = Math.ceil(this.state.candidateInterviews.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.candidateInterviews.length;
            this.setState({ itemOffset: newOffset });
        };
        return (
            <>
                <main id="content" role="main"
                    className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}
                //    className="main swift-p-default"
                >
                    {/* <main id="content" role="main" className="main"> */}
                    {/*<!-- Content -->*/}
                    <div className="content container-fluid">
                        {/*<!-- Page Header -->*/}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="page-header-title">Dashboard</h1>
                                </div>
                                {/*<!-- End Col -->*/}


                                {/*<!-- End Col -->*/}
                            </div>
                            {/*<!-- End Row -->*/}
                        </div>
                        {/*<!-- End Page Header -->*/}

                        {/*<!-- Stats -->*/}
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                                {/*<!-- Card -->*/}
                                <a className="card card-hover-shadow b-top h-100" href="javascript:void(0);">
                                    <div className="card-body">
                                        <h6 className="card-subtitle f-b-20">Active Jobs</h6>

                                        <div className="row align-items-center gx-2 mb-1">
                                            <div className="col-6">
                                                <h2 className="card-title text-inherit">{this.state.openJobs}</h2>
                                            </div>
                                            {/*<!-- End Col -->*/}

                                            <div className="col-6">
                                                {/*<!-- Chart -->*/}
                                                <div className="chartjs-custom" style={{ height: '3rem' }}>
                                                </div>
                                                {/*<!-- End Chart -->*/}
                                            </div>
                                            {/*<!-- End Col -->*/}

                                        </div>
                                        {/*<!-- End Row -->*/}

                                        <span className="badge bg-soft-success text-success">
                                            <i className="bi bi-award"></i> {isNaN(this.state.jobCountPer) ? 0 : this.state.jobCountPer} % active jobs
                                        </span>
                                        <span className="text-body fs-6 ms-1">from total {this.state.totalJobCount} jobs</span>
                                    </div>
                                </a>
                                {/*<!-- End Card -->*/}
                            </div>

                            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                                {/*<!-- Card -->*/}
                                <a className="card card-hover-shadow h-100 b-top" href="javascript:void(0);">
                                    <div className="card-body">
                                        <h6 className="card-subtitle f-b-20">Tenant Users</h6>

                                        <div className="row align-items-center gx-2 mb-1">
                                            <div className="col-6">
                                                <h2 className="card-title text-inherit">{this.state.activeUserCount}</h2>
                                            </div>
                                            {/*<!-- End Col -->*/}

                                            <div className="col-6">
                                                {/*<!-- Chart -->*/}
                                                <div className="chartjs-custom" style={{ height: '3rem' }}>

                                                </div>
                                                {/*<!-- End Chart -->*/}
                                            </div>
                                            {/*<!-- End Col -->*/}
                                        </div>
                                        {/*<!-- End Row -->*/}

                                        <span className="badge bg-warning text-body">
                                            <i className="bi bi-people-fill"></i> {isNaN(this.state.activeUserCountPer) ? 0 : this.state.activeUserCountPer} % active users
                                        </span>
                                        <span className="text-body fs-6 ms-1">from {this.state.totalUserCount} users</span>
                                    </div>
                                </a>
                                {/*<!-- End Card -->*/}
                            </div>

                            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                                {/*<!-- Card -->*/}
                                <a className="card card-hover-shadow h-100 b-top" href="javascript:void(0);">
                                    <div className="card-body">
                                        <h6 className="card-subtitle f-b-20">Interviews</h6>


                                        <div className="gx-2 mb-1 mt-2">
                                            <span className="badge bg-secondary text-white">
                                                <i className="bi bi-clipboard-data"></i>
                                                <span className="pl-lg-3"> {this.state.openOnewayInterviewCount} Open Interviews </span></span>

                                        </div>

                                        <div className="gx-2 mb-1">
                                            <span className="badge bg-success text-white">
                                                <i className="bi bi-list-task"></i>
                                                <span className="pl-lg-3"> {this.state.closeOnewayInterviewCount} Closed Interviews </span></span>

                                        </div>

                                        <div className="gx-2 mb-1">
                                            <span className="badge bg-danger text-white">
                                                <i className="bi bi-camera-reels"></i>
                                                <span className="pl-lg-3"> {this.state.liveInterviewCount} Live Interviews</span> </span>
                                        </div>


                                    </div>
                                </a>
                                {/*<!-- End Card -->*/}
                            </div>
                        </div>
                        {/*<!-- End Stats -->*/}

                        <div className="row">

                            {/*<!-- End Col -->*/}

                            <div className="col-lg-6 mb-3 mb-lg-5">
                                {/*<!-- Card -->*/}
                                <div className="card">
                                    {/*<!-- Header -->*/}
                                    <div className="card-header card-header-content-sm-between">
                                        <h4 className="card-header-title mb-2 mb-sm-0">Interviews Conducted Chart</h4>


                                    </div>
                                    {/*<!-- End Header -->*/}

                                    {/*<!-- Body -->*/}
                                    <div className="card-body">

                                        {/*<!-- Bar Chart -->*/}
                                        <div className="chartjs-custom">

                                            <PieChart chartData={this.state.pieChartData} />


                                        </div>
                                        {/*<!-- End Bar Chart -->*/}
                                    </div>
                                    {/*<!-- End Body -->*/}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3 mb-lg-5">
                                <div className="card">
                                    {/*<!-- Header -->*/}
                                    <div className="card-header card-header-content-sm-between">
                                        <h4 className="card-header-title mb-2 mb-sm-0">Avg Hiring Chart</h4>


                                    </div>
                                    {/*<!-- End Header -->*/}

                                    {/*<!-- Body -->*/}
                                    <div className="card-body">

                                        {/*<!-- Bar Chart -->*/}
                                        <div className="chartjs-custom">

                                            <PieChart chartData={this.state.pieChartDataForAvgHiring} />


                                        </div>
                                        {/*<!-- End Bar Chart -->*/}
                                    </div>
                                    {/*<!-- End Body -->*/}
                                </div>
                                {/*<!-- End Card -->*/}
                            </div>
                            {/*<!-- End Col -->*/}
                        </div>
                        {/*<!-- End Row -->*/}



                        <div className="row">

                            {/*<!-- End Col -->*/}

                            <div className="col-lg-12 mb-3 mb-lg-5">
                                {/*<!-- Card -->*/}
                                <div className="card h-100">
                                    {/*<!-- Header -->*/}
                                    <div className="card-header card-header-content-sm-between">
                                        <h4 className="card-header-title mb-2 mb-sm-0">Job Applicant Chart</h4>


                                    </div>
                                    {/*<!-- End Header -->*/}

                                    {/*<!-- Body -->*/}
                                    <div className="card-body">

                                        {/*<!-- Bar Chart -->*/}
                                        <div className="chartjs-custom">
                                            <BarChart chartData={this.state.jobWithNoApplicants} />

                                        </div>
                                        {/*<!-- End Bar Chart -->*/}
                                    </div>
                                    {/*<!-- End Body -->*/}
                                </div>
                                {/*<!-- End Card -->*/}
                            </div>
                            {/*<!-- End Col -->*/}
                        </div>
                        {/*<!-- End Row -->*/}




                        {/*<!-- Card -->*/}
                        <div className="mb-5">
                            <span class="label label-large label-pink bg-secondary text-white arrowed-right">Requested</span>
                            <span class="label label-large label-light arrowed-right bg-black text-white">Rescheduled</span>
                            <span class="label label-large label-yellow bg-info text-white arrowed-right">Accepted</span>
                            <span class="label label-large label-purple bg-warning text-white arrowed-right">Completed</span>
                            <span class="label label-large label-grey bg-primary text-white  arrowed-right">Reviewed</span>
                            <span class="label label-large label-light bg-success text-white">Evaluated


                            </span>


                        </div>
                        {candidateInterviews.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                            <div className="card-body py-10">
                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                <div className="text-center">

                                    <h1>You don't have any recent Interviews</h1>
                                </div>

                            </div>
                        </div> :


                            <div className="card">
                                {/*<!-- Header --> */}
                                <div className="card-header card-header-content-between">
                                    <div className="row justify-content-between align-items-center flex-grow-1">
                                        <div className="col-12 col-md-auto">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h3 className="card-header-title">Latest Interview Invites</h3>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                {/*<!-- End Header --> */}

                                {/*<!-- Table --> */}
                                <div className="table-responsive datatable-custom">

                                    <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                        <thead className="thead-light">
                                            <tr>


                                                <th style={{ width: '0px', paddingRight: '0px' }} > <strong>Applicant Name </strong></th>
                                                <th style={{ width: '0px', paddingRight: '0px' }}><strong>Job Title</strong></th>
                                                <th style={{ width: '0px', paddingRight: '0px' }} ><strong>Invite Status  </strong></th>
                                                <th style={{ width: '0px', paddingRight: '0px' }} ><strong>Interview Status </strong></th>
                                                <th style={{ width: '0px', paddingRight: '0px' }}><strong>Invite Request </strong></th>
                                                <th style={{ width: '0px', paddingRight: '0px' }}><strong>Invite Expiry </strong></th>
                                            </tr>

                                        </thead>

                                        <tbody>
                                            {currentItems.length > 0 && currentItems.map((candidateInterview, index) => (

                                                <tr key={index} style={{ cursor: 'pointer' }} onClick={() => this.handleRowClick(candidateInterview.jobId, candidateInterview.interviewId, candidateInterview.interviewInviteId)}
>
                                                    
                                                    <td style={{ paddingRight: '0px', marginRight: '0px',  }} id={"candidateDetails" + index} data-tip data-for={"candidateDetails" + index}>
                                                      
                                                        <span className="d-block h5 mb-0 truncate"> {candidateInterview.fullName}
   
                                                            </span>
                                                      
                                                        <ReactTooltip id={"candidateDetails" + index} place="top" type="dark" effect="float">
                                                            <span className="d-block fs-5"> {candidateInterview.email}</span>
                                                        </ReactTooltip>
                                                    </td>
                                                    <td style={{ paddingRight: '0px', paddingLeft: '0px', marginRight: '0px', cursor: 'pointer' }} id={"jobTitle" + index} data-tip data-for={"jobTitle" + index}>
                                                        
                                                        <span className="d-block h5 mb-0 truncate"> {candidateInterview.jobTitle}</span>

                                                        <ReactTooltip id={"jobTitle" + index} place="top" type="dark" effect="float">
                                                            <span className="d-block fs-5">Job Id:{candidateInterview.jobId}</span>
                                                            <span className="d-block fs-5">{candidateInterview.jobTitle}</span>
                                                        </ReactTooltip>
                                                    </td>
                                                    <td style={{ paddingRight: '0px', cursor: 'pointer' }}>
                                                       

                                                        {
                                                            candidateInterview.inviteStatus == "Accepted" &&

                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-info text-white">{candidateInterview.inviteStatus}</span>


                                                        }
                                                        {
                                                            candidateInterview.inviteStatus == "Reviewed" &&

                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-primary text-white">{candidateInterview.inviteStatus}</span>
                                                        }
                                                        {
                                                            candidateInterview.inviteStatus == "Requested" &&
                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-secondary text-white">{candidateInterview.inviteStatus}</span>
                                                        }
                                                        {
                                                            candidateInterview.inviteStatus == "Evaluated" &&

                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-success text-white">{candidateInterview.inviteStatus}</span>
                                                        }
                                                        {
                                                            candidateInterview.inviteStatus == "Completed" &&

                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-warning text-dark">{candidateInterview.inviteStatus}</span>
                                                        }
                                                        {
                                                            candidateInterview.inviteStatus == "Rescheduled" &&

                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-dark text-white">{candidateInterview.inviteStatus}</span>
                                                        }
                                                        {
                                                            candidateInterview.inviteStatus == "Expired" &&

                                                            <span style={{ width: '90px' }} class="badge rounded-pill f-size-12 bg-danger text-white">{candidateInterview.inviteStatus}</span>
                                                        }
                                                    </td>
                                                    <td style={{ paddingRight: '0px', cursor: 'pointer' }}>

                                                        
                                                        {
                                                            candidateInterview.evaluationStatus == "Hired" &&
                                                            <label className='badge evaluation-status-badge badge-outline-success ms-1 fw-bold fs-5'>
                                                                <i className="bi bi-check-circle-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='hired'></i> Hired <ReactTooltip id="hired" place="right" type="dark" effect="float">
                                                                    <span> {candidateInterview.evaluationStatus} </span>
                                                                </ReactTooltip> </label>
                                                        }
                                                        {
                                                            candidateInterview.evaluationStatus == "Not Evaluated" &&

                                                            <label className='badge evaluation-status-badge badge-outline-info ms-1 fw-bold fs-5'>
                                                                <i className="bi bi-clock-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='notEvaluated'></i> Not Evaluated <ReactTooltip id="notEvaluated" place="right" type="dark" effect="float">
                                                                    <span> {candidateInterview.evaluationStatus} </span>
                                                                </ReactTooltip>   </label>
                                                        }
                                                        {
                                                            candidateInterview.evaluationStatus == "Failed" &&
                                                            <label className='badge evaluation-status-badge badge-outline-danger ms-1 fw-bold fs-5'>
                                                                <i className="bi bi-x-square-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='rejected'></i> Failed <ReactTooltip id="failed" place="right" type="dark" effect="float">
                                                                    <span> {candidateInterview.evaluationStatus} </span>
                                                                </ReactTooltip></label>
                                                        }
                                                        {
                                                            candidateInterview.evaluationStatus == "Rejected" &&
                                                            <label className='badge evaluation-status-badge badge-outline-danger ms-1 fw-bold fs-5'>
                                                                <i className="bi bi-x-square-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='rejected'></i> Rejected <ReactTooltip id="rejected" place="right" type="dark" effect="float">
                                                                    <span> {candidateInterview.evaluationStatus} </span>
                                                                </ReactTooltip></label>
                                                            }
                                                    </td>
                                                    <td style={{ paddingRight: '0px', cursor: 'pointer' }} >
                                                       
                                                            {candidateInterview.inviteRequestedDate}
                                                    </td>
                                                    <td style={{ paddingRight: '0px', cursor: 'pointer' }}>
                                                        
                                                            {candidateInterview.inviteExpiryDate}
                                                    </td>
                                                        
                                                </tr>


                                            ))}

                                        </tbody>
                                    </table>
                                </div>


                                {/*<!-- End Table --> */}

                                {/*<!-- Footer --> */}
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">

                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel={<i className='bi bi-chevron-right'></i>}
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel={<i className='bi bi-chevron-left'></i>}
                                            renderOnZeroPageCount={null}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                    {/*<!-- End Content -->*/}


                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}
                />

            </>

        );
    }
}
export default withRouter(Dashboard);