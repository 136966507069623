import React from "react";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { login } from '../Auth/oidc';

class BaseComponent extends React.Component
{
    constructor()
    {
        super();
        if (!verifyTokenOnLocal())
            return login();
    }
}

export default BaseComponent;