/* General Utility*/


/**
 * Get query string value like getQueryVariable('aid') //160990
 * @param {any} variable
 */
function getParameterByName(name, url = window.location.href)
{
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Function convert the string parameter to boolean
 * @param {string} strValue
 */
function stringToBoolean(strValue)
{
    if (strValue == null || strValue == undefined)
        return false;

    if (typeof strValue === 'boolean')
        return strValue;
    
    if (typeof str === 'string')
    {
        switch (strValue.toLowerCase().trim())
        {
            case "true":
            case "yes":
            case "1":
                return true;

            case "false":
            case "no":
            case "0":
            case null:
                return false;

            default:
                return Boolean(strValue);
        }
    }

    console.error("Unable to parse value to boolean");
}

/**
 * Get host from email address
 * hostname: tc-bpo.com
 * @param {any} email
 */
function getHostFromEmail(email)
{
    var my_email = email;
    var ind = my_email.indexOf("@");
    var host = my_email.slice((ind + 1), my_email.length);
    return host;
}

/**
 * Validate username
 * @param {any} username
 */
function isValidUsername(username)
{
    const usernameRegex = /^[a-z0-9_.]+$/
    return usernameRegex.test(username)
}

module.exports = { stringToBoolean, getParameterByName, getHostFromEmail, isValidUsername };