import React, { useEffect } from "react";
import { createPopper } from "@popperjs/core";
import imgdescription6 from '../../assets/img/160x160/img6.jpg';
import imgdescription7 from '../../assets/img/160x160/img6.jpg';
import profileImage from "../../assets/img/160x160/img1.jpg";
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import axios from "axios";
import { parseJwt } from '../../modules/TokenParser';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

const NavDropdown = () => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);

    const [notificationcount, setnotificationcount] = React.useState("");
    const [tenantemail, settenantemail] = React.useState("");
    const [tenantusername, settenantusername] = React.useState("");
    const [profileLogo, setProfileLogo] = React.useState(null);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start",
        });
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    useEffect(() => {
        var url = null;
        var subId = null; 
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            subId = parseJwt(bearer).sub;
            url = process.env.REACT_APP_CORE_API + "/api/UserProfileName?userId=" + subId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    settenantusername(response.data.firstName + ' ' + response.data.lastName);
                    settenantemail(response.data.email);
                    if (response.data.profileImageUrl != null) {
                        setProfileLogo(response.data.profileImageUrl);
                    }
                })
                .catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");
                });
        }
    }, [])
    return (
        <> 
            <Link className="navbar-dropdown-account-wrapper"
                to="#"
                ref={btnDropdownRef}
                onClick={(e) => {
                    e.preventDefault();
                    dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                }}>
                <div className="avatar avatar-sm avatar-circle">
                    {profileLogo == "" || profileLogo == null ? <img className="avatar-img" src={profileImage} alt="Image Description" />
                        : <img className="avatar-img" src={profileLogo} width="100" height="100" alt="Logo" data-hs-theme-appearance="default" />
                    }
                    <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                </div>
            </Link>

            <div ref={popoverDropdownRef}
                className="dropdown" >

                <div
                    className={(dropdownPopoverShow ? "show " : "hidden ") + "dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account "} aria-labelledby="accountNavbarDropdown" style={{ width: "16rem", marginLeft: '-12rem' }} >
                    <div className="dropdown-item-text">
                        <div className="d-flex align-items-center">
                            <div className="avatar avatar-sm avatar-circle">
                                {profileLogo == "" || profileLogo == null ? <img className="avatar-img" src={profileImage} alt="Image Description" />
                                    : <img className="avatar-img" src={profileLogo} width="100" height="100" alt="Logo" data-hs-theme-appearance="default" />
                                }
                            </div>
                            <div className="flex-grow-1 ms-3 nav-username">
                                <h5 className="mb-0">{tenantusername}</h5>
                                <p className="card-text text-body">{tenantemail}</p>
                            </div>
                        </div>
                    </div>

                    <div className="dropdown-divider"></div>

                    <Link className="dropdown-item" to="/userprofile" onClick={closeDropdownPopover}>Settings</Link>


                    <div className="dropdown-divider"></div>

                    <a className="dropdown-item" href="/logout">Sign out</a>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ width: '35%' }}

            />
        </>
    );
};

export default NavDropdown;