
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseJwt, parseToken } from '../../modules/TokenParser';
import axios from "axios";
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import CompanyLogo from '../../assets/svg/brands/codingkey-icon.svg';
import { CheckPermission } from '../../modules/CheckPermission';
import ReactTooltip from 'react-tooltip';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import ReactPaginate from 'react-paginate';

class CandidateInterviews extends React.Component {

    constructor(props) {
        super(props);

        this.state =
        {
            candidateInterviewInvites: [ ],
            isloadedgetVideos: false,
            isloadedgetCandidateDetails: true,
            isloadedgetTenantUserGroups: true,
            isloadedgetQuestionSets: true,
            editJobPermission: true,
            createJobPermission: true,
            createInterviewPermission: true,
            candidateFiles: [],
            name: "",
            candidateEmail: "",
            itemOffset: 0,
            phoneNumber:"",

        };



    }

    componentDidMount() {
        document.title = "Tenant App - Candidates";

        this.getCandidateInterviews(this.props.match.params.userId);
    }
  
    /** Get interview details of candidates */
    getCandidateInterviews(userId) {
        let bearer = validateAndGetFormatedToken();
        let holder = [];

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/CandidateInterviewInviteInfo";
            axios(
                url,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': bearer,
                        'accept': '*/*',
                        'Content-Type': 'application/json-patch+json'
                    },
                    data: { applicationUserId: userId },
                    async: false
                })
                .then(response => {
                    this.setState({
                        candidateInterviewInvites: response.data,
                        candidateEmail: response.data[0] == null ? " " : response.data[0].candidateEmail,
                        phoneNumber: response.data[0] == null ? " " : response.data[0].phoneNumber,
                      
                        name: response.data[0] == null ? " " : response.data[0].firstName + ' ' + response.data[0].lastName
                    });
                })



                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Candidate details!', "top-center");
                });

            console.log("holder length is " + holder.length);


        }
    }


    render() {
        //for pagination
        const endOffset = this.state.itemOffset + 10;
        const candidateInterviewInvitesItems = this.state.candidateInterviewInvites != null ? this.state.candidateInterviewInvites.slice(this.state.itemOffset, endOffset) : [];
        const pageCount = this.state.candidateInterviewInvites != null ? Math.ceil(this.state.candidateInterviewInvites.length / 10) : 0;

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.candidateInterviewInvites.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            this.setState({ itemOffset: newOffset });
        };
        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    {/* <!-- Breadcrumbs --> */}
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                            <li className="breadcrumb-item"><Link to="/candidates">Candidates</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Candidate Interviews</li>
                                        </ol>
                                    </nav>
                                    {/* <!-- End Breadcrumbs --> */}
                                    <h1 className="page-header-title">Candidate Interviews</h1>
                                   
                                </div>

                            </div>
                            {/* <!-- End Row --> */}
                        </div>
                        {/* <!-- End Page Header --> */}


                        {/* <!-- Content --> */}

                        <div className="card d-none d-lg-block">
                            {/*<!-- Header --> */}
                            <div className="card-header card-header-content-between">
                                <div className="row justify-content-between align-items-center flex-grow-1">
                                    <div className="col-12 col-md-auto">
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <h5 className="f-size-18 f-weight-600 card-header-title ">{this.state.name}</h5>

                                        </div>
                                        <div className="row ">
                                            <p className="f-size-12  f-weight-600 f-gray">{this.state.candidateEmail}  |  {this.state.phoneNumber}</p>
                                        </div>
                                       

                                    </div>

                                </div>
                            </div>

                            {/*<!-- End Header --> */}

                            {/* <!-- Table --> */}
                            <div className="table-responsive datatable-custom w-100">
                                <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                    <thead className="thead-light">
                                        <tr className="f-weight-600">
                                          
                                            <th style={{ width: "0px" }}
                                            >
                                                <strong>Job Title  </strong>
                                            </th>
                                            <th style={{ width: "0px" }}
                                            >
                                                <strong>Interview Type </strong>
                                                
                                            </th>
                                            <th  style={{ width: "0px" }}>
                                                <strong>Invitation Date</strong>
                                            </th>

                                            <th style={{ width: "0px" }}>
                                                 <strong>Invitation Expiry</strong>
                                            </th>
                                            <th style={{ width: "0px" }}>
                                                <strong>Interview Status</strong>
                                            </th>
                                            <th style={{ width: "0px" }}>
                                                <strong>Evaluation Status</strong>
                                            </th>
                                            <th style={{ width: "0px" }}>
                                                <strong>Offer Letter</strong>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            candidateInterviewInvitesItems.map((candidateInterviewInvite, index) => {
                                                return (

                                                    <tr key={candidateInterviewInvite.interviewInviteId}>
                                                       
                                                        <td >
                                                            <span className="d-block h5 mb-0 truncate inter-ff" id={"jobName" + index} data-tip data-for={"jobName" + index}>

                                                                {candidateInterviewInvite.jobName}
                                                                <ReactTooltip id={"jobName" + index} place="top" type="dark" effect="float">
                                                                    <span className="d-block fs-5">{candidateInterviewInvite.jobName}</span>
                                                                </ReactTooltip>
                                                            </span>
                                                            <span className="inter-ff">{candidateInterviewInvite.industry}</span>

                                                        </td>

                                                        <td >
                                                            <span className="d-block h5 mb-0 inter-ff">
                                                                {candidateInterviewInvite.interviewType}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  mb-0 inter-ff f-gray">
                                                                {convertUTCDateToBrowserLocale(candidateInterviewInvite.inviteRequestedDate)}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span className="d-block  mb-0 inter-ff f-gray">
                                                                {convertUTCDateToBrowserLocale(candidateInterviewInvite.inviteExpiredDate)}
                                                            </span>
                                                        </td>

                                                        <td className="interview-status-badge">
                                                            {
                                                                candidateInterviewInvite.inviteStatus == "Accepted" &&
                                                               
                                                                <span class="badge  rounded-pill f-size-12 f-weight-500 badge-base-accepted  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                            }
                                                            {
                                                                candidateInterviewInvite.inviteStatus == "Reviewed" &&
                                                               
                                                                <span class="badge rounded-pill f-size-12 f-weight-500 badge-base-reviewed  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                            }
                                                            {
                                                                candidateInterviewInvite.inviteStatus == "Requested" &&
                                                               
                                                                <span class="badge rounded-pill f-size-12 f-weight-500 badge-base-requested  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                            }
                                                            {
                                                                candidateInterviewInvite.inviteStatus == "Evaluated" &&
                                                                
                                                                <span class="badge rounded-pill  f-size-12 f-weight-500 badge-base-evaluated  inter-ff " >{candidateInterviewInvite.inviteStatus}</span>
                                                            }
                                                            {
                                                                candidateInterviewInvite.inviteStatus == "Completed" &&
                                                                
                                                                <span class="badge rounded-pill  f-size-12 f-weight-500   inter-ff badge-base-completed ">{candidateInterviewInvite.inviteStatus}</span>
                                                            }
                                                            {
                                                                candidateInterviewInvite.inviteStatus == "Rescheduled" &&
                                                              
                                                                <span class="badge rounded-pill  f-size-12 f-weight-500 badge-base-rescheduled  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                            }


                                                        </td>

                                                        <td style={{ textAlign: 'left' }}>
                                                            {
                                                                candidateInterviewInvite.evaluationStatus == "Hired" &&
                                                                <label className='badge evaluation-status-badge badge-outline-success ms-1 fw-bold fs-5'>
                                                                    <i class="bi bi-check-circle-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='hired'></i> Hired <ReactTooltip id="hired" place="top" type="dark" effect="float">
                                                                        <span> {candidateInterviewInvite.evaluationStatus} </span>
                                                                    </ReactTooltip> </label>
                                                            }
                                                            {
                                                                candidateInterviewInvite.evaluationStatus == "Not Evaluated" &&

                                                                <label className='badge evaluation-status-badge badge-outline-info ms-1 fw-bold fs-5'>
                                                                    <i class="bi bi-clock-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='notEvaluated'></i> Not Evaluated <ReactTooltip id="notEvaluated" place="top" type="dark" effect="float">
                                                                        <span> {candidateInterviewInvite.evaluationStatus} </span>
                                                                    </ReactTooltip>   </label>
                                                            }
                                                            {
                                                                candidateInterviewInvite.evaluationStatus == "Failed" &&
                                                                <label className='badge evaluation-status-badge badge-outline-danger ms-1 fw-bold fs-5'>
                                                                    <i className="bi bi-x-square-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='rejected'></i> Failed <ReactTooltip id="failed" place="top" type="dark" effect="float">
                                                                        <span> {candidateInterviewInvite.evaluationStatus} </span>
                                                                    </ReactTooltip></label>
                                                            }
                                                            {
                                                                candidateInterviewInvite.evaluationStatus == "Rejected" &&
                                                                <label className='badge evaluation-status-badge badge-outline-danger ms-1 fw-bold fs-5'>
                                                                    <i className="bi bi-x-square-fill me-1" style={{ cursor: 'pointer' }} data-tip data-for='rejected'></i> Rejected <ReactTooltip id="rejected" place="top" type="dark" effect="float">
                                                                        <span> {candidateInterviewInvite.evaluationStatus} </span>
                                                                    </ReactTooltip></label>
                                                            }
                                                        </td>


                                                        <td>

                                                            {candidateInterviewInvite.offerLetterUrl == null ? '' : <a id="requested" className="btn btn-outline-primary btn-icon btn-sm me-2" type="button" style={{ marginLeft: '1%' }}
                                                                href={candidateInterviewInvite.offerLetterUrl} target="_blank" ><span class="material-symbols-outlined f-size-20" data-tip data-for='requested'>
                                                                    cloud_download
                                                                </span> 
                                                                <ReactTooltip id="requested" place="top" type="dark" effect="float">
                                                                    <span> View requested/sent offer letter </span>
                                                                </ReactTooltip></a>}

                                                            {candidateInterviewInvite.offerLetterUrl == null ? '' : <Link id="offerletter" className="btn btn-outline-primary btn-icon btn-sm me-2" style={{ marginLeft: '1%' }}
                                                                to={'/OfferLetterDetail/' + candidateInterviewInvite.interviewInviteId}>   <i className="bi bi-box-arrow-up-right" data-tip data-for='offerletter'></i>
                                                                <ReactTooltip id="offerletter" place="top" type="dark" effect="float">
                                                                    <span> Candidate offer letter details </span>
                                                                </ReactTooltip></Link>}
                                                        </td>

                                                    </tr>

                                                )

                                            })
                                        }

                                    </tbody>
                                </table>

                            </div>

                            {/*<!-- End Table --> */}

                            {/*<!-- Footer --> */}
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={<i className='bi bi-chevron-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={<i className='bi bi-chevron-left'></i>}
                                    renderOnZeroPageCount={null}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}

                                >
                                </ReactPaginate>
                                </div>
                            </div>
                            {/*<!-- End Footer --> */}
                        </div>

                        {/*Mobile Start Here*/}
                        <div className="row justify-content-center align-items-center g-0 p-0 mt-0 d-lg-none">
                            <div className="col-md-12 d-flex d-lg-none mb-2" style={{ paddingRight: '8px' }}>
                                
                            </div>
                            <div className=" col-md-12">
                                <div className="card border-0 jc-radius pb-3">
                                    <table className="table jobs-overview-table">
                                        <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                            <tr>
                                                <th scope="col " className="p-3" colSpan={3}>
                                                    <p className="f-size-16 f-weight-600 mb-0">  {this.state.name}</p>
                                                    <p className="f-size-12 f-weight-600 mb-0 f-gray">{this.state.candidateEmail} | {this.state.phoneNumber}</p>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {candidateInterviewInvitesItems.map((candidateInterviewInvite, index) => (
                                                <tr key={candidateInterviewInvite.interviewInviteId}>
                                                    <td className="p-3">
                                                        <div className="d-flex align-items-center mb-0">
                                                            <div className="col d-flex">
                                                                <div>
                                                                    <div className="d-block ">

                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div>
                                                                        <h2 className="inter-ff f-size-14 f-weight-500 mb-0">{candidateInterviewInvite.jobName}</h2>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                            <div className="col-3  mb-2 align-middle d-flex gap-0 justify-content-end">
                                                               

                                                                <div className="col-3  align-middle d-flex gap-0 justify-content-end">

                                                                    {candidateInterviewInvite.offerLetterUrl == null ? '' : <a id="requested" className="btn btn-outline-primary btn-icon btn-sm me-2" type="button" style={{ marginLeft: '1%' }}
                                                                        href={candidateInterviewInvite.offerLetterUrl} target="_blank" ><span class="material-symbols-outlined f-size-20" data-tip data-for='requested'>
                                                                            cloud_download
                                                                        </span> 
                                                                        <ReactTooltip id="requested" place="top" type="dark" effect="float">
                                                                            <span> View requested/sent offer letter </span>
                                                                        </ReactTooltip></a>}

                                                                    {candidateInterviewInvite.offerLetterUrl == null ? '' : <Link id="offerletter" className="btn btn-outline-primary btn-icon btn-sm me-2" style={{ marginLeft: '1%' }}
                                                                        to={'/OfferLetterDetail/' + candidateInterviewInvite.interviewInviteId}>   <i className="bi bi-box-arrow-up-right" data-tip data-for='offerletter'></i>
                                                                        <ReactTooltip id="offerletter" place="top" type="dark" effect="float">
                                                                            <span> Candidate offer letter details </span>
                                                                        </ReactTooltip></Link>}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  mb-1">
                                                            <div className="col-6 d-flex align-items-center ">
                                                                <span class="material-symbols-outlined f-size-12">
                                                                    note_alt
                                                                </span>
                                                                <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter mx-1">
                                                                    {candidateInterviewInvite.interviewType}
                                                                </p>
                                                            </div>
                                                            <div className="col-6 d-flex align-items-center ">
                                                                <span class="material-symbols-outlined f-size-12">
                                                                    mark_email_read
                                                                </span>
                                                                <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter text-overflow-leads mx-1">
                                                                    {convertUTCDateToBrowserLocale(candidateInterviewInvite.inviteRequestedDate)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="col-6 d-flex align-items-center ">
                                                                <span class="material-symbols-outlined f-size-12">
                                                                    event_busy
                                                                </span>
                                                                <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                    {convertUTCDateToBrowserLocale(candidateInterviewInvite.inviteExpiredDate)}
                                                                </p>
                                                            </div>
                                                           

                                                        </div>
                                                        <div className="d-flex mt-1 ">
                                                            <div className="interview-status-badge-mb col-6">
                                                               
                                                                {
                                                                    candidateInterviewInvite.inviteStatus == "Accepted" &&

                                                                    <span class="badge  rounded-pill f-size-10 f-weight-500 badge-base-accepted  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.inviteStatus == "Reviewed" &&

                                                                    <span class="badge rounded-pill f-size-10 f-weight-500 badge-base-reviewed  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.inviteStatus == "Requested" &&

                                                                    <span class="badge rounded-pill f-size-10 f-weight-500 badge-base-requested  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.inviteStatus == "Evaluated" &&

                                                                    <span class="badge rounded-pill  f-size-10 f-weight-500 badge-base-evaluated  inter-ff " >{candidateInterviewInvite.inviteStatus}</span>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.inviteStatus == "Completed" &&

                                                                    <span class="badge rounded-pill  f-size-10 f-weight-500   inter-ff badge-base-completed ">{candidateInterviewInvite.inviteStatus}</span>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.inviteStatus == "Rescheduled" &&

                                                                    <span class="badge rounded-pill  f-size-10 f-weight-500 badge-base-rescheduled  inter-ff ">{candidateInterviewInvite.inviteStatus}</span>
                                                                }

                                                            </div>

                                                            <div className="col-6">
                                                                {
                                                                    candidateInterviewInvite.evaluationStatus == "Hired" &&
                                                                    <label className='badge evaluation-status-badge badge-outline-success-mb ms-1 fw-bold fs-5'>
                                                                        <i class="bi bi-check-circle-fill me-1" style={{ cursor: 'pointer' }} ></i> Hired  </label>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.evaluationStatus == "Not Evaluated" &&

                                                                    <label className='badge evaluation-status-badge badge-outline-info-mb ms-1  '>
                                                                        <i class="bi bi-clock-fill me-1" style={{ cursor: 'pointer' }} data-tip ></i> Not Evaluated   </label>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.evaluationStatus == "Failed" &&
                                                                    <label className='badge evaluation-status-badge badge-outline-danger-mb ms-1 fw-bold fs-5'>
                                                                        <i className="bi bi-x-square-fill me-1" style={{ cursor: 'pointer' }} ></i> Failed </label>
                                                                }
                                                                {
                                                                    candidateInterviewInvite.evaluationStatus == "Rejected" &&
                                                                    <label className='badge evaluation-status-badge badge-outline-danger-mb ms-1 fw-bold fs-5'>
                                                                        <i className="bi bi-x-square-fill me-1" style={{ cursor: 'pointer' }} ></i> Rejected </label>
                                                                }
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                       
                                                        
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {/*<!-- Footer --> */}
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-center">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCount}
                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                renderOnZeroPageCount={null}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link'}
                                                containerClassName={'pagination'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                activeClassName={'active'}

                                            >
                                            </ReactPaginate>
                                        </div>
                                    </div>
                                    {/*<!-- End Footer --> */}
                                </div>

                            </div>
                        </div>
                        {/*Mobile End Here*/}





                    </div>
                    {/* <!-- End Content --> */}


                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}

export default CandidateInterviews;

