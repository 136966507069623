import React from 'react';
import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';

import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage } from '../../modules/ToastMessage';
import { parseJwt, parseToken } from '../../modules/TokenParser';
import axios from "axios";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { CheckPermission } from '../../modules/CheckPermission';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import Applicants from "../Leads/Applicants"
import JobInterviewsComponent from "../Interviews/JobInterviewsComponent"
import Highcharts, { Tooltip } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class ViewJob extends BaseComponent {

    constructor(props) {
        super(props);

        this.state =
        {
            title: "",
            tenantName: "",
            totalInterviews: "",
            description: "",
            location: "",
            jobManagerEmail: null,
            jobManager: null,
            jobManagerFirstName: null,
            jobManagerLastName: null,

            jobVisibilityLevel: "everyone",
            jobSource: "",
            usergroupid: null,

            introVideoUrl: "",
            outroVideoUrl: "",
            isActive: false,
            publishingStatus: "",
            tenantId: 0,
            createdBy: '',

            //Version 2
            jobType: "",
            industry: "",
            country: "",
            state: "",
            postalCode: "",
            salary: "",
            workExperience: "",

            phoneqconstraint: "required",
            cvqconstraint: "required",
            specificusers: "",


            AddIntroVideo: false,
            AddOutroVideo: false,
            IntroVideos: [],
            OutroVideos: [],
            isUserCreated: false,
            editorState: "",
            //For Dropdown control
            tenantUsersList: [],
            tenantUserGroups: [],
            defaultQuestionSet: -1,

            salaryType: "",
            salaryFrequencyType: "",
            maxSalary: 0,
            minSalary: 0,
            vacantPosition: "",
            skills: "",

            jobBonuses: [],
            questionSets: [],
            isloadedgetVideos: false,
            isloadedgetTenantUsers: true,
            isloadedgetTenantUserGroups: true,
            isloadedgetQuestionSets: true,
            editJobPermission: true,
            createJobPermission: true,
            createInterviewPermission: true,
            sfc_AllowedJobsCount: 0,
            selectedWorkspaceNatureType: "",
            workspaceNatureTypes: [],
            forFemaleOnly: "",
            isAutoInterviewSchedule: "",
            activeTab: "details-tab-pane",
            activeTabLink: "",

            InterviewsByjobList: {},
            OpeninterviewList: {},
            ClosedinterviewList: {},
            LiveinterviewList: {},
            InterviewPerType: {},
            BarChartForInterviewType: {},
            InterviewTypeList: [],
            isInterviewType: 0,
            LeadsByJobCount: 0,
            LeadsByJobList: {},
            isLeadsByjobPopUp: 0,
            LeadsByjobPopUpList: [],
            selectedLeadsByjobPopUpLabel: "",
            applicantsLoaded: false,
            interviewsLoaded: false,
            detailsLoaded:false,
        };



    }


    async componentDidMount() {
        if (verifyTokenOnLocal()) {
            await isActiveAccessToken();

            document.title = "Tenant App - Jobs";
            document.body.classList.remove('modal-open');
            this.handleActiveTabStatusFromSessionStorage()
            this.getTenantSubscriptionid();
            this.getWorkspaceNatureTypes();
            this.getJobBonuses(this.props.match.params.jobId);
            this.checkEditJobPermission();
            this.checkCreateInterviewPermission();
            
        }
    }


    /**
  * WorkspaceNatureTypes from look up*/
    getWorkspaceNatureTypes() {
        console.log("in workSpaceNatureTypes")
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let workSpaceNatureTypes = null;
        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/WorkSpaceNatureTypesLookup";
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data != null) {
                        workSpaceNatureTypes = response.data;
                    }
                    else
                        console.error("Unale to get Web Space Nature");
                })
                .then(() => {
                    this.getSalaryFrequencylookup(workSpaceNatureTypes)
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Web Space Nature lookup!', "top-center");

                });
        }

    }

    /**
       * Salary Frequency from look up*/
    getSalaryFrequencylookup(workSpaceNatureTypes) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let salaryFrequencies = null;
        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/SalaryFrequencyLookup";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    salaryFrequencies = response.data;
                })
                .then(() => {
                    this.getJobTypesLookup(workSpaceNatureTypes, salaryFrequencies);
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  salary frequency lookup!', "top-center");

                });
        }

    }

    /**
    * Get job types look up*/
    getJobTypesLookup(workSpaceNatureTypes, salaryFrequencies) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let jobTypes = null;
        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobTypesLookup";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    jobTypes = response.data;
                })
                .then(() => {
                    this.getJob(this.props.match.params.jobId, workSpaceNatureTypes, salaryFrequencies, jobTypes);

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Job Type lookup!', "top-center");

                });
        }

    }

    /**
     * get job by Id
     * @param {jobId} event
     */

    getJob(jobId, workSpaceNatureTypes, salaryFrequencies, jobTypes) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/Jobs/" + jobId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then((response) => response.json())
                .then((result) => {
                    if (result != null || result != undefined) {
                        Object.keys(workSpaceNatureTypes).forEach((key) => {
                            if (result.workspaceNatureType == workSpaceNatureTypes[key]) {
                                result.workspaceNatureType = key;
                            }
                        });
                        Object.keys(salaryFrequencies).forEach((key) => {
                            if (result.salaryFrequencyType == salaryFrequencies[key]) {
                                result.salaryFrequencyType = key;
                            }
                        });
                        Object.keys(jobTypes).forEach((key) => {
                            if (result.jobType == jobTypes[key]) {
                                result.jobType = key;
                            }
                        });
                        this.setState({
                            title: result.title,
                            description: result.description,
                            location: result.location,
                            jobManager: result.jobManager,
                            jobVisibilityLevel: result.jobVisibilityLevel.toString().charAt(0).toUpperCase() + result.jobVisibilityLevel.slice(1),
                            jobSource: result.jobSource,
                            introVideoUrl: result.introVideoUrl,
                            outroVideoUrl: result.outroVideoUrl,
                            JobId: result.jobId,
                            createdBy: result.createdBy,
                            createdDate: result.createdDate,
                            defaultQuestionSet: result.defaultQuestionSet,
                            isActive: result.isActive ? "Active" : "Inactive",
                            isAutoInterviewSchedule: result.isAutoInterviewSchedule ? "Yes" : "No",
                            publishingStatus: result.status.toString().charAt(0).toUpperCase() + result.status.slice(1),
                            tenantId: result.tenantId,
                            userGroupId: result.userGroupId,
                            visibleToAll: result.visibleToAll,
                            tenantName: result.tenantName,
                            totalInterviews: result.totalInterviews,
                            jobType: result.jobType,
                            industry: result.industry,
                            country: result.country,
                            state: result.state,
                            postalCode: result.postalCode,
                            salary: result.salary,
                            workExperience: result.workExperience,
                            salaryType: result.salaryType.toString(),
                            salaryFrequencyType: result.salaryFrequencyType,
                            maxSalary: result.maxSalary,
                            minSalary: result.minSalary,
                            vacantPosition: result.vacantPosition,
                            skills: result.skills,
                            selectedWorkspaceNatureType: result.workspaceNatureType,
                            forFemaleOnly: result.isForFemaleOnly == true ? "Yes" : "No",
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Job details!', "top-center");

                });
        }
    }


    /**
   * get job bonus by Id
   * @param {jobId} event
   */

    getJobBonuses(jobId) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobBonus/" + jobId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then((response) => response.json())
                .then((result) => {
                    console.log("result from view job bonus", result);
                    this.setState({
                        jobBonuses: result
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Job details!', "top-center");

                });
        }
    }

    getInterviewByjob(jobId) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Interview/" + jobId;
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(result => {
                    console.log("getInterviewByjob : ");
                    console.log(result);
                    let Openinterview = [];
                    let Closedinterview = [];
                    let Liveinterview = [];
                    let InterviewListByType = [];
                    if (result.length > 0) {

                        for (var i = 0; i < result.length; i++) {
                            if (result[i].interviewType === "onewayopen") {
                                Openinterview.push({
                                    interviewType: result[i].interviewType,
                                    interviewId: result[i].interviewId,
                                    interviewLevel: result[i].interviewLevel,
                                    jobName: result[i].jobName,
                                    jobId: result[i].jobId,

                                });
                            }
                            else if (result[i].interviewType === "oneway") {
                                Closedinterview.push({
                                    interviewType: result[i].interviewType,
                                    interviewId: result[i].interviewId,
                                    interviewLevel: result[i].interviewLevel,
                                    jobName: result[i].jobName,
                                    jobId: result[i].jobId,

                                });
                            }
                            else if (result[i].interviewType === "live") {
                                Liveinterview.push({
                                    interviewType: result[i].interviewType,
                                    interviewId: result[i].interviewId,
                                    interviewLevel: result[i].interviewLevel,
                                    jobName: result[i].jobName,
                                    jobId: result[i].jobId,

                                });
                            }
                        }
                        InterviewListByType.push({
                            name: "Open",
                            y: Openinterview.length,
                            drilldown: "Open",
                        });
                        InterviewListByType.push({
                            name: "Closed",
                            y: Closedinterview.length,
                            drilldown: "Closed",
                        });
                        InterviewListByType.push({
                            name: "Live",
                            y: Liveinterview.length,
                            drilldown: "Live",
                        });

                        this.setState({
                            InterviewsByjobList: result,
                            OpeninterviewList: Openinterview,
                            ClosedinterviewList: Closedinterview,
                            LiveinterviewList: Liveinterview,
                            InterviewPerType: {
                                series: [{
                                    name: "Interview Type",
                                    colorByPoint: true,
                                    data: InterviewListByType
                                }],
                                title: "Interview count by type for job " + result[0].jobName,
                                xaxistitle: "Interview Type",
                                yaxistitle: "Interviews"
                            },
                        });

                        this.setBarChartForInterviewPerType(this.state.InterviewPerType);
                    }
                    else {
                        InterviewListByType.push({
                            name: "Open",
                            y: 0,
                            drilldown: "Open",
                        });
                        InterviewListByType.push({
                            name: "Closed",
                            y: 0,
                            drilldown: "Closed",
                        });
                        InterviewListByType.push({
                            name: "Live",
                            y: 0,
                            drilldown: "Live",
                        });

                        this.setState({
                            InterviewPerType: {
                                series: [{
                                    name: "Interview Type",
                                    colorByPoint: true,
                                    data: InterviewListByType
                                }],
                                title: "Interview count by type for job ",
                                xaxistitle: "Interview Type",
                                yaxistitle: "Interviews"
                            },
                        });

                        this.setBarChartForInterviewPerType(this.state.InterviewPerType);
                    }
                   
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching InterviewsByJob!', "top-center");
                });
        }
        else
            return;
    };

    setBarChartForInterviewPerType(InterviewPerType) {
        this.setState({
            BarChartForInterviewType: {
                chart: {
                    type: 'column'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: InterviewPerType.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: InterviewPerType.xaxistitle

                    }
                },
                yAxis: {
                    title: {
                        text: InterviewPerType.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: InterviewPerType.series,
                drilldown: InterviewPerType.drilldown,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleInterviewsColumnClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }

    handleInterviewsColumnClick = (event) => {
        if (event.point.y < 1) {
            return;
        }
        //console.log(event.point.drilldown);
        if (event.point.drilldown === "Closed") {
            this.setState({
                isInterviewType: 1,
                InterviewTypeList: this.state.ClosedinterviewList,
                selectedInterviewTypePopUpLabel : " Interviews List for Type " + event.point.drilldown
            });
        }
        if (event.point.drilldown === "Open")
        {
            this.setState({
                isInterviewType: 1,
                InterviewTypeList: this.state.OpeninterviewList,
                selectedInterviewTypePopUpLabel: " Interviews List for Type " + event.point.drilldown
            });
        }
        if (event.point.drilldown === "Live")
        {
            this.setState({
                isInterviewType: 1,
                InterviewTypeList: this.state.LiveinterviewList,
                selectedInterviewTypePopUpLabel: " Interviews List for Type " + event.point.drilldown
            });
        }
       
    }

    async getLeadsByjob(jobId) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/LeadsTracking/" + jobId;
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(result => {
                    console.log("Candidates ");
                    console.log(result);
                    this.setState({
                        LeadsByJobCount : result.length,
                        LeadsByJobList: result
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Candidates By Job!', "top-center");
                });
        }
        else
            return;
    };

    LeadsByjobPopUp(PopupType) {
        if (this.state.LeadsByJobCount < 1) {
            return;
        }
        
            this.setState({
                isLeadsByjobPopUp: 1,
                LeadsByjobPopUpList: this.state.LeadsByJobList,
                selectedLeadsByjobPopUpLabel: " Candidates List ",
            });
       
        
    }


    /** Check Permission for creating interview and edit job */

    checkEditJobPermission() {
        let bearer = validateAndGetFormatedToken();
        var editJobPermission = false;
        var createJobPermission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                createJobPermission = CheckPermission(parseToken(bearer).roles, "Permissions.Job.Create", bearer);
                this.setState({ createJobPermission: createJobPermission });

                editJobPermission = CheckPermission(parseToken(bearer).roles, "Permissions.Job.Edit", bearer);
                this.setState({ editJobPermission: editJobPermission });

            }
        }
    }
    checkCreateInterviewPermission() {
        let bearer = validateAndGetFormatedToken();
        var permission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                permission = CheckPermission(parseToken(bearer).roles, "Permissions.Interview.Create", bearer);
                this.setState({ createInterviewPermission: permission });
            }
        }
    }

    /**
    * Get the tenant subscription id 
    */
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Jobs");
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }

    /**
     * Get the tenant subscriotion feature key value 
     * @param {number} tenantSubscriptionId
     * @param {string} featureKey
     */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let tenantSubscriptionData = response.data;
                    let jobsCount = parseInt(tenantSubscriptionData.value);
                    this.setState({
                        sfc_AllowedJobsCount: jobsCount
                    });
                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }
    //function to handle tab click
    handleTabClick = (tab) => {
        this.setState({ activeTab: tab })

    }
    //function to hanle active tab status from the session storage
    handleActiveTabStatusFromSessionStorage = () => {
        const activeTabLink = sessionStorage.getItem('activeTabLink');

        if (!activeTabLink) {
            this.setState({ activeTabLink: "details" });
        } else {
            switch (activeTabLink) {
                case "details":
                    this.setState({ activeTabLink: activeTabLink, detailsLoaded:true });
                    break;
                case "applicants":
                    this.setState({ activeTabLink: activeTabLink, applicantsLoaded: true });
                    break;
                case "interviews":
                    this.setState({ activeTabLink: activeTabLink, interviewsLoaded:true });
                    break; 
                case "reports":
                    this.setState({ activeTabLink: activeTabLink });
                    this.getInterviewByjob(this.props.match.params.jobId);
                    this.getLeadsByjob(this.props.match.params.jobId);
                    break; 
                default:
                    this.setState({ activeTabLink: activeTabLink });
            }
        }
    }

   


    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    {/* <!-- Content --> */}
                    <div className="content container-fluid">



                        <>
                            {/* Desktop start here */}



                            <div className="" >
                                <div className="">

                                    <div className="row justify-content-center align-items-center g-0 d-none d-lg-block  ">
                                        <div className="col-md-12 ">
                                            <div className="g">
                                                <div className="card border-0 jc-radius" >
                                                    <div className="row g-0 p-4 pb-0">
                                                        <div className="col-md-12 mb-4" >
                                                            <div className="row g-0 pb-0">
                                                                <div className="col-md-12">
                                                                    <div className="d-flex  align-items-center">
                                                                        <h1 className="f-size-18 f-weight-600">
                                                                            <i class="bi bi-briefcase me-2 job-title-icon-bg"></i>
                                                                            <span className="me-2">
                                                                                {this.state.title}

                                                                            </span>
                                                                            {this.state.isActive === "Active" ? (
                                                                                <>
                                                                                    <i
                                                                                        className="bi bi-circle-fill text-success me-2 f-size-14"
                                                                                        data-tip
                                                                                        data-for="active-job"
                                                                                    />
                                                                                    <ReactTooltip id="active-job" place="top" type="dark" effect="float">
                                                                                        <span>Job is Active</span>
                                                                                    </ReactTooltip>
                                                                                </>
                                                                            ) : (
                                                                                    <>
                                                                                        <i className="bi bi-circle-fill text-danger me-2 f-size-14"
                                                                                            data-tip
                                                                                            data-for="inactive-job"/>
                                                                                        <ReactTooltip id="inactive-job" place="top" type="dark" effect="float">
                                                                                            <span>Job is Inactive</span>
                                                                                        </ReactTooltip>
                                                                                </>
                                                                                 
                                                                            )}
                                                                            

                                                                        </h1>
                                                                        <div className="ms-auto">

                                                                            {this.state.editJobPermission &&

                                                                                <Link to={`/updatejob/${this.state.JobId}`} className="btn btn-primary border-0 px-4 py-2 mx-2" style={{ backgroundColor: "#3FB5C3" }}> <i class="bi bi-pencil-square"></i> Edit Job</Link>
                                                                            }

                                                                            {this.state.createInterviewPermission && this.state.isActive === "Active" &&

                                                                                <Link to={{
                                                                                    pathname: "/createinterview/" + this.state.JobId,
                                                                                }} className="btn btn-primary border-0  px-4 py-2" > <span
                                                                                    className="material-symbols-outlined"
                                                                                    style={{ verticalAlign: "middle", fontSize: 16 }}
                                                                                >
                                                                                        add_circle
                                                                                    </span> Create Interview</Link>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="  " style={{ position: 'relative' }} >

                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <ul
                                                                            className="nav nav-tabs job-tabs-view"
                                                                            id="myTab"
                                                                            role="tablist"
                                                                        >
                                                                            <li
                                                                                className="nav-item "
                                                                                role="presentation"
                                                                            >
                                                                                <button
                                                                                    className={`${this.state.activeTabLink === "details" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                                                    id="details-tab"
                                                                                    data-bs-toggle="tab"
                                                                                    data-bs-target="#details-tab-pane"
                                                                                    type="button"
                                                                                    role="tab"
                                                                                    aria-controls="details-tab-pane"
                                                                                    aria-selected="true"
                                                                                    onClick={() => {
                                                                                        this.setState({ activeTabLink: "details", detailsLoaded:true, })
                                                                                        sessionStorage.setItem('activeTabLink', "details");

                                                                                    }}
                                                                                > <i class="bi bi-file-text me-1"></i>

                                                                                    Summary
                                                                                </button>
                                                                            </li>
                                                                            <li
                                                                                className="nav-item  "
                                                                                role="presentation"
                                                                            >
                                                                                <button
                                                                                    className={`${this.state.activeTabLink === "applicants" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                                                    id="applicants-tab"
                                                                                    data-bs-toggle="tab"
                                                                                    data-bs-target="#applicants-tab-pane"
                                                                                    type="button"
                                                                                    role="tab"
                                                                                    aria-controls="applicants-tab-pane"
                                                                                    aria-selected="false"
                                                                                    onClick={() => {
                                                                                        this.setState({ activeTabLink: "applicants" , applicantsLoaded:true})
                                                                                        sessionStorage.setItem('activeTabLink', "applicants");

                                                                                    }}
                                                                                >
                                                                                    <span class="bi bi-person-lines-fill "></span> Applicants
                                                                                </button>
                                                                            </li>
                                                                            <li
                                                                                className="nav-item  "
                                                                                role="presentation"
                                                                            >
                                                                                <button
                                                                                    className={`${this.state.activeTabLink === "interviews" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                                                    id="interviews-tab"
                                                                                    data-bs-toggle="tab"
                                                                                    data-bs-target="#interviews-tab-pane"
                                                                                    type="button"
                                                                                    role="tab"
                                                                                    aria-controls="interviews-tab-pane"
                                                                                    aria-selected="false"
                                                                                    onClick={() => {
                                                                                        this.setState({ activeTabLink: "interviews", interviewsLoaded:true, })
                                                                                        sessionStorage.setItem('activeTabLink', "interviews");

                                                                                    }}
                                                                                >
                                                                                    <span class="bi bi-clipboard-check "></span> Interviews
                                                                                </button>
                                                                            </li>
                                                                            <li
                                                                                className="nav-item  "
                                                                                role="presentation"
                                                                            >
                                                                                <button
                                                                                    className={`${this.state.activeTabLink === "reports" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                                                    id="reports-tab"
                                                                                    data-bs-toggle="tab"
                                                                                    data-bs-target="#reports-tab-pane"
                                                                                    type="button"
                                                                                    role="tab"
                                                                                    aria-controls="reports-tab-pane"
                                                                                    aria-selected="false"
                                                                                    onClick={() => {
                                                                                        this.setState({ activeTabLink: "reports" })
                                                                                        sessionStorage.setItem('activeTabLink', "reports");
                                                                                        this.getInterviewByjob(this.props.match.params.jobId);
                                                                                        this.getLeadsByjob(this.props.match.params.jobId);
                                                                                    }}
                                                                                ><i class="bi bi-file-earmark-text me-1"></i>

                                                                                    Reports
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                               

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-0 ">
                                                        <div className="col-md-12  ">

                                                            <div className="">
                                                                <div className=" tab-content " id="myTabContent">
                                                                    <div

                                                                        className={`${this.state.activeTabLink === "details" ? "tab-pane fade show active " : "tab-pane fade show "}`}

                                                                        id="details-tab-pane"
                                                                        role="tabpanel"
                                                                        aria-labelledby="details-tab"
                                                                        tabIndex={0}
                                                                    >
                                                                        {(this.state.activeTabLink === "details" || this.state.detailsLoaded) &&
                                                                        <> 
                                                                            <div className="row d-none d-md-flex  g-0 p-4 pt-0 pb-0 ">
                                                                                <div className="col-lg-6 mb-3 mb-lg-5 pe-2">
                                                                                    <div className="card detail-box-area">
                                                                                        <div className="detail-header pt-3 pb-3 ps-3">
                                                                                            <h1 className="f-size-14 f-weight-600 mb-0 f-dark-color">
                                                                                                Job Description
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="detail-body pt-3 pb-3 ps-3 pe-3">

                                                                                            <div className="detail-inner-box pt-3 pb-0">
                                                                                                <p className="f-size-14 pt-0 pe-4 ps-0 pb-2">
                                                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.description }}>

                                                                                                    </div>
                                                                                                </p>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 mb-3 mb-lg-5 ps-2">
                                                                                    <div className="card detail-box-area job-detial-area">
                                                                                        <div className="detail-header pt-3 pb-3 ps-3">
                                                                                            <h1 className="f-size-14 f-weight-600 mb-0 f-dark-color">
                                                                                                Job Details
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="detail-body pt-3 pb-3 ps-3 pe-3">

                                                                                            <div className="detail-inner-box pt-3 pb-0">
                                                                                                <div className="row d-none d-md-flex  g-0 p-4 pt-0 pb-0 ps-3">
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Tenant Name
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.tenantName}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Job Status
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.isActive}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Created On
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {convertUTCDateToBrowserLocale(this.state.createdDate)}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Location
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.location}, {this.state.state}, {this.state.country}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Job Type
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.jobType}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Work Experience
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.workExperience}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Interviews
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.totalInterviews}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Vacancies
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.vacantPosition}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Visibility
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.jobVisibilityLevel}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Automated Interview
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.isAutoInterviewSchedule}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Industry
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.industry}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Worksite
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.selectedWorkspaceNatureType}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Salary Type
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.salaryType}
                                                                                                            </p>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    {this.state.salaryType === "gross" ?
                                                                                                        <>
                                                                                                            <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                        Salary
                                                                                                                    </h1>
                                                                                                                </div>
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                        {this.state.salary}
                                                                                                                    </p>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                        Min Salary
                                                                                                                    </h1>
                                                                                                                </div>
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                        {this.state.minSalary}
                                                                                                                    </p>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                        Max Salary
                                                                                                                    </h1>
                                                                                                                </div>
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                        {this.state.maxSalary}
                                                                                                                    </p>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </>
                                                                                                    }

                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Bonuses
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            {this.state.jobBonuses.length === 0 ? (
                                                                                                                <p className="f-size-13 f-weight-600 f-dark-color"> - </p>
                                                                                                            ) : (
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.jobBonuses.map((bonus, index) => (
                                                                                                                    <>
                                                                                                                        <span key={index}>{bonus.title} ({bonus.frequency}), </span>

                                                                                                                    </>
                                                                                                                ))}
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Frequency Type
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.salaryFrequencyType}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Publishing Status
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.publishingStatus}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Job Manager
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.jobManager}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                For Female Only
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.forFemaleOnly}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Skills
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                                {this.state.skills}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Intro Video
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 d-flex align-items-center">
                                                                                                                {this.state.introVideoUrl != "" ? <><i class="f-size-22 f-weight-600 bi bi-person-video"></i> <a className="link text-decoration-underline ps-2" href={this.state.introVideoUrl}>Watch </a> </> : <>

                                                                                                                    <i class="f-size-22 f-weight-600 bi bi-camera-video"></i> <Link to="/videorecording" className="text-decoration-underline ps-2"> Create Video </Link></>
                                                                                                                }
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0">
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                                Outro Video
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 p-0">
                                                                                                           
                                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 d-flex align-items-center">
                                                                                                                {this.state.outroVideoUrl != "" ? <><i class="f-size-22 f-weight-600 bi bi-person-video"></i> <a className="link text-decoration-underline ps-2" href={this.state.outroVideoUrl}>Watch </a> </> : <>
                                                                                                                    <i class="f-size-22 f-weight-600 bi bi-camera-video"></i>  <Link to="/videorecording" className="text-decoration-underline ps-2"> Create Video </Link>
                                                                                                                </>}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        }
                                                                   </div>
                                                                  
                                                                   
                                                                </div>

                                                                <div className=" tab-content " id="myTabContent">
                                                                    {
                                                                        (this.state.activeTabLink === "applicants" || this.state.applicantsLoaded) &&
                                                                        <div
                                                                            className={`${this.state.activeTabLink === "applicants" ? "tab-pane fade show active" : "tab-pane fade show"}`}
                                                                            id="applicants-tab-pane"
                                                                            role="tabpanel"
                                                                            aria-labelledby="applicants-tab"
                                                                            tabIndex={0}
                                                                        >
                                                                            <div className="row p-4 g-0 pt-0 pb-0">
                                                                                <div className="col">
                                                                                    <Applicants jobId={this.props.match.params.jobId} displayHeader={false} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                                <div className=" tab-content " id="myTabContent">
                                                                    {(this.state.activeTabLink === "interviews" || this.state.interviewsLoaded) &&
                                                                        <div
                                                                            className={`${this.state.activeTabLink === "interviews" ? "tab-pane fade show active" : "tab-pane fade show "}`}

                                                                            id="interviews-tab-pane"
                                                                            role="tabpanel"
                                                                            aria-labelledby="interviews-tab"
                                                                            tabIndex={0}
                                                                        >
                                                                            <div className="row p-4  g-0 pt-0 pb-0 ">
                                                                                <div className="col ">
                                                                                    <div class="fixed-div">
                                                                                        <JobInterviewsComponent jobId={this.props.match.params.jobId} displayHeader={false}></JobInterviewsComponent>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className=" tab-content  " id="myTabContent">

                                                                    <div
                                                                        className={`${this.state.activeTabLink === "reports" ? "tab-pane fade show active" : "tab-pane fade show "}`}

                                                                        id="reports-tab-pane"
                                                                        role="tabpanel"
                                                                        aria-labelledby="reports-tab"
                                                                        tabIndex={0}
                                                                    >

                                                                        <div class="fixed-div p-4 pt-0">
                                                                            
                                                                            <div className="row">

                                                                                <div className="float-sm-start" style={{ width: "33%", marginLeft: '31%' }}>
                                                                                        <div className="col-lg-12 mb-3 mb-lg-5 ">
                                                                                        {this.state.LeadsByJobCount > 0 &&
                                                                                                <div className="bg-success pe-auto" onClick={() => {
                                                                                                this.LeadsByjobPopUp("LeadsByJobCount");
                                                                                                }}>
                                                                                                    <div className="card-body text-center">
                                                                                                    <small className="text-cap">Candidates Count By Job</small>
                                                                                                        <span className="js-counter d-block display-3 text-dark mb-2">
                                                                                                        {this.state.LeadsByJobCount}
                                                                                                        </span>

                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        {this.state.LeadsByJobCount === 0 &&
                                                                                                <div className="bg-success pe-auto">
                                                                                                    <div className="card-body text-center">
                                                                                                    <small className="text-cap">Candidates Count By Job</small>
                                                                                                        <span className="js-counter d-block display-3 text-dark mb-2">
                                                                                                        {this.state.LeadsByJobCount}
                                                                                                        </span>

                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-12 mb-3 mb-lg-5">
                                                                                    <div className="card h-100">
                                                                                        <div className="card-header card-header-content-sm-between">
                                                                                            <h4 className="card-header-title mb-2 mb-sm-0">Interview by Interview Type</h4>
                                                                                        </div>
                                                                                        <div className="card-body">

                                                                                            {/*<!-- Bar Chart -->*/}
                                                                                            <div className="chartjs-custom">

                                                                                                <div>
                                                                                                    <HighchartsReact
                                                                                                        highcharts={Highcharts}
                                                                                                        options={this.state.BarChartForInterviewType}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/*<!-- End Bar Chart -->*/}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            {
                                                                                this.state.isInterviewType === 1 &&
                                                                                <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                                                                                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" style={{ width: '50%'}} role="document">
                                                                                        <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                                                                            <div className="modal-header">
                                                                                                <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedInterviewTypePopUpLabel}</h4>
                                                                                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ isInterviewType: 0, InterviewTypeList: [] }) }}></button>
                                                                                            </div>
                                                                                            <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                                                                                <div className="table-responsive datatable-custom w-100">
                                                                                                    <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                                        <thead className="thead-light">
                                                                                                                <tr>
                                                                                                                    <th>
                                                                                                                    InterviewId
                                                                                                                    </th>
                                                                                                                <th>
                                                                                                                    Interview Type
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                        Job Title
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Interview Level
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                                {
                                                                                                                    this.state.InterviewTypeList.map((interview, index) => {
                                                                                                                        return (
                                                                                                                            <tr key={index}>
                                                                                                                                <td>
                                                                                                                                        {interview.interviewId}
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                        {interview.interviewType}
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                        {interview.jobName}
                                                                                                                                </td>

                                                                                                                                <td >
                                                                                                                                        {interview.interviewLevel}
                                                                                                                                </td>

                                                                                                                            </tr>
                                                                                                                        )

                                                                                                                    })
                                                                                                                }  

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="modal-footer">
                                                                                                <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                                                                                    this.setState({ isInterviewType: 0, InterviewTypeList: [] })
                                                                                                }}>Cancel</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }


                                                                            {
                                                                                this.state.isLeadsByjobPopUp === 1 &&
                                                                                <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                                                                                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" style={{ width: '50%' }} role="document">
                                                                                        <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                                                                            <div className="modal-header">
                                                                                                    <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedLeadsByjobPopUpLabel}</h4>
                                                                                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ isLeadsByjobPopUp: 0, LeadsByjobPopUpList: [] }) }}></button>
                                                                                            </div>
                                                                                            <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                                                                                <div className="table-responsive datatable-custom w-100">
                                                                                                    <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                                        <thead className="thead-light">
                                                                                                            <tr>
                                                                                                                    <th>
                                                                                                                        First Name
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Last Name
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Email

                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Contact
                                                                                                                    </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                    this.state.LeadsByjobPopUpList.map((lead, index) => {
                                                                                                                    return (
                                                                                                                        <tr key={index}>
                                                                                                                            <td>
                                                                                                                                    {lead.firstName}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                    {lead.lastName}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                    {lead.email}
                                                                                                                            </td>
                                                                                                                            <td >
                                                                                                                                    {lead.mobile}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )

                                                                                                                })
                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="modal-footer">
                                                                                                <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                                                                                        this.setState({ isLeadsByjobPopUp: 0, LeadsByjobPopUpList: [] })
                                                                                                }}>Cancel</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Desktop End Here */}
                        </>
                        <>
                            {/* Mobile Start here */}
                            <div className="row justify-content-center align-items-center g-0 d-lg-none d-block ">
                                <div className="col-md-12">
                                    <div className="card border-0 jc-radius mb-3">
                                        <div className="row g-0 p-3 pb-0">
                                            <div className="col-12 d-flex">
                                                <div className="col-6 ">
                                                    <div className="d-flex">
                                                        <h1
                                                            className="f-size-18 f-weight-700 "
                                                            style={{ marginRight: 8 }}
                                                        >
                                                            {this.state.title}                                                        </h1>
                                                        <div className="check-icon-bg-job">
                                                            <i
                                                                className="bi bi-check "
                                                                style={{ fontSize: "0.9rem", color: "#00C9A7" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className="f-size-12 ">Posted by:{this.state.tenantName}</p>
                                                </div>
                                                <div className="col-6 ms-auto text-end">

                                                    {this.state.editJobPermission &&

                                                        <Link to={`/updatejob/${this.state.JobId}`} className="btn btn-primary border-0 px-3 py-2 me-1" style={{ backgroundColor: "#3FB5C3" }}> <span
                                                            className="material-symbols-outlined"
                                                            style={{ verticalAlign: "middle", fontSize: 18 }} >
                                                            edit_square
                                                        </span>
                                                        </Link>
                                                    }
                                                    {this.state.createInterviewPermission &&

                                                        <Link to={{ pathname: "/createinterview/" + this.state.JobId, }}
                                                            className="btn btn-primary border-0 px-3 py-2" >
                                                            <span
                                                                className="material-symbols-outlined"
                                                                style={{ verticalAlign: "middle", fontSize: 18 }}
                                                            >
                                                                add_circle
                                                            </span>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-0 p-4 pt-0">
                                            <div className="col-12">
                                                <ul
                                                    className="nav nav-tabs col-12 nav-tabs-bordered"
                                                    id="myTab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item col-6" role="presentation">
                                                        <button
                                                            className={`nav-link  f-size-14 f-weight-500 ${this.state.activeTab === "details-tab-pane" ? "active" : ""}`}

                                                            id="details-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#details-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="details-tab-pane"
                                                            aria-selected="true"

                                                            onClick={() => this.handleTabClick('details-tab-pane')}

                                                        >
                                                            Job details
                                                        </button>

                                                    </li>
                                                    <li className="nav-item  col-6" role="presentation">
                                                        <button
                                                            className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "description-tab-pane" ? "active" : ""}`}
                                                            id="description-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#description-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="description-tab-pane"
                                                            aria-selected="false"
                                                            onClick={() => this.handleTabClick('description-tab-pane')}


                                                        >
                                                            Job Description
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="myTabContent">
                                                    <div
                                                        className={`tab-pane fade pt-3 ${this.state.activeTab === "details-tab-pane" ? "show active" : ""}`}
                                                        id="details-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="details-tab"
                                                        tabIndex={0}

                                                    >
                                                        <div className="row pt-3 ">
                                                            <div className="col-6 ">
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        TENANT NAME
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.tenantName}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        CREATED ON
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                        {convertUTCDateToBrowserLocale(this.state.createdDate)}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        JOB TYPE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.jobType}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        VACANCIES
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.vacantPosition}

                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                        AUTOMATED INTERVIEW
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.isAutoInterviewSchedule}

                                                                    </p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                            WORK EXPERIENCE
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {this.state.workExperience}
                                                                        </p>
                                                                    </div>

                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                            SALARY TYPE
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {this.state.salaryType}
                                                                        </p>
                                                                    </div>
                                                                    {this.state.salaryType === "gross" ?
                                                                        <>
                                                                            <div className="mb-4">
                                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                                    Salary
                                                                                </h1>
                                                                                <p className="f-size-13 f-weight-600 f-dark-color">
                                                                                    {this.state.salary}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="mb-4">
                                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                                    MIN SALARY
                                                                                </h1>
                                                                                <p className="f-size-13 f-weight-600 f-dark-color">
                                                                                    {this.state.minSalary}
                                                                                </p>
                                                                            </div>
                                                                            <div className="mb-4">
                                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                                    MAX SALARY
                                                                                </h1>
                                                                                <p className="f-size-13 f-weight-600 f-dark-color">
                                                                                    {this.state.maxSalary}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                            BONUSES
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {this.state.jobBonuses.map((bonus, index) => (
                                                                                <>
                                                                                    <span key={index}>{bonus.title} ({bonus.frequency}), </span>

                                                                                </>
                                                                            ))}                                                                        </p>
                                                                    </div>
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                            FOR FEMALE ONLY
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                            {this.state.forFemaleOnly}

                                                                        </p>
                                                                    </div>
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                            SKILLS
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                            {this.state.skills}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 ">
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        JOB STATUS
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                        {this.state.isActive}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        LOCATION
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color overflow-text">
                                                                        {this.state.location}, {this.state.state}, {this.state.country}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        INTERVIEWS
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.totalInterviews}</p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        VISIBILITY
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.jobVisibilityLevel}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        INDUSTRY
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color overflow-text">
                                                                        {this.state.industry}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        WORKSITE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.selectedWorkspaceNatureType}
                                                                    </p>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        JOB MANAGER
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.jobManager}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        FREQUENCY TYPE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.salaryFrequencyType}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        PUBLISHING STATUS
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.publishingStatus}
                                                                    </p>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-14 f-weight-600 mb-1">
                                                                        INTRO VIDEO
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color  ">
                                                                        {this.state.introVideoUrl != "" ? <a className="link" href={this.state.introVideoUrl}>Watch </a> : <>
                                                                            <small> No Intro Video </small>
                                                                            <Link className="btn btn-outline-primary btn-sm" to="/videorecording">+ Create Video </Link></>
                                                                        }                                                                     </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        OUTRO VIDEO
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                        {this.state.outroVideoUrl != "" ? <a className="link" href={this.state.outroVideoUrl}>Watch </a> : <> <small> No Outro Video </small> <Link className="btn btn-outline-primary btn-sm" to="/videorecording">+ Create Video </Link></>}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`tab-pane fade pt-3 ${this.state.activeTab === "description-tab-pane" ? "show active" : ""}`}

                                                        id="description-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="description-tab"
                                                        tabIndex={0}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.description }}>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mobile end here */}
                        </>


                    </div>
                    {/* <!-- End Content --> */}



                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}

export default ViewJob;

