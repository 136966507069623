import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';

import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseJwt, parseToken } from '../../modules/TokenParser';
import axios from "axios";
import { Link } from 'react-router-dom';

import CompanyLogo from '../../assets/svg/brands/codingkey-icon.svg';
import { CheckPermission } from '../../modules/CheckPermission';
import StarRatingComponent from 'react-star-rating-component';
import ReactTooltip from 'react-tooltip';
import BeatLoaderComponent from "../Loader/BeatLoader";
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import CandidateDetailsComponent from '../Candidates/CandidateDetailsComponent'
import OfferLetterDetailComponent from '../Candidates/OfferLetterDetailComponent'



class LeadDetails extends BaseComponent {

    constructor(props) {
        super(props);

        this.state =
        {
            leadDetails: {},
            selectedRating: 0,
            ratingModal: false,
            ratingByUserName: "",
            activeTab: "",
            nestedActiveTab: "",
            nestedTabButtons: "",
            preSelectedActiveTab: "",
            preSelectedNestedActiveTab: "",
            preSelectedNestedTabButtons: "",
            eventLeadTimeLine: {},
            isLeadTimeLineFetched: true,
            leadStatusDetailsFetched: true,
            isNoComments: false,
            isCommentsInvalid: false,
            comments: "",
            enableRating: false,
            activeTabLink: "lead-status",
            openStatusChecked: false,
            inprocessStatusChecked: false,
            closedStatusChecked: false,
            defaultLeadStageStatus: "",
            activeTabLinkMobile: "lead-details-mb",
            activeTabLinkApplicantDetail: "details",
            applicationUserIdOfApplicant: "",
            applicantInterviewInviteID: "",
            isapplicantFound: true,
            editReviewPermission: true,
            setInterviewInviteId: "",
            setInterviewId:"",

        };



    }
    async componentDidMount() {
        if (verifyTokenOnLocal()) {
            await isActiveAccessToken();

            document.title = "Tenant App - Applicant Details";
            document.body.classList.remove('modal-open');

            let bearer = validateAndGetFormatedToken();
            if (bearer != "" || bearer != null) {
                this.getLeadDetails(this.props.applicantId);
                this.getUserEmail(parseJwt(bearer).sub);

            }

        }
    }
    /**
     * Get applicationUserId of an applicant by passing encoded email to the api
     * */

    async getApplicationUserId() {
        try {
            const base64Output = btoa(this.state.leadDetails.email);
            console.log("base64Output:", base64Output);

            let bearer = validateAndGetFormatedToken();
            if (bearer) {
                const url = `${process.env.REACT_APP_IDENTITY_API}/Account/GetApplicationUserId?userName=${base64Output}`;
                const response = await axios.post(url, {}, {
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                });

                this.setState({ applicationUserIdOfApplicant: response.data });
                console.log("getApplicationUserId", response.data);
            } else {
                console.error("Invalid bearer token.");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            this.setState({ isapplicantFound:false})
            throw error;
        }
    }





    /**
     * Get Lead details
     * */
    getLeadDetails(leadId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/LeadDetails/" + leadId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        leadDetails: result,
                        selectedRating: result.rating,
                        preSelectedActiveTab: result.leadStatus,
                        preSelectedNestedActiveTab: result.leadStage,
                        preSelectedNestedTabButtons: result.leadStageStatus,
                        leadStatusDetailsFetched: false,
                        activeTab: result.leadStatus,
                        nestedActiveTab: result.leadStage,
                        defaultLeadStageStatus: result.leadStageStatus,
                        applicantInterviewInviteID: result.interviewInviteDTOs[0] !== undefined ? result.interviewInviteDTOs[0].interviewInviteId : "",
                    })


                    if (result.leadStatus === 'open') {
                        this.setState({ openStatusChecked: true, inprocessStatusChecked: false, closedStatusChecked: false });
                    }
                    if (result.leadStatus === 'inprocess') {
                        this.setState({ inprocessStatusChecked: true, openStatusChecked: false, closedStatusChecked: false });
                    }
                    if (result.leadStatus === 'closed') {
                        this.setState({ closedStatusChecked: true, openStatusChecked: false, inprocessStatusChecked: false });
                    }

                    if (result.ratingByUserName != "" || result.ratingByUserName != null) {
                        this.getUserEmail(parseJwt(bearer).sub);
                    }
                    this.getApplicationUserId()
                }).then(() => {

                    this.getLeadTimeLine(leadId);

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Lead details!', "top-center")

                });
        }

    }

    /**
     * Get lead log*/
    async getLeadTimeLine(leadId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/LeadTimeLine/" + leadId;
            await axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data != '') {
                        response.data.events.forEach((event, index) => {
                            //let i = event.eventDateUtc.split("T");
                            response.data.events[index].eventDateUtc = event.eventDateUtc;
                        });
                        this.setState({
                            isLeadTimeLineFetched: false,
                            leadStatusDetailsFetched: false,
                            eventLeadTimeLine: response.data.events
                        });
                    }
                    else {
                        this.setState({
                            isLeadTimeLineFetched: false,
                            leadStatusDetailsFetched: false,
                            eventLeadTimeLine: []
                        })
                    }
                }).catch(err => {
                    console.error("Error while fetching Lead Time Line == >" + err);
                    displayErrorMessage('Error while fetching Lead Time Line!', "top-center");
                });

        }
    }

    /**
      * Update Lead stage & status*/
    updateLeadStatus(leadId, status, stage, stageStatus, closeReason) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            this.setState({ leadStatusDetailsFetched: true })
            let url = process.env.REACT_APP_CORE_API + "/api/LeadEventManagement/" + leadId + "/" + stage + "/" + status + "/" + stageStatus + "/" + closeReason;

            axios(url,
                {
                    method: 'PUT',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.data == true) {
                        if (this.state.eventLeadTimeLine != '') {
                            this.updateLeadTimeLine(leadId, stage, status, stageStatus, closeReason);
                        }
                        else {
                            this.createLeadTimeLine(leadId, "lead_review_rating", "open", "pending", closeReason);
                        }
                        this.getLeadDetails(leadId);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ leadStatusDetailsFetched: true })
                    displayErrorMessage('Error while fetching Lead status stage!', "top-center")

                });
        }
    }

    /**
     * Update Lead time line*/
    updateLeadTimeLine(leadId, stage, status, stageStatus, closeReason) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {


            url = process.env.REACT_APP_IDENTITY_API + "/Account/FindUserById?userId=" + parseJwt(bearer).sub;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    url = process.env.REACT_APP_CORE_API + "/api/UpdateLeadEvent/" + leadId;
                    let inputModel = {};
                    let tempStage = "";
                    if (stage != '' && stage != null && stage != undefined) {
                        var wordArray = stage.split("_");
                        for (var count = 0; count < wordArray.length; count++) {
                            wordArray[count] = wordArray[count].charAt(0).toUpperCase() + wordArray[count].slice(1);
                        }
                        tempStage = wordArray.join(' ');
                    }
                    if (this.state.leadDetails.leadStatus != this.state.activeTab) {
                        inputModel = {
                            eventType: "LeadStatusUpdated",
                            comments: null,
                            commentsBy: response.data.firstName + " " + response.data.lastName,
                            eventDetail: "Lead status has been changed to " + status + ".  Stage  is " + tempStage + " and stage status is " + stageStatus,
                            eventDateUtc: new Date()

                        }
                    }
                    else if (this.state.leadDetails.leadStage != this.state.nestedActiveTab) {
                        inputModel = {
                            eventType: "LeadStageUpdated",
                            comments: null,
                            commentsBy: response.data.firstName + " " + response.data.lastName,
                            eventDetail: "Lead status is " + status + ".  Stage  has been changed to " + tempStage + " and stage status is " + stageStatus,
                            eventDateUtc: new Date()

                        }
                    }
                    else {

                        inputModel = {
                            eventType: "LeadStageStatusUpdated",
                            comments: null,
                            commentsBy: response.data.firstName + " " + response.data.lastName,
                            eventDetail: "Lead status is " + status + ".  Stage  is " + tempStage + " and stage status has been changed to " + stageStatus,
                            eventDateUtc: new Date()

                        }
                    }


                    var model = JSON.stringify(inputModel);
                    const requestOptions = {
                        method: 'PUT',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        },
                        body: model
                    };

                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result == "Error: lead event not updated") {
                                displayErrorMessage('Error while updating Lead time line!', "top-center")
                            }
                            this.getLeadDetails(leadId);
                        })
                        .catch(err => {
                            console.log(err);
                            displayErrorMessage('Error while updating Lead time line!', "top-center")

                        });


                }).catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");


                });
        }

    }

    /**
     * Update Lead time line*/
    createLeadTimeLine(leadId, stage, status, stageStatus, closeReason) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let commentsBy = "";

        if (bearer != "" || bearer != null) {

            url = process.env.REACT_APP_IDENTITY_API + "/Account/FindUserById?userId=" + parseJwt(bearer).sub;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    url = process.env.REACT_APP_CORE_API + "/api/LeadTimeLine/";
                    let inputModel = {};

                    let tempStage = "";
                    if (stage != '' && stage != null && stage != undefined) {
                        var wordArray = stage.split("_");
                        for (var count = 0; count < wordArray.length; count++) {
                            wordArray[count] = wordArray[count].charAt(0).toUpperCase() + wordArray[count].slice(1);
                        }
                        tempStage = wordArray.join(' ');
                    }
                    inputModel = {
                        Id: leadId,
                        TenantId: parseJwt(bearer).tenantid,
                        Events: [
                            {
                                eventType: "lead_created",
                                comments: null,
                                commentsBy: response.data.firstName + " " + response.data.lastName,
                                eventDetail: "Lead status has been changed to " + status + ".  Stage  is " + tempStage + " and stage status is " + stageStatus,
                                eventDateUtc: new Date()
                            }
                        ]
                    }


                    var model = JSON.stringify(inputModel);
                    const requestOptions = {
                        method: 'POST',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        },
                        body: model
                    };

                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result == "Error: lead event not created") {
                                displayErrorMessage('Error while creating Lead time line!', "top-center")
                            }
                            this.getLeadDetails(leadId);
                        })
                        .catch(err => {
                            console.log(err);
                            displayErrorMessage('Error while creating Lead time line!', "top-center")

                        });


                }).catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");


                });
        }

    }


    /**
    * Update Lead time line*/
    updateLeadTimeLineComment(leadId, comments) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let commentsBy = "";

        if (bearer != "" || bearer != null) {

            url = process.env.REACT_APP_IDENTITY_API + "/Account/FindUserById?userId=" + parseJwt(bearer).sub;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    url = process.env.REACT_APP_CORE_API + "/api/UpdateLeadEvent/" + leadId;
                    let inputModel = {};
                    inputModel = {
                        eventType: "LeadCommentUpdated",
                        comments: comments,
                        commentsBy: response.data.firstName + " " + response.data.lastName,
                        eventDetail: null,
                        eventDateUtc: new Date()
                    }


                    var model = JSON.stringify(inputModel);
                    const requestOptions = {
                        method: 'PUT',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        },
                        body: model
                    };

                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result == "Error: lead comment not updated") {
                                displayErrorMessage('Error while updating Lead time line comment!', "top-center");
                                return;
                            }
                            this.setState({ comments: "" });
                            this.getLeadDetails(leadId);
                        })
                        .catch(err => {
                            console.log(err);
                            displayErrorMessage('Error while updating Lead time line comment!', "top-center")

                        });


                }).catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");


                });
        }

    }

    /**
   * Create Lead time line*/
    createLeadTimeLineComment(leadId, comments) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let commentsBy = "";

        if (bearer != "" || bearer != null) {

            url = process.env.REACT_APP_IDENTITY_API + "/Account/FindUserById?userId=" + parseJwt(bearer).sub;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    url = process.env.REACT_APP_CORE_API + "/api/LeadTimeLine/";
                    let inputModel = {};
                    inputModel = {
                        Id: leadId,
                        TenantId: parseJwt(bearer).tenantid,
                        Events: [{
                            eventType: "LeadCommentUpdated",
                            comments: comments,
                            commentsBy: response.data.firstName + " " + response.data.lastName,
                            eventDetail: null,
                            eventDateUtc: new Date()
                        }]
                    }


                    var model = JSON.stringify(inputModel);
                    const requestOptions = {
                        method: 'POST',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        },
                        body: model
                    };

                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result == "Error: lead comment not updated") {
                                displayErrorMessage('Error while creating Lead time line comment!', "top-center");
                                return;
                            }
                            this.setState({ comments: "" });
                            this.getLeadDetails(leadId);
                        })
                        .catch(err => {
                            console.log(err);
                            displayErrorMessage('Error while creating Lead time line comment!', "top-center")

                        });


                }).catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");


                });
        }

    }
    /**
     * Update Lead Rating*/
    updateLeadRatings(leadId, rating) {
        let bearer = validateAndGetFormatedToken();
        this.setState({ enableRating: true });

        if (bearer != "" || bearer != null) {
            var subId = null;

            subId = parseJwt(bearer).sub;
            let url = process.env.REACT_APP_CORE_API + "/api/Leads/" + leadId + "/" + rating + "/" + subId;

            axios(url,
                {
                    method: 'PUT',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.data == true) {
                        displaySuccessMessage("Lead rating status updated");
                        this.getLeadDetails(leadId);
                        this.getUserEmail(subId);
                        this.setState({
                            enableRating: false,
                            ratingModal: false
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Lead details!', "top-center")

                });
        }
    }
    /** Get the user email */
    getUserEmail(subId) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        url = process.env.REACT_APP_IDENTITY_API + "/Account/GetUsername?userId=" + subId;

        axios(url,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({ ratingByUserName: response.data });
            }).catch(err => {
                console.error("Unable to fetch email of tenant == >" + err);
                displayErrorMessage('Error while Fetching email of tenant!', "top-center");


            });
    }


    ///Update Rating
    onStarClick(nextValue, prevValue, name) {
        this.setState({ selectedRating: nextValue, ratingModal: true });
    }

    /*
*Copy text invitation link
* */
    invitationLinkCopy(e, id) {
        /* Get the text field */
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        displaySuccessMessage('Link Copied :' + copyText.value, "top-center");

    }
    /** Get Interview review Permission */
    interviewReviewPermissions() {
        let bearer = validateAndGetFormatedToken();
        var viewReviewPermission = false;
        var editReviewPermission = false;
        if (bearer !== "" || bearer != null) {
            if (parseToken(bearer).roles !== "TenantAdmin") {

                viewReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.View", bearer);
                this.setState({ viewReviewPermission: viewReviewPermission });

                editReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.Edit", bearer);
                this.setState({ editReviewPermission: editReviewPermission });
            }

        }
    }




    render() {
        const { selectedRating } = this.state;
        const activeLeadsSelectStyle = (tab) => (

            (tab === this.state.nestedActiveTab ?
                { color: 'white', backgroundColor: '#482D7D', borderColor: '#875cdb' } :
                {}
            )

        );


        return (
            <>


                {/*Dekstop start here*/}
                <>
                    <div className="row  align-items-center g-0 d-none d-lg-block">
                        <div className="col-md-12">
                            <div className="card no-border p-3 pt-4">
                                
                                <div className="row g-0  ">
                                    <div className="col-md-12 d-flex gap-2">
                                        <div class="d-flex">
                                            {this.state.leadDetails.profileImageUrl != null ? 
                                                <div class="">
                                                    <img
                                                        width="60"
                                                        height="60"
                                                        src={this.state.leadDetails.profileImageUrl}
                                                        alt="Profile"
                                                        className="applicant-profile-image"
                                                    />

                                                </div>
                                                :
                                                    <div class="flex-shrink-0"> 
                                                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M46.7578 45.0234C44.168 40.5234 39.3047 37.5 33.75 37.5H26.25C20.6953 37.5 15.832 40.5234 13.2422 45.0234C17.3672 49.6172 23.3438 52.5 30 52.5C36.6562 52.5 42.6328 49.6055 46.7578 45.0234ZM60 30C60 46.5703 46.5703 60 30 60C13.4297 60 0 46.5703 0 30C0 13.4297 13.4297 0 30 0C46.5703 0 60 13.4297 60 30ZM30 31.875C34.6641 31.875 38.4375 28.1016 38.4375 23.4375C38.4375 18.7734 34.6641 15 30 15C25.3359 15 21.5625 18.7734 21.5625 23.4375C21.5625 28.1016 25.3359 31.875 30 31.875Z" fill="#C7C7C7">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                }
                                            
                                           
                                            <div class="flex-grow-1 ms-3 ">
                                                <h4 class="text-start mb-0 f-weight-700 f-size-20"  >{this.state.leadDetails.firstName} {this.state.leadDetails.lastName}  </h4>
                                                <div class="text-start mb-1 d-flex gap-2">
                                                    <p className="f-weight-600 f-size-16">
                                                        Applicant ID: <span className="f-weight-500 ">{this.state.leadDetails.leadId}</span>
                                                    </p>
                                                    <p>|</p>
                                                    <p className="f-weight-600 f-size-16">
                                                        Applicant Date: <span className="f-weight-500 ">{convertUTCDateToBrowserLocale(this.state.leadDetails.createdDate)}</span>
                                                    </p>
                                                    <p>|</p>
                                                    <p className="f-weight-600 f-size-16">
                                                        Email:{" "}
                                                        <span className="f-weight-500 ">  {this.state.leadDetails.email == null ? "-" : this.state.leadDetails.email}</span>
                                                    </p>

                                                </div>
                                            </div>
                                           
                                        </div>
                                        


                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <ul
                                            className="nav nav-tabs job-tabs-view"
                                            id="myTab"
                                            role="tablist"
                                        >
                                            <li
                                                className="nav-item "
                                                role="presentation"
                                            >
                                                <button
                                                    className={`${this.state.activeTabLinkApplicantDetail === "details" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                    id="details-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#details-tab-pane"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="details-tab-pane"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        this.setState({ activeTabLinkApplicantDetail: "details" })
                                                    }}
                                                > <i class="bi bi-info-circle me-1"></i>

                                                    Detail
                                                </button>
                                            </li>
                                            <li
                                                className="nav-item  "
                                                role="presentation"
                                            >
                                                <button
                                                    className={`${this.state.activeTabLinkApplicantDetail === "documents" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                    id="applicants-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#applicants-tab-pane"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="applicants-tab-pane"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                        this.setState({ activeTabLinkApplicantDetail: "documents" })
                                                        this.getApplicationUserId()
                                                        console.log("defaultLeadStageStatus", this.state.nestedActiveTab)
                                                    }}
                                                >
                                                    <i className="bi bi-file-earmark-text"></i> Documents
                                                </button>
                                            </li>
                                            <li
                                                className="nav-item  "
                                                role="presentation"
                                            >
                                                <button
                                                    className={`${this.state.activeTabLinkApplicantDetail === "offerletter" ? "nav-link actived f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                    id="interviews-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#interviews-tab-pane"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="interviews-tab-pane"
                                                    aria-selected="false"

                                                    onClick={() => {
                                                        this.setState({ activeTabLinkApplicantDetail: "offerletter" })
                                                    }}
                                                >
                                                    <i className="bi bi-envelope"></i> Offer Letter
                                                </button>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="row g-0 mb-2 ">
                                    <div className="col-md-12">
                                        <div className="card p-4 mb-3">




                                            <div className=" tab-content " id="myTabContent">
                                                <div

                                                    className={`${this.state.activeTabLinkApplicantDetail === "documents" ? "tab-pane fade show active " : "tab-pane fade show "}`}

                                                    id="details-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="details-tab"
                                                    tabIndex={0}
                                                >
                                                    {this.state.applicationUserIdOfApplicant !== "" && (
                                                                <CandidateDetailsComponent userId={this.state.applicationUserIdOfApplicant} isapplicantFound={this.state.isapplicantFound }></CandidateDetailsComponent>

                                                    )}
                                                    {!this.state.isapplicantFound && (
                                                        <div className=" card-centered mb-3 mb-lg-5">
                                                            <div className="card-body py-10">
                                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                <div className="text-center">

                                                                    <h1>No documents uploaded.</h1>




                                                                </div>

                                                            </div>
                                                        </div>

                                                            ) }
                                                </div>
                                            </div>
                                            <div className=" tab-content " id="myTabContent">
                                                <div

                                                    className={`${this.state.activeTabLinkApplicantDetail === "offerletter" ? "tab-pane fade show active " : "tab-pane fade show "}`}

                                                    id="details-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="details-tab"
                                                    tabIndex={0}
                                                >
                                                    {this.state.applicantInterviewInviteID !== "" ? (
                                                        <OfferLetterDetailComponent interviewInviteId={this.state.applicantInterviewInviteID}></OfferLetterDetailComponent>

                                                    ) :
                                                        <div className=" card-centered mb-3 mb-lg-5">
                                                            <div className="card-body py-10">
                                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                <div className="text-center">

                                                                    <h1>No offer letter uploaded.</h1>




                                                                </div>

                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <div className=" tab-content " id="myTabContent">
                                                <div

                                                    className={`${this.state.activeTabLinkApplicantDetail === "details" ? "tab-pane fade show active " : "tab-pane fade show "}`}

                                                    id="details-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="details-tab"
                                                    tabIndex={0}
                                                >
                                                    <div>
                                                        <div className="row d-md-flex  g-0 p-0">

                                                            <div className="col-lg-12 mb-3 mb-lg-5 ps-2">
                                                                <div className="card detail-box-area job-detial-area">
                                                                    <div className="detail-header pt-3 pb-3 ps-3">
                                                                        <h1 className="f-size-14 f-weightApplicant Detail
-600 mb-0 f-dark-color">
                                                                            Personal Information
                                                                        </h1>
                                                                    </div>
                                                                    <div className="detail-body pt-3 pb-3 ps-3 pe-3">

                                                                        <div className="detail-inner-box pt-3 pb-0">
                                                                            <div className="row d-md-flex  g-0 p-0">
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 mb-0 mb-lg-0 ps-0 pe-8 pt-0 pb-0 border-col">
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-buildings me-1"></i> City Name
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.leadCityName == null ? "-" : this.state.leadDetails.leadCityName}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-telephone me-1"></i> Contact Number
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.mobile == null ? "-" : this.state.leadDetails.mobile}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-person-vcard me-1"></i> CNIC
                                                                                            </h1>
                                                                                        </div>
                                                                                        {this.state.leadDetails.countryName != null && this.state.leadDetails.countryName.toLowerCase() === "pakistan" && (
                                                                                            <div className="col-md-6 p-0 text-end">
                                                                                                <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                    {this.state.leadDetails.cnic == null || this.state.leadDetails.cnic === "" ? "-" : this.state.leadDetails.cnic}
                                                                                                </p>
                                                                                            </div>

                                                                                        )}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 mb-0 mb-lg-0 ps-8 pe-0 pt-0 pb-0">
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 fab fa-facebook-f me-1"></i> Facebook Profile
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.facebook == null ? "-" : this.state.leadDetails.facebook}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 fab fa-linkedin-in me-1"></i> LinkedIn Profile
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.linkedIn == null ? "-" : this.state.leadDetails.linkedIn.toLowerCase()}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-globe me-1"></i> Country Name
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.countryName == null ? "-" : this.state.leadDetails.countryName}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mb-3 mb-lg-5 ps-2">
                                                                <div className="card detail-box-area job-detial-area">
                                                                    <div className="detail-header pt-3 pb-3 ps-3">
                                                                        <h1 className="f-size-14 f-weight-600 mb-0 f-dark-color">
                                                                            Applicant Detail
                                                                        </h1>
                                                                    </div>
                                                                    <div className="detail-body pt-3 pb-3 ps-3 pe-3">

                                                                        <div className="detail-inner-box pt-3 pb-0">

                                                                            <div className="row d-md-flex  g-0 p-0">
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 mb-0 mb-lg-0 ps-0 pe-8 pt-0 pb-0 border-col">
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-briefcase me-1"></i> Job Title
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.jobTitle == null ? "-" : this.state.leadDetails.jobTitle}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-person-plus me-1"></i> Experience
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.experienceYears == null ? "-" : this.state.leadDetails.experienceYears} Months
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-building me-1"></i> Current Company
                                                                                            </h1>
                                                                                        </div>
                                                                                        {this.state.leadDetails.countryName != null && this.state.leadDetails.countryName.toLowerCase() === "pakistan" ? (
                                                                                            <div className="col-md-6 p-0 text-end">
                                                                                                <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                    {this.state.leadDetails.currentEmployer == null ? "-" : this.state.leadDetails.currentEmployer}
                                                                                                </p>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="col-md-6 p-0 text-end">
                                                                                                <p className="f-gray f-size-13 f-weight-600 mb-0 dash">
                                                                                                    -
                                                                                                </p>
                                                                                            </div>

                                                                                        )}

                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-cash me-1"></i> Current Salary
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.currentSalary == null ? "-" : this.state.leadDetails.currentSalary}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-person-exclamation me-1"></i> Short-Listed
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.isShortListed == true ? "Yes" : "No"}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 mb-0 mb-lg-0 ps-8 pe-0 pt-0 pb-0">
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-calendar-week me-1"></i> Short-Listed Date
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.shortListedDateUtc == null ? "-" : this.state.leadDetails.shortListedDateUtc}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-file-person me-1"></i> Applicant CV
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {(this.state.leadDetails.cvFilePath == null || this.state.leadDetails.cvFilePath == "") ? "[No CV]" : <a className="btn-link" type="button"
                                                                                                    href={this.state.leadDetails.cvFilePath} target="_blank"><i className="bi bi-cloud-arrow-down me-1" style={{ fontSize: '1.2rem' }}></i></a>}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-door-closed me-1"></i> Applicant Closed Reason
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.leadClosedReason == null ? "-" : this.state.leadDetails.leadClosedReason}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-headset me-1"></i> Call Center Experience
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.callCenterExperience == null ? "-" : this.state.leadDetails.callCenterExperience}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                                        <div className="col-md-6 p-0">
                                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                                <i class="f-size-15 bi bi-calendar3 me-1"></i> How soon you can join
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="col-md-6 p-0 text-end">
                                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                                {this.state.leadDetails.howSoonYouCanJoin == null ? "-" : this.state.leadDetails.howSoonYouCanJoin}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.leadDetails.interviewInviteDTOs != undefined && this.state.leadDetails.interviewInviteDTOs.length > 0 ?
                                                        <div className="card mb-3">

                                                            <div className="card-body">


                                                                <h3>Interview Invites</h3>
                                                                <hr className="mt-0" />
                                                                <div className="table-responsive datatable-custom">

                                                                    <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                                        <thead className="thead-light">
                                                                            <tr>

                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} > <strong>Name </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Invite Date </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Expiry Date </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Invitation Link </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Invite Status </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Review </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Evaluate </strong></th>
                                                                                <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Summary </strong></th>

                                                                            </tr>

                                                                        </thead>

                                                                        <tbody>


                                                                            {this.state.leadDetails.interviewInviteDTOs != undefined && this.state.leadDetails.interviewInviteDTOs.map((interviewInvite, index) => (
                                                                                <tr role="row" className="odd" key={index}>
                                                                                    <td >
                                                                                        <span data-tip data-for={"candidateName" + index}> {interviewInvite.firstName} {interviewInvite.lastName}</span>
                                                                                        <ReactTooltip id={"candidateName" + index} place="top" type="dark" effect="float">
                                                                                            <span className="d-block fs-5">{interviewInvite.candidateEmail}</span>
                                                                                        </ReactTooltip>

                                                                                    </td>

                                                                                    <td >
                                                                                        <span >{convertUTCDateToBrowserLocale(interviewInvite.requestedDate)}</span>
                                                                                    </td>
                                                                                    <td >
                                                                                        <span >{convertUTCDateToBrowserLocale(interviewInvite.expiryDate)}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div id={"copyLink" + index} data-tip data-for={"copyLink" + index} className=" flex-nowrap btn btn-outline-primary btn-icon btn-sm me-2 ms-2">
                                                                                            {interviewInvite.invitationLink != null || interviewInvite.invitationLink != undefined ?
                                                                                                <>
                                                                                                    <i className="bi bi-clipboard-fill " style={{ cursor: 'pointer' }} onClick={(e) => this.invitationLinkCopy(e, interviewInvite.interviewId)}></i>
                                                                                                    <input type="text" className="form-control" value={interviewInvite.invitationLink} id={interviewInvite.interviewId} hidden />
                                                                                                    <ReactTooltip id={"copyLink" + index} place="top" type="dark" effect="float">
                                                                                                        <span className="d-block fs-5">Copy open interview link</span>
                                                                                                    </ReactTooltip>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Accepted" &&

                                                                                            <span class="review-interviews-status status-accepted f-size-12 inter-ff f-weight-500 ">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Reviewed" &&

                                                                                            <span class="review-interviews-status status-reviewed f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Requested" &&

                                                                                            <span class="review-interviews-status status-requested f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Evaluated" &&

                                                                                            <span class="review-interviews-status status-evaluated f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Completed" &&

                                                                                            <span class="review-interviews-status status-completed f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Rescheduled" &&

                                                                                            <span class="review-interviews-status status-rescheduled f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus === "Expired" &&

                                                                                            <span class="review-interviews-status status-expired f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>


                                                                                        {interviewInvite.inviteStatus === "Completed" || interviewInvite.inviteStatus === "Reviewed" ?
                                                                                            <>

                                                                                                <button class="btn btn-primary " data-tip data-for='reviewInterivew'
                                                                                                    onClick={() => {

                                                                                                        this.setState({
                                                                                                            setInterviewInviteId: interviewInvite.interviewInviteId,
                                                                                                            setInterviewId: interviewInvite.interviewId,

                                                                                                        }, () => {
                                                                                                                    this.props.closeApplicantDetailAndOpenRequiredModal(this.state.leadDetails.jobId, this.state.setInterviewId, this.state.setInterviewInviteId, "review" )
                                                                                                            console.log("detail:", this.state.leadDetails.jobId, this.state.setInterviewId, this.state.setInterviewInviteId)

                                                                                                                } )
                                                                                                        document.body.classList.add('modal-open');

                                                                                                    }}
                                                                                                > <i className="bi bi-clipboard-check me-1 f-size-12"></i><span className="f-size-12">Review</span></button>
                                                                                                <ReactTooltip id="reviewInterivew" place="top" type="dark" effect="float">
                                                                                                    <span> Review Interview </span>
                                                                                                </ReactTooltip>

                                                                                            </>
                                                                                            :
                                                                                            <button class="btn btn-primary disabled-btn" disabled> <i className="bi bi-clipboard-check me-1 f-size-12"></i><span className="f-size-12">Review</span></button>
                                                                                        }



                                                                                    </td>
                                                                                    <td>




                                                                                        {interviewInvite.inviteStatus === "Reviewed" && this.state.editReviewPermission ?
                                                                                            <>

                                                                                                <button class="btn btn-primary" data-tip data-for='evaluateInterview'
                                                                                                    onClick={() => {

                                                                                                        this.setState({
                                                                                                            setInterviewInviteId: interviewInvite.interviewInviteId,
                                                                                                            setInterviewId: interviewInvite.interviewId,

                                                                                                        }, () => {
                                                                                                            this.props.closeApplicantDetailAndOpenRequiredModal(this.state.leadDetails.jobId, this.state.setInterviewId, this.state.setInterviewInviteId, "evaluate")
                                                                                                            console.log("detail:", this.state.leadDetails.jobId, this.state.setInterviewId, this.state.setInterviewInviteId)

                                                                                                        })
                                                                                                        document.body.classList.add('modal-open');

                                                                                                    }}
                                                                                                ><i className="bi bi-person-check me-1 "></i><span className="f-size-12">Evaluate</span></button>
                                                                                                <ReactTooltip id="evaluateInterview" place="top" type="dark" effect="float">
                                                                                                    <span> Evaluate Interview </span>
                                                                                                </ReactTooltip>

                                                                                            </>
                                                                                            :
                                                                                            <button class="btn btn-primary disabled-btn" disabled> <i className="bi bi-person-check me-1 "></i><span className="f-size-12">Evaluate</span></button>
                                                                                        }
                                                                                    </td>
                                                                                    <td>

                                                                                        {interviewInvite.inviteStatus === "Reviewed" || interviewInvite.inviteStatus === "Evaluated" ?
                                                                                            <>

                                                                                                <button class="btn btn-primary  d-flex align-items-center" data-tip data-for='summaryOfInterview'
                                                                                                    onClick={() => {

                                                                                                        this.setState({
                                                                                                            setInterviewInviteId: interviewInvite.interviewInviteId,
                                                                                                            setInterviewId: interviewInvite.interviewId,

                                                                                                        }, () => {
                                                                                                            this.props.closeApplicantDetailAndOpenRequiredModal(this.state.leadDetails.jobId, this.state.setInterviewId, this.state.setInterviewInviteId, "summary")
                                                                                                            console.log("detail:", this.state.leadDetails.jobId, this.state.setInterviewId, this.state.setInterviewInviteId)

                                                                                                        })
                                                                                                        document.body.classList.add('modal-open');

                                                                                                    }}

                                                                                                ><span class="material-symbols-outlined f-size-16 me-1">
                                                                                                        summarize
                                                                                                 </span>
                                                                                                <span className="f-size-12">Summary</span></button>
                                                                                                <ReactTooltip id="summaryOfInterview" place="top" type="dark" effect="float">
                                                                                                    <span> Summary of Interview </span>
                                                                                                </ReactTooltip>

                                                                                            </>
                                                                                            :
                                                                                            <button class="btn btn-primary disabled-btn" disabled>
                                                                                                <span class="material-symbols-outlined f-size-16 me-1">
                                                                                                summarize
                                                                                                </span>
                                                                                            <span className="f-size-12">Summary</span></button>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}

                                                                        </tbody>
                                                                    </table>
                                                                </div>


                                                            </div>

                                                        </div>
                                                        : null}

                                                    <div className="card mb-3 " >
                                                        <div className="row m-3 align-middle">
                                                            <div className="col-md-6">
                                                                <span className=" d-block h4 ms-1">
                                                                    <i className="bi bi-person-badge"></i> Rating by:
                                                                    <hr className="mt-2 mb-2" />
                                                                    {this.state.ratingByUserName}

                                                                </span>
                                                            </div>
                                                            <div className="col-md-6">

                                                                <span className="d-block h4 ms-1" data-tip data-for='rating' id="rating">
                                                                    <i className="bi bi-star"></i> Rating:
                                                                    <hr className="mt-2 mb-2" />
                                                                    <div style={{ fontSize: '25px', }}><StarRatingComponent
                                                                        id="rating"
                                                                        name="rate1"
                                                                        starCount={5}
                                                                        value={selectedRating}
                                                                        onStarClick={this.onStarClick.bind(this)}
                                                                    />
                                                                    </div>
                                                                    <ReactTooltip id="rating" place="top" type="dark" effect="float">
                                                                        <span> On changing will update rating</span>
                                                                    </ReactTooltip>
                                                                </span>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="card mt-3">
                                                        <div className="row justify-content-center align-items-center g-0 p-3 pt-4 ">
                                                            <div className="col-md-12">
                                                                <div className=" border-0 jc-radius mb-3 ">
                                                                    <div className="row g-0 p-2 pt-0">
                                                                        <div className="col-md-12">
                                                                            <ul
                                                                                className="nav nav-tabs nav-tabs-bordered status-log-applicant-tab-width"
                                                                                id="myTab"
                                                                                role="tablist"
                                                                            >
                                                                                <li
                                                                                    className="nav-item col-md-6"
                                                                                    role="presentation"
                                                                                >
                                                                                    <button
                                                                                        className={`${this.state.activeTabLink === "lead-status" ? "nav-link active f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                                                        id="status-tab"
                                                                                        data-bs-toggle="tab"
                                                                                        data-bs-target="#lead-status-tab-pane"
                                                                                        type="button"
                                                                                        role="tab"
                                                                                        aria-controls="lead-status-tab-pane"
                                                                                        aria-selected="true"
                                                                                        onClick={() => {
                                                                                            this.setState({ activeTabLink: "lead-status" })
                                                                                        }}
                                                                                    >
                                                                                        APPLICANT STATUS
                                                                                    </button>
                                                                                </li>
                                                                                <li
                                                                                    className="nav-item  col-md-6"
                                                                                    role="presentation"
                                                                                >
                                                                                    <button
                                                                                        className={`${this.state.activeTabLink === "lead-log" ? "nav-link active f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500 no-hover-effect"}`}
                                                                                        id="log-tab"
                                                                                        data-bs-toggle="tab"
                                                                                        data-bs-target="#lead-log-tab-pane"
                                                                                        type="button"
                                                                                        role="tab"
                                                                                        aria-controls="lead-log-tab-pane"
                                                                                        aria-selected="false"
                                                                                        onClick={() => {
                                                                                            this.setState({ activeTabLink: "lead-log" })
                                                                                        }}
                                                                                    >
                                                                                        APPLICANT LOG{" "}
                                                                                    </button>
                                                                                </li>
                                                                            </ul>
                                                                            <div className="tab-content" id="myTabContent">
                                                                                <div

                                                                                    className={`${this.state.activeTabLink === "lead-status" ? "tab-pane fade show active" : "tab-pane fade show "}`}

                                                                                    id="lead-status-tab-pane"
                                                                                    role="tabpanel"
                                                                                    aria-labelledby="lead-tab"
                                                                                    tabIndex={0}
                                                                                >
                                                                                    <div className="row pt-3 mt-3">
                                                                                        <div className="col-md-12 d-flex gap-4">
                                                                                            <div className="">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input mr-checkbox"
                                                                                                    id="open-status"
                                                                                                    checked={this.state.openStatusChecked}
                                                                                                    onChange={() => {
                                                                                                        this.setState((prevState) => ({
                                                                                                            openStatusChecked: !prevState.openStatusChecked,
                                                                                                            inprocessStatusChecked: false, closedStatusChecked: false,

                                                                                                        }));
                                                                                                    }}


                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor="open-status"
                                                                                                >
                                                                                                    Open
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input mr-checkbox"
                                                                                                    id="in-process-status"

                                                                                                    checked={this.state.inprocessStatusChecked}
                                                                                                    onChange={() => {
                                                                                                        this.setState((prevState) => ({
                                                                                                            inprocessStatusChecked: !prevState.inprocessStatusChecked,
                                                                                                            openStatusChecked: false, closedStatusChecked: false,

                                                                                                        }));
                                                                                                    }}
                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor="in-process-status"
                                                                                                >
                                                                                                    In-process
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input mr-checkbox"
                                                                                                    id="closed-status"
                                                                                                    checked={this.state.closedStatusChecked}
                                                                                                    onChange={() => {
                                                                                                        this.setState((prevState) => ({
                                                                                                            closedStatusChecked: !prevState.closedStatusChecked,
                                                                                                            openStatusChecked: false, inprocessStatusChecked: false,

                                                                                                        }));
                                                                                                    }}
                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor="closed-status"
                                                                                                >
                                                                                                    Closed
                                                                                                </label>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.openStatusChecked && (
                                                                                        <div className="row justify-content-center align-items-center g-0  pt-4 ">
                                                                                            <div className="col-md-9">
                                                                                                <div className="col-md-12 d-flex gap-2  justify-content-center align-items-center">
                                                                                                    <div className="col-2">
                                                                                                        <div className={`${this.state.nestedActiveTab === "lead_review_rating" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                            <div className="m-0 mb-3 number-count-bg">
                                                                                                                1
                                                                                                            </div>
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "lead_review_rating" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                                Rating
                                                                                                            </h2>
                                                                                                            <select
                                                                                                                active-leads-status-select
                                                                                                                className="form-select mb-4 font-size-12 p-2"

                                                                                                                aria-label="Default select"
                                                                                                                style={activeLeadsSelectStyle("lead_review_rating")}
                                                                                                                value={this.state.nestedActiveTab === "lead_review_rating" ? this.state.defaultLeadStageStatus : ""}

                                                                                                                id="ratingDropDown"
                                                                                                                onChange={(event) => {

                                                                                                                    if (event.target.value === "pending") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "open", "lead_review_rating", "pending", null)

                                                                                                                    }
                                                                                                                    if (event.target.value === "completed") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "open", "lead_review_rating", "completed", null)

                                                                                                                    }


                                                                                                                }}
                                                                                                            >
                                                                                                                <option className="f-size-10" value="" >
                                                                                                                    Select status
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="pending" >
                                                                                                                    Pending
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="completed" >
                                                                                                                    Completed
                                                                                                                </option>

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}


                                                                                    {this.state.inprocessStatusChecked && (

                                                                                        <div className="row justify-content-center align-items-center g-0  pt-4 ">
                                                                                            <div className="col-md-12 d-flex gap-2">
                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "interview_with_hr" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                        <div className="m-0 mb-3 number-count-bg">
                                                                                                            1
                                                                                                        </div>
                                                                                                        <h2 className={`${this.state.nestedActiveTab === "interview_with_hr" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                            HR Interview
                                                                                                        </h2>
                                                                                                        <select
                                                                                                            className="form-select mb-4 font-size-12 p-2"
                                                                                                            aria-label="Default select"
                                                                                                            id="hrInterviewDropDown"
                                                                                                            style={activeLeadsSelectStyle("interview_with_hr")}
                                                                                                            value={this.state.nestedActiveTab === "interview_with_hr" ? this.state.defaultLeadStageStatus : ""}
                                                                                                            onChange={(event) => {
                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "completed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "completed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "closed", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="completed">
                                                                                                                Completed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "shortlisted" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>

                                                                                                        <div className="m-0 mb-3 number-count-bg">
                                                                                                            2
                                                                                                        </div>
                                                                                                        <h2 className={`${this.state.nestedActiveTab === "shortlisted" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                            Short-listed
                                                                                                        </h2>
                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("shortlisted")}
                                                                                                            value={this.state.nestedActiveTab === "shortlisted" ? this.state.defaultLeadStageStatus : ""}

                                                                                                            id="shortlistedDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "completed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "completed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "rejected") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "rejected", null)


                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "closed", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="completed" >
                                                                                                                Completed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="rejected">
                                                                                                                Rejected
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col  ">
                                                                                                    <div className={`${this.state.nestedActiveTab === "FirstInterview" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card p-2 pt-3 text-center align-items-center "}`}>
                                                                                                        <div className="m-0 number-count-bg">
                                                                                                            3
                                                                                                        </div>
                                                                                                        <div className="mb-2 mt-3">
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "FirstInterview" ? "text-white f-size-12 f-weight-700 m-0 " : " f-color f-size-12 f-weight-700 m-0 "}`}>
                                                                                                                First technical <br></br> interview
                                                                                                            </h2>
                                                                                                        </div>

                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2 mt-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("FirstInterview")}
                                                                                                            value={this.state.nestedActiveTab === "FirstInterview" ? this.state.defaultLeadStageStatus : ""}
                                                                                                            id="firstInterviewDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "completed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "completed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "evaluated") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "evaluated", null)


                                                                                                                }
                                                                                                                if (event.target.value === "reviewed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "reviewed", null)


                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "closed", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="completed" >
                                                                                                                Completed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="reviewed" >
                                                                                                                Reviewed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="evaluated">
                                                                                                                Evaluated
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "SecondInterview" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                        <div className="m-0 number-count-bg">
                                                                                                            4
                                                                                                        </div>
                                                                                                        <div className="mb-2 mt-3 ">
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "SecondInterview" ? "text-white f-size-12 f-weight-700 m-0 " : " f-color f-size-12 f-weight-700 m-0 "}`}>
                                                                                                                Second  technical<br></br>
                                                                                                                interview
                                                                                                            </h2>
                                                                                                        </div>
                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2 mt-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("SecondInterview")}
                                                                                                            value={this.state.nestedActiveTab === "SecondInterview" ? this.state.defaultLeadStageStatus : ""}

                                                                                                            id="secondInterviewDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "completed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "completed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "evaluated") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "evaluated", null)


                                                                                                                }
                                                                                                                if (event.target.value === "reviewed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "reviewed", null)


                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "closed", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="completed" >
                                                                                                                Completed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="reviewed" >
                                                                                                                Reviewed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="evaluated">
                                                                                                                Evaluated
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "FinalInterview" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                        <div className="m-0 mb-3 number-count-bg">
                                                                                                            5
                                                                                                        </div>
                                                                                                        <h2 className={`${this.state.nestedActiveTab === "FinalInterview" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                            Final Interview
                                                                                                        </h2>
                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("FinalInterview")}
                                                                                                            value={this.state.nestedActiveTab === "FinalInterview" ? this.state.defaultLeadStageStatus : ""}

                                                                                                            id="finalInterviewDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "completed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "completed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "evaluated") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "evaluated", null)


                                                                                                                }
                                                                                                                if (event.target.value === "reviewed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "reviewed", null)


                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "closed", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="completed" >
                                                                                                                Completed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="reviewed" >
                                                                                                                Reviewed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="evaluated">
                                                                                                                Evaluated
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "offered" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>

                                                                                                        <div className="m-0 mb-3 number-count-bg ">
                                                                                                            6
                                                                                                        </div>
                                                                                                        <h2 className={`${this.state.nestedActiveTab === "offered" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                            Offered
                                                                                                        </h2>
                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("offered")}
                                                                                                            value={this.state.nestedActiveTab === "offered" ? this.state.defaultLeadStageStatus : ""}

                                                                                                            id="offeredDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "accepted") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "accepted", null)

                                                                                                                }
                                                                                                                if (event.target.value === "reviewed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "reviewed", null)


                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "closed", null)


                                                                                                                }
                                                                                                                if (event.target.value === "rejected") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "rejected", null)


                                                                                                                }
                                                                                                                if (event.target.value === "hold") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "hold", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="accepted" >
                                                                                                                Accepted
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="reviewed" >
                                                                                                                Reviewed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="rejected">
                                                                                                                Rejected
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="hold">
                                                                                                                Hold
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "contract_signed" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                        <div className="m-0 mb-3 number-count-bg">
                                                                                                            7
                                                                                                        </div>
                                                                                                        <h2 className={`${this.state.nestedActiveTab === "contract_signed" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                            Contract
                                                                                                        </h2>
                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("contract_signed")}
                                                                                                            value={this.state.nestedActiveTab === "contract_signed" ? this.state.defaultLeadStageStatus : ""}

                                                                                                            id="finalInterviewDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "signed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "signed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "hold") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "hold", null)


                                                                                                                }

                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "closed", null)

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="signed" >
                                                                                                                Signed
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="hold" >
                                                                                                                Hold
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col">
                                                                                                    <div className={`${this.state.nestedActiveTab === "joined" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                        <div className="m-0 mb-3 number-count-bg">
                                                                                                            8
                                                                                                        </div>
                                                                                                        <h2 className={`${this.state.nestedActiveTab === "joined" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                            Joined
                                                                                                        </h2>
                                                                                                        <select
                                                                                                            active-leads-status-select
                                                                                                            className="form-select mb-4 font-size-12 p-2"

                                                                                                            aria-label="Default select"
                                                                                                            style={activeLeadsSelectStyle("joined")}
                                                                                                            value={this.state.nestedActiveTab === "joined" ? this.state.defaultLeadStageStatus : ""}

                                                                                                            id="finalInterviewDropDown"
                                                                                                            onChange={(event) => {

                                                                                                                if (event.target.value === "pending") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "pending", null)

                                                                                                                }
                                                                                                                if (event.target.value === "processing") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "processing", null)

                                                                                                                }
                                                                                                                if (event.target.value === "completed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "completed", null)

                                                                                                                }
                                                                                                                if (event.target.value === "hold") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "hold", null)


                                                                                                                }
                                                                                                                if (event.target.value === "closed") {
                                                                                                                    this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "closed", null)


                                                                                                                }


                                                                                                            }}
                                                                                                        >
                                                                                                            <option className="f-size-10" value="" >
                                                                                                                Select status
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="pending" >
                                                                                                                Pending
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="processing" >
                                                                                                                Processing
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="hold" >
                                                                                                                Hold
                                                                                                            </option>
                                                                                                            <option className="f-size-10" value="completed" >
                                                                                                                Completed
                                                                                                            </option>

                                                                                                            <option className="f-size-10" value="closed">
                                                                                                                Closed
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}


                                                                                    {this.state.closedStatusChecked && (
                                                                                        <div className="row justify-content-center align-items-center g-0   pt-4 ">
                                                                                            <div className="col-md-8">
                                                                                                <div className="col-md-12 d-flex gap-2">
                                                                                                    <div className="col">
                                                                                                        <div className={`${this.state.nestedActiveTab === "black_listed" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                            <div className="m-0 mb-3 number-count-bg">
                                                                                                                1
                                                                                                            </div>
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "black_listed" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                                Black Listed
                                                                                                            </h2>
                                                                                                            <select
                                                                                                                active-leads-status-select
                                                                                                                className="form-select mb-4 font-size-12 p-2"

                                                                                                                aria-label="Default select"
                                                                                                                style={activeLeadsSelectStyle("black_listed")}
                                                                                                                value={this.state.nestedActiveTab === "black_listed" ? this.state.defaultLeadStageStatus : ""}

                                                                                                                id="blacklistedDropDown"
                                                                                                                onChange={(event) => {


                                                                                                                    if (event.target.value === "completed") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "closed", "black_listed", "completed", null)

                                                                                                                    }


                                                                                                                }}
                                                                                                            >
                                                                                                                <option className="f-size-10" value="" >
                                                                                                                    Select status
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="completed" >
                                                                                                                    Completed
                                                                                                                </option>

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <div className={`${this.state.nestedActiveTab === "hired" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                            <div className="m-0 mb-3 number-count-bg">
                                                                                                                2
                                                                                                            </div>
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "hired" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                                Hired
                                                                                                            </h2>
                                                                                                            <select
                                                                                                                active-leads-status-select
                                                                                                                className="form-select mb-4 font-size-12 p-2"

                                                                                                                aria-label="Default select"
                                                                                                                style={activeLeadsSelectStyle("hired")}
                                                                                                                value={this.state.nestedActiveTab === "hired" ? this.state.defaultLeadStageStatus : ""}

                                                                                                                id="blacklistedDropDown"
                                                                                                                onChange={(event) => {


                                                                                                                    if (event.target.value === "completed") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "closed", "hired", "completed", null)

                                                                                                                    }


                                                                                                                }}
                                                                                                            >
                                                                                                                <option className="f-size-10" value="" >
                                                                                                                    Select status
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="completed" >
                                                                                                                    Completed
                                                                                                                </option>

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col">
                                                                                                        <div className={`${this.state.nestedActiveTab === "rejected" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                            <div className="m-0 mb-3 number-count-bg">
                                                                                                                3
                                                                                                            </div>
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "rejected" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                                Rejected
                                                                                                            </h2>
                                                                                                            <select
                                                                                                                active-leads-status-select
                                                                                                                className="form-select mb-4 font-size-12 p-2"

                                                                                                                aria-label="Default select"
                                                                                                                style={activeLeadsSelectStyle("rejected")}
                                                                                                                value={this.state.nestedActiveTab === "rejected" ? this.state.defaultLeadStageStatus : ""}

                                                                                                                id="blacklistedDropDown"
                                                                                                                onChange={(event) => {


                                                                                                                    if (event.target.value === "completed") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "closed", "rejected", "completed", null)

                                                                                                                    }


                                                                                                                }}
                                                                                                            >
                                                                                                                <option className="f-size-10" value="" >
                                                                                                                    Select status
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="completed" >
                                                                                                                    Completed
                                                                                                                </option>

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <div className={`${this.state.nestedActiveTab === "not_interested" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                            <div className="m-0 mb-3 number-count-bg">
                                                                                                                4
                                                                                                            </div>
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "not_interested" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                                Not Interested
                                                                                                            </h2>
                                                                                                            <select
                                                                                                                active-leads-status-select
                                                                                                                className="form-select mb-4 font-size-12 p-2"

                                                                                                                aria-label="Default select"
                                                                                                                style={activeLeadsSelectStyle("not_interested")}
                                                                                                                value={this.state.nestedActiveTab === "not_interested" ? this.state.defaultLeadStageStatus : ""}

                                                                                                                id="blacklistedDropDown"
                                                                                                                onChange={(event) => {


                                                                                                                    if (event.target.value === "completed") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "closed", "not_interested", "completed", null)

                                                                                                                    }


                                                                                                                }}
                                                                                                            >
                                                                                                                <option className="f-size-10" value="" >
                                                                                                                    Select status
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="completed" >
                                                                                                                    Completed
                                                                                                                </option>

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <div className={`${this.state.nestedActiveTab === "disqualified" ? "text-center align-items-center p-2 pt-3 active-lead-status card" : "card text-center align-items-center p-2 pt-3"}`}>
                                                                                                            <div className="m-0 mb-3 number-count-bg">
                                                                                                                5
                                                                                                            </div>
                                                                                                            <h2 className={`${this.state.nestedActiveTab === "disqualified" ? "text-white f-size-12 f-weight-700 m-0 mb-5" : " f-color f-size-12 f-weight-700 m-0 mb-5"}`}>
                                                                                                                Disqualified
                                                                                                            </h2>
                                                                                                            <select
                                                                                                                active-leads-status-select
                                                                                                                className="form-select mb-4 font-size-12 p-2"

                                                                                                                aria-label="Default select"
                                                                                                                style={activeLeadsSelectStyle("disqualified")}
                                                                                                                value={this.state.nestedActiveTab === "disqualified" ? this.state.defaultLeadStageStatus : ""}

                                                                                                                id="blacklistedDropDown"
                                                                                                                onChange={(event) => {


                                                                                                                    if (event.target.value === "completed") {
                                                                                                                        this.updateLeadStatus(this.props.applicantId, "closed", "disqualified", "completed", null)

                                                                                                                    }


                                                                                                                }}
                                                                                                            >
                                                                                                                <option className="f-size-10" value="" >
                                                                                                                    Select status
                                                                                                                </option>
                                                                                                                <option className="f-size-10" value="completed" >
                                                                                                                    Completed
                                                                                                                </option>

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                </div>
                                                                                <div
                                                                                    className={`${this.state.activeTabLink === "lead-log" ? "tab-pane fade show active" : "tab-pane fade show "}`}
                                                                                    id="lead-log-tab-pane"
                                                                                    role="tabpanel"
                                                                                    aria-labelledby="log-tab"
                                                                                    tabIndex={0}
                                                                                >
                                                                                    <h1 className="f-size-16 mt-3 mb-3">Comments</h1>
                                                                                    <div className="row g-0 ">
                                                                                        <div className="d-flex gap-2 ">
                                                                                            <textarea name="comments"
                                                                                                type="text"
                                                                                                placeholder="Add a comment (Maximum 200 characters)"
                                                                                                maxLength="200"
                                                                                                value={this.state.comments}
                                                                                                onChange={(e) => { this.setState({ comments: e.target.value }) }}
                                                                                                className="form-control"
                                                                                            />


                                                                                            <button className="btn btn-primary no-border send-comment-btn d-flex align-items-center" type="button" style={{ height: '50px' }}
                                                                                                onClick={() => {
                                                                                                    if (this.state.comments.trim() === "") {
                                                                                                        this.setState({ isNoComments: true });

                                                                                                        return;
                                                                                                    }
                                                                                                    else if (this.state.comments.trim().length > 200) {
                                                                                                        this.setState({ isCommentsInvalid: true });

                                                                                                        return;
                                                                                                    }
                                                                                                    else { this.setState({ isNoComments: false }); }
                                                                                                    if (this.state.eventLeadTimeLine != '') {
                                                                                                        this.updateLeadTimeLineComment(this.props.applicantId, this.state.comments)
                                                                                                    }
                                                                                                    else {

                                                                                                        this.createLeadTimeLineComment(this.props.applicantId, this.state.comments)
                                                                                                    }
                                                                                                }}>

                                                                                                <span
                                                                                                    className="material-symbols-outlined"
                                                                                                    style={{ color: "#ffffff", fontSize: 20 }}
                                                                                                >
                                                                                                    send
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                        {this.state.isNoComments == true ?
                                                                                            <span id="errorNewtitle" class="form-label-secondary text-danger">Please enter comments.</span>
                                                                                            : null}
                                                                                        {this.state.isCommentsInvalid == true ?
                                                                                            <span id="errorNewtitle" class="form-label-secondary text-danger">Comments maximum lenght 200 charachters.</span>
                                                                                            : null}
                                                                                    </div>
                                                                                    <hr />
                                                                                    <div className="row g-0 ">
                                                                                        <div className="col-md-12">

                                                                                            {this.state.isLeadTimeLineFetched ?

                                                                                                <BeatLoaderComponent />
                                                                                                :
                                                                                                <ul className="list-comment">

                                                                                                    {(this.state.eventLeadTimeLine != undefined || this.state.eventLeadTimeLine != []) ?
                                                                                                        <>
                                                                                                            {this.state.eventLeadTimeLine.length > 0 ? this.state.eventLeadTimeLine.map((event, index) => (

                                                                                                                <>
                                                                                                                    <li className="list-item ">
                                                                                                                        {/* <!-- Media --> */}
                                                                                                                        <div
                                                                                                                            className="card comment-log p-3 mb-3"

                                                                                                                        >
                                                                                                                            <div className="row mb-2 g-0">
                                                                                                                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                                                                                                                    <div>
                                                                                                                                        <h1 className="m-0 f-color f-size-16 f-weight-600">
                                                                                                                                            {event.commentsBy}
                                                                                                                                        </h1>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <p className="m-0 f-size-14 f-weight-600">
                                                                                                                                            {convertUTCDateToBrowserLocale(event.eventDateUtc)}                                                                                                                                    </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <p className="f-size-14 f-gray">
                                                                                                                                {event.eventDetail != null ? event.eventDetail : event.comments}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        {/* <!-- End Media --> */}


                                                                                                                    </li>





                                                                                                                </>
                                                                                                            )) :
                                                                                                                <div className="card card-centered mb-3 mb-lg-5">
                                                                                                                    <div className="card-body py-10">
                                                                                                                        <img className="avatar avatar-xxl mb-3 " src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                                                                        <div className="text-center">
                                                                                                                            <h1>No log found!</h1>
                                                                                                                        </div>


                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <div className="card card-centered mb-3 mb-lg-5">
                                                                                                            <div className="card-body py-10">
                                                                                                                <img className="avatar avatar-xxl mb-3 " src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                                                                <div className="text-center">
                                                                                                                    <h1>No log found!</h1>
                                                                                                                </div>


                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }


                                                                                                </ul>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Desktop end here */}
                </>

                <>
                    {/* Mobile start here */}
                    <div className="row  align-items-center g-0 d-block d-lg-none">
                        <div className="col-md-12">
                            <div className="card no-border p-3 pt-4 ">
                                <div className="row g-0 ">
                                    <div className="col-md-12">
                                        <h1 className="f-size-16 f-weight-700">{this.state.leadDetails.firstName} {this.state.leadDetails.lastName} </h1>
                                        <p className="f-weight-500 f-size-10">
                                            Lead ID: <span className="f-weight-400">{this.state.leadDetails.leadId}</span>{" "}
                                            <span className="mx-2">|</span> Email:{" "}
                                            <span className="f-weight-400">{this.state.leadDetails.email}</span>{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="row justify-content-center align-items-center g-0  ">
                                    <div className="col-md-12 ">
                                        <div className="row g-0 pt-0 ">
                                            <div className="col-12">
                                                <ul
                                                    className="nav nav-tabs col-12 nav-tabs-bordered"

                                                    id="myTab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item col-4" role="presentation">
                                                        <button
                                                            className={`${this.state.activeTabLinkMobile === "lead-details-mb" ? "nav-link active f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500"}`}

                                                            id="lead-details-mb-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#lead-details-mb-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="lead-details-mb-tab-pane"
                                                            aria-selected="true"
                                                            onClick={() => {
                                                                this.setState({ activeTabLinkMobile: "lead-details-mb" })
                                                            }}
                                                        >
                                                            Details
                                                        </button>
                                                    </li>
                                                    <li className="nav-item  col-4" role="presentation">
                                                        <button
                                                            className={`${this.state.activeTabLinkMobile === "lead-status-mb" ? "nav-link active f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500"}`}
                                                            id="lead-status-mb-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#lead-status-mb-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="lead-status-mb-tab-pane"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.setState({ activeTabLinkMobile: "lead-status-mb" })
                                                            }}
                                                        >
                                                            Status
                                                        </button>
                                                    </li>
                                                    <li className="nav-item  col-4" role="presentation">
                                                        <button
                                                            className={`${this.state.activeTabLinkMobile === "lead-log-mb" ? "nav-link active f-size-14 f-weight-500" : "nav-link f-size-14 f-weight-500"}`}
                                                            id="lead-log-mb-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#lead-log-mb-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="lead-log-mb-tab-pane"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.setState({ activeTabLinkMobile: "lead-log-mb" })
                                                                console.log("azeem", this.state.leadDetails.cnic)
                                                            }}
                                                        >
                                                            Log
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="myTabContent">
                                                    <div
                                                        className={`${this.state.activeTabLinkMobile === "lead-details-mb" ? "tab-pane fade show active  pt-3" : "tab-pane fade show   pt-3"}`}

                                                        id="lead-details-mb-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="lead-details-mb-tab"
                                                        tabIndex={0}
                                                    >
                                                        <div className="row  g-0">
                                                            <div className="col-6 ">
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        JOB TITLE{" "}
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color text-overflow-leads">
                                                                        {this.state.leadDetails.jobTitle == null ? "-" : this.state.leadDetails.jobTitle}                                                                       </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        CONTACT NO.{" "}
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                        {this.state.leadDetails.mobile == null ? "-" : this.state.leadDetails.mobile}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        SSN
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">

                                                                        {this.state.leadDetails.cnic === null || this.state.leadDetails.cnic === ""
                                                                            ? "-"
                                                                            : this.state.leadDetails.cnic}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        CURRENT SALARY
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.currentSalary == null ? "-" : this.state.leadDetails.currentSalary}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                        SHORT-LISTED DATE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.shortListedDateUtc == null ? "-" : this.state.leadDetails.shortListedDateUtc}                                                                            </p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                            CALL CENTER EXPERIENCE
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {this.state.leadDetails.callCenterExperience == null ? "-" : this.state.leadDetails.callCenterExperience}                                                                                </p>
                                                                    </div>
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                            LEAD CLOSED REASON
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {this.state.leadDetails.leadClosedReason == null ? "-" : this.state.leadDetails.leadClosedReason}
                                                                        </p>
                                                                    </div>
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                            COUNTRY NAME
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {this.state.leadDetails.countryName == null ? "-" : this.state.leadDetails.countryName}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 ">
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        CITY NAME
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color overflow-text">
                                                                        {this.state.leadDetails.leadCityName == null ? "-" : this.state.leadDetails.leadCityName}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        EXPERIENCE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                        {this.state.leadDetails.experienceYears == null ? "-" : this.state.leadDetails.experienceYears} Months                                                                            </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        CURRENT COMPANY
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.currentEmployer == null ? "-" : this.state.leadDetails.currentEmployer}                                                                            </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        SHORT-LISTED
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.isShortListed == true ? "Yes" : "No"}                                                                            </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        APPLICANT CV
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color overflow-text">
                                                                        {(this.state.leadDetails.cvFilePath == null || this.state.leadDetails.cvFilePath == "") ? "[No CV]" : <a className="btn-link" type="button"
                                                                            href={this.state.leadDetails.cvFilePath} target="_blank"><i className="bi bi-cloud-arrow-down me-1" style={{ fontSize: '1.2rem' }}></i></a>}                                                                            </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        HOW SOON YOU CAN JOIN
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.howSoonYouCanJoin == null ? "-" : this.state.leadDetails.howSoonYouCanJoin}                                                                            </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        FACEBOOK PROFILE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.facebook == null ? "-" : this.state.leadDetails.facebook}                                                                            </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        LINKEDIN PROFILE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.leadDetails.linkedIn == null ? "-" : this.state.leadDetails.linkedIn}                                                                            </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`${this.state.activeTabLinkMobile === "lead-status-mb" ? "tab-pane fade show active  pt-3" : "tab-pane fade show   pt-3"}`}

                                                        id="lead-status-mb-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="lead-status-mb-tab"
                                                        tabIndex={0}
                                                    >
                                                        <div className="row g-0 mb-3">
                                                            <div className="col-md-12 d-flex ">
                                                                <div className="col">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input "
                                                                        id="open-status"
                                                                        style={{ marginRight: 8 }}
                                                                        checked={this.state.openStatusChecked}
                                                                        onChange={() => {
                                                                            this.setState((prevState) => ({
                                                                                openStatusChecked: !prevState.openStatusChecked,
                                                                                inprocessStatusChecked: false, closedStatusChecked: false,

                                                                            }));
                                                                        }}

                                                                    />
                                                                    <label
                                                                        className="form-check-label f-size-14"
                                                                        htmlFor="open-status"
                                                                    >
                                                                        Open
                                                                    </label>
                                                                </div>
                                                                <div className="col ">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="inprocess"
                                                                        style={{ marginRight: 8 }}
                                                                        checked={this.state.inprocessStatusChecked}
                                                                        onChange={() => {
                                                                            this.setState((prevState) => ({
                                                                                inprocessStatusChecked: !prevState.inprocessStatusChecked,
                                                                                openStatusChecked: false, closedStatusChecked: false,

                                                                            }));
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="form-check-label f-size-14"
                                                                        htmlFor="inprocess"
                                                                    >
                                                                        In-process
                                                                    </label>
                                                                </div>
                                                                <div className="col  ">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="closed-status"
                                                                        style={{ marginRight: 8 }}
                                                                        checked={this.state.closedStatusChecked}
                                                                        onChange={() => {
                                                                            this.setState((prevState) => ({
                                                                                closedStatusChecked: !prevState.closedStatusChecked,
                                                                                openStatusChecked: false, inprocessStatusChecked: false,

                                                                            }));
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="form-check-label f-size-14"
                                                                        htmlFor="closed-status"
                                                                    >
                                                                        Closed
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {this.state.openStatusChecked && (
                                                            <div className="row g-0">
                                                                <div className="col-md-12">
                                                                    <div className={`${this.state.nestedActiveTab === "lead_review_rating" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        1
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "lead_review_rating" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            Rating
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("lead_review_rating")}
                                                                                        value={this.state.nestedActiveTab === "lead_review_rating" ? this.state.defaultLeadStageStatus : ""}

                                                                                        onChange={(event) => {

                                                                                            if (event.target.value === "pending") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "open", "lead_review_rating", "pending", null)

                                                                                            }
                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "open", "lead_review_rating", "completed", null)

                                                                                            }


                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="pending" >
                                                                                            Pending
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed" >
                                                                                            Completed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state.inprocessStatusChecked && (
                                                            <div className="row g-0">
                                                                <div className="col-md-12">
                                                                    <div className={`${this.state.nestedActiveTab === "interview_with_hr" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        1
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "interview_with_hr" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            HR Interview
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("interview_with_hr")}
                                                                                        value={this.state.nestedActiveTab === "interview_with_hr" ? this.state.defaultLeadStageStatus : ""}
                                                                                        onChange={(event) => {
                                                                                            if (event.target.value === "pending") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "pending", null)

                                                                                            }
                                                                                            if (event.target.value === "processing") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "processing", null)

                                                                                            }
                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "completed", null)

                                                                                            }
                                                                                            if (event.target.value === "closed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "inprocess", "interview_with_hr", "closed", null)

                                                                                            }

                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="pending" >
                                                                                            Pending
                                                                                        </option>
                                                                                        <option className="f-size-10" value="processing" >
                                                                                            Processing
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed">
                                                                                            Completed
                                                                                        </option>
                                                                                        <option className="f-size-10" value="closed">
                                                                                            Closed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${this.state.nestedActiveTab === "shortlisted" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                            <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    2
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "shortlisted" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                        Short-listed
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    value={this.state.nestedActiveTab === "shortlisted" ? this.state.defaultLeadStageStatus : ""}
                                                                                    style={activeLeadsSelectStyle("shortlisted")}

                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "completed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "completed", null)

                                                                                        }
                                                                                        if (event.target.value === "rejected") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "rejected", null)


                                                                                        }
                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "shortlisted", "closed", null)

                                                                                        }

                                                                                    }}
                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="completed" >
                                                                                        Completed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="rejected">
                                                                                        Rejected
                                                                                    </option>
                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className={`${this.state.nestedActiveTab === "FirstInterview" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                                <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    3
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "FirstInterview" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                        1st technical interview
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    value={this.state.nestedActiveTab === "FirstInterview" ? this.state.defaultLeadStageStatus : ""}
                                                                                    style={activeLeadsSelectStyle("FirstInterview")}
                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "processing") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "processing", null)

                                                                                        }
                                                                                        if (event.target.value === "completed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "completed", null)

                                                                                        }
                                                                                        if (event.target.value === "evaluated") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "evaluated", null)


                                                                                        }
                                                                                        if (event.target.value === "reviewed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "reviewed", null)


                                                                                        }
                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FirstInterview", "closed", null)

                                                                                        }

                                                                                    }}
                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="processing" >
                                                                                        Processing
                                                                                    </option>

                                                                                    <option className="f-size-10" value="completed" >
                                                                                        Completed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="reviewed" >
                                                                                        Reviewed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="evaluated">
                                                                                        Evaluated
                                                                                    </option>
                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className={`${this.state.nestedActiveTab === "SecondInterview" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                                <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    4
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "SecondInterview" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                        2nd technical interview
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    value={this.state.nestedActiveTab === "SecondInterview" ? this.state.defaultLeadStageStatus : ""}
                                                                                    style={activeLeadsSelectStyle("SecondInterview")}
                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "processing") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "processing", null)

                                                                                        }
                                                                                        if (event.target.value === "completed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "completed", null)

                                                                                        }
                                                                                        if (event.target.value === "evaluated") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "evaluated", null)


                                                                                        }
                                                                                        if (event.target.value === "reviewed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "reviewed", null)


                                                                                        }
                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "SecondInterview", "closed", null)

                                                                                        }

                                                                                    }}
                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="processing" >
                                                                                        Processing
                                                                                    </option>

                                                                                    <option className="f-size-10" value="completed" >
                                                                                        Completed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="reviewed" >
                                                                                        Reviewed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="evaluated">
                                                                                        Evaluated
                                                                                    </option>
                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className={`${this.state.nestedActiveTab === "FinalInterview" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                                <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    5
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "FinalInterview" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                        Final interview
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    style={activeLeadsSelectStyle("FinalInterview")}
                                                                                    value={this.state.nestedActiveTab === "FinalInterview" ? this.state.defaultLeadStageStatus : ""}

                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "processing") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "processing", null)

                                                                                        }
                                                                                        if (event.target.value === "completed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "completed", null)

                                                                                        }
                                                                                        if (event.target.value === "evaluated") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "evaluated", null)


                                                                                        }
                                                                                        if (event.target.value === "reviewed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "reviewed", null)


                                                                                        }
                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "FinalInterview", "closed", null)

                                                                                        }

                                                                                    }}

                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="processing" >
                                                                                        Processing
                                                                                    </option>

                                                                                    <option className="f-size-10" value="completed" >
                                                                                        Completed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="reviewed" >
                                                                                        Reviewed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="evaluated">
                                                                                        Evaluated
                                                                                    </option>
                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className={`${this.state.nestedActiveTab === "offered" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                                  <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    6
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "offered" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>                                                                                                    Offered
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    style={activeLeadsSelectStyle("offered")}
                                                                                    value={this.state.nestedActiveTab === "offered" ? this.state.defaultLeadStageStatus : ""}

                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "processing") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "processing", null)

                                                                                        }
                                                                                        if (event.target.value === "accepted") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "accepted", null)

                                                                                        }
                                                                                        if (event.target.value === "reviewed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "reviewed", null)


                                                                                        }
                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "closed", null)


                                                                                        }
                                                                                        if (event.target.value === "rejected") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "rejected", null)


                                                                                        }
                                                                                        if (event.target.value === "hold") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "offered", "hold", null)

                                                                                        }

                                                                                    }}

                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="processing" >
                                                                                        Processing
                                                                                    </option>

                                                                                    <option className="f-size-10" value="accepted" >
                                                                                        Accepted
                                                                                    </option>
                                                                                    <option className="f-size-10" value="reviewed" >
                                                                                        Reviewed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="rejected">
                                                                                        Rejected
                                                                                    </option>
                                                                                    <option className="f-size-10" value="hold">
                                                                                        Hold
                                                                                    </option>
                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className={`${this.state.nestedActiveTab === "contract_signed" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                                <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    7
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "contract_signed" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>                                                                                                    Contract
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    style={activeLeadsSelectStyle("contract_signed")}
                                                                                    value={this.state.nestedActiveTab === "contract_signed" ? this.state.defaultLeadStageStatus : ""}
                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "processing") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "processing", null)

                                                                                        }
                                                                                        if (event.target.value === "signed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "signed", null)

                                                                                        }
                                                                                        if (event.target.value === "hold") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "hold", null)


                                                                                        }

                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "contract_signed", "closed", null)

                                                                                        }

                                                                                    }}

                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="processing" >
                                                                                        Processing
                                                                                    </option>

                                                                                    <option className="f-size-10" value="signed" >
                                                                                        Signed
                                                                                    </option>
                                                                                    <option className="f-size-10" value="hold" >
                                                                                        Hold
                                                                                    </option>

                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                    <div className={`${this.state.nestedActiveTab === "joined" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>                                                                                  <div className="row g-0">
                                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                                            <div className="col d-flex ">
                                                                                <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                    8
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center  mx-2 ">
                                                                                    <h1 className={`${this.state.nestedActiveTab === "joined" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>                                                                                                    Joined
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5 text-center">
                                                                                <select
                                                                                    className="form-select f-size-10 p-2"
                                                                                    aria-label="Default select"
                                                                                    id=""
                                                                                    style={activeLeadsSelectStyle("joined")}
                                                                                    value={this.state.nestedActiveTab === "joined" ? this.state.defaultLeadStageStatus : ""}

                                                                                    onChange={(event) => {

                                                                                        if (event.target.value === "pending") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "pending", null)

                                                                                        }
                                                                                        if (event.target.value === "processing") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "processing", null)

                                                                                        }
                                                                                        if (event.target.value === "completed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "completed", null)

                                                                                        }
                                                                                        if (event.target.value === "hold") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "hold", null)


                                                                                        }
                                                                                        if (event.target.value === "closed") {
                                                                                            this.updateLeadStatus(this.props.applicantId, "inprocess", "joined", "closed", null)


                                                                                        }


                                                                                    }}
                                                                                >
                                                                                    <option className="f-size-10" value="" >
                                                                                        Select status
                                                                                    </option>
                                                                                    <option className="f-size-10" value="pending" >
                                                                                        Pending
                                                                                    </option>
                                                                                    <option className="f-size-10" value="processing" >
                                                                                        Processing
                                                                                    </option>

                                                                                    <option className="f-size-10" value="hold" >
                                                                                        Hold
                                                                                    </option>
                                                                                    <option className="f-size-10" value="completed" >
                                                                                        Completed
                                                                                    </option>

                                                                                    <option className="f-size-10" value="closed">
                                                                                        Closed
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state.closedStatusChecked && (
                                                            <div className="row g-0">
                                                                <div className="col-md-12">
                                                                    <div className={`${this.state.nestedActiveTab === "black_listed" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        1
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "black_listed" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            Black Listed
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("black_listed")}
                                                                                        value={this.state.nestedActiveTab === "black_listed" ? this.state.defaultLeadStageStatus : ""}
                                                                                        onChange={(event) => {


                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "closed", "black_listed", "completed", null)

                                                                                            }


                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed" >
                                                                                            Completed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`${this.state.nestedActiveTab === "hired" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        2
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "hired" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            Hired
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("hired")}
                                                                                        value={this.state.nestedActiveTab === "hired" ? this.state.defaultLeadStageStatus : ""}

                                                                                        onChange={(event) => {


                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "closed", "hired", "completed", null)

                                                                                            }


                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed" >
                                                                                            Completed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`${this.state.nestedActiveTab === "rejected" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        3
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "rejected" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            Rejected
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("rejected")}
                                                                                        value={this.state.nestedActiveTab === "rejected" ? this.state.defaultLeadStageStatus : ""}
                                                                                        onChange={(event) => {


                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "closed", "rejected", "completed", null)

                                                                                            }


                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed" >
                                                                                            Completed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`${this.state.nestedActiveTab === "not_interested" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        4
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "not_interested" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            Not Interested

                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("not_interested")}
                                                                                        value={this.state.nestedActiveTab === "not_interested" ? this.state.defaultLeadStageStatus : ""}

                                                                                        onChange={(event) => {


                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "closed", "not_interested", "completed", null)

                                                                                            }


                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed" >
                                                                                            Completed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`${this.state.nestedActiveTab === "disqualified" ? "active-lead-status-mb card p-2 mb-3" : "card  p-2 mb-3"}`}>
                                                                        <div className="row g-0">
                                                                            <div className="col-12 d-flex align-items-center justify-content-center">
                                                                                <div className="col d-flex ">
                                                                                    <div className="m-0 f-weight-700 f-size-10 number-count-bg-mb">
                                                                                        5
                                                                                    </div>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-2 ">
                                                                                        <h1 className={`${this.state.nestedActiveTab === "disqualified" ? "f-weight-700 f-size-11 text-white m-0" : "f-weight-700 f-size-11 f-color m-0"}`}>
                                                                                            Disqualified
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5 text-center">
                                                                                    <select
                                                                                        className="form-select f-size-10 p-2"
                                                                                        aria-label="Default select"
                                                                                        id=""
                                                                                        style={activeLeadsSelectStyle("disqualified")}
                                                                                        value={this.state.nestedActiveTab === "disqualified" ? this.state.defaultLeadStageStatus : ""}

                                                                                        onChange={(event) => {


                                                                                            if (event.target.value === "completed") {
                                                                                                this.updateLeadStatus(this.props.applicantId, "closed", "disqualified", "completed", null)

                                                                                            }


                                                                                        }}
                                                                                    >
                                                                                        <option className="f-size-10" value="" >
                                                                                            Select status
                                                                                        </option>
                                                                                        <option className="f-size-10" value="completed" >
                                                                                            Completed
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div
                                                        className={`${this.state.activeTabLinkMobile === "lead-log-mb" ? "tab-pane fade show active  pt-3" : "tab-pane fade show   pt-3"}`}
                                                        id="lead-log-mb-tab"
                                                        role="tabpanel"
                                                        aria-labelledby="lead-log-mb-tab-pane"
                                                        tabIndex={0}
                                                    >
                                                        <h1 className="f-size-16  mb-1">Comments</h1>
                                                        <div className="row g-0 ">
                                                            <div className="d-flex gap-2 ">
                                                                <textarea name="comments"
                                                                    type="text"
                                                                    placeholder="Add a comment (Maximum 200 characters)"
                                                                    maxLength="200"
                                                                    value={this.state.comments}
                                                                    onChange={(e) => { this.setState({ comments: e.target.value }) }}
                                                                    className="form-control"
                                                                />


                                                                <button className="btn btn-primary no-border send-comment-btn d-flex align-items-center" type="button" style={{ height: '50px' }}
                                                                    onClick={() => {
                                                                        if (this.state.comments.trim() === "") {
                                                                            this.setState({ isNoComments: true });

                                                                            return;
                                                                        }
                                                                        else if (this.state.comments.trim().length > 200) {
                                                                            this.setState({ isCommentsInvalid: true });

                                                                            return;
                                                                        }
                                                                        else { this.setState({ isNoComments: false }); }
                                                                        if (this.state.eventLeadTimeLine != '') {
                                                                            this.updateLeadTimeLineComment(this.props.applicantId, this.state.comments)
                                                                        }
                                                                        else {

                                                                            this.createLeadTimeLineComment(this.props.applicantId, this.state.comments)
                                                                        }
                                                                    }}>

                                                                    <span
                                                                        className="material-symbols-outlined"
                                                                        style={{ color: "#ffffff", fontSize: 20 }}
                                                                    >
                                                                        send
                                                                    </span>
                                                                </button>

                                                            </div>
                                                            {this.state.isNoComments == true ?
                                                                <span id="errorNewtitle" class="form-label-secondary text-danger">Please enter comments.</span>
                                                                : null}
                                                            {this.state.isCommentsInvalid == true ?
                                                                <span id="errorNewtitle" class="form-label-secondary text-danger">Comments maximum lenght 200 charachters.</span>
                                                                : null}
                                                        </div>
                                                        <hr />
                                                        <div className="row g-0 ">
                                                            <div className="col-md-12">

                                                                {this.state.isLeadTimeLineFetched ?

                                                                    <BeatLoaderComponent />
                                                                    :
                                                                    <ul className="list-comment">

                                                                        {(this.state.eventLeadTimeLine != undefined || this.state.eventLeadTimeLine != []) ?
                                                                            <>
                                                                                {this.state.eventLeadTimeLine.length > 0 ? this.state.eventLeadTimeLine.map((event, index) => (

                                                                                    <>
                                                                                        <li className="list-item ">
                                                                                            {/* <!-- Media --> */}
                                                                                            <div
                                                                                                className="card comment-log p-3 mb-3"

                                                                                            >
                                                                                                <div className="row mb-2 g-0">
                                                                                                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                                                                                                        <div>
                                                                                                            <h1 className="m-0 f-color f-size-16 f-weight-600">
                                                                                                                {event.commentsBy}
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <p className="m-0 f-size-12 f-weight-600">
                                                                                                                {convertUTCDateToBrowserLocale(event.eventDateUtc)}                                                                                                                                    </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className="f-size-12 f-gray">
                                                                                                    {event.eventDetail != null ? event.eventDetail : event.comments}
                                                                                                </p>
                                                                                            </div>
                                                                                            {/* <!-- End Media --> */}


                                                                                        </li>





                                                                                    </>
                                                                                )) :
                                                                                    <div className="card card-centered mb-3 mb-lg-5">
                                                                                        <div className="card-body py-10">
                                                                                            <img className="avatar avatar-xxl mb-3 " src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                                            <div className="text-center">
                                                                                                <h1>No log found!</h1>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <div className="card card-centered mb-3 mb-lg-5">
                                                                                <div className="card-body py-10">
                                                                                    <img className="avatar avatar-xxl mb-3 " src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                                    <div className="text-center">
                                                                                        <h1>No log found!</h1>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        }


                                                                    </ul>
                                                                }



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mobile end here */}
                        </div>
                    </div>
                </>





                {this.state.ratingModal &&
                    <div className=" d-block modal" id="AddIntroVideo" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header ">
                                    <h4 className="modal-title whiteColor" id="accountUpdatePlanModalLabel">Rating Update</h4>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ ratingModal: false }) }}></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">


                                    <div className="row">
                                        {/* <!-- Card --> */}

                                        <h3 className="h4 inter-ff" >Do you want to update rating?</h3>


                                        {/* <!-- End Card --> */}

                                    </div>
                                    {/* <!-- End Row --> */}



                                    <div className="d-flex justify-content-center justify-content-sm-end gap-3">
                                        <button type="button" className="btn btn-primary" disabled={this.state.enableRating} data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.updateLeadRatings(this.state.leadDetails.leadId, selectedRating, this.state.tenantEmail) }}>Yes</button>
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ ratingModal: false }) }}>No</button>

                                    </div>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                }



                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}

export default LeadDetails;

