import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { parseToken } from '../../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';

import { Link } from 'react-router-dom';
import { ApiCall } from '../../../modules/ApiCall';
import axios from 'axios';
import { parseJwt } from '../../../modules/TokenParser';
import { ToastContainer } from 'react-toastify';
import SuccessQuestionSet from "../../../assets/svg/illustrations/oc-hi-five.svg";
import ReactTooltip from 'react-tooltip';
import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import { Oval } from 'react-loader-spinner'

class CreateSkill extends Component {

    constructor() {
        super();
        this.state = {
            industries: [],
            parentSkills: [],
            selectedIndustry: "",
            selectedParentSkill: "",
            selectedParentSkillId: "",
            selectedIndustryId: "",
            parentSkill: "",
            createParentSkillModal: false,
            skill: "",
            SuccessMessage: false,
            disableSaveBtn: false,
            isSkillSaving : 0
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Tenant App - Create Skill";

        this.getIndustrieslookup();
    }

    /**
     * Industries from look up*/
    getIndustrieslookup() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let industryId = null;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data != null) {
                        this.setState({
                            selectedIndustry: response.data[0].title,
                            selectedIndustryId: response.data[0].defaultIndustryId,
                            industries: response.data,
                        });
                        industryId = response.data[0].defaultIndustryId;
                    }
                    else
                        console.error("Unable to get industries");


                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Industy lookup!', "top-center");

                });
        }

    }

    /** input values event onchange
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


   /**
    * Save the tenant skills in DefaultSkills table with TeantId
    * @param {any} event
    * @returns
    */

    handleSubmit(event) {

        if (this.state.skill.trim() === "" || this.state.skill === undefined)
        {
            displayWarningMessage('Please enter skill!', "top-center");
            return;
        }

        if (this.state.skill.trim().includes(",")) {
            displayWarningMessage('Comma (,) is not allowed in Skills!', "top-center");
            return;
        }

        let bearer = validateAndGetFormatedToken();
        var tenantId = null;
        this.setState({ isSkillSaving: 1 });
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let data = {
                Title: this.state.skill,
                ShortDesc: "",
                IsDefault: false,
                TenantId: tenantId,
                IsActive: true,
            }

            let url = process.env.REACT_APP_CORE_API + '/api/TenantSkills';
            var model = JSON.stringify(data);
            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    
                    this.setState({ isSkillSaving: 0 });
                    if (result.isSuccess) {
                        displaySuccessMessage('Skill Saved Successfully!', "top-center");
                        window.location.reload(true);
                    }
                    else {
                        displayErrorMessage(result.message, "top-center");
                    }
                }).catch(err => {
                    this.setState({ isSkillSaving: 0 });
                    console.log(err);
                    displayErrorMessage('Error while Saving Skill!', "top-center");
                });
        }

    }



    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>
                                {/* <!-- End Col --> */}
                                <div className="col-auto">
                                    <button className="btn btn-outline-primary" onClick={() => this.props.history.goBack()}>
                                        <i className="bi bi-arrow-left me-1"></i> Back
                                    </button>
                                </div>
                                {/* <!-- End Col --> */}
                            </div>
                            {/* <!-- End Row --> */}
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />

                            </div>
                        </div>
                        {/* <!-- End Page Header --> */}
                        <>
                            {this.state.SuccessMessage ?

                                <div id="validationFormSuccessMessage" className="js-success-message">
                                    <div className="text-center">
                                        <img className="img-fluid mb-3" src={SuccessQuestionSet} alt="Image Description" style={{ maxWidth: '15rem' }} />

                                        <div className="mb-4">
                                            <h2>Successful!</h2>
                                            <p>Your Skill have been successfully saved.</p>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-white me-3" onClick={() => { this.props.history.goBack() }}>
                                                <i className="bi-chevron-left small ms-1"></i> Back to Skills
                                            </button>
                                            <button className="btn btn-primary" onClick={() => { window.location.reload(true); }}>
                                                <i className="tio-city me-1"></i> Create New Skill
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>



                                    <div className="row">
                                        {/* <!--Find me--> */}

                                        <div className="col-lg-12">
                                            <div className="d-grid gap-3 gap-lg-5">

                                                {/* <!-- Card --> */}
                                                <div id="descriptionSection" className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Create Skill</h4>
                                                    </div>

                                                    {/* <!-- Body --> */}
                                                    <div className="card-body">
                                                        {/*<div className="row mb-4">*/}
                                                        {/*    <label for="industries" className="col-sm-3 col-form-label form-label">Industries <span className="form-label-secondary text-danger">*</span></label>*/}

                                                        {/*    <div className="col-sm-9">*/}
                                                        {/*        */}{/* <!-- Select --> */}
                                                        {/*        <div className="tom-select-custom mb-4">*/}
                                                        {/*            <select className="form-select"*/}
                                                        {/*                name="industries" onChange={(e) => {*/}

                                                        {/*                    this.setState({ selectedIndustryId: e.target.value });*/}
                                                        {/*                    this.getParentSkills(e.target.value);*/}

                                                        {/*                }}>*/}


                                                        {/*                {this.state.industries != [] && this.state.industries.map((industry, index) =>*/}
                                                        {/*                    <option key={index} value={industry.defaultIndustryId}>{industry.title}</option>*/}


                                                        {/*                )}*/}

                                                        {/*            </select>*/}

                                                        {/*        </div>*/}
                                                        {/*        */}{/* <!-- End Select --> */}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="row mb-4">*/}
                                                        {/*    <label for="parentSkills" className="col-sm-3 col-form-label form-label">Parent Skills <span className="form-label-secondary text-danger">*</span></label>*/}

                                                        {/*    <div className="col-sm-8">*/}
                                                        {/*        */}{/* <!-- Select --> */}
                                                        {/*        <div className="tom-select-custom mb-4">*/}
                                                        {/*            <select className="form-select"*/}
                                                        {/*                name="parentSkills" onChange={(e) => {*/}
                                                        {/*                    console.log("selectedParentSkillId", e.target.value)*/}
                                                        {/*                    this.setState({ selectedParentSkillId: e.target.value });*/}
                                                        {/*                }}>*/}


                                                        {/*                {this.state.parentSkills != [] && this.state.parentSkills.map((parentSkill, index) =>*/}
                                                        {/*                    <option key={index} value={parentSkill.defaultSkillId}>{parentSkill.title}</option>*/}


                                                        {/*                )}*/}

                                                        {/*            </select>*/}

                                                        {/*        </div>*/}
                                                        {/*        */}{/* <!-- End Select --> */}
                                                        {/*    </div>*/}
                                                        {/*    <label className="col-sm-1 col-form-label form-label"> <i className="bi bi-plus-circle-fill text-danger fw-bold" style={{ cursor: 'pointer' }} data-tip data-for='createParentSkillTitle' onClick={() => { this.setState({ createParentSkillModal: true }) }}></i>*/}
                                                        {/*        <ReactTooltip id="createParentSkillTitle" place="top" type="dark" effect="float" >*/}
                                                        {/*            <span> If Parent Skill not found, you can create by clicking </span>*/}
                                                        {/*        </ReactTooltip></label>*/}
                                                        {/*</div>*/}

                                                        {/* <!-- Form --> */}
                                                        <form>
                                                            {/* <!-- Form --> */}
                                                            <div className="row mb-4">
                                                                <label for="skill" className="col-sm-2 col-form-label form-label">Skill <span className="form-label-secondary text-danger">*</span></label>

                                                                <div className="col-sm-8">
                                                                    <input type="text" className="form-control" onChange={this.handleInputChange} maxLength="30" name="skill" id="skill" aria-label="Your department" />


                                                                </div>

                                                            </div>
                                                            {/* <!-- End Form --> */}

                                                        </form>
                                                        {/* <!-- End Form --> */}
                                                    </div>
                                                    {/* <!-- End Body --> */}
                                                </div>
                                                {/* <!-- End Card --> */}

                                                {this.state.isSkillSaving > 0 && (
                                                    <div class="overlay">
                                                        <div className="overlay-content">
                                                            <Oval
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                color="#4fa94d"
                                                                ariaLabel="oval-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-end gap-3">
                                                    <Link type="button" className="btn btn-white" onClick={() => { this.props.history.goBack() }}>Cancel</Link>
                                                    <button type="button" id="btnSave" className="btn btn-primary" onClick={this.handleSubmit}>Save</button>
                                                </div>
                                            </div>
                                            {/* <!-- Sticky Block End Point --> */}
                                            <div id="stickyBlockEndPoint"></div>
                                        </div>
                                    </div>
                                    {/* <!-- End Row --> */}
                                </>}
                        </>



                        {/* <!-- End Step Form --> */}
                        {/* <!--End Stepper--> */}

                    </div>

                    {/* <!-- End Content --> */}

                </main>
                {this.state.createParentSkillModal &&
                    <div className=" d-block modal" id="createParentSkillModal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header">
                                    <h4 className="modal-title whiteColor" id="createParentSkillModal">Create Parent Skill</h4>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ createParentSkillModal: false }) }}></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">


                                    <div className="row">
                                        {/* <!--Find me--> */}

                                        <div className="col-lg-12">
                                            <div className="d-grid gap-3 gap-lg-5">

                                                {/* <!-- Card --> */}

                                                <div className="row mb-4">
                                                    <label for="parentSkill" className="col-sm-3 col-form-label form-label">Parent Skill <span className="form-label-secondary text-danger">*</span></label>

                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" onChange={this.handleInputChange} maxLength="30" name="parentSkill" id="parentSkill" aria-label="Your department" />


                                                    </div>



                                                </div>
                                            </div>

                                            {/* <!-- Sticky Block End Point --> */}
                                            <div id="stickyBlockEndPoint"></div>
                                        </div>
                                    </div>
                                    {/* <!-- End Row --> */}
                                    <div className="d-flex justify-content-center justify-content-sm-end gap-3">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                            this.saveParentSkill();
                                        }}>Save</button>
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ createParentSkillModal: false }) }}>Cancel</button>
                                    </div>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                }
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}
export default CreateSkill;