import React, { Component } from 'react';


class Footer extends Component {
    render() {
        const isCollapsed = this.props;
        const currentYear = new Date().getFullYear();
        return (
            // <!-- Footer -->

            <div className="footer swift-footer-wrapper">
                <div className="row justify-content-between align-items-center">
                    <div className={this.props.isCollapsed ? "col collapse-footer-margin" : "col expand-footer-margin "}>
                        <p className="fs-6 mb-0 ">&copy; {currentYear} SwiftRecruit. </p>

                    </div>
                    {/* <!-- End Col --> */}

                    <div className="col-auto">
                        <div className="d-flex justify-content-end">
                            {/* <!-- List Separator --> */}
                            <ul className="list-inline list-separator">
                                <li className="list-inline-item">
                                    <a className="list-separator-link" target="_blank" href="https://swiftrecruit.com/Home/Privacy" rel="noreferrer">Privacy Policy</a>
                                </li>

                                <li className="list-inline-item">
                                    <a className="list-separator-link" target="_blank" href="https://swiftrecruit.com/Home/Privacy" rel="noreferrer">Terms</a>
                                </li>
                            </ul>
                            {/* <!-- End List Separator --> */}
                        </div>
                    </div>
                    {/* <!-- End Col --> */}
                </div>
                {/* <!-- End Row --> */}
            </div>

            // <!-- End Footer -->
        );
    }
}
export default Footer;