import React, { Component } from 'react';
import { displayErrorMessage } from '../../modules/ToastMessage';
import { parseToken } from '../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import { Link } from 'react-router-dom';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../Loader/BeatLoader";
import { ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import SrQuestionlisting from "./../../assets/img/new-design/sr-questions-listing.png";

class QuestionSets extends Component {
    constructor() {
        super();
        this.state = {
            questionsset: [],
            createQuestionSetPermission: true,
            editQuestionSetPermission: true,
            isloadedgetQuestionSets: true,
            itemOffset: 0,
            filteredData: [],
            currentPage:"",

        }
    }

    componentDidMount() {
        document.title = "Tenant App - QuestionSets";

        this.getPermissions();
        this.getQuestionSets();

    }

    getQuestionSets() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet";


            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        questionsset: result,
                        filteredData: result,
                        isloadedgetQuestionSets: false
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Question sets!', "top-center");

                });
        }
    }

    getPermissions() {
        let bearer = validateAndGetFormatedToken();
        var createQuestionSetPermission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {
                createQuestionSetPermission = CheckPermission(parseToken(bearer).roles, "Permissions.QuestionSet.Create", bearer);
                this.setState({ createQuestionSetPermission: createQuestionSetPermission });

            }

        }
    }


    render() {
        const { questionsset } = this.state;

        const filteration = () => {
            let filteredData = questionsset.length > 0 && questionsset.filter((item) => {
                return item.questionSetName != null && item.questionSetName.toLowerCase().indexOf(this.state.filterQuestionSetName != null && this.state.filterQuestionSetName.trim().toLowerCase()) > -1

            }
            );
            this.setState({
                filteredData: filteredData
            })
        }

        //for pagination
        const endOffset = this.state.itemOffset + 9;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.filteredData.slice(this.state.itemOffset, endOffset);
        const pageCount = Math.ceil(this.state.filteredData.length / 9);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 9) % this.state.filteredData.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            this.setState({ itemOffset: newOffset, currentPage :event.selected});
        };
        return (
            <>
                <main id="content" role="main"
                    // className="main"
                    className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}
                >
                    {/* <!-- Content --> */}
                    <div className="container-fluid">
                        {/* <!--Enter row below here--> */}
                        <div className="row mt-3">
                            {/* <!--Enter cole below here--> */}
                            <div className="offset-lg-2 col-lg-9 col-md-12 p-0">
                                
                                {/* Job Card With Image */}
                                <div className="card h-swift-130 jc-radius border-0 mb-2">
                                    <div className="row g-0">
                                        <div className="col-md-12">
                                            <div className="job-card-img jc-pad">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h5 className="card-title jc-title mb-0">
                                                        Question Sets
                                                    </h5>
                                                    <img
                                                        src={SrQuestionlisting}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Start content for Question Sets--> */}
                                {this.state.isloadedgetQuestionSets ?
                                    <BeatLoaderComponent />
                                    :
                                    <>
                                        {this.state.questionsset.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                            <div className="card-body py-10">
                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                <div className="text-center">

                                                    <h1>You have not created anything  yet.</h1>
                                                    <p>Once you create, they'll show up here.</p>
                                                    <Link className="btn btn-primary" to="/createquestionset"><i className="bi bi-plus-lg me-1"></i>Get started now!</Link>
                                                </div>

                                            </div>
                                        </div>
                                            : <>
                                                <div className="card h-swift-80 jc-radius border-0 mb-2">
                                                    <div className="row   p-3">

                                                        <div className="col-md-9 col-9">
                                                            <div className="input-group input-group-merge">
                                                                <div className="input-group-prepend input-group-text">
                                                                    <i className="bi-search"></i>
                                                                </div>
                                                                <input type="search" className="form-control" placeholder="Search Question Sets" onChange={(e) => {
                                                                    this.setState({ filterQuestionSetName: e.target.value })
                                                                    if (e.target.value == "") {
                                                                        this.setState({ filteredData: this.state.questionsset })
                                                                    }
                                                                }}
                                                                    name="questionSetName" id="questionSetName"
                                                                    onKeyDown={(event) => {
                                                                        if (event.key === 'Enter') {
                                                                            event.preventDefault();

                                                                            filteration();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 d-none d-md-block">
                                                            <Link className="btn btn-primary" to="/createquestionset">
                                                                <i className="bi bi-plus-circle me-1"></i> Create New
                                                            </Link>
                                                        </div>
                                                        <div className="col-3 d-block d-md-none">
                                                            <Link className="btn btn-primary" to="/createquestionset">
                                                                <i className="bi bi-plus-circle me-1"></i>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>

                                                {currentItems.length <= 0 && <div className="card card-centered mb-3 mb-lg-5">
                                                    <div className="card-body py-10">
                                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                        <div className="text-center">

                                                            <h1>No Record Found.</h1>
                                                        </div>

                                                    </div>
                                                </div>}
                                                <div className="card jc-radius border-0 mb-2 p-2">
                                                    {/* <!-- Connections --> */}
                                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                                        {currentItems != [] && currentItems.map((questionsset, index) => {
                                                            return <div className="col mb-3 mb-lg-5" key={index}>

                                                                <Link className="card card-leads bg-q-set" to={"/viewquestionset/" + questionsset.questionSetId}>
                                                                    <div className="card-body">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0">

                                                                                <div className="job-icon-square">

                                                                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_1639_40152)">
                                                                                            <path d="M37.8647 2.71924H1.44023C0.646078 2.71924 0 3.36532 0 4.15947V13.0798C0 13.8739 0.646078 14.52 1.44023 14.52H37.8647C38.6588 14.52 39.3049 13.8739 39.3049 13.0798V4.15947C39.305 3.36532 38.6589 2.71924 37.8647 2.71924ZM9.66367 13.1262H1.44031C1.4147 13.1262 1.39382 13.1054 1.39382 13.0797V4.15938C1.39382 4.13377 1.41462 4.11289 1.44031 4.11289H9.66367V13.1262ZM37.9112 13.0798C37.9112 13.1054 37.8904 13.1263 37.8647 13.1263H11.0574V4.11297H37.8647C37.8902 4.11297 37.9112 4.13377 37.9112 4.15947V13.0798Z" fill="#482D7D" />
                                                                                            <path d="M25.9706 6.43604H13.3336C12.9487 6.43604 12.6367 6.74807 12.6367 7.1329C12.6367 7.51773 12.9488 7.82977 13.3336 7.82977H25.9706C26.3555 7.82977 26.6675 7.51773 26.6675 7.1329C26.6675 6.74807 26.3555 6.43604 25.9706 6.43604Z" fill="#482D7D" />
                                                                                            <path d="M34.8922 9.40938H20.025C19.64 9.40938 19.3281 9.72142 19.3281 10.1062C19.3281 10.4911 19.6402 10.8031 20.025 10.8031H34.8922C35.2771 10.8031 35.589 10.4911 35.589 10.1062C35.589 9.72142 35.277 9.40938 34.8922 9.40938Z" fill="#482D7D" />
                                                                                            <path d="M17.0506 9.40938H13.3336C12.9487 9.40938 12.6367 9.72142 12.6367 10.1062C12.6367 10.4911 12.9488 10.8031 13.3336 10.8031H17.0506C17.4355 10.8031 17.7474 10.4911 17.7474 10.1062C17.7474 9.72142 17.4354 9.40938 17.0506 9.40938Z" fill="#482D7D" />
                                                                                            <path d="M5.52792 4.94931C4.11889 4.94931 2.97266 6.09563 2.97266 7.50458C2.97266 7.88949 3.2847 8.20144 3.66952 8.20144C4.05435 8.20144 4.36639 7.8894 4.36639 7.50458C4.36639 6.86408 4.88743 6.34304 5.52792 6.34304C6.16841 6.34304 6.68945 6.86417 6.68945 7.50458C6.68945 8.14498 6.16841 8.66611 5.52792 8.66611C5.14301 8.66611 4.83105 8.97815 4.83105 9.36297C4.83105 9.7478 5.14309 10.0598 5.52792 10.0598C6.93695 10.0598 8.08319 8.91361 8.08319 7.50458C8.08319 6.09554 6.93695 4.94931 5.52792 4.94931Z" fill="#482D7D" />
                                                                                            <path d="M5.5365 10.8961H5.52911C5.1442 10.8961 4.83594 11.2081 4.83594 11.593C4.83594 11.9778 5.15167 12.2898 5.53659 12.2898C5.9215 12.2898 6.23345 11.9778 6.23345 11.593C6.23345 11.2081 5.92133 10.8961 5.5365 10.8961Z" fill="#482D7D" />
                                                                                            <path d="M25.9706 19.8164H13.3336C12.9487 19.8164 12.6367 20.1284 12.6367 20.5133C12.6367 20.8981 12.9488 21.2101 13.3336 21.2101H25.9706C26.3555 21.2101 26.6675 20.8981 26.6675 20.5133C26.6675 20.1284 26.3555 19.8164 25.9706 19.8164Z" fill="#482D7D" />
                                                                                            <path d="M32.6621 22.7899H20.025C19.6401 22.7899 19.3281 23.1019 19.3281 23.4867C19.3281 23.8715 19.6402 24.1836 20.025 24.1836H32.6621C33.047 24.1836 33.359 23.8715 33.359 23.4867C33.359 23.1019 33.0469 22.7899 32.6621 22.7899Z" fill="#482D7D" />
                                                                                            <path d="M17.0506 22.7899H13.3336C12.9487 22.7899 12.6367 23.1019 12.6367 23.4867C12.6367 23.8715 12.9488 24.1836 13.3336 24.1836H17.0506C17.4355 24.1836 17.7474 23.8715 17.7474 23.4867C17.7474 23.1019 17.4354 22.7899 17.0506 22.7899Z" fill="#482D7D" />
                                                                                            <path d="M5.52792 18.3297C4.11889 18.3297 2.97266 19.4759 2.97266 20.885C2.97266 21.2699 3.2847 21.5818 3.66952 21.5818C4.05435 21.5818 4.36639 21.2698 4.36639 20.885C4.36639 20.2445 4.88743 19.7234 5.52792 19.7234C6.16841 19.7234 6.68945 20.2445 6.68945 20.885C6.68945 21.5255 6.16841 22.0465 5.52792 22.0465C5.14301 22.0465 4.83105 22.3585 4.83105 22.7434C4.83105 23.1282 5.14309 23.4402 5.52792 23.4402C6.93695 23.4402 8.08319 22.294 8.08319 20.885C8.08319 19.4759 6.93695 18.3297 5.52792 18.3297Z" fill="#482D7D" />
                                                                                            <path d="M5.5365 24.2766H5.52911C5.1442 24.2766 4.83594 24.5886 4.83594 24.9734C4.83594 25.3583 5.15167 25.6703 5.53659 25.6703C5.9215 25.6703 6.23345 25.3583 6.23345 24.9734C6.23345 24.5886 5.92133 24.2766 5.5365 24.2766Z" fill="#482D7D" />
                                                                                            <path d="M25.9706 33.1968H13.3336C12.9487 33.1968 12.6367 33.5088 12.6367 33.8937C12.6367 34.2785 12.9488 34.5905 13.3336 34.5905H25.9706C26.3555 34.5905 26.6675 34.2785 26.6675 33.8937C26.6675 33.5088 26.3555 33.1968 25.9706 33.1968Z" fill="#482D7D" />
                                                                                            <path d="M33.4055 36.1702H20.025C19.6401 36.1702 19.3281 36.4823 19.3281 36.8671C19.3281 37.2519 19.6402 37.564 20.025 37.564H33.4055C33.7904 37.564 34.1023 37.2519 34.1023 36.8671C34.1023 36.4823 33.7903 36.1702 33.4055 36.1702Z" fill="#482D7D" />
                                                                                            <path d="M17.0506 36.1702H13.3336C12.9487 36.1702 12.6367 36.4823 12.6367 36.8671C12.6367 37.2519 12.9488 37.564 13.3336 37.564H17.0506C17.4355 37.564 17.7474 37.2519 17.7474 36.8671C17.7474 36.4823 17.4354 36.1702 17.0506 36.1702Z" fill="#482D7D" />
                                                                                            <path d="M5.52792 31.7101C4.11889 31.7101 2.97266 32.8563 2.97266 34.2653C2.97266 34.6503 3.2847 34.9622 3.66952 34.9622C4.05435 34.9622 4.36639 34.6502 4.36639 34.2653C4.36639 33.6249 4.88743 33.1038 5.52792 33.1038C6.16841 33.1038 6.68945 33.6249 6.68945 34.2653C6.68945 34.9058 6.16841 35.4269 5.52792 35.4269C5.14301 35.4269 4.83105 35.7389 4.83105 36.1237C4.83105 36.5086 5.14309 36.8206 5.52792 36.8206C6.93695 36.8206 8.08319 35.6744 8.08319 34.2653C8.08319 32.8564 6.93695 31.7101 5.52792 31.7101Z" fill="#482D7D" />
                                                                                            <path d="M5.5365 37.657H5.52911C5.1442 37.657 4.83594 37.969 4.83594 38.3538C4.83594 38.7386 5.15167 39.0507 5.53659 39.0507C5.9215 39.0507 6.23345 38.7386 6.23345 38.3538C6.23345 37.969 5.92133 37.657 5.5365 37.657Z" fill="#482D7D" />
                                                                                            <path d="M43.9704 31.3564C44.0444 31.1107 43.9774 30.844 43.7959 30.6625L39.3049 26.1715V17.5398C39.3049 16.7457 38.6588 16.0996 37.8647 16.0996H1.44023C0.646078 16.0996 0 16.7457 0 17.5398V26.4601C0 27.2543 0.646078 27.9004 1.44023 27.9004H34.9377V29.48H1.44023C0.646078 29.48 0 30.1261 0 30.9203V39.8406C0 40.6348 0.646078 41.2808 1.44023 41.2808H37.8647C38.6588 41.2808 39.3049 40.6348 39.3049 39.8406V37.705L39.9549 39.2744C40.0257 39.4451 40.1613 39.5808 40.3321 39.6516C40.4175 39.6869 40.5082 39.7046 40.5988 39.7046C40.6894 39.7046 40.78 39.687 40.8654 39.6516L43.366 38.6157C43.7216 38.4685 43.8904 38.0608 43.7431 37.7052L41.4776 32.2358L43.4412 31.8384C43.693 31.7874 43.8963 31.6022 43.9704 31.3564ZM9.66367 26.5066H1.44031C1.4147 26.5066 1.39382 26.4858 1.39382 26.4601V17.5398C1.39382 17.5142 1.41462 17.4933 1.44031 17.4933H9.66367V26.5066ZM11.0574 26.5066V17.4934H37.8647C37.8903 17.4934 37.9112 17.5142 37.9112 17.5399V24.7778L36.1274 22.994C35.9281 22.7947 35.6282 22.7351 35.3679 22.843C35.1074 22.9508 34.9377 23.205 34.9377 23.4868V26.5067L11.0574 26.5066ZM9.66367 39.887H1.44031C1.4147 39.887 1.39382 39.8662 1.39382 39.8405V30.9203C1.39382 30.8947 1.41462 30.8738 1.44031 30.8738H9.66367V39.887ZM37.9112 39.8405C37.9112 39.8661 37.8904 39.887 37.8647 39.887H11.0574V30.8738H34.9377V34.3129C34.9377 34.5689 35.0781 34.8043 35.3034 34.926C35.5286 35.0477 35.8025 35.0361 36.0167 34.8958L37.6877 33.8005L37.9112 34.34V39.8405ZM40.3717 31.0375C40.1676 31.0789 39.9927 31.2093 39.8948 31.3931C39.7969 31.577 39.7864 31.7949 39.8661 31.9874L42.1888 37.5948L40.976 38.0972L38.6533 32.4898C38.5739 32.298 38.4131 32.1516 38.2148 32.0905C38.1475 32.0698 38.0784 32.0596 38.0096 32.0596C37.8754 32.0596 37.7424 32.0983 37.6276 32.1736L36.3317 33.023V25.1693L41.8924 30.73L40.3717 31.0375Z" fill="#3FB5C3" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1639_40152">
                                                                                                <rect width="44" height="44" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>



                                                                                </div>

                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <span class="js-counter d-block display-3 text-dark f-size-26" data-value={this.state.itemOffset + index + 1}>{this.state.itemOffset + index + 1}</span>

                                                                                <h2 className="text-break fw-semibold text-capitalize f-size-14">{questionsset.questionSetName}</h2>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Link>
                                                                {/* <!-- End Card 1 Grid--> */}
                                                            </div>

                                                            {/* <!-- End Col --> */ }

                                                        })}




                                                    </div>
                                                    {/* <!-- End Connections --> */}


                                                    <div class="card  jc-t-pad h-swift-92 mb-3">
                                                        <div className="pagination-wrapper ">
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={
                                                                    <>
                                                                        <button
                                                                            className={`btn ${this.state.currentPage < pageCount - 1 ? "btn-primary" : "btn-light"} border-swift btn-swift-text swift-shadow`}
                                                                        >
                                                                            Next <i className="bi bi-arrow-right ms-1"></i>
                                                                        </button>
                                                                    </>
                                                                }
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel={
                                                                    <>
                                                                        <button
                                                                            className={`btn ${this.state.currentPage > 0 ? "btn-primary" : "btn-light"} border-swift btn-swift-text swift-shadow`}
                                                                        >
                                                                            <i className="bi bi-arrow-left"></i> Previous 
                                                                        </button>
                                                                    </>}

                                                                renderOnZeroPageCount={null}
                                                                breakClassName={"page-item"}
                                                                breakLinkClassName={"page-link"}
                                                                containerClassName={"pagination pagination-swift d-flex"}
                                                                pageClassName={"page-item mobile-hidden"}
                                                                pageLinkClassName={"page-link mobile-hidden"}
                                                                previousClassName={"page-item"}
                                                                previousLinkClassName={"page-link"}
                                                                nextClassName={"page-item"}
                                                                nextLinkClassName={"page-link"}
                                                                activeClassName={"active"} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        }

                                    </>

                                }


                            </div>

                        </div>

                        {/* <!--enter row above here--> */}
                    </div>
                    {/* <!-- End Content --> */}



                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}
export default QuestionSets;