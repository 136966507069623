import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default class OidcCallback extends Component {
    static displayName = OidcCallback.name;

    constructor(props)
    {
        super(props);
        this.state = {
            isAuthenticated: false
        };

        var urlParams = new URLSearchParams(window.location.hash);

        if (urlParams.has('#id_token'))
        {
            var idtoken = urlParams.get('#id_token');
            //console.info(idtoken);
        }
        if (urlParams.has('access_token')) {
            var accesstoken = urlParams.get('access_token');
            //console.info(accesstoken);
        }
        else
        {
            console.info("unable to access token");
        }


        //console.info("------------------------------")
        localStorage.setItem("overall", window.location.hash);
      

        localStorage.setItem("id_token", idtoken);
        localStorage.setItem("access_token", accesstoken);
    }



    render()
    {
        return (<Redirect to="/"></Redirect>);       
    }
}
