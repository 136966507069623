import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { stringToBoolean } from '../../../modules/Utilities';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'


class EditTenantIndustry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            industryTitle: "",
            industryShortDesc: "",
            industryIsActive: true,
            tenantIndustryId: this.props.match.params.tenantindustryId,
            disableSaveBtn: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";

        await this.getIndustry(this.props.match.params.tenantindustryId)
    }

    /** Get the Application User id from token
 * return {string} : applicationUserId | null */
    getApplicationUserIdFromToken() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var appUserId = null;

            appUserId = parseJwt(bearer).sub;
            return appUserId;
        }
        return null;
    }


    async getIndustry(tenantindustryId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/GetTenantIndustry/" + tenantindustryId;
            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        industryTitle: result.title,
                        industryShortDesc: result.shortDesc,
                        industryIsActive: result.isActive,
                    })
                })
                .catch(err => {
                    console.log("get user", err);
                    displayErrorMessage('Error while Fetching Industry !', "top-center");

                });
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleUpdate(event) {
        if (this.state.industryTitle == "") {
            displayWarningMessage('Please fill title field!', "top-center");
            return;
        }
        if (this.state.industryShortDesc == "") {
            displayWarningMessage('Please fill description field!', "top-center");

        }

        else {

            let inputModel = {};
            inputModel.TenantIndustryId = this.state.tenantIndustryId;
            inputModel.Title = this.state.industryTitle;
            inputModel.ShortDesc = this.state.industryShortDesc;
            inputModel.IsActive = this.state.industryIsActive;
            this.setState({
                disableSaveBtn: true
            });

            let bearer = validateAndGetFormatedToken();
            if (bearer != "" || bearer != null) {
                var model = JSON.stringify(inputModel);

                let url = process.env.REACT_APP_CORE_API + '/api/TenantIndustry/UpdateTenantIndustry';

                const requestOptions = {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: model
                };


                fetch(url, requestOptions)
                    .then((response) => {
                        console.log("Industry Updated", response);
                        this.setState({
                            industryTitle: "",
                            industryShortDesc: ""
                        })
                        this.setState({
                            disableSaveBtn: false
                        });
                        setTimeout(function () {
                            window.location.replace('/tenantindustrysettings');
                        }, 5000);
                        displaySuccessMessage("Industry Updated Successfully", "top-center");
                    })


                    .catch(() => {
                        displayErrorMessage('Error During Industry Creation!', "top-center");
                        this.setState({
                            disableSaveBtn: false
                        });
                    });


            }




        }
    }



    render() {

        return (
            <>


                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>


                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Edit Industry</h4>


                            </div>
                            <div className="card-body">

                                <div className="row mb-4">
                                    <label for="industryTitle" className="col-sm-3 col-form-label form-label">Industry Title <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" name="industryTitle" id="industryTitle" value={this.state.industryTitle} placeholder="Industry name" aria-label="Your first name" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="industryShortDesc" className="col-sm-3 col-form-label form-label">Industry Short description <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" name="industryShortDesc" id="industryShortDesc" value={this.state.industryShortDesc} placeholder="Industry description" aria-label="Your first name" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label form-label">Is Active <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="col-lg form-check" onChange={this.handleInputChange}>

                                            <input type="radio" value={true} name="industryIsActive" checked={this.state.industryIsActive == true} /> <span className="form-check-label mx-2">Active</span>
                                            <input type="radio" value={false} name="industryIsActive" checked={this.state.industryIsActive == false} /> <span className="form-check-label mx-2">InActive</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex gap-3">
                                        <Link type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" to="/tenantindustrysettings">Cancel</Link>
                                        <button type="button" className="btn btn-primary" disabled={this.state.disableSaveBtn} onClick={(e) => this.handleUpdate(e)}>Update </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default EditTenantIndustry;
