/** Funtion validate access_token key on localstorage
 *  and return [true] if Token exisit in local storage */
function verifyTokenOnLocal()
{
    var token = localStorage.getItem("access_token");

    if ((token !== null) && (token !== undefined) && (token !== '') && (token !== 'undefined'))
    {
         return true;
    }
    else
    {
        return false;
    }  
}

/** Get the access token from local storage
 * if no token found the it return null
 * */
function getAccessToken()
{
    var token =  localStorage.getItem("access_token");
    if ((token !== null) && (token !== undefined) && (token !== '') && (token !== 'undefined')) {
        return token;
    }
    console.error("Error  :: ==> No access token found!");
    return null;
}


/** Get the bearer access token from local storage
 * if no token found the it return null
 * Format [Bearer Access-Token]
 * */
function getBearerAccessToken() {
    var access_token = localStorage.getItem("access_token");

    if ((access_token !== null) && (access_token !== undefined) && (access_token !== '') && (access_token !== 'undefined')) {
        return 'Bearer ' + access_token;
    }
    else { console.error("Error  :: ==> No access token found!"); } 

    return null;
}



module.exports = { verifyTokenOnLocal, getAccessToken, getBearerAccessToken };




