import React, { Component } from "react";
import {
    displayErrorMessage,
    displayWarningMessage, displaySuccessMessage,
} from "../../modules/ToastMessage";
import { validateAndGetFormatedToken } from "../../modules/CheckToken";
import axios from "axios";
import { parseJwt } from "../../modules/TokenParser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { ToastContainer } from "react-toastify";
import { stateFromHTML } from "draft-js-import-html";
import { Link } from "react-router-dom";
import SrQuestionlisting from "./../../assets/img/new-design/create-questions.png";
import Congratulations from "./common/Congratulations";
import { right } from "@popperjs/core";
import Select from "react-select";
import { Oval } from 'react-loader-spinner'

class UpdateQuestion extends Component {
    constructor() {
        super();
        this.state = {
            questions: [],
            questiontitle: "",
            description: "",
            difficultylevel: "",
            questiontype: "text",
            answertype: "video",
            tags: "",
            search: "",
            QuestionId: "",
            UpdateQuestion: {},
            Questions: [],
            editorState: "",
            addQuestionUrl: false,
            questionUrl: "",
            tenantVideoMetaId: 0,
            numberOfAttempts: null,
            thinkTime: null,
            timeLimit: null,
            wordCount: 0,
            maxWords: 800,
            marks: null,
            correctAnswers: [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
            ],
            correctOption: "",
            questiontypevalues: [],
            answertypevalues: [],

            //Dummy Question set for
            questionsset: [],
            tenantSubscriptionId: 0,
            tenantSubscriptionDataPermissionThinkTime: {},
            tenantSubscriptionDataPermissionNoOfAttempts: {},
            tenantSubscriptionDataPermissionTimeLimit: {},
            disableSaveBtn: false,
            answerOption: "",
            answerOptions: [],
            correctOptions: [],
            comprehensionQuestion: "",
            comprehensionQuestions: [],
            questionContentType: "",
            questionContentTypes: [{ label: "Basic", title: "Basic", value: "basic" }, { label: "Grammar", title: "Grammar", value: "grammar" }, { label: "Comprehension", title: "Comprehension", value: "comprehension" }],
            selectedContentType: "",
            questiontitle: "",
            confirmationModal: false,
            isAutoTranscriptionLoading: 0,
            autoTranscriptionButtonApplied: false,
            autoTranscriptionButtonCanceled: false,
            questionVideoPlayModal:false,

        };
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addOptions = this.addOptions.bind(this);
        this.addComprehensionQuestions = this.addComprehensionQuestions.bind(this);
    }

    componentDidMount() {
        document.title = "Tenant App - Questions";

        this.getTenantSubscriptionid();
        this.getQuestion(this.props.match.params.questionId);
        this.getQuestionVideos();
        //  this.getQuestionTypes();
        //  this.getAnswerTypes();
    }

    /** Get list of videos */
    getQuestionVideos() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url =
                process.env.REACT_APP_CORE_API +
                "/api/Tenant/" +
                tenantId +
                "/Question";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        questionVideos: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching intro outro videos!",
                        "top-center"
                    );
                });
        }
    }

    getQuestionTypes() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionTypesLookup";

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log("QuestionTypes", result);
                    let tempResult = [];
                    Object.keys(result).map((i) => {
                        tempResult.push({ label: i, value: result[i] });
                    });
                    console.log("tempResult", tempResult);
                    this.setState({
                        questiontypevalues: tempResult,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching Question Types!",
                        "top-center"
                    );
                });
        }
    }

    getAnswerTypes() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/AnswerTypesLookup";

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log("answertypevalues", result);
                    let tempResult = [];
                    Object.keys(result).map((i) => {
                        console.log("i", i);
                        tempResult.push({ label: i, value: result[i] });
                    });
                    console.log("tempResult", tempResult);
                    this.setState({
                        answertypevalues: tempResult,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching Answer Types!",
                        "top-center"
                    );
                });
        }
    }

    getQuestion(questionId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url =
                process.env.REACT_APP_CORE_API +
                "/api/Questions/GetQuestion/" +
                questionId;
            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log("result for get specific question", result);
                    this.setState({
                        QuestionId: result.questionId,
                        description: result.description,
                        editorState: EditorState.createWithContent(
                            stateFromHTML(result.description)
                        ),
                        questiontype: result.questionType,
                        answertype: result.answerType,
                        tags: result.tags,
                        questiontitle: result.title,
                        questionUrl: result.videoFileUrl,
                        numberOfAttempts: result.numberOfAttempts,
                        thinkTime: result.thinkTime,
                        timeLimit: result.timeLimit,
                        tenantVideoMetaId: result.tenantVideoMetaId,
                        selectedContentType: result.questionContentType === undefined ? "" : result.questionContentType,
                        correctOption: result.correctOption,
                        //marks: result.marks
                    });

                    if (this.state.selectedContentType.toLowerCase() === "comprehension") {
                        let paraphraseQuestionTitle = result.title.split("<ParaphraseQuestions>");
                        if (paraphraseQuestionTitle.length > 1) {

                            let paraphraseQuestionList = paraphraseQuestionTitle[1].split("<li>");

                            let paraphraseQuestions = [];
                            paraphraseQuestionList.map((question) => {
                                if (question !== "") {
                                    question = question.replace('</li>', '').replace('</ParaphraseQuestions></div>', '');
                                    paraphraseQuestions.push({
                                        label: question,
                                        title: question,
                                        value: question
                                    });
                                }
                            });

                            this.setState({ comprehensionQuestions: paraphraseQuestions, comprehensionQuestion: "", questiontitle: paraphraseQuestionTitle[0] });
                        }
                    }

                    if (this.state.selectedContentType.toLowerCase() === "grammar") {
                        let grammarQuestionTitle = result.title.split("<GrammarOptions>");
                        if (grammarQuestionTitle.length > 1) {
                            let grammarOptionList = grammarQuestionTitle[1].split("<li>");

                            let grammarOptions = [];
                            grammarOptionList.map((option) => {
                                if (option !== "") {
                                    option = option.replace('</li>', '').replace('</GrammarOptions></div>', '');
                                    grammarOptions.push({
                                        label: option,
                                        title: option,
                                        value: option
                                    });
                                }
                            });

                            this.setState({ answerOptions: grammarOptions, questiontitle: grammarQuestionTitle[0] });
                        }
                    }
                })
                .catch((err) => {
                    console.log("get question", err);
                    displayErrorMessage(
                        "Error while Fetching specific Question !",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get the tenant subscription id
     */
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url =
                process.env.REACT_APP_IDENTITY_API +
                "/TenantSubscription/GetTenantSubscriptions?tenantId=" +
                tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data.length > 0) {
                        this.setState({
                            tenantSubscriptionId: response.data[0].tenantSubscriptionId,
                        });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                })
                .then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "TimeLimit");
                })
                .then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "NoOfAttempts");
                })
                .then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "ThinkTime");
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Tenant Subscription Id",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get the tenant subscriotion feature key value
     * @param {number} tenantSubscriptionId
     * @param {string} featureKey
     */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url =
                process.env.REACT_APP_CORE_API +
                "/api/TenantSubscriptionData/" +
                tenantSubscriptionId +
                "/" +
                featureKey;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data.featureKey === "TimeLimit") {
                        this.setState({
                            tenantSubscriptionDataPermissionTimeLimit: response.data,
                        });
                    }
                    if (response.data.featureKey === "NoOfAttempts") {
                        this.setState({
                            tenantSubscriptionDataPermissionNoOfAttempts: response.data,
                        });
                    }
                    if (response.data.featureKey === "ThinkTime") {
                        this.setState({
                            tenantSubscriptionDataPermissionThinkTime: response.data,
                        });
                    }

                    console.log("tenantSubscriptionData", response.data);
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Tenant Subscription Data!",
                        "top-center"
                    );
                });
        }
    }

    onEditorStateChange(editorState) {
        const contentState = editorState.getCurrentContent();
        const descriptionHtml = draftToHtml(convertToRaw(contentState));
        const descriptionCharCount = contentState.getPlainText().length;

        if (descriptionCharCount <= this.state.maxDescriptionLength) {
            this.setState({
                editorState: editorState,
                description: descriptionHtml,
                descriptionCharCount: descriptionCharCount,
            });
        }
    }


    /**
     * Input changes get from user inputs
     * @returns
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }


    handleCorrectOption = (selectedOption) => {
        //this.setState({ correctOption: selectedOption.value, selectedOption: "" })
        this.state.correctOption = selectedOption.value;
    }

    handleContentType = (selectedOption) => {
        this.setState({
            selectedContentType: selectedOption.value,
        })

    }

    addOptions() {
        if (this.state.answerOption.trim() === "" || this.state.answerOption === null) {
            displayErrorMessage("Pleasse add a valid option!", "top-center");

            return;
        }

        const found = this.state.answerOptions.find(obj => {
            return obj.value.toLowerCase() === this.state.answerOption.toLowerCase().trim();
        });

        if (found) {
            displayWarningMessage("Option already added in list", "top-center");
            return;
        }

        if (this.state.answerOptions.length >= 10) {
            displayErrorMessage("Max 10 option can add!", "top-center");
            return;
        }
        let options = [...this.state.answerOptions];
        options.push({
            label: this.state.answerOption,
            title: this.state.answerOption,
            value: this.state.answerOption
        });

        this.setState({ answerOptions: options, answerOption: "" });
    }

    handleDeleteClick = (index) => {
        const updatedAnswerOptions = [...this.state.answerOptions];

        var removedOption = updatedAnswerOptions[index].value;
        if (removedOption === this.state.correctOption.trim()) {
            this.setState({ correctOption: "" });
        } 

        updatedAnswerOptions.splice(index, 1);
        this.setState({ answerOptions: updatedAnswerOptions });
    }

    addComprehensionQuestions() {
        if (this.state.comprehensionQuestion.trim() === "" || this.state.comprehensionQuestion === null) {
            return;
        }

        const found = this.state.comprehensionQuestions.find(obj => {
            return obj.value.toLowerCase() === this.state.comprehensionQuestion.toLowerCase().trim();
        });

        if (found) {
            displayWarningMessage("Question already added in list", "top-center");
            return;
        }

        if (this.state.comprehensionQuestions.length >= 10) {
            displayErrorMessage("Max 10 question can add!", "top-center");
            return;
        }
        let questions = [...this.state.comprehensionQuestions];
        questions.push({
            label: this.state.comprehensionQuestion,
            title: this.state.comprehensionQuestion,
            value: this.state.comprehensionQuestion
        });

        this.setState({ comprehensionQuestions: questions, comprehensionQuestion: "" });
    }
    handleQuestionDeleteClick = (index) => {
        const updatedComprehensionQuestions = [...this.state.comprehensionQuestions];
        updatedComprehensionQuestions.splice(index, 1);

        this.setState({ comprehensionQuestions: updatedComprehensionQuestions });
    }

    handleSubmit() {
        if (this.state.selectedContentType.toLowerCase() !== "grammar" && this.state.selectedContentType.toLowerCase() !== "comprehension") {

            if (this.state.description === "") {
                displayWarningMessage("Please fill all required fields", "top-center");
                return;
            }
            if (this.state.description.trim().length < 50) {
                displayWarningMessage(
                    "min length of description is 50!",
                    "top-center"
                );

                return;
            }
            if (this.state.description.trim().length > 800) {
                displayWarningMessage(
                    "max length of description is 800!",
                    "top-center"
                );

                return;
            }
        }
        if (this.state.questiontitle === "") {
            displayWarningMessage("Please fill all required fields", "top-center");
            return;
        }

        if (this.state.questiontitle.trim().length < 3) {
            displayWarningMessage(
                "min length of question title is 3!",
                "top-center"
            );

            return;
        }
        if (this.state.questiontitle.trim.length > 70) {
            displayWarningMessage(
                "max length of question title is 70!",
                "top-center"
            );
            return;
        }

        if (this.state.questiontype === "video" && this.state.tenantVideoMetaId === 0) {
            displayWarningMessage("Please add question video!", "top-center");
            return;
        }

        if (this.state.numberOfAttempts % 1 !== 0) {
            displayWarningMessage(
                "Please add integer numberOfAttempts!",
                "top-center"
            );
            return;
        }
        if (this.state.thinkTime % 1 !== 0) {
            displayWarningMessage("Please add integer thinkTime!", "top-center");
            return;
        }
        if (this.state.timeLimit % 1 !== 0) {
            displayWarningMessage("Please add integer timeLimit!", "top-center");
            return;
        }
        if (!this.state.numberOfAttempts) {
            displayWarningMessage(
                "Please add Number of attempts!",
                "top-center"
            );
            return;
        }
        if (this.state.numberOfAttempts < 0) {
            displayWarningMessage(
                "Number of attempts should be greater than 0!",
                "top-center"
            );
            return;
        }
        if (this.state.thinkTime < 0) {
            displayWarningMessage(
                "Think time should be greater than 0!",
                "top-center"
            );
            return;
        }
        if (this.state.timeLimit < 30) {
            displayWarningMessage(
                "Time limit should be greater than 30!",
                "top-center"
            );
            return;
        }
        if (this.state.thinkTime < 30) {
            displayWarningMessage(
                "Think time should be greater than 30!",
                "top-center"
            );
            return;
        }
        if (this.state.numberOfAttempts > 5) {
            displayWarningMessage(
                "Number of attempts excceeds the limit i.e 5!",
                "top-center"
            );
            return;
        }
        if (this.state.thinkTime > 300) {
            displayWarningMessage(
                "Think time exceeds the limit i.e 300s (5 mints)!",
                "top-center"
            );
            return;
        }

        if (this.state.selectedContentType.toLowerCase() === "grammar") {

            if (this.state.correctOption === "") {
                displayErrorMessage("Please provide correct option!", "top-center");
                return;
            }
            if (this.state.answerOptions.length <= 0) {
                displayErrorMessage("Please provide option(s)!", "top-center");
                return;
            }
            this.state.questiontype = "text";
            this.state.answertype = "text";

            let questionOptions = "<GrammarOptions>";

            this.state.answerOptions.map((option) => {
                questionOptions += "<li>" + option.title + "</li>";
            });
            questionOptions += "</GrammarOptions>";

            questionOptions = (this.state.questiontitle + questionOptions + '</div>');

            this.state.questiontitle = questionOptions;
        }

        if (this.state.selectedContentType.toLowerCase() === "comprehension") {

            if (this.state.comprehensionQuestions.length <= 0) {
                displayErrorMessage("Please provide question(s)!", "top-center");
                return;
            }

            let questionOptions = "<ParaphraseQuestions>";

            this.state.comprehensionQuestions.map((option) => {
                questionOptions += "<li>" + option.title + "</li>";
            });
            questionOptions += "</ParaphraseQuestions>";

            questionOptions = (this.state.questiontitle + questionOptions + '</div>');

            this.state.questiontitle = questionOptions;
        }

        if (this.state.timeLimit > 300) {
            displayWarningMessage(
                "Time limit exceeds the limit i.e 300s (5 mints)!",
                "top-center"
            );
            return;
        } else {
            let bearer = validateAndGetFormatedToken();

            if (bearer != "" || bearer != null) {
                this.setState({ disableSaveBtn: true });
                let url =
                    process.env.REACT_APP_CORE_API + "/api/questions/updatequestion ";
                axios
                    .put(
                        url,
                        {
                            QuestionId: this.state.QuestionId,
                            Title: this.state.questiontitle.trim(),
                            description: this.state.description.trim(),
                            difficultylevel: this.state.difficultylevel,
                            questiontype: this.state.questiontype,
                            answertype: this.state.answertype,
                            tags: this.state.tags,
                            correctOption: this.state.correctOption,
                            tenantVideoMetaId: this.state.tenantVideoMetaId,
                            numberOfAttempts: this.state.numberOfAttempts,
                            thinkTime: this.state.thinkTime,
                            timeLimit: this.state.timeLimit,
                            correctOptionsCSV: this.state.correctOption,
                            questionContentType: this.state.selectedContentType
                        },
                        {
                            headers: {
                                Authorization: bearer,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((response) => {
                        // displaySuccessMessage('Question Updated Successfully!', "top-center");

                        this.setState({
                            questions: [],
                            questiontitle: "",
                            description: "",
                            tags: "",
                            SuccessMessage: true,
                            disableSaveBtn: false,
                        });
                    })
                    .catch((error) => {
                        console.log("error is", error.response);
                        this.setState({ disableSaveBtn: false });
                        displayErrorMessage(error.response.data, "top-center");
                    });
            }
        }
    }

    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        const wordcount = this.countCharacters(data); // Use a separate function to count characters
        console.log(data);
        console.log("length", wordcount);

        this.setState({ description: data });
        this.setState({ wordCount: wordcount });
    };

    handleGrammarEditorChange = (event, editor) => {
        const data = editor.getData();
        const wordcount = this.countCharacters(data); // Use a separate function to count characters
        console.log(data);
        console.log("length", wordcount);

        this.setState({ questiontitle: data });
        this.setState({ wordCount: wordcount });
    };

    countCharacters = (text) => {
        // Remove HTML tags and count characters
        const cleanText = text.replace(/<[^>]*>/g, '');
        return cleanText.length;
    };


    //function to handle video transcription
    applyTranscription = async () => {
        let bearer = validateAndGetFormatedToken();
        let url = process.env.REACT_APP_CORE_API + "/api/VideoTranscript";

        try {
            const response = await axios.post(
                url,
                {
                    tenantVideoMetaId: this.state.tenantVideoMetaId,
                    questionVideoURL: this.state.questionUrl,
                }
            );

            this.setState({ questiontitle: response.data, isAutoTranscriptionLoading: 0 });
            displaySuccessMessage("Video's Auto Transcription has been processed!", "top-center");

        } catch (error) {
            this.setState({ questiontitle: "", isAutoTranscriptionLoading: 0 });

            // Handle error response
            if (error.response) {
                // Server responded with a status other than 2xx
                console.error('Error:', error.response.status, error.response.data);

                if (error.response.status === 400) {
                    displayErrorMessage("No speech could be recognized in the video file. Please ensure the video contains clear speech and try again.", "top-center");
                } else if (error.response.status === 415) {
                    displayErrorMessage(" There is some issue with media file sound, Either it contains no sound or have some other issue, unable to generate transcription!", "top-center");

                }
                else {
                    displayErrorMessage("Unable to Auto Transcript the video!", "top-center");
                }
            } else if (error.request) {
                // Request was made but no response received
                console.error('Error: No response received', error.request);
                displayErrorMessage("Unable to Auto Transcript the video!", "top-center");
            } else {
                // Other errors
                console.error('Error:', error.message);
                displayErrorMessage("Unable to Auto Transcript the video!", "top-center");
            }
        }
    }

 
    render() {
        return (
            <>
                <main id="content" role="main"
                    className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}
                // className="main"
                >
                    <section id="main-right">
                        {this.state.SuccessMessage ? (
                            <div
                                id="validationFormSuccessMessage"
                                className="js-success-message"
                            >
                                <Congratulations history={this.props.history} redirectFromUpdateQuestion={true} />
                            </div>
                        ) : (
                                <div className={this.state.confirmationModal > 0 && this.state.selectedContentType.toLowerCase() === "comprehension" ? 'container - fluid blur-background' : 'container - fluid'}>

                                    {this.state.isAutoTranscriptionLoading > 0 && (
                                        <div class="overlay">
                                            <div className="overlay-content" style={{ top: '25%' }}>
                                                <Oval
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        </div>
                                    )}
                                <div className="row mt-3">
                                    {/* Swift Form */}

                                    <div className="offset-lg-2 col-lg-9 col-md-12 pb-4">
                                        <div className="swift-form-job">
                                           

                                            {/* Job Card With Image */}
                                            <div className="card h-swift-130 jc-radius border-0 mb-3">
                                                <div className="row g-0">
                                                    <div className="col-md-12">
                                                        <div className="job-card-img jc-pad">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <h5 className="card-title jc-title mb-0">
                                                                    Update Question
                                                                </h5>
                                                                <img
                                                                    src={SrQuestionlisting}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card border-0 jc-radius mb-3">

                                                <div className="row g-0">

                                                    <div className="col-md-12">
                                                        <div className="card-body jc-t-pad">

                                                            <form
                                                                id="create-job"
                                                                action="#"
                                                            >
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-2 mb-sm-0 mt-4">
                                                                        <label
                                                                            for="questionTitle"
                                                                            className="form-label f-size-14 fw-bold"
                                                                        >
                                                                            Question Content Type{" "}
                                                                            <span className="asterisk"> *</span>
                                                                        </label>

                                                                        <Select
                                                                            id="questionContentTypes"
                                                                            name="questionContentTypes"
                                                                            value={this.state.questionContentTypes.find(option => option.value === this.state.selectedContentType)}
                                                                            options={this.state.questionContentTypes}
                                                                            onChange={this.handleContentType}
                                                                            className="marg-b-10"
                                                                            placeholder="Select content type"
                                                                            isDisabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {this.state.selectedContentType !==
                                                                    "" && (
                                                                        <div className="row mt-2">

                                                                            <div class="col col-md-8 swift-radio-group radio-btn-types">

                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="questiontype"
                                                                                        class="form-label f-size-14 fw-bold"
                                                                                    >
                                                                                        Question Type
                                                                                        <span class="asterisk"> *</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div
                                                                                        class="form-check no-border form-check-inline"

                                                                                    >
                                                                                        <input
                                                                                            class="form-check-input"
                                                                                            type="radio"
                                                                                            value="text"
                                                                                            name="questiontype"
                                                                                            defaultChecked={this.state.questiontype === 'text'}

                                                                                            onChange={this.handleInputChange}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            for="questiontype"
                                                                                        >
                                                                                            Text
                                                                                        </label>
                                                                                    </div>
                                                                                    {this.state.selectedContentType !== "" && this.state.selectedContentType.toLowerCase() !==
                                                                                        "grammar" && (
                                                                                            <div
                                                                                                class="form-check no-border form-check-inline video-radio-btn"

                                                                                            >
                                                                                                <input
                                                                                                    class="form-check-input"
                                                                                                    type="radio"
                                                                                                    value="video"
                                                                                                    name="questiontype"
                                                                                                defaultChecked={this.state.questiontype === 'video'}

                                                                                                    onChange={this.handleInputChange}
                                                                                                />
                                                                                                <label
                                                                                                    class="form-check-label"
                                                                                                    for="questionvideo"
                                                                                                >
                                                                                                    Video
                                                                                                </label>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>

                                                                            <div class="col col-md-4 swift-radio-group radio-btn-types">
                                                                                <div class="form-group" style={{ marginLeft: 10 }}>
                                                                                    <label
                                                                                        for="workExperience"
                                                                                        class="form-label f-size-14 fw-bold"
                                                                                    >
                                                                                        Answer Type
                                                                                        <span class="asterisk"> *</span>
                                                                                    </label>
                                                                                </div>
                                                                                {(this.state.selectedContentType.toLowerCase() === "basic") && (
                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="form-check no-border form-check-inline"
                                                                                            onChange={this.handleInputChange}
                                                                                        >
                                                                                            <input class="form-check-input" type="radio" name="answertype" value="text" defaultChecked={this.state.answertype === 'text'}/> <span className="form-check-label">Text</span>
                                                                                        </div>

                                                                                        <div class="form-check no-border form-check-inline video-radio-btn" onChange={this.handleInputChange} >
                                                                                            <input class="form-check-input" type="radio" name="answertype" value="video" defaultChecked={this.state.answertype === 'video'} /> <span className="form-check-label">Video</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )} 
                                                                                {(this.state.selectedContentType.toLowerCase() === "grammar" || this.state.selectedContentType.toLowerCase() === "comprehension") && (
                                                                                    <div class="form-group">
                                                                                        <div class="form-check no-border form-check-inline" onChange={this.handleInputChange}  >
                                                                                            <input class="form-check-input" type="radio" name="answertype" value="text" defaultChecked /> <span className="form-check-label">Text</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {(this.state.selectedContentType.toLowerCase() !== "grammar" && this.state.selectedContentType.toLowerCase() !== "comprehension") && (
                                                                                <div>
                                                                                    <div className="row mb-2">
                                                                                        <label
                                                                                            for="questionTitle"
                                                                                            className="form-label f-size-14 fw-bold"
                                                                                        >
                                                                                            Question Title{" "}
                                                                                            <span className="asterisk"> *</span>
                                                                                        </label>

                                                                                        <div className="">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="marg-b-10 form-control swift-shadow h-swift-44"
                                                                                                onChange={this.handleInputChange}
                                                                                                value={this.state.questiontitle}
                                                                                                name="questiontitle"
                                                                                                id="questionTitle"
                                                                                                placeholder="Type question title"
                                                                                                aria-label="Your department"
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-12">
                                                                                        <label
                                                                                            for="questionDescription"
                                                                                            className="form-label f-size-14 fw-bold pt-2"
                                                                                        >
                                                                                            Description
                                                                                            <span className="asterisk"> *</span>
                                                                                        </label>
                                                                                        <div>

                                                                                            <CKEditor
                                                                                                name="questionsetdescription"
                                                                                                editor={ClassicEditor}
                                                                                                data={this.state.description}
                                                                                                config={{
                                                                                                    toolbar: [
                                                                                                        'heading',
                                                                                                        '|',
                                                                                                        'bold',
                                                                                                        'italic',
                                                                                                        'bulletedlist',
                                                                                                        'numberedlist',
                                                                                                        '|',
                                                                                                        'undo',
                                                                                                        'redo',
                                                                                                    ],
                                                                                                }}
                                                                                                onReady={(editor) => { }}
                                                                                                onChange={this.handleEditorChange}
                                                                                                onBlur={(event, editor) => { }}
                                                                                                onFocus={(event, editor) => { }}
                                                                                            />
                                                                                            <p className="text-right">{this.state.wordCount}/{this.state.maxWords}</p>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {this.state.questiontype.toLowerCase() ===
                                                                                "video" && this.state.selectedContentType.toLowerCase() === "comprehension" && (
                                                                                    <div className="mb-2">
                                                                                        <label className="form-label f-size-14 fw-bold">
                                                                                            Question Videos {" "}
                                                                                            <span className="form-label-secondary text-danger">
                                                                                                *
                                                                                            </span>
                                                                                        </label>

                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-12 d-flex flex-wrap align-items-center">
                                                                                            {/* Input Field */}
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="col form-control swift-shadow h-swift-44 custom-form-control flex-grow-1 "
                                                                                                    value={this.state.questionUrl}
                                                                                                    name="questionUrl"
                                                                                                    id="questionTitle"
                                                                                                    aria-label="Your department"
                                                                                                    disabled
                                                                                                />
                                                                                          

                                                                                            {/* View Video Button */}
                                                                                            {this.state.questionUrl && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-outline-primary border-swift btn-swift-text swift-shadow theme-color ms-2 "
                                                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            questionVideoPlayModal: true,
                                                                                                        });
                                                                                                        document.body.classList.add('modal-open');
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fas fa-play-circle me-1"></i>View Video
                                                                                                </button>
                                                                                            )}
                                                                                            <button
                                                                                                type="button"
                                                                                                className=" btn btn-primary swift-shadow theme-color ms-2"
                                                                                                style={{ whiteSpace: 'nowrap' }}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        addQuestionUrl: true,
                                                                                                    });
                                                                                                    document.body.classList.add('modal-open');
                                                                                                }}
                                                                                            >
                                                                                                <i class="fas fa-video me-1 f-size-10"></i>Select Video
                                                                                            </button>

                                                                                            
                                                                                        </div>
                                                                                    </div>


                                                                                            {/* <!-- End Row --> */}
                                                                                    </div>
                                                                                )}

                                                                            {(this.state.selectedContentType.toLowerCase() === "grammar" || this.state.selectedContentType.toLowerCase() === "comprehension") && (
                                                                                <div>
                                                                                    <div className="col-md-12">
                                                                                        {this.state.selectedContentType.toLowerCase() === "grammar" && (
                                                                                            <label
                                                                                                for="questionTitle"
                                                                                                className="form-label f-size-14 fw-bold pt-2"
                                                                                            >
                                                                                                Question Title
                                                                                                <span className="asterisk"> *</span>
                                                                                            </label>
                                                                                        )}
                                                                                       

                                                                                       
                                                                                        {this.state.selectedContentType.toLowerCase() === "comprehension" && (
                                                                                            <label
                                                                                                for="questionTitle"
                                                                                                className="form-label f-size-14 fw-bold pt-2"
                                                                                            >
                                                                                                Comprehension Passage (Question Title)
                                                                                                <span className="asterisk"> *</span>
                                                                                            </label>
                                                                                        )}
                                                                                        <div>

                                                                                            <CKEditor
                                                                                                name="questiontitle"
                                                                                                editor={ClassicEditor}
                                                                                                data={this.state.questiontitle}
                                                                                                config={{
                                                                                                    toolbar: [
                                                                                                        'heading',
                                                                                                        '|',
                                                                                                        'bold',
                                                                                                        'italic',
                                                                                                        'bulletedlist',
                                                                                                        'numberedlist',
                                                                                                        '|',
                                                                                                        'undo',
                                                                                                        'redo',
                                                                                                    ],
                                                                                                }}
                                                                                                onReady={(editor) => { }}
                                                                                                onChange={this.handleGrammarEditorChange}
                                                                                                onBlur={(event, editor) => { }}
                                                                                                onFocus={(event, editor) => { }}
                                                                                            />
                                                                                            <p className="text-right">{this.state.wordCount}/{this.state.maxWords}</p>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}


                                                                            {this.state.questiontype.toLowerCase() ===
                                                                                "video" && this.state.selectedContentType.toLowerCase() === "basic" && (
                                                                                    <div className="mb-2">
                                                                                        <label className="form-label f-size-14 fw-bold">
                                                                                            Question Videos{" "}
                                                                                            <span className="form-label-secondary text-danger">
                                                                                                *
                                                                                            </span>
                                                                                        </label>

                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-12 d-flex flex-wrap align-items-center">
                                                                                            {/* Input Field */}
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="col form-control swift-shadow h-swift-44 custom-form-control flex-grow-1 "
                                                                                                    value={this.state.questionUrl}
                                                                                                    name="questionUrl"
                                                                                                    id="questionTitle"
                                                                                                    aria-label="Your department"
                                                                                                    disabled
                                                                                                />


                                                                                            {/* View Video Button */}
                                                                                            {this.state.questionUrl && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-outline-primary border-swift btn-swift-text swift-shadow theme-color ms-2 "
                                                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            questionVideoPlayModal: true,
                                                                                                        });
                                                                                                        document.body.classList.add('modal-open');
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fas fa-play-circle me-1"></i>View Video
                                                                                                </button>
                                                                                            )}
                                                                                            <button
                                                                                                type="button"
                                                                                                className=" btn btn-primary swift-shadow theme-color ms-2"
                                                                                                style={{ whiteSpace: 'nowrap' }}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        addQuestionUrl: true,
                                                                                                    });
                                                                                                    document.body.classList.add('modal-open');
                                                                                                }}
                                                                                            >
                                                                                                <i class="fas fa-video me-1 f-size-10"></i>Select Video
                                                                                            </button>


                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                )}

                                                                            {this.state.selectedContentType.toLowerCase() ===
                                                                                "grammar" && (

                                                                                    <div className="col-md-12">
                                                                                        <div className="mb-2">
                                                                                            <label className="form-label f-size-14 fw-bold">
                                                                                                Option{" "}
                                                                                                <span className="form-label-secondary text-danger">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>

                                                                                            <div className="col-md-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-8 mb-2 mb-sm-0">
                                                                                                        {/* <!-- Form Radio --> */}
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="marg-b-10 form-control swift-shadow h-swift-44"
                                                                                                            value={this.state.answerOption}
                                                                                                            onChange={this.handleInputChange}
                                                                                                            name="answerOption"
                                                                                                            id="answerOption"
                                                                                                            aria-label="Answer Option"
                                                                                                            placeholder="Add option"
                                                                                                        />
                                                                                                        {/* <!-- End Form Radio --> */}
                                                                                                    </div>

                                                                                                    <div className="col-sm-2 mb-2 mb-sm-0">
                                                                                                        {/* <!-- Form Radio --> */}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="border-swift btn-swift-text btn btn-primary swift-shadow theme-color "
                                                                                                            onClick={this.addOptions}
                                                                                                        >
                                                                                                            Add Option
                                                                                                        </button>
                                                                                                        {/* <!-- End Form Radio --> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <!-- End Row --> */}
                                                                                            </div>

                                                                                            {this.state.answerOptions.length > 0 &&


                                                                                                <div className="col-md-12">
                                                                                                    {/*<p className="swift-answer-option">*/}
                                                                                                    {/*    <label style={{ marginRight: 10 }}> {index + 1} )</label>*/}
                                                                                                    {/*    {option.title}*/}
                                                                                                    {/*</p>*/}


                                                                                                    <div className="table-responsive datatable-custom w-100">
                                                                                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" >
                                                                                                            <thead className="thead-light">
                                                                                                                <tr>
                                                                                                                    <th
                                                                                                                    >
                                                                                                                        No.
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                    >
                                                                                                                        Option
                                                                                                                    </th>

                                                                                                                    <th
                                                                                                                    >

                                                                                                                    </th>



                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {this.state.answerOptions.map((option, index) => {
                                                                                                                    return (
                                                                                                                        <tr key={index}>
                                                                                                                            <td>
                                                                                                                                {index + 1}
                                                                                                                            </td>


                                                                                                                            <td className="fw-bold text-wrap" >
                                                                                                                                {option.title}

                                                                                                                            </td>

                                                                                                                            <td>

                                                                                                                                <button type="button" className="btn-close btn-close-red" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.handleDeleteClick(index)}></button>
                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                    )

                                                                                                                })

                                                                                                                }


                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>


                                                                                            }

                                                                                            <div className="col-md-12 mb-2 mb-sm-0 mt-2">
                                                                                                <label
                                                                                                    for="questionTitle"
                                                                                                    className="form-label f-size-14 fw-bold"
                                                                                                >
                                                                                                    Correct Option{" "}
                                                                                                    <span className="asterisk"> *</span>
                                                                                                </label>

                                                                                                <Select
                                                                                                    id="correctOption"
                                                                                                    name="correctOption"
                                                                                                    options={this.state.answerOptions}
                                                                                                    onChange={this.handleCorrectOption}
                                                                                                    className="marg-b-10"
                                                                                                    placeholder="Select correct option"
                                                                                                />
                                                                                                <p className="form-text text-muted f-size-14" >Previous selected option was <strong> {this.state.correctOption}</strong></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            {this.state.selectedContentType.toLowerCase() ===
                                                                                "comprehension" && (

                                                                                    <div className="col-md-12">
                                                                                        <div className="mb-2">
                                                                                            <label className="form-label f-size-14 fw-bold">
                                                                                                Question
                                                                                                <span className="form-label-secondary text-danger">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>

                                                                                            <div className="col-md-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-10 mb-2 mb-sm-0">
                                                                                                        {/* <!-- Form Radio --> */}
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="marg-b-10 form-control swift-shadow h-swift-44"
                                                                                                            value={this.state.comprehensionQuestion}
                                                                                                            onChange={this.handleInputChange}
                                                                                                            name="comprehensionQuestion"
                                                                                                            id="comprehensionQuestion"
                                                                                                            aria-label="Add Question"
                                                                                                            placeholder="Add question"
                                                                                                        />
                                                                                                        {/* <!-- End Form Radio --> */}
                                                                                                    </div>

                                                                                                    <div className="col-sm-2 mb-2 mb-sm-0">
                                                                                                        {/* <!-- Form Radio --> */}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="border-swift btn-swift-text btn btn-primary swift-shadow theme-color "
                                                                                                            onClick={this.addComprehensionQuestions}
                                                                                                        >
                                                                                                            Add Question
                                                                                                        </button>
                                                                                                        {/* <!-- End Form Radio --> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <!-- End Row --> */}
                                                                                            </div>

                                                                                            {this.state.comprehensionQuestions.length > 0 &&


                                                                                                <div className="col-md-12">
                                                                                                    {/*<p className="swift-answer-option">*/}
                                                                                                    {/*    <label style={{ marginRight: 10 }}> {index + 1} )</label>*/}
                                                                                                    {/*    {option.title}*/}
                                                                                                    {/*</p>*/}


                                                                                                    <div className="table-responsive datatable-custom w-100">
                                                                                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" >
                                                                                                            <thead className="thead-light">
                                                                                                                <tr>
                                                                                                                    <th
                                                                                                                    >
                                                                                                                        No.
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                    >
                                                                                                                        Question
                                                                                                                    </th>

                                                                                                                    <th
                                                                                                                    >

                                                                                                                    </th>



                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {this.state.comprehensionQuestions.map((question, index) => {
                                                                                                                    return (
                                                                                                                        <tr key={index}>
                                                                                                                            <td>
                                                                                                                                {index + 1}
                                                                                                                            </td>


                                                                                                                            <td className="fw-bold text-wrap" >
                                                                                                                                {question.title}

                                                                                                                            </td>

                                                                                                                            <td>

                                                                                                                                <button type="button" className="btn-close btn-close-red" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.handleQuestionDeleteClick(index)}></button>
                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                    )

                                                                                                                })

                                                                                                                }


                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>


                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            {/* tags */}
                                                                            <label
                                                                                htmlFor="tags"
                                                                                className="form-label f-size-14 fw-bold"
                                                                            >
                                                                                Tags
                                                                                <span className="fw-400">
                                                                                    &nbsp;(Optional)
                                                                                </span>
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input
                                                                                    type="tags"
                                                                                    value={this.state.tags}
                                                                                    onChange={this.handleInputChange}
                                                                                    className="marg-b-10 form-control swift-shadow h-swift-44"
                                                                                    name="tags"
                                                                                    placeholder="Question tag"
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.tenantSubscriptionDataPermissionNoOfAttempts
                                                .value === "true" &&
                                                this.state.tenantSubscriptionDataPermissionTimeLimit
                                                    .value === "true" &&
                                                this.state.tenantSubscriptionDataPermissionThinkTime
                                                    .value === "true" &&
                                                this.state.selectedContentType !==
                                                "" &&
                                                (
                                                    <div className="card border-0 jc-radius mb-3">
                                                        <div className="row g-0">
                                                            <div className="col-md-12">
                                                                <div className="card-body jc-t-pad">
                                                                    <form
                                                                        id="create-job"
                                                                        action="#"
                                                                        className="pad-20-b"
                                                                    >
                                                                        <label
                                                                            htmlFor="preferences"
                                                                            className="form-label marg-b-0 f-size-16 fw-bold "
                                                                        >
                                                                            Preferences
                                                                        </label>
                                                                        <p> <small
                                                                            id="no-of-attempts"
                                                                            className="form-text swift-help-text text-muted f-size-14" >
                                                                            Select number of attempts, time limit and
                                                                            think time against specific questions
                                                                        </small>
                                                                        </p>

                                                                        {this.state
                                                                            .tenantSubscriptionDataPermissionNoOfAttempts
                                                                            .value === "true" && (
                                                                                <div>
                                                                                    <label
                                                                                        htmlFor="attempts"
                                                                                        className="form-label f-size-14 fw-bold"
                                                                                    >
                                                                                    Set number of re-attempts
                                                                                        <span className="asterisk"> *</span>
                                                                                    </label>

                                                                                    <input className="marg-b-10 form-control swift-shadow h-swift-44" onChange={this.handleInputChange} name="numberOfAttempts" type="number" value={this.state.numberOfAttempts} max="20" />
                                                                                </div>
                                                                            )}
                                                                        {this.state
                                                                            .tenantSubscriptionDataPermissionTimeLimit
                                                                            .value === "true" && (
                                                                                <div>
                                                                                    <label
                                                                                        htmlFor="timelimit"
                                                                                        className="form-label f-size-14 fw-bold"
                                                                                    >
                                                                                        Set time limit in seconds
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>

                                                                                    <input className="marg-b-10 form-control swift-shadow h-swift-44" onChange={this.handleInputChange} type="number" name="timeLimit" value={this.state.timeLimit} max="300" />
                                                                                </div>
                                                                            )}
                                                                        {this.state
                                                                            .tenantSubscriptionDataPermissionThinkTime
                                                                            .value === "true" && (
                                                                                <div>
                                                                                    <label
                                                                                        htmlFor="think-time"
                                                                                        className="form-label f-size-14 fw-bold"
                                                                                    >
                                                                                        Set think time in seconds
                                                                                        <span className="asterisk"> *</span>
                                                                                    </label>

                                                                                    <input className="marg-b-10 form-control swift-shadow h-swift-44" onChange={this.handleInputChange} type="number" name="thinkTime" value={this.state.thinkTime} max="300" />
                                                                                </div>
                                                                            )}
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            {this.state.selectedContentType !==
                                                "" && (
                                                    <div className="card border-0 jc-radius  jc-t-pad h-swift-92 mb-3">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="d-md-flex d-flex gap-3 justify-content-between">
                                                                    <Link
                                                                        type="button"
                                                                        className="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                        to="/questions"
                                                                    >
                                                                        Cancel
                                                                    </Link>

                                                                    <button
                                                                        id="createJobBtn"
                                                                        disabled={this.state.disableSaveBtn}
                                                                        onClick={this.handleSubmit}
                                                                        className="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            Save{" "}
                                                                            <i className="bi bi-arrow-right ml-1"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </section >
                </main >


                {this.state.questionVideoPlayModal && (
                    <div className="modal d-block video-play-modal" id="demovideo" tabIndex="-1" aria-labelledby="introvideo" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document" >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title text-white" id="exampleModalLabel">Question</h5>
                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({
                                                questionVideoPlayModal: false,
                                            });
                                            document.body.classList.remove('modal-open');
                                        }}>
                                    </button>
                                </div>
                                <div className="modal-body p-0">
                                    <video className="video-control-modal" controls autoPlay src={this.state.questionUrl} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.confirmationModal && this.state.selectedContentType.toLowerCase() === "comprehension" && (
                    <div className="modal-wrapper">
                        <div className="modal d-block" id="confirmationModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <h1>Do you want to apply Auto Transcription?</h1>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={(event) => {
                                            event.preventDefault();
                                            this.setState({ confirmationModal: false, autoTranscriptionButtonCanceled:true, });
                                            document.body.classList.remove('modal-open');

                                        }}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={(event) => {
                                            event.preventDefault();
                                            this.applyTranscription();
                                            this.setState({ confirmationModal: false, isAutoTranscriptionLoading: 1, autoTranscriptionButtonApplied: true, });
                                            displayWarningMessage("Auto transcription may take 2-3 minutes!", "top-center");
                                            document.body.classList.remove('modal-open');
                                        }}>Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.addQuestionUrl && (
                    <div
                        className=" d-block modal video-modal"
                        id="AddIntroVideo"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog-scrollable set-modal-size   "
                            role="document"
                        // style={{width:'100% !important'}}
                        >
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header">
                                    <h4
                                        className="modal-title whiteColor"
                                        id="accountUpdatePlanModalLabel"
                                    >
                                        Videos
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            this.setState({ addQuestionUrl: false });
                                            document.body.classList.remove('modal-open');

                                        }}
                                    ></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">
                                    <div className="row">
                                        {/* <!-- Card --> */}
                                        {this.state.questionVideos != [] ? (
                                            this.state.questionVideos.map((questionVideo, index) => (
                                                <div className="col-4 mb-3" key={index}>
                                                    {console.log("questionVideo", questionVideo)}
                                                    <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                                                        <div className="card-header text-center">
                                                            <p className="card-text">
                                                                {" "}
                                                                {questionVideo.title}
                                                            </p>
                                                        </div>

                                                        <div className="card-body d-flex justify-content-center">
                                                            {/* <!-- List Checked --> */}
                                                            <div className="card card-sm">
                                                                <video
                                                                    src={questionVideo.videoFileName}
                                                                    controls
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                            {/* <!-- End List Checked --> */}
                                                        </div>
                                                        <div className="card-footer border-0 text-center">
                                                            <div className="d-grid mb-2">
                                                                <button
                                                                    type="button"
                                                                    className="form-check-select-stretched-btn btn btn-white"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            addQuestionUrl: false,
                                                                            questionUrl: questionVideo.videoFileName,
                                                                            tenantVideoMetaId:
                                                                            questionVideo.tenantVideoMetaId,
                                                                            autoTranscriptionButtonApplied: false,
                                                                            questiontitle: questionVideo.description,

                                                                        })
                                                                        if (this.state.selectedContentType.toLowerCase() === "comprehension") {
                                                                            document.body.classList.add('modal-open');

                                                                        } else {
                                                                            document.body.classList.remove('modal-open');

                                                                        }
                                                                        document.body.classList.remove('modal-open');

                                                                    }}
                                                                >
                                                                    Select video
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <h3 className="text-primary">No Videos Found</h3>
                                        )}
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Row --> */}

                                    <div className="d-flex justify-content-center justify-content-sm-end gap-3">
                                        <button
                                            type="button"
                                            className="btn btn-white"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.setState({ addQuestionUrl: false });
                                                document.body.classList.remove('modal-open');

                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                )}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: "35%" }}
                />
            </>
        );
    }
}
export default UpdateQuestion;
