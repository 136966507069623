import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { stringToBoolean } from '../../../modules/Utilities';


class ViewGroupMembers extends React.Component {

    constructor() {
        super();
        this.state = {
            members: [],

        }

    }

    async componentDidMount() {
        document.title = "Tenant App - Settings";

        await this.getMembers(this.props.match.params.userGroupId);
    }

    async getMembers(userGroupId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_IDENTITY_API + "/TenantUserGroup/GetUsersofGroup?userGroupId=" + userGroupId + "&tenantId=" + tenantId;

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    //console.log("users are", result);
                    this.setState({
                        members: result
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching members!', "top-center");
                });
        }
    }


    render() {
        const addSpacesToCamelCase = (str) => {
            // Using regex to find where a lowercase letter is followed by an uppercase letter
            return str.replace(/([a-z])([A-Z])/g, '$1 $2');
        };
        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Group Members</h1>

                                </div>


                            </div>

                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Group Members</h4>

                                {/* <!-- Dropdown --> */}
                                <Link className="btn btn-white btn-sm" to={"/editgroup/" + this.props.match.params.userGroupId}>
                                    <i className="bi bi-pencil-square me-1"></i> Edit
                                </Link>
                                {/* <!-- End Dropdown --> */}
                            </div>
                            <div className="table-responsive datatable-custom position-relative">
                                <div id="datatable_wrapper" className="dataTables_wrapper no-footer">

                                    <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                        <thead className="thead-light">
                                            <tr role="row">
                                                <th className="table-column-ps-0 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >S. No.</th>
                                                <th className="table-column-ps-0 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Name & Email</th>
                                                <th className="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" >Role</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.members.map((user, index) => (
                                                <tr role="row" className="odd" key={index}>

                                                    <td className="table-column-pe-0">
                                                        <a className="d-flex align-items-center">

                                                            <div className="ms-0">
                                                                <span className="d-block h5 text-inherit mb-0">{index + 1}</span>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td className="table-column-ps-0">
                                                        <a className="d-flex align-items-center">

                                                            <div className="ms-0">
                                                                <span className="d-block h5 text-inherit mb-0">{user.firstName} {user.lastName}</span>
                                                                <span className="d-block fs-5 text-body">  {user.username}</span>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span className="d-block h5 mb-0"> {addSpacesToCamelCase(user.role)}</span>
                                                    </td>



                                                </tr>


                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="card-footer">
                                <div className="d-flex justify-content-end gap-3">
                                    <Link type="button" className="btn btn-white" to="/usergroup">Back</Link>

                                </div>
                            </div>
                        </div>

                    </div>

                    {this.state.userModal && <div className="modal show" id="addUserModal" tabindex="-1" aria-labelledby="addUserModalLabel" aria-modal="true" role="dialog" style={{ display: 'block', paddingLeft: '0px;' }}>
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addUserModalLabel">Add new user</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                        this.setState({ userModal: false })
                                    }}></button>
                                </div>
                                <div className="modal-body">

                                    <div className="tab-content" id="editUserModalTabContent">
                                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <form>

                                                <div className="row mb-4">
                                                    <label for="editFirstNameModalLabel" className="col-sm-3 col-form-label form-label">Full name <span style={{ color: 'red' }}>*</span></label>

                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-sm-vertical">
                                                            <input type="text" className="form-control" name="firstName" id="editFirstNameModalLabel" placeholder="Your first name" aria-label="Your first name" value={this.state.firstName} onChange={this.handleInputChange} />
                                                            <input type="text" className="form-control" name="lastName" id="editLastNameModalLabel" placeholder="Your last name" aria-label="Your last name" value={this.state.lastName} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <label for="email" className="col-sm-3 col-form-label form-label">Email                                                     <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="col-sm-9">
                                                        <input type="email" className="form-control" name="email" id="email" placeholder="Email" aria-label="Email" onChange={this.handleInputChange}
                                                            value={this.state.email}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row mb-4">
                                                    <label className="col-sm-3 col-form-label form-label">Role                                                     <span style={{ color: 'red' }}>*</span></label>

                                                    <div className="col-sm-9">
                                                        <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                            <input type="radio" value="TenantAdmin" name="selectedrole" defaultChecked /> <span className="form-check-label mx-2">Tenant Admin</span>
                                                            <input type="radio" value="TenantUser" name="selectedrole" /> <span className="form-check-label mx-2">Tenant User</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <label className="col-sm-3 col-form-label form-label">Status                                                     <span style={{ color: 'red' }}>*</span></label>

                                                    <div className="col-sm-9">
                                                        <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                            <input type="radio" value={true} name="status" defaultChecked /> <span className="form-check-label mx-2">Active</span>
                                                            <input type="radio" value={false} name="status" /> <span className="form-check-label mx-2">InActive</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <div className="d-flex gap-3">
                                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                                            this.setState({ userModal: false })
                                                        }}>Cancel</button>
                                                        <button type="button" className="btn btn-primary" onClick={(e) => this.handleSubmit(e)}>Save </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default ViewGroupMembers;
