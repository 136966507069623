import { verifyTokenOnLocal, getBearerAccessToken } from './TokenHelper';
import { login } from '../components/Auth/oidc';
import jwt_decode from 'jwt-decode';
import { displayWarningMessage } from '../modules/ToastMessage';




    /**
 * Chek that token has valid expiry date
 * @returns true or false
 */
   export async function isActiveAccessToken()
   {
    try {
        let access_Token = getBearerAccessToken();
        if ((access_Token !== null) && (access_Token !== undefined) && (access_Token !== '') && (access_Token !== 'undefined')) {

            const accessToken = access_Token;
            // Decode the access token
            const decodedToken = jwt_decode(accessToken);
            // Extract the expiration time
            const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

            const currentTime = Date.now();

            if (expirationTime < currentTime)
            {
                displayWarningMessage('Session get expired, please logout and login again.', "top-center");
                console.log("isActiveAccessToken()() => Token has expired, initiate logout");
            }
            else
            {
                console.log("isActiveAccessToken() => Token is still valid, continue with normal application flow");
            }
        }
    }
    catch (e) {
        console.error("isValidTokenExpiryDate() ==> An error occured ===> " +e);
    }

}


/**
 * Verify that access token is valid and active
 * Append Bearer with token and return it
 * @returns
 */
export function validateAndGetFormatedToken()
{
    let isTokenExisit = false;
    isTokenExisit = verifyTokenOnLocal();
    
    if (isTokenExisit)
    {
        let access_Token = getBearerAccessToken();
        if ((access_Token !== null) && (access_Token !== undefined) && (access_Token !== '') && (access_Token !== 'undefined'))
        {
            return (access_Token);
        }
    }
    else
    {
        //if token not exits then send user to login
        console.error("No token found");
        return login();   
    }

    return login();
}





/**
 * *Validate the access token from Identity Server
 * @param {any} accessToken
 */
export function isValidAccessToken(accessToken) {
    if ((accessToken != null) && (accessToken != '') && (accessToken != undefined) && (accessToken != 'undefined'))
    {
        const url = `${process.env.REACT_APP_IDENTITY_API}/connect/introspect`;
        const data =
        {
            token: accessToken,
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(data),
        })
            .then(response =>
            {
                if (response.status === 401) {
                    // Redirect the user to the login page or perform any other desired action
                    console.log("isValidateToken still return 401");
                    //return window.location.href = '/logout';
                }
                else
                {
                    console.log("isValidateToken return ==>" + response);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error
            });
    }
}
