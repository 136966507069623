import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';

import { Link } from 'react-router-dom';
import { ApiCall } from '../../../modules/ApiCall';
import axios from 'axios';
import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../../Loader/BeatLoader";
import { ToastContainer } from 'react-toastify';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

class JobTitles extends Component {

    constructor() {
        super();
        this.state = {
            AllJobTitles: [],
            isloadedgetJobTitles: true,
            filterJobTitle: "",
            filterIndustryTitle: "",
            hideFilteration: false,
            industries: [],
            order: "DSC",
            filteredData: [],
            itemOffset: 0

        }
        this.handleInputChange = this.handleInputChange.bind(this);

    }


    //Page_load

    async componentDidMount() {
        document.title = "Tenant App - Job Titles";
        this.getIndustrieslookup();
        this.getAllJobTitles();

    }



    /**
     * Industries from look up*/
    getIndustrieslookup() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data != null) {
                        const sorted = [...response.data].sort((a, b) =>
                            a["title"] != undefined ? (a["title"] > b["title"] ? 1 : -1) : ""
                        );
                        this.setState({
                            industries: sorted,
                        });
                    }
                    else
                        console.error("Unable to get industries");
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Industy lookup!', "top-center");

                });
        }

    }

    /**
     * Get All job titles default and tenant*/
    getAllJobTitles() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobTitles";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    const sorted = [...response.data].sort((a, b) =>
                        a["title"] != undefined ? (a["title"] > b["title"] ? 1 : -1) : ""
                    );
                    this.setState({
                        AllJobTitles: sorted,
                        filteredData: sorted,
                        isloadedgetJobTitles: false
                    });

                }).catch(err => {
                    console.log(err);
                    if (err.response.data == "No data found") {
                        this.setState({
                            AllJobTitles: [],
                            filteredData: [],
                            isloadedgetJobTitles: false
                        });
                    }
                    else {
                        displayErrorMessage('Error while Fetching  Job Titles!', "top-center");
                    }
                });
        }

    }


    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    render() {

        const { AllJobTitles } = this.state;
        const filteration = () => {
            let filteredData = AllJobTitles.length > 0 && AllJobTitles.filter((jobTitle) =>
            (jobTitle.title != undefined ? (jobTitle.title.toLowerCase().indexOf(this.state.filterJobTitle.toLowerCase()) > -1
                && jobTitle.industryTitle.toLowerCase().indexOf(this.state.filterIndustryTitle.toLowerCase()) > -1) : "")
            );
            this.setState({
                filteredData: filteredData
            });

        }

        const sorting = (col) => {
            if (this.state.order == "ASC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "DSC"
                })
            }
            if (this.state.order == "DSC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "ASC"
                })
            }
        }
        //for pagination
        const endOffset = this.state.itemOffset + 10;
        console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.filteredData.slice(this.state.itemOffset, endOffset);
        const pageCount = Math.ceil(this.state.filteredData.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.filteredData.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            this.setState({ itemOffset: newOffset });
        };

        return (

            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/*<!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">

                                    <h1 className="page-header-title">Settings</h1>

                                </div>
                              
                            </div>
                            {/*<!-- End Row --> */}
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />

                            </div>
                        </div>
                        {/*<!-- End Page Header --> */}





                        {/*<!--Card--> */}
                        {this.state.isloadedgetJobTitles ? <BeatLoaderComponent />
                            :
                            <>
                                {AllJobTitles.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                    <div className="card-body py-10">
                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                        <div className="text-center">

                                            <h1>You have not created anything  yet.</h1>
                                            <p>Once you create, they'll show up here.</p>


                                            <Link className="btn btn-primary" to="/createjobtitle" ><i className="bi bi-plus-lg me-1"></i>Get started now!</Link>


                                        </div>

                                    </div>
                                </div>
                                    :
                                    <>


                                        {/*<!-- Start Job Data table-->*/}

                                        <div className="card mb-4">

                                            <div className="card-body">


                                                <div className="row">
                                                    <div className="col-6">
                                                        <h4 style={{ float: 'left' }}>Filteration</h4>

                                                    </div>
                                                    <div className="col-6">


                                                        {this.state.hideFilteration ? <i style={{ float: 'right', cursor: 'pointer' }} className="bi bi-arrow-down-square pe-auto" onClick={() => { this.setState({ hideFilteration: false }) }}></i> : <i style={{ float: 'right', cursor: 'pointer', fontSize: '27px' }} onClick={() => { this.setState({ hideFilteration: true }) }} className="bi bi-x lh-1"></i>}




                                                    </div>
                                                </div>

                                                <div className="row align-items-end">
                                                    {!(this.state.hideFilteration) && <>
                                                                                                                <div className="row row-small mt-3">

                                                        <div className="col-6">
                                                          

                                                            <input type="text" className="form-control" value={this.state.filterJobTitle}
                                                                onChange={(e) => { this.setState({ filterJobTitle: e.target.value }) }}
                                                                name="jobTitle" id="jobTitle" placeholder="All Jobtitles" />

                                                        </div>
                                                        <div className="col-6">
                                                       
                                                            {/* <!-- Select --> */}
                                                            <div className="tom-select-custom">
                                                                <select className="form-select"
                                                                    name="industry" onChange={(e) => {
                                                                        this.setState({ filterIndustryTitle: e.target.value });
                                                                    }}>

                                                                    <option value="" selected={this.state.filterIndustryTitle == ""}>All Industries</option>
                                                                    {this.state.industries != [] && this.state.industries.map((industry, index) =>
                                                                        <option key={index} value={industry.title}>{industry.title}</option>


                                                                    )}

                                                                </select>

                                                                {/* <!-- End Select --> */}


                                                            </div>

                                                        </div>
                                                        </div>
                                                        <div className="row row-small mt-3">

                                                        <div className="col-8"></div>
                                                        <div className="col-2">

                                                            <button className="btn btn-primary  w-100" onClick={(event) => {
                                                                event.preventDefault();
                                                                filteration();
                                                            }}>Apply Filters</button>

                                                        </div>
                                                        <div className="col-2">
                                                            <button className="btn btn-clear   w-100" onClick={(event) => {
                                                                event.preventDefault();
                                                                this.setState({
                                                                    filterIndustryTitle: "",
                                                                    filterJobTitle: "",

                                                                }, () =>
                                                                    filteration()
                                                                );
                                                            }}>Clear</button>

                                                            </div>
                                                        </div>

                                                    </>}
                                                </div>


                                            </div>

                                        </div>


                                        <div className="row my-2">

                                            <div className="col-md-12">

                                                <div className="card">
                                                    {/*<!-- Header --> */}
                                                    <div className="card-header card-header-content-between">
                                                        <div className="row justify-content-between align-items-center flex-grow-1">
                                                            <div className=" col-auto">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5 className="card-header-title">Job Titles</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">

                                                                <Link className="btn btn-primary" to="/createjobtitle" >

                                                                    <i className="bi bi-plus me-1"></i> Create Job Title
                                                                </Link>

                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/*<!-- End Header --> */}

                                                    {/*<!-- Table --> */}
                                                    <div className="table-responsive datatable-custom">

                                                        <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th onClick={() => sorting("title")} style={{ cursor: 'pointer' }} ><strong>Job Title <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                    <th className="table-column-ps-1" style={{ cursor: 'pointer' }} > <strong>Short Desc <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                    <th className="table-column-ps-1" onClick={() => sorting("industryTitle")} style={{ cursor: 'pointer' }} > <strong>Industry Title <i className="bi bi-sort-alpha-down"></i></strong></th>

                                                                </tr>

                                                            </thead>

                                                            <tbody>


                                                                {currentItems != [] && currentItems.map((jobTitle, index) => (
                                                                    <tr role="row" className="odd" key={index}>
                                                                        <td className="table-column-ps-1"><a >
                                                                            {jobTitle.title}

                                                                        </a></td>
                                                                        <td>
                                                                            {jobTitle.shortDescription}
                                                                        </td>
                                                                        <td>
                                                                            {jobTitle.industryTitle}

                                                                        </td>


                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    {/*<!-- End Table --> */}

                                                    {/*<!-- Footer --> */}
                                                    <div className="card-footer">
                                                        <div className="d-flex justify-content-center">
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                renderOnZeroPageCount={null}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                containerClassName={'pagination'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                            />
                                                        </div>

                                                    </div>
                                                    {/*<!-- End Footer --> */}
                                                </div>

                                            </div>
                                        </div>




                                    </>
                                }
                            </>
                        }

                        {/*<!--End Card--> */}
                        {/*<!-- End Job Data table--> */}

                    </div>
                    {/* <!-- End Content --> */}


                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />

            </>

        );
    }
} export default JobTitles;
