import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken } from '../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import axios from 'axios';
import Select from 'react-select';
import StarRatingComponent from 'react-star-rating-component';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';


class ReviewSummaryComponent extends Component {

    constructor() {
        super();
        this.state = {
            ratings: [
                { label: 1, value: 1 },
                { label: 2, value: 2 }
                , { label: 3, value: 3 }
                , { label: 4, value: 4 }
                , { label: 5, value: 5 }

            ],
            liveInterviewQuestionsWithReviews: [],
            questionAnswers: [],
            videoUrl: "",
            localVideoUrl: "",
            comment: "",
            selectedRating: 0,
            interviewAnswerId: "",
            interviewOverallRating: {},
            interviewAnswerReviewForQuestions: [],
            liveInterviewOverallRating: {},
            liveInterviewOveralRating: 0,
            liveInterviewVideo: "",
            viewReviewPermission: true,
            editReviewPermission: true,
            viewResume: false,
            viewComments: false,
            interviewInvite: {},
            selectedQuestion: 0,
            selectedQuestionObject: {},
            bearer: "",
            evaluations: [{
                label: "Passed",
                value: "Passed"

            },
            {
                label: "Failed",
                value: "Failed"
            },
                //{
                //    label: "Undecided",
                //    value: "Undecided"
                //}
            ],
            selectedEvaluation: "",
            numPages: null,
            pageNumber: 1,
            liveInterviewQuestions: [],
            selectedQuestionObject: {},
            answerReviewModal: false,
            summary: [],
            viewDetails: false,
            expandedRows: [] ,// Array to keep track of expanded rows
            videoPlayModal: false,
            videoURLForModal: "",
            videoModalHeader:"",

        }
    }


    componentDidMount() {
        document.title = "Tenant App - Interviews";

        this.setState({ bearer: validateAndGetFormatedToken() })

        let interviewInviteId = this.props.interviewInviteId;
        let jobTitle = this.props.interviewId;

        this.getInterviewInvitesForReviews(interviewInviteId, jobTitle);

        this.interviewReviewPermissions();
        this.getSummaryOfInterviewInvites(interviewInviteId);

    }

    /** get interview invite details */
    getInterviewInvitesForReviews(interviewInviteId, jobTitle) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewCandidateInfo/" + interviewInviteId; //Get all filtered interview invites where role!=moderator

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((result) => {
                    let interviewInvite =
                    {
                        candidateEmail: result.candidateEmail,
                        firstName: result.firstName,
                        interviewId: result.interviewId,
                        interviewInviteId: result.interviewInviteId,
                        interviewType: result.interviewType,
                        invitationLink: result.invitationLink,
                        inviteExpiredDate: result.displayExpiryDate,
                        inviteRequestedDate: result.displayRequestedDate,
                        inviteStatus: result.inviteStatus,
                        jobId: result.jobId,
                        lastName: result.lastName,
                        cvLink: result.cvLink,
                        jobTitle: jobTitle,
                        profileImageUrl: result.profileImageUrl
                    };

                    this.setState({
                        interviewInvite: interviewInvite,
                    });

                    if (interviewInvite.interviewType === "live") {
                        this.LiveInterviewDetails(this.props.interviewInviteId);
                        this.GetLiveInterviewQuestions(this.props.interviewInviteId);
                    }
                    else {
                        /*Overall interview rating (Average)*/
                        this.InterviewOverallRating();


                        /*Get the question, its text or video answer with rating 0 */
                        this.GetQuestionAndCandidateAnswerWithAvgRating();


                    }

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching interview Invites!', "top-center")

                });

        }


    }



    /** get summary on the basis interview invite details */
    getSummaryOfInterviewInvites(interviewInviteId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewReviewSummary/" + interviewInviteId; //Get all filtered interview invites where role!=moderator

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((result) => {
                    this.setState({
                        summary: result
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching summary of interview Invites!', "top-center")

                });

        }


    }


    /** Get Interview review Permission */
    interviewReviewPermissions() {
        let bearer = validateAndGetFormatedToken();
        var viewReviewPermission = false;
        var editReviewPermission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                viewReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.View", bearer);
                this.setState({ viewReviewPermission: viewReviewPermission });

                editReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.Edit", bearer);
                this.setState({ editReviewPermission: editReviewPermission });
            }

        }
    }
    /** Get Interview Answer Review
     * Get the question, its text or video answer 
     * BUT with rating = 0*/
    GetQuestionAndCandidateAnswerWithAvgRating() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewAnswerReview/" + this.props.interviewInviteId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log("questionAnswers", result);
                    this.setState({
                        questionAnswers: response.data,
                    });
                    this.InterviewAnswerReviewForQuestions();
                })
                .catch(err => {
                    displayErrorMessage('Error while fetching questionAnswers!', "top-center")

                });
        }
    }

    /** Get Rating of answer given by candidate, Multiple reviewers can give 
     * diffrent rating on candidate answer, InterviewAnswerId is key
     *  We need to calculate rating so we get InterviewAnswerId and get its rating
     */

    InterviewAnswerReviewForQuestions() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewResult/GetInterviewAnswerReviews?interviewInviteId=" + this.props.interviewInviteId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json())
                .then((result) => {
                    this.setState({
                        interviewAnswerReviewForQuestions: result
                    })
                    let questionAnswers = this.state.questionAnswers;
                    for (let i = 0; i < this.state.questionAnswers.length; i++) {
                        let avgrating = 0;
                        let reviewers = 0;

                        for (let j = 0; j < result.length; j++) {
                            if (result[j].questionId === this.state.questionAnswers[i].questionId) {
                                avgrating += result[j].rating;
                                reviewers++;
                            }
                        }
                        if (Number(avgrating / reviewers)) {
                            questionAnswers[i].rating = avgrating / reviewers;

                        }
                        else {
                            questionAnswers[i].rating = 0;

                        }
                        questionAnswers[i].reviewers = reviewers;

                    }
                    this.setState({
                        questionAnswers: questionAnswers,

                    });


                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching questionAnswers!', "top-center")


                });
        }
    }


    /** Get Interview
     *  Overall interview rating (Average)
     */
    InterviewOverallRating() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewResult/GetInterviewAnswerRating?interviewInviteId=" + this.props.interviewInviteId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        interviewOverallRating: result,
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Interview Review!', "top-center")

                });
        }
    }


    /** Get Live Interview Overall Review*/

    LiveInterviewDetails() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/LiveInterviewResult/" + this.props.interviewInviteId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    this.setState({
                        liveInterviewOverallRating: response.data,
                    })
                    let url1 = process.env.REACT_APP_CORE_API + "/api/LiveInterviewVideos?archiveId=" + response.data.archiveId;
                    axios(url1,
                        {
                            method: 'GET',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            }
                        }).then(response => {
                            this.setState({
                                liveInterviewVideo: response.data,
                            })

                        })
                        .catch(err => {
                            console.log(err);
                            displayErrorMessage('Error while fetching Live Interview Review!', "top-center")

                        });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Live Interview Review!', "top-center")

                });
        }
    }

    /**
     * Get Live Interview Questions
     * @param {any} nextValue
     * @param {any} prevValue
     * @param {any} name
     */

    GetLiveInterviewQuestions(interviewInviteId) {

        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/LiveInterviewQuestionAnswerReview/" + interviewInviteId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    console.log("response live interview questions", response);
                    let questionAnswers = response.data;
                    let overallAvgRating = 0;
                    let overallReviewers = 0;

                    for (let i = 0; i < questionAnswers.length; i++) {
                        let avgrating = 0;
                        let reviewers = 0;
                        overallAvgRating = questionAnswers[i].stars;
                        for (let j = 0; j < questionAnswers.length; j++) {
                            if (questionAnswers[j].liveInterviewQuestionId === questionAnswers[i].liveInterviewQuestionId) {
                                avgrating += questionAnswers[j].stars;
                                reviewers++;
                                overallReviewers++;
                            }
                        }
                        if (Number(avgrating / reviewers)) {
                            questionAnswers[i].stars = avgrating / reviewers;

                        }
                        else {
                            questionAnswers[i].stars = 0;

                        }
                        questionAnswers[i].reviewers = reviewers;

                    }
                    overallAvgRating = overallAvgRating / overallReviewers;
                    this.setState({ liveInterviewQuestions: questionAnswers, selectedRating: questionAnswers[0].stars, liveInterviewOveralRating: overallAvgRating });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Live Interview Questions!', "top-center")

                });
        }
    }



    onStarClick(nextValue, prevValue, name) {
        this.setState({ selectedRating: nextValue });
    }

    handleChange(selectedOption) {

        if (selectedOption.value == "Passed") {
            this.props.history.push('/evaluateinterview/' + this.state.interviewInvite.interviewInviteId + "/" + this.state.interviewInvite.interviewId);
        }
        if (selectedOption.value == "Failed") {
            this.props.history.push('/evaluateinterview/' + this.state.interviewInvite.interviewInviteId + "/" + this.state.interviewInvite.interviewId);
        }
        this.setState({ selectedEvaluation: selectedOption.value })

    }
    handleRowClick(index) {
        const { expandedRows } = this.state;
        const isExpanded = expandedRows.includes(index);

        this.setState({
            expandedRows: isExpanded ? expandedRows.filter(i => i !== index) // Remove from expandedRows
                : [...expandedRows, index] // Add to expandedRows
        });
    }



    renderOpenCloseInterviews() {
        const { selectedRating } = this.state;
        const { summary, expandedRows } = this.state;

        return <>
       
            <div className="" >
                {/* <!-- Page Header --> */}
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col lg-10">
                            
                            <div className="mt-2">

                            </div>
                            <h1 className="f-size-18 f-weight-600 mb-4 mt-2">
                                <i class="bi bi-briefcase me-2 job-title-icon-bg mb-4"></i>
                                <span className="me-2">
                                    {this.state.interviewOverallRating.jobTitle}
                                </span>
                            </h1>
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-3">
                                            
                                            {this.state.interviewInvite.profileImageUrl != null ?
                                                <div class="">
                                                    <img
                                                        width="60"
                                                        height="60"
                                                        src={this.state.interviewInvite.profileImageUrl}
                                                        alt="Profile"
                                                        className="applicant-profile-image"
                                                    />

                                                </div>
                                                :
                                                <div class="flex-shrink-0">
                                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M46.7578 45.0234C44.168 40.5234 39.3047 37.5 33.75 37.5H26.25C20.6953 37.5 15.832 40.5234 13.2422 45.0234C17.3672 49.6172 23.3438 52.5 30 52.5C36.6562 52.5 42.6328 49.6055 46.7578 45.0234ZM60 30C60 46.5703 46.5703 60 30 60C13.4297 60 0 46.5703 0 30C0 13.4297 13.4297 0 30 0C46.5703 0 60 13.4297 60 30ZM30 31.875C34.6641 31.875 38.4375 28.1016 38.4375 23.4375C38.4375 18.7734 34.6641 15 30 15C25.3359 15 21.5625 18.7734 21.5625 23.4375C21.5625 28.1016 25.3359 31.875 30 31.875Z" fill="#C7C7C7">
                                                        </path>
                                                    </svg>
                                                </div>
                                            }

                                      
                                      </div>
                                      <div className="col-9">
                                            <h1 className="page-header-title">{this.state.interviewInvite.firstName} {this.state.interviewInvite.lastName}</h1>
                                        <div className="row">
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <i className="bi bi-bookmark-star list-inline-item"></i> <strong>Interview Rating:</strong>
                                          </div>
                                          <div className="col-12 col-md-4 col-lg-3">
                                            {this.state.interviewOverallRating.rating}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <i className="bi bi-calendar-week list-inline-item"></i> <strong>Interview Status:</strong>
                                          </div>
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <span className="badge bg-soft-success text-success">
                                              <span className="legend-indicator bg-success"></span>
                                              {this.state.interviewInvite.inviteStatus}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <i className="bi bi-calendar2 list-inline-item"></i> <strong>Requested Date:</strong>
                                          </div>
                                          <div className="col-12 col-md-7  col-lg-6">
                                            {convertUTCDateToBrowserLocale(this.state.interviewInvite.inviteRequestedDate)}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <i className="bi bi-envelope me-1"></i> <strong>Email:</strong>
                                          </div>
                                          <div className="col-12 col-md-7 col-lg-6">
                                            {this.state.interviewInvite.candidateEmail}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <i className="bi bi-justify me-1"></i> <strong>Interview Type:</strong>
                                          </div>
                                          <div className="col-12 col-md-4 col-lg-3">
                                            {this.state.interviewInvite.interviewType}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12 col-md-4 col-lg-3">
                                            <i className="bi bi-text-indent-left me-1"></i> <strong>Job Title:</strong>
                                          </div>
                                          <div className="col-12 col-md-4 col-lg-3">
                                            {this.state.interviewOverallRating.jobTitle}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                        </div>


                        {/* <!-- End Col --> */}

                    </div>
                    {/* <!-- End Row --> */}
                </div>
                {/* <!-- End Page Header --> */}
                

                {/* <!-- Card --> */}
                <div className="card mb-2">
                    {/* <!-- Header --> */}
                    <div className="card-header card-header-content-md-between">
                        <h4 className="card-header-title">Reviews</h4>



                    </div>
                    {/* <!-- End Header --> */}

                    {/* <!-- Table --> */}
                    <div className="table-responsive datatable-custom mb-2">
                        <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
                            <div className="dt-buttons"> <button className="dt-button buttons-copy buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>Copy</span></button> <button className="dt-button buttons-excel buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>Excel</span></button> <button className="dt-button buttons-csv buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>CSV</span></button> <button className="dt-button buttons-pdf buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>PDF</span></button> <button className="dt-button buttons-print d-none" tabindex="0" aria-controls="datatable" type="button"><span>Print</span></button> </div>
                            {/* <div id="datatable_filter" className="dataTables_filter"><label>Search:<input type="search" className="" placeholder="" aria-controls="datatable" /></label></div> */}
                            <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                <thead className="thead-light">
                                    <tr role="row">
                                        <th className="table-column-ps-1 sorting" tabIndex="0" aria-controls="datatable" rowSpan="1" colSpan="1" aria-label="Project: activate to sort column ascending">Sr No</th>
                                        <th className="sorting_disabled" rowSpan="1" colSpan="1" aria-label="Tasks">Reviewer Name</th>
                                        <th className="sorting_disabled" rowSpan="1" colSpan="1" aria-label="Tasks">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary.map((summary, index) => (
                                        <React.Fragment key={index}>
                                            <tr role="row" className="odd">
                                                <td className="table-column-ps-1">
                                                    <span className="d-block h5 text-inherit"> {index + 1}</span>
                                                </td>
                                                <td>{summary.reviewBy}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-soft-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#videoLargeModalLabel"
                                                        onClick={() => this.handleRowClick(index)}
                                                    >
                                                        {expandedRows.includes(index) ? (
                                                            <>
                                                                <i className="bi bi-dash-square"></i> Close
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="bi bi-plus-square"></i> Expand
                                                            </>
                                                        )}
                                                    </button>
                                                </td>
                                            </tr>
                                            {expandedRows.includes(index) && (
                                                <tr>
                                                    <td colSpan="4" className="ps-0 pe-0">
                                                        <div className="table-responsive datatable-custom">
                                                            <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
                                                               
                                                                {/* <div id="datatable_filter" className="dataTables_filter"><label>Search:<input type="search" className="" placeholder="" aria-controls="datatable" /></label></div> */}
                                                                <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                                                    <thead className="table-secondary">
                                                                        <tr role="row">
                                                                            <th className="table-column-ps-1 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Project: activate to sort column ascending" >Sr No</th>
                                                                            
                                                                            <th className="sorting_disabled"  aria-label="Tasks" >Question</th>
                                                                            <th className="sorting_disabled"  aria-label="Tasks" >Answer</th>
                                                                            <th className="sorting_disabled"  aria-label="Tasks" >Rating</th>
                                                                           

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {summary.reviews.map((reviews, idx) => (
                                                                            <tr role="row" className="odd" key={idx}>
                                                                                <td className="table-column-ps-1">
                                                                                    <span className="d-block h5 text-inherit"> {idx + 1} </span>
                                                                                </td>
                                                                                
                                                                                <td className="table-column-ps-1" style={{ width: "40%" }}>

                                                                                    <span className="d-block h5 text-inherit text-wrap inter-ff"
                                                                                        
                                                                                        style={{ width: 'auto', cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: reviews.question }}>
                                                                                    </span>
                                                                                    {
                                                                                        reviews.questionType == "video" && (
                                                                                            <button className="btn btn-outline f-size-12 h5  inter-ff" onClick={() => {
                                                                                                this.setState({ videoPlayModal: true, videoURLForModal: reviews.questionVideoUrl, videoModalHeader: "Question" })
                                                                                                document.body.classList.add('modal-open');
                                                                                            }}>
                                                                                                <i className="fas fa-play-circle me-1"></i>View Question
                                                                                            </button>
                                                                                        )
                                                                                    }


                                                                                </td>
                                                                                <td className="table-column-ps-1" style={{ width: "40%" }}>

                                                                                    <div
                                                                                        className="d-block h5 text-inherit text-wrap inter-ff"
                                                                                        style={{ width: 'auto', cursor: 'pointer' }}
                                                                                    >

                                                                                        {(() => {
                                                                                            if (reviews.questionContentType === "comprehension") {
                                                                                                return <span dangerouslySetInnerHTML={{ __html: reviews.textAnswer }} />;
                                                                                            } else if (reviews.questionContentType === "basic" && !reviews.videoUrl) {
                                                                                                return <span dangerouslySetInnerHTML={{ __html: reviews.textAnswer }} />;
                                                                                            } else if (reviews.questionContentType === "basic" && reviews.videoUrl) {
                                                                                                return (
                                                                                                    <button className="btn btn-outline f-size-12" onClick={() => {
                                                                                                        this.setState({ videoPlayModal: true, videoURLForModal: reviews.videoUrl, videoModalHeader:"Answer" })
                                                                                                        document.body.classList.add('modal-open');
                                                                                                    }}>
                                                                                                        <i className="fas fa-play-circle me-1"></i>View Answer 
                                                                                                    </button>
                                                                                                );
                                                                                            } else {
                                                                                                return <span dangerouslySetInnerHTML={{ __html: reviews.selectedOptionsAnswer }} />;
                                                                                            }
                                                                                        })()}
                                                                                    </div>

                                                                                </td>
                                                                                <td style={{minWidth:"180px"} }>
                                                                                    <StarRatingComponent
                                                                                        id="selectratings"
                                                                                        name="rate1"
                                                                                        starCount={5}
                                                                                        value={reviews.rating}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                   
                    {/* <!-- End Footer --> */}
                </div>

                {/* <!-- End Card --> */}

                <div className="card ">
                    {/* <!-- Header --> */}
                    <div className="card-header card-header-content-md-between">
                        <h4 className="card-header-title">Overall Comments</h4>



                    </div>
                    {/* <!-- End Header --> */}

                    {/* <!-- Table --> */}
                    <div className="table-responsive datatable-custom">
                        <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
                            <div className="dt-buttons"> <button className="dt-button buttons-copy buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>Copy</span></button> <button className="dt-button buttons-excel buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>Excel</span></button> <button className="dt-button buttons-csv buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>CSV</span></button> <button className="dt-button buttons-pdf buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>PDF</span></button> <button className="dt-button buttons-print d-none" tabindex="0" aria-controls="datatable" type="button"><span>Print</span></button> </div>
                            {/* <div id="datatable_filter" className="dataTables_filter"><label>Search:<input type="search" className="" placeholder="" aria-controls="datatable" /></label></div> */}
                            <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                <thead className="thead-light">
                                    <tr role="row">
                                        <th className="table-column-ps-1 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Project: activate to sort column ascending" >Srno.</th>
                                        <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Tasks" >Reviewer Name</th>
                                        <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Tasks" >Comments</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.summary.map((summary, index) => {
                                        return (
                                            <>
                                                <tr role="row" className="odd" >
                                                    <td className="table-column-ps-1">

                                                        <span className="d-block h5 text-inherit"> {index + 1}</span>


                                                    </td>
                                                    <td>


                                                        <span >
                                                            {summary.reviewBy}

                                                        </span>


                                                    </td>
                                                    <td dangerouslySetInnerHTML={{ __html: summary.overallComments }}>
                                                    </td>


                                                </tr>
                                            </>
                                        )
                                    })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="card-footer">

                    </div>
                    {/* <!-- End Footer --> */}
                </div>

                {/* <!-- End Card --> */}



            </div>
       
            {/* <!-- End Content --> */}
        </>
    }

    render() {
        return (
            <>





                <main id="content" role="main">

                    {

                        this.renderOpenCloseInterviews()
                    }

                    {/* <!-- Content --> */}

                   
                    {this.state.videoPlayModal && (
                        <div className="modal d-block video-play-modal" id="demovideo" tabIndex="-1" aria-labelledby="introvideo" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered" role="document" >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-white" id="exampleModalLabel">{this.state.videoModalHeader}</h5>
                                        <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                            onClick={() => {
                                                this.setState({
                                                    videoPlayModal: false,
                                                    videoURLForModal: "",
                                                    videoModalHeader:""
                                                });
                                            }}>
                                        </button>
                                    </div>
                                    <div className="modal-body p-0">
                                        <video className="video-control-modal" controls autoPlay src={this.state.videoURLForModal} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        );
    }
} export default ReviewSummaryComponent;