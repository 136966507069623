import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { stringToBoolean } from '../../../modules/Utilities';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../../Loader/BeatLoader";


class UserManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],

            createuserPermission: true,
            edituserPermission: true,
            editUserModalData: {},
            sfc_AllowedUsersCount: 0,
            isloadedgetUsers: true
        };
    }


    componentDidMount() {
        document.title = "Tenant App - Settings";

        this.getTenantSubscriptionid();
        var createuserPermission = false;
        var edituserPermission = false;
        let bearer = validateAndGetFormatedToken();

        this.checkRolePermission(bearer, createuserPermission, edituserPermission);

        this.getUsers();
    }

    /**
     * Check role ase permission
     * @param {any} bearer
     * @param {any} createuserPermission
     * @param {any} edituserPermission
     */
    checkRolePermission(bearer, createuserPermission, edituserPermission) {
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {
                this.setState({
                    createuserPermission: CheckPermission(parseToken(bearer).roles, "Permissions.Users.Create", bearer),
                    edituserPermission: CheckPermission(parseToken(bearer).roles, "Permissions.Users.Edit", bearer)
                    })
               
            }
        }
    }

    /** Get the Application User id from token
 * return {string} : applicationUserId | null */
    getApplicationUserIdFromToken() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var appUserId = null;

            appUserId = parseJwt(bearer).sub;
            return appUserId;
        }
        return null;
    }




    /** Get list of tenant standatd users */
    getUsers() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            let appUserId = this.getApplicationUserIdFromToken();

            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsers?hideTenantAdmin=" + true;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    //console.log("users are", result);
                    this.setState({
                        users: result,
                        isloadedgetUsers: false

                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching users!', "top-center");

                });
        }
    }

    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;

                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Users");
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }

    /**
   * Get the tenant subscriotion feature key value 
   * @param {number} tenantSubscriptionId
   * @param {string} featureKey
   */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let tenantSubscriptionData = response.data;
                    let usersCount = parseInt(tenantSubscriptionData.value);
                    this.setState({
                        sfc_AllowedUsersCount: usersCount
                    });

                    console.log("TenantSubscriptionData", response);

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }





    render() {
        const addSpacesToCamelCase = (str) => {
            // Using regex to find where a lowercase letter is followed by an uppercase letter
            return str.replace(/([a-z])([A-Z])/g, '$1 $2');
        };
        return (
            <>


                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!-- Page Header --> */}
                        <div className="page-header">
                            <div className="col">
                                
                                <h1 className="page-header-title">Settings</h1>

                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />

                            </div>
                        </div>

                        {/* <!-- End Page Header --> */}
                        {/*<!--Card--> */}
                        {(this.state.isloadedgetUsers) ?
                            <BeatLoaderComponent />
                            :
                            <>
                               
                               
                                {this.state.users.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                    <div className="card-body py-10">
                                        
                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                        <div className="text-center">

                                            <h1>You have not created anything  yet.</h1>
                                            <p>Once you create, they'll show up here.</p>
                                            <Link className="btn btn-primary" to="/createuser"><i className="bi bi-plus-lg me-1"></i>Get started now!</Link>
                                        </div>

                                    </div>
                                </div>
                                    :
                                    <>





                                        <div className="row my-2">

                                            <div className="col-md-12">

                                                <div className="card">
                                                    {/*<!-- Header --> */}
                                                    <div className="card-header card-header-content-between">
                                                        <div className="row justify-content-between align-items-center flex-grow-1">
                                                            <div className=" col-auto">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h4 className="card-header-title">Users</h4>
                                                                </div>
                                                            </div>

                                                            {this.state.createuserPermission && (
                                                                <div className=" col-auto">
                                                                    <Link
                                                                        className="btn btn-primary "
                                                                        to="/createuser"
                                                                        style={this.state.sfc_AllowedUsersCount === 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                                                                    >
                                                                        <i className="bi-person-plus me-1"></i> Create User ({this.state.sfc_AllowedUsersCount})
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    {/*<!-- End Header --> */}

                                                    {/*<!-- Table --> */}
                                                    <div className="table-responsive datatable-custom">

                                                        <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                            <thead className="thead-light">
                                                                <tr>

                                                                    <th className="table-column-ps-1" > <strong>S. No.</strong></th>
                                                                    <th ><strong>Name & Email</strong></th>
                                                                    <th  ><strong>Role </strong></th>
                                                                    <th><strong>Status</strong></th>
                                                                    <th><strong>Action</strong></th>
                                                                </tr>

                                                            </thead>

                                                            <tbody>
                                                                {this.state.users.map((user, index) => (
                                                                    <tr role="row" className="odd" key={index}>

                                                                        <td className="table-column-pe-0">
                                                                            <a className="d-flex align-items-center">

                                                                                <div className="ms-0">
                                                                                    <span className="d-block h5 text-inherit mb-0">{index + 1}</span>
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td >
                                                                            <a className="d-flex align-items-center">

                                                                                <div className="ms-0">
                                                                                    <span className="d-block h5 text-inherit mb-0">{user.firstName} {user.lastName}</span>
                                                                                    <span className="d-block fs-5 text-body">  {user.email}</span>
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <span className="d-block h5 mb-0"> {addSpacesToCamelCase(user.role)}</span>
                                                                        </td>

                                                                        <td>
                                                                            <span className={(user.status.toLowerCase() == "active"
                                                                                ? "legend-indicator bg-success" :
                                                                                "legend-indicator bg-danger")}></span> {user.status}
                                                                        </td>

                                                                        {this.state.edituserPermission &&
                                                                            /*ToDo:Please check for StandardUser permission*/
                                                                            <td>
                                                                                {
                                                                                    user.userId == this.getApplicationUserIdFromToken() ? "" : <Link type="button" className="btn btn-sm btn-outline-primary" to={"/edituser/" + user.userId}>
                                                                                        <i className="bi-pencil me-1"></i>
                                                                                    </Link>
                                                                                }


                                                                            </td>
                                                                        }

                                                                    </tr>


                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="card-footer">
                                                    </div>


                                                </div>
                                            </div>

                                        </div>


                                    </>
                                }
                            </>
                        }

                        {/*<!--End Card--> */}
                        {/*<!-- End Job Data table--> */}

                    </div>
                    {/* <!-- End Content --> */}


                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default UserManagement;
