import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { stringToBoolean } from '../../../modules/Utilities';


class EditGroup extends React.Component {

    constructor() {
        super();
        this.state = {
            users: [],
            selectedUsers: [],
            groupName: "",
            groupDescription: "",
            status: true,
            companyName: "",
            phone: ""


        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    async componentDidMount() {
        document.title = "Tenant App - Settings";

        await this.getUserGroup(this.props.match.params.userGroupId);
        await this.getCompanyProfile();

    }
    async getCompanyProfile() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_IDENTITY_API + "/Tenant/GetTenant?tenantId=" + tenantId;

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(async (result) => {
                    console.log("profile", result);
                    this.setState({
                        companyName: result.companyName,


                    });

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Company Profile!', "top-center");

                });
        }
    }



    async getUserGroup(userGroupId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_IDENTITY_API + "/TenantUserGroup/GetGroupAndMembers?userGroupId=" + userGroupId;

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(async (result) => {
                    this.setState({
                        groupName: result.userGroup.name,
                        groupDescription: result.userGroup.description,
                        status: result.userGroup.isActive,
                        selectedUsers: result.groupMembers
                    });
                    
                })
                .then(() => {
                    this.getUsers();
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching members!', "top-center");

                });
        }
    }

    async getUsers() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsersWithRoles";

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let members = this.state.selectedUsers;
                    for (let i = 0; i < result.length; i++) {
                        for (let j = 0; j < members.length; j++) {
                            if (result[i].email == members[j].username) {
                                result[i].isChecked = true;
                                console.log("result[i].email", result[i]);
                            }
                         
                        }
                    }
                    this.setState({
                        users: result
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching users!', "top-center");

                });
        }
    }

    async handleSubmit() {

        if (this.state.groupName === "" || this.state.groupName === undefined || this.state.groupDescription === "" || this.state.groupDescription === undefined || this.state.status === "") {
            displayWarningMessage('Please fill all required fields!', "top-center");
            return;
        }
        else {
            let selectedUsers = [];

            this.state.users.forEach(

                (x) => {
                    if (x.isChecked) {
                        selectedUsers.push({ userGroupId: this.props.match.params.userGroupId, applicationUserId: x.userId, username: x.email });
                    }
                }
            )
            if (selectedUsers.length <= 0 || selectedUsers == null && selectedUsers == []) {
                displayWarningMessage('Please select atleast 1 user from the bottom table!', "top-center");

                return;
            }
            let inputModel = {
                TenantUserGroup: {
                    userGroupId: this.props.match.params.userGroupId,
                    name: this.state.groupName,
                    description: this.state.groupDescription,
                    IsActive: this.state.status,
                    TenantId: tenantId
                },

                TenantUserInGroups: selectedUsers
            }

            let bearer = validateAndGetFormatedToken();
            if (bearer != "" || bearer != null) {
                var model = JSON.stringify(inputModel);

                var tenantId = null;

                tenantId = parseJwt(bearer).tenantid;
                let url = process.env.REACT_APP_CORE_API + "/api/TenantUserGroup";


                const requestOptions = {
                    method: 'PUT',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: model
                };
                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.props.history.push('/usergroup');
                    })
                    .catch((err) => {

                        console.log("Error During User Group Updation!", err);
                        displayErrorMessage('Error During User Group Updation!', "top-center");
                    });
            }
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        const addSpacesToCamelCase = (str) => {
            // Using regex to find where a lowercase letter is followed by an uppercase letter
            return str.replace(/([a-z])([A-Z])/g, '$1 $2');
        };
        const onRowSelectforusers = (row, isSelected) => {
            if (isSelected.target.checked) {


                let usersprotoype = this.state.users
                for (let i = 0; i < usersprotoype.length; i++) {
                    if (usersprotoype[i].userId == row.userId) {

                        usersprotoype[i].isChecked = true;

                    }
                }
                this.setState({
                    users: usersprotoype
                });

            }
            else {


                let usersprotoype = this.state.users
                for (let i = 0; i < usersprotoype.length; i++) {
                    if (usersprotoype[i].userId == row.userId) {

                        usersprotoype[i].isChecked = false;

                    }
                }
                this.setState({
                    users: usersprotoype
                });

            }
        };
        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                   
                                    <h1 className="page-header-title">Group Members</h1>

                                </div>


                            </div>

                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Edit Group</h4>

                            </div>
                            <div className="card-body">

                                <div className="row mb-4">
                                    <label for="groupName" className="col-sm-3 col-form-label form-label">Name <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" name="groupName" id="groupName" placeholder="Group Name" aria-label="Clarice" value={this.state.groupName}
 onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="groupDescription" className="col-sm-3 col-form-label form-label">Description <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="groupDescription" id="groupDescription" placeholder="Group Description" value={this.state.groupDescription} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label form-label">Status                                                     <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="col-lg form-check" onChange={this.handleStatusInputChange}>

                                           <input type="radio" value={true} name="status" onChange={() => {
                                                this.setState({ status: true })
                                            }} checked={this.state.status == true} /> <span className="form-check-label mx-2">Active</span>
                                            <input type="radio" value={false} name="status" onChange={() => {
                                                this.setState({ status: false })
                                            }} checked={this.state.status == false} /> <span className="form-check-label mx-2">Inactive</span>

                                        </div>

                                    </div>
                                </div>


                                </div>
                            <div className="table-responsive datatable-custom position-relative">
                                <div id="datatable_wrapper" className="dataTables_wrapper no-footer">

                                    <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                        <thead className="thead-light">
                                            <tr role="row">
                                                <th className="table-column-ps-1 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >S. No.</th>
                                                <th className="table-column-ps-0 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Select</th>
                                                <th className="table-column-ps-0 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Name & Email</th>
                                                <th className="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" >Role</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.users.map((user, index) => (
                                                <tr role="row" className="odd" key={index}>

                                                    <td className="table-column-ps-1">
                                                        <a className="d-flex align-items-center">

                                                            <div className="ms-0">
                                                                <span className="d-block h5 text-inherit mb-0">{index + 1}</span>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td className="table-column-ps-0" style={{ textAlign: 'left' }}>
                                                        <input type="checkbox"
                                                          
                                                            value={user.userId}
                                                            checked={user.isChecked}
                                                            onClick={(e) => {
                                                                onRowSelectforusers(user, e)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="table-column-ps-0">
                                                        <a className="d-flex align-items-center">

                                                            <div className="ms-0">
                                                                <span className="d-block h5 text-inherit mb-0">{user.firstName} {user.lastName}</span>
                                                                <span className="d-block fs-5 text-body">  {user.email}</span>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span className="d-block h5 mb-0"> {addSpacesToCamelCase(user.role)}</span>
                                                    </td>



                                                </tr>


                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="card-footer">
                                <div className="d-flex justify-content-end gap-3">
                                    <Link type="button" className="btn btn-white" to="/usergroup">Back</Link>
                                    <button type="button" className="btn btn-primary" onClick={this.handleSubmit}
                                    >Update</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default EditGroup;
