import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



/**
 * Display toast notification message
 * @param {any} message message to display
 * @param {any} position by default set to top-center
 */
export function displayErrorMessage(message, position) {
    toast.error(message, {
        position: position,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

/**
* Display toast notification message
* @param {any} message message to display
* @param {any} position by default set to top-center
*/
export function displaySuccessMessage(message, position) {
    toast.success(message, {
        position: position,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

/**
* Display toast notification message
* @param {any} message message to display
* @param {any} position by default set to top-center
*/
export function displayWarningMessage(message, position) {
    toast.warn(message, {
        position: position,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

