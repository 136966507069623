import { logout } from './oidc';
let tokenParser = require('../../modules/TokenParser');


export class AuthorizeService
{
    _callbacks = [];
    _nextSubscriptionId = 0;
    _isAuthenticated = false;

    /**
     * Check that user has valid Id and access token
     * and if user has token TenantCandidate then log it out*/
    async isAuthenticated()
    {
        var id_token = localStorage.getItem("id_token");
        var access_token = localStorage.getItem("access_token")

        if (id_token !== null && id_token !== undefined && id_token !='')
        {
            //Check access token and if found TenantCandidate token then logged it out
            if (access_token !== null && access_token !== undefined && access_token !== '') {
                //Parse access token for role
                var tokenParsedData = tokenParser.parseJwt(access_token)
                {
                    if (tokenParsedData !== null) {
                        var roles = tokenParsedData.roles;
                        if (roles !== undefined) {
                            if (roles.trim() === "TenantCandidate") {
                                this._isAuthenticated = false;
                                //Force to redirect to logout page
                                //window.location.href = "/logout?error=invalid-token";
                                return logout();
                            }
                            else
                                this._isAuthenticated = true;
                        }
                        else
                            console.error("Unable to get roles");                       
                    }      
                }
            }
            else
            {
                console.error("Auth token is null");
            }
           
            return this._isAuthenticated;
        }
       return false;
    }

    /** User has been signed out broadcast to subscribers */
    //completeSignOut() {
       
    //    try {
    //        console.error("=========> From logout executing updateState(false)=============");
    //        this.updateState(false);
            
    //    } catch (error)
    //    {
    //       return this.error(error);
    //    }
    //}

    /**
     * Set _isAuthenticated value to true of false
     * Broadcast notification to subscribers
     * @param {boolean} value
     */
    //updateState(value)
    //{
    //    this._isAuthenticated = value;
    //    this.notifySubscribers();
    //}

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;       
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }


    notifySubscribers()
    {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
            
        }
    }

    
}
const authService = new AuthorizeService();
export default authService;