import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { stringToBoolean } from '../../../modules/Utilities';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../../Loader/BeatLoader";

class PlanManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tenantSubscriptionDetails: [],
            isloadedgetTenantSubscriptionDetails: true
        };
    }


    componentDidMount() {
        document.title = "Tenant App - Settings";
        this.getTenantSubscriptionDetais();
    }

    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionDetais() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({
                        tenantSubscriptionDetails: response.data,
                        isloadedgetTenantSubscriptionDetails: false
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  getTenantSubscriptionDetais', "top-center");

                });
        }
    }



    render() {

        return (
            <>

                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>

                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />

                            </div>
                        </div>


                        {/* <!-- End Page Header --> */}
                        {/*<!--Card--> */}
                        {(this.state.isloadedgetTenantSubscriptionDetails) ?
                            <BeatLoaderComponent />
                            :
                            <>
                                {this.state.tenantSubscriptionDetails.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                    <div className="card-body py-10">
                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                        <div className="text-center">

                                            <h1>You have not created anything  yet.</h1>
                                            <p>Once you create, they'll show up here.</p>
                                        </div>

                                    </div>
                                </div>
                                    :
                                    <>





                                        <div className="row my-2">

                                            <div className="col-md-12">

                                                <div className="card">
                                                    {/*<!-- Header --> */}
                                                    <div className="card-header card-header-content-between">
                                                        <div className="row justify-content-between align-items-center flex-grow-1">
                                                            <div className="col-12 col-md-auto">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h4 className="card-header-title">Subscrtiptions</h4>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/*<!-- End Header --> */}

                                                    {/*<!-- Table --> */}
                                                    <div className="table-responsive datatable-custom">

                                                        <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                            <thead className="thead-light">
                                                                <tr>

                                                                    <th className="table-column-ps-1" > <strong>S. No.</strong></th>
                                                                    <th ><strong>Subscription Type </strong></th>
                                                                    <th  ><strong>Created By </strong></th>
                                                                    <th><strong>Subscription Date </strong></th>
                                                                    <th><strong>Expiry Date</strong></th>
                                                                </tr>

                                                            </thead>

                                                            <tbody>
                                                                {this.state.tenantSubscriptionDetails.map((tenantSubscriptionDetail, index) => (
                                                                    <tr role="row" className="odd" key={index}>
                                                                        <td className="table-column-pe-0">
                                                                            {index + 1}
                                                                        </td>
                                                                        <td >
                                                                            {tenantSubscriptionDetail.subscriptionName}
                                                                        </td>
                                                                        <td >
                                                                            {tenantSubscriptionDetail.createdBy}
                                                                        </td>
                                                                        <td>
                                                                            {tenantSubscriptionDetail.createdDate}
                                                                        </td>
                                                                        <td >
                                                                            {tenantSubscriptionDetail.expiryDate}
                                                                        </td>


                                                                    </tr>


                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="card-footer">
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </>
                                }
                            </>
                        }

                        {/*<!--End Card--> */}
                        {/*<!-- End Job Data table--> */}

                    </div>
                    {/* <!-- End Content --> */}


                </main>



            </>

        );
    }

}
export default PlanManagement;
