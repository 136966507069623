import React from 'react'
import { Component } from 'react'
import { Route } from 'react-router-dom'
import authService from './AuthorizeService';
import { login } from './oidc';
import { displayErrorMessage } from '../../modules/ToastMessage';


export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: true,
        };
    }

    componentDidMount() {

        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
        document.body.classList.remove('modal-open');


    }
    componentDidUpdate() {
        if (this.checkIfMobile()) {
            displayErrorMessage('For better and updated user experience use web version (Google Chrome or Microsoft Edge), please be aware that the mobile version is still under development and may not be fully functional at this time.!', "top-center");
        }
        
        document.body.classList.remove('modal-open');

    }
    checkIfMobile() {
        return window.innerWidth <= 768;
    }

    render() {
        const { authenticated } = this.state;
        {

            const { component: Component, isCollapsed, appLoggedUserEmail, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        return <Component {...props} isCollapsed={isCollapsed} appLoggedUserEmail={appLoggedUserEmail} />
                    } else {
                        console.error("Authentication failed");
                        return login();
                    }
                }} />
        }
    }

    /**Check weather user is signed in or not */
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    /**
     * Callback event registered to catch subscribe changes
     * */
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
        //console.info("==================> Authenticated state is ===========>" + this.state.authenticated);
    }
}
