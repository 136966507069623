

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { getHostFromEmail, isValidUsername } from '../../../modules/Utilities';
import { generatePassword, isValidPassword } from '../../../modules/PasswordHelper';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

class BonusSettings extends React.Component {
    currentUsername = "";

    constructor(props) {
        super(props);

        this.state = {
            bonusTitle: "",
            bonusFrequency: "",
            bonusFrequencies: [],
            bonusDescription: "",
            bonuses: []
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.AddBonus = this.AddBonus.bind(this);
    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";

        this.getBonusFrequencyLookup();
        this.getAllBonuses();

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    /**
     * Bonus Frequency from look up*/
    getBonusFrequencyLookup() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/BonusFrequencyLookup";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        bonusFrequencies: response.data,
                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Bonus frequency lookup!', "top-center");

                });
        }

    }



    /**
      * Get All Bonuses from Db*/
    getAllBonuses() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantBonus";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        bonuses: response.data,
                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Bonuses!', "top-center");

                });
        }

    }


    /**
     * 
     * Add bonus
     * @param {any} event
     */
    AddBonus(event) {

        let bearer = validateAndGetFormatedToken();


        if (this.state.bonusTitle === "") {
            displayWarningMessage('Please fill title field!', "top-center");

            return;
        }
        
        if (this.state.bonusDescription === "") {
            displayWarningMessage("Please fill description field", "top-center")
            return;

        }
        if (this.state.bonusFrequency === "") {
            displayWarningMessage('Please fill frequency field!', "top-center");

            return;
        }
        let inputModel = {};
        inputModel.Title = this.state.bonusTitle;
        inputModel.Frequency = this.state.bonusFrequency;
        //version 2 updates
        inputModel.ShortDesc = this.state.bonusDescription;
        inputModel.CreatedDate = new Date();
        inputModel.CreatedBy = "";


        //ToDo: Fix the values (hard)
        inputModel.IsActive = true;

        //Created By


        let tenantId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            inputModel.TenantId = tenantId;

            var model = JSON.stringify(inputModel);

            let url = process.env.REACT_APP_CORE_API + '/api/TenantBonus/';

            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };

            fetch(url, requestOptions)
                .then((response) => {
                    console.log("bonuse createed", response);
                    this.setState({
                        bonusTitle: "",
                        bonusDescription: "",
                        bonusFrequency: ""

                    });
                    this.getAllBonuses();
                    displaySuccessMessage("Bonus Saved Successfully", "top-center");
                })


                .catch(() => {
                    displayErrorMessage('Error During Bonus Creation!', "top-center");

                });



        }


    }




    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>


                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Bonus Settings</h4>


                            </div>
                            <div className="card-body">

                                <div className="row mb-4">
                                    <label for="bonusTitle" className="col-sm-3 col-form-label form-label">Title <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" value={this.state.bonusTitle} name="bonusTitle" id="bonusTitle" placeholder="Bonus Title" aria-label="Your first name" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="bonusDescription" className="col-sm-3 col-form-label form-label">Description <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" value={this.state.bonusDescription} name="bonusDescription" id="bonusDescription" placeholder="Bonus Description" aria-label="Your first name" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="bonusFrequency" className="col-sm-3 col-form-label form-label">Frequency<span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <select className="form-select"
                                            name="bonusFrequency" type="text"
                                            placeholder="Bonus Frequency"
                                            value={this.state.bonusFrequency}

                                            onChange={this.handleInputChange}>

                                            <option value="">Select Frequency </option>
                                            {
                                                Object.keys(this.state.bonusFrequencies).map((bonusFrequency, i) => {
                                                    let DisplayBonusFrequencies = bonusFrequency;
                                            // Convert 'BiAnnual' to 'biannual'
                                            if (bonusFrequency === 'BiAnnual') {
                                                DisplayBonusFrequencies = 'Biannual';
                                                }

                                            return (
                                            <option key={i} value={this.state.bonusFrequencies[bonusFrequency]}>
                                                {DisplayBonusFrequencies}
                                            </option>
                                            );


                                           })}

                                        </select>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <div className="d-flex gap-3">
                                        <Link type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" to="/bonussettings" onClick={() => {
                                            this.setState({
                                                bonusTitle: "",
                                                bonusDescription: "",
                                                bonusFrequency: ""
                                            });
                                        }}

                                        >Clear</Link>
                                        <button type="button" className="btn btn-primary" onClick={this.AddBonus} >Save </button>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="Bonuses" className="col-sm-3 col-form-label form-label" id="locationLabel-ts-label">Bonuses <span className="form-label-secondary text-danger">*</span></label>

                                    <div className="table-responsive datatable-custom w-100">
                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th
                                                    >
                                                        S. No.
                                                    </th>

                                                    <th
                                                    >
                                                        Bonus
                                                    </th>
                                                    <th
                                                    >
                                                        Description
                                                    </th>
                                                    <th
                                                    >
                                                        Frequency
                                                    </th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.bonuses.length > 0 && this.state.bonuses.map((bonus, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>

                                                        <td>
                                                            {bonus.title}
                                                        </td>
                                                        <td>
                                                            {bonus.shortDesc}
                                                        </td>
                                                        <td>
                                                            {bonus.frequency.charAt(0).toUpperCase() + bonus.frequency.slice(1)}
                                                        </td>
                                                    </tr>

                                                ))}

                                            </tbody>
                                        </table>
                                    </div>

                                </div>


                                {/*<div className="card my-4">*/}

                                {/*    */}{/*<!-- Header --> */}
                                {/*    <div className="card-header card-header-content-between">*/}
                                {/*        <div className="row justify-content-between align-items-center flex-grow-1">*/}
                                {/*            <div className="col-12 col-md-auto">*/}
                                {/*                <div className="d-flex justify-content-between align-items-center">*/}
                                {/*                    <h5 className="card-header-title">Industries</h5>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*<ul className="list-group">*/}
                                {/*    <li className="list-group-item">*/}



                                {/*            <div className="row col-sm-divider">*/}
                                {/*                <div className="col-sm-4">*/}
                                {/*                    */}{/* <!-- Stats --> */}
                                {/*                    <div className="d-lg-flex align-items-md-center">*/}


                                {/*                        <div className="flex-grow-1 ms-lg-0">*/}
                                {/*                            <span className="d-block fs-6 fw-bold">Industry Name</span>*/}
                                {/*                            <div className="d-flex align-items-center">*/}
                                {/*                                <p><small>Industry A</small></p>*/}
                                {/*                            </div>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                    */}{/* <!-- End Stats --> */}
                                {/*                </div>*/}



                                {/*            </div>*/}



                                {/*        </li>*/}


                                {/*    </ul>*/}
                                {/*    </div>*/}


                            </div>
                        </div>


                    </div>

                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default BonusSettings;
