import React from 'react';
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


class BeatLoaderComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (

            <div className="sweet-loading" style={{ textAlign: 'center' }}>
                <BeatLoader css={override} size={18} color={"#334155"} loading={true} speedMultiplier={1.5} />
            </div >
        )
    }
}
export default BeatLoaderComponent;