import React from 'react';
import SuccessQuestion from  './../../../assets/img/new-design/sr-successful.png';

class Congratulations extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state if needed
    this.state = {
        title:"Successful!",
        description:"Your question has been saved successfully"
    };
  }
  render() {
    const { title, description, isUpdate, history } = this.props;
    return (
      <section id="main-right">
        <div className="container-fluid">
          
          <div className="row justify-content-center align-items-center">
           

            <div className="offset-lg-1 col-lg-7 col-md-8 p-10 swift-right-column">
              <div className="swift-form-job">             

                <div className="card border-0 jc-radius mb-3">
                  <div className="row justify-content-center align-items-center g-0">
                    <div className="col-md-12">
                      <div className="card-body text-center">
                        <div>
                          <img className="success-img" src={SuccessQuestion} alt="Success" />
                          <h1 className="f-size-24 fw-bold m-2" style={{ color: '#482D7D' }}>
                          {title ? title : this.state.title}</h1>
                          <p>
                          {description ? description : this.state.description}
                          </p>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button  onClick={() => { history.push('/questions'); }} className="border-swift btn-swift-text btn btn-light swift-shadow mob-b-d-size btn-swiftsuccess text-center btn-back-to-ques" type="button"><span>Back to Questions</span></button>
                          {isUpdate ? null : (
                          <button onClick={() => {
                            if (this.props.redirectFromUpdateQuestion) {
                                history.push('/createquestion');
                            }
                            else {
                                this.props.redirectingCreateQuestion()
                                }
                             }} className="border-swift btn-swift-text btn btn-primary swift-shadow theme-color mob-b-d-size btn-swiftsuccess text-center" type="button">Create Question</button>
                            )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Congratulations;
