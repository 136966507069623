import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';

import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../Loader/BeatLoader";

class VideoListing extends React.Component {
    static displayName = VideoListing.name;


    constructor(props) {
        super(props);
        this.state = {
            isLoadedGetLink: false,
            videoLibraryLink: ""
        }
    }

    componentDidMount() {
        this.redirectToVideoLibrary();
    }



    /** Redirect to video libraray */
    redirectToVideoLibrary() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_IDENTITY_API + "/connect/userinfo";
            console.log("access token found, url to get endpoit is =>" + url);

            var access_token = localStorage.getItem("access_token");

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
                .then(result => {
                    let userId = result.sub;

                    var tenantId = result.tenantid;

                    if (tenantId != undefined && tenantId != null) {
                        this.setState({
                            videoLibraryLink: process.env.REACT_APP_VIDEO_LIB + "/Home/VideoListing?tenantId=" + tenantId + "&accessToken=" + access_token + "&userId=" + userId
                        });

                     
                    }
                    else {
                        displayErrorMessage('Unable to get tenant id!', "top-center");

                    }


                })
                .catch(err => {
                    displayErrorMessage('Error While connect call api /connect/userinfo!', "top-center");

                    console.log(err);
                });

            console.info("this.state.videoLibraryLink ===> " + this.state.videoLibraryLink);
        }
    }
    render() {
        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                    {/* <!-- Content --> */}
                    <div className="">

                        {/*<!--Card--> */}
                        {
                            (this.state.isLoadedGetLink) ?
                                <BeatLoaderComponent />
                                :
                                <>
                                    {this.state.isLoadedGetLink ? <div className="card card-centered mb-3 mb-lg-5">
                                        <div className="card-body py-10">
                                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                            <div className="text-center">

                                                <h1>You have not created anything  yet.</h1>
                                                <p>Once you create, they'll show up here.</p>
                                            </div>

                                        </div>
                                    </div>
                                        :
                                        <>

                                            {/*<!-- Start Job Data table-->*/}




                                            <div className="my-2">

                                                <div className="col-md-12">

                                                    <div className="card">
                                                        {/*<!-- Header --> */}
                                                        <div className="card-header card-header-content-between">
                                                            <div className="row justify-content-between align-items-center flex-grow-1">
                                                                <div className="col-12 col-md-auto">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-header-title">Video Library</h5>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        {/*<!-- End Header --> */}
                                                        <div className="table-responsive datatable-custom">

                                                            <iframe src={this.state.videoLibraryLink} width="100%" height="800" title="" allow="camera;microphone" ></iframe>
                                                            
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>




                                        </>
                                    }
                                </>
                        }

                        {/*<!--End Card--> */}
                        {/*<!-- End Job Data table--> */}

                    </div>
                    {/* <!-- End Content --> */}


                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        );
    }
}
export default VideoListing;
