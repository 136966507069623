import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

class TenantIndustrySettings extends React.Component {
    currentUsername = "";

    constructor(props) {
        super(props);

        this.state = {

            industryTitle: "",
            industryShortDesc: "",
            industryIsActive: true,

            industries: [],
            deleteTenanatIndustryModal: false,
            tenantIndustryId: 0,
            disclaimerForAIIndustryDesciprtion: "", 
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.AddTenantIndustry = this.AddTenantIndustry.bind(this);

        this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";

        this.getIndustrieslookup();

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleDeleteSubmit(event) {

        let bearer = validateAndGetFormatedToken();


        let inputModel = {};
        inputModel.Title = this.state.industryTitle;
        inputModel.ShortDesc = this.state.industryShortDesc;

        inputModel.IsActive = this.state.industryIsActive;

        let tenantId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            inputModel.TenantId = tenantId;

            var model = JSON.stringify(inputModel);

            let url = process.env.REACT_APP_CORE_API + '/api/TenantIndustry/' + this.state.tenantIndustryId;

            const requestOptions = {
                method: 'DELETE',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, requestOptions)
                .then((response) => {
                    if (response.ok == true) {
                        console.log("Industry created", response);
                        displaySuccessMessage("Industry Deleted!", "top-center");
                        this.getIndustrieslookup();
                    }
                    if (response.ok == false) {
                        displayErrorMessage('Error In Deleted Industry, Industry already in used!', "top-center");
                    }
                    else
                        displayErrorMessage(response.data, "top-center");
                })


                .catch((error) => {
                    if (error.response.data == false)
                        displayErrorMessage('Error In Deleted Industry!', "top-center");
                    else
                        displayErrorMessage(error.response.data, "top-center");
                });



        }
        this.setState({ deleteTenanatIndustryModal: false })
    }


    /**
     * Industries from look up*/
    getIndustrieslookup() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        industries: response.data,
                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Industy lookup!', "top-center");

                });
        }

    }


    /**
* Get Industry Description by Industry title from chat GPT
* */
    GetIndustryDescription() {
        if (this.state.industryTitle.trim() === "") {
            displayWarningMessage('Please fill title field!', "top-center");
            return;
        }
        this.setState({ description: "", disclaimerForAIIndustryDesciprtion: "" });
        var industryTitle = this.state.industryTitle.trim();
        console.log("industryTitle : " + industryTitle);

        let JobDescriptionRequest = {
            Query: industryTitle,
        };
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            var model = JSON.stringify(JobDescriptionRequest);

            let url = process.env.REACT_APP_CORE_API + "/api/IndustryDescription/";

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions)

                .then((response) => response.json())
                .then((result) => {
                    this.setState({ industryShortDesc: result.description, disclaimerForAIIndustryDesciprtion: "*The above content has been automatically generated by an AI system and we cannot guarantee the accuracy, completeness, or timeliness of the information provided. It is advised to read before submitting." });
                })
                .catch((error) => {
                    console.log("error during GetJobDescription", error);
                });
        }
    }



    /**
     * Add Tenant Industry
     * 
     * @param {any} event
     */
    AddTenantIndustry(event) {

        let bearer = validateAndGetFormatedToken();


        if (this.state.industryTitle.trim() == "") {
            displayWarningMessage('Please fill title field!', "top-center");

            return;
        }
        if (this.state.industryShortDesc.trim() == "") {
            displayWarningMessage('Please fill description field!', "top-center");

            return;
        }

        let inputModel = {};
        inputModel.Title = this.state.industryTitle;
        inputModel.ShortDesc = this.state.industryShortDesc;
        inputModel.IsDefault = false;
        inputModel.IsActive = this.state.industryIsActive;

        let tenantId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            inputModel.TenantId = tenantId;

            var model = JSON.stringify(inputModel);

            let url = process.env.REACT_APP_CORE_API + '/api/TenantIndustry/';

            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.isSuccess) {
                        displaySuccessMessage('Industry Saved Successfully!', "top-center");
                        window.location.reload(true);
                    }
                    else {
                        displayErrorMessage(result.message, "top-center");
                    }
                })
                .catch(() => {
                    displayErrorMessage('Error During Industry Creation!', "top-center");

                });



        }


    }



    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>


                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Industry Information</h4>


                            </div>
                            <div className="card-body">

                                <div className="row mb-4">
                                    <label for="industryTitle" className="col-sm-3 col-form-label form-label">Title <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical mb-2">
                                            <input type="text" className="form-control" name="industryTitle" id="industryTitle" value={this.state.industryTitle} placeholder="Industry name" aria-label="Your first name" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-sm-12">
                                            <button
                                                type="button"
                                                className="btn btn-primary d-flex align-items-center"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => {
                                                    this.GetIndustryDescription();
                                                }}
                                            >
                                                <span className="material-symbols-outlined">
                                                    lightbulb
                                                </span>
                                                <span className="ms-2">Help me write an industry description</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="industryShortDesc" className="col-sm-3 col-form-label form-label">Short Description <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <textarea rows="5" type="text" className="form-control" name="industryShortDesc" id="industryShortDesc" value={this.state.industryShortDesc} placeholder="Industry description" aria-label="Your first name" onChange={this.handleInputChange} />
                                            
                                        </div>
                                        <i>
                                            <label class="text-left font-italic" style={{ color: 'rgb(231, 76, 60)' }} >
                                                {this.state.disclaimerForAIIndustryDesciprtion}
                                            </label>
                                        </i>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label form-label">Status  <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="col-lg form-check" onChange={this.handleInputChange}>

                                            <input type="radio" value={true} name="industryIsActive" defaultChecked /> <span className="form-check-label mx-2">Active</span>
                                            <input type="radio" value={false} name="industryIsActive" /> <span className="form-check-label mx-2">Inactive</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex gap-3">
                                        {/*<Link type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" to="/usermanagement">Cancel</Link>*/}
                                        <button type="button" className="btn btn-primary" onClick={this.AddTenantIndustry}>Save </button>
                                    </div>
                                </div>


                                <div className="card my-4">

                                    {/*<!-- Header --> */}
                                    <div className="card-header card-header-content-between">
                                        <div className="row justify-content-between align-items-center flex-grow-1">
                                            <div className="col-12 col-md-auto">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h4 className="card-header-title">Industries</h4>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <ul className="list-group">
                                        {this.state.industries.map((industry, index) => (
                                            <li key={index} className="list-group-item">



                                                <div className="row col-sm-divider">
                                                    <div className="col-sm-12">
                                                        {/*<!-- Stats --> */}
                                                        <div className="d-lg-flex align-items-md-center">


                                                            <div className="flex-grow-1 ms-lg-0">
                                                                <span className="fs-6 fw-bold">{industry.title}</span>

                                                                {/*<button type="button" className="btn btn-white btn-sm float-end" onClick={() => {*/}
                                                                {/*    this.setState({ deleteTenanatIndustryModal: true, tenantIndustryId: industry.tenantIndustryId });*/}
                                                                {/*}}>*/}

                                                                {/*    <i className="bi bi-trash" style={{ color: 'red' }}></i>*/}
                                                                {/*</button>*/}

                                                                {/*<Link type="button" style={{ marginRight: '5px' }} className="btn btn-white btn-sm float-end" to={"/edittenantindustry/" + industry.tenantIndustryId}>*/}
                                                                {/*    <i className="bi-pencil me-1"></i>*/}
                                                                {/*</Link>*/}



                                                                <div className="d-flex align-items-center">
                                                                    <p><small>{industry.shorrtDesc}</small></p>
                                                                </div>



                                                            </div>
                                                        </div>
                                                        {/*<!-- End Stats --> */}
                                                    </div>



                                                </div>



                                            </li>

                                        ))}


                                    </ul>
                                </div>


                            </div>
                        </div>


                    </div>

                </main>

                {this.state.deleteTenanatIndustryModal &&
                    <div className=" d-block modal" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header">
                                    <h4 className="modal-title text-white" id="accountUpdatePlanModalLabel">Delete Tenant Industry</h4>
                                    <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ deleteTenanatIndustryModal: false }) }}></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">


                                    <div className="row">
                                        <h4>
                                            Are you sure you want to delete tenant industry?
                                        </h4>
                                    </div>
                                    {/* <!-- End Row --> */}




                                </div>
                                <div className=" mx-2 modal-footer">
                                    <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Yes" onClick={this.handleDeleteSubmit}>Yes</button>

                                    <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ deleteTenanatIndustryModal: false }) }}>Cancel</button>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                }

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default TenantIndustrySettings;
