import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { getHostFromEmail, isValidUsername } from '../../../modules/Utilities';
import { generatePassword, isValidPassword } from '../../../modules/PasswordHelper';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

class TenantConfigurations extends React.Component {
    currentUsername = "";

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            firstName: "",
            lastName: "",
            email: "",
            selectedrole: "TenantAdmin",
            status: "Initial",
            password: "",
            createuserPermission: true,
            edituserPermission: true,
            usernameValidationMessage: '',
            displayValidationMessage: 'none'
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.Request = this.Request.bind(this);
        this.validateUsernameHandler = this.validateUsernameHandler.bind(this);
    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";


        this.getUsers();

        this.getCurrentUsername();
    }


    /** get current user username */
    async getCurrentUsername() {
        var url = null;
        var currentUserId = null;
        let bearer = validateAndGetFormatedToken();

        try {

            currentUserId = this.getApplicationUserIdFromToken();
            if (currentUserId == null || currentUserId == undefined || currentUserId == '') {
                console.error("Unablet o get current user Id from token");
                return;
            }


            //Get current user name
            url = process.env.REACT_APP_IDENTITY_API + "/Account/GetUsername?userId=" + currentUserId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.currentUsername = response.data;
                })
                .catch(err => {
                    console.error("Unable to get current user name" + err);
                });

        }
        catch (e) {
            console.error("An error occured when geeting username ofc urrent logged in user => " + e);
        }
    }


    /** Get the Application User id from token
 * return {string} : applicationUserId | null */
    getApplicationUserIdFromToken() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var appUserId = null;

            appUserId = parseJwt(bearer).sub;
            return appUserId;
        }
        return null;
    }


    /**
     * Validate userName
     * @param {any} userName
     */
    validateUsernameHandler(userName) {
        console.log('userName pass to function=>' + userName);
        var result = isValidUsername(userName);
        if (result == false) {
            this.setState({ displayValidationMessage: 'block', usernameValidationMessage: 'Invalid username, only [A-Z], [a-z], [0-9], _. are allowed' });
            console.error("Invalid username");

            return false;
        }
        else {
            this.setState({ displayValidationMessage: 'none', usernameValidationMessage: '' });
            console.log("Valid username");
            return true;
        }
    }



    /**
     * Verify that username not already exist in system
     * @param {any} userName
     * @param {any} e
     * @param {any} hostAddress
     */
    verifyUsername(userName, e, hostAddress) {

        var targetUsername = encodeURIComponent(userName) + "@" + hostAddress;
        console.info("targetUsername ===> " + targetUsername);
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        tenantId = parseJwt(bearer).tenantid;

        let url = process.env.REACT_APP_IDENTITY_API + "/Account/VerifyUser?userName=" + targetUsername + "&tenantId=" + tenantId;

        fetch(url,
            {
                method: 'GET',
                headers:
                {
                    'Content-Type': 'application/json',
                }
            }).then(response => response.json())
            .then(result => {

                if (typeof result === "string") {

                    if (result == "tenant user") {
                        document.getElementById("user-type").innerHTML = "tenant user";
                        var element = document.getElementById("promote");
                        element.classList.remove("d-none");
                    }
                    else if (result == "success") {
                        e.target.value = "";
                        displayErrorMessage("Validation Error:" + userName + " username already exist", 'top-center');
                        var element = document.getElementById("promote");
                        element.classList.add("d-none");
                    }
                    else {
                        var element = document.getElementById("promote");
                        element.classList.add("d-none");
                    }
                }
                else {
                    this.setState({
                        firstName: result.firstName,
                        lastName: result.lastName,
                    })
                    document.getElementById("user-type").innerHTML = "candidate";
                    var element = document.getElementById("promote");
                    element.classList.remove("d-none");
                }
            })
            .catch(err => {
                console.log(err);
                displayErrorMessage('Error while Fetching users!', "top-center");

            });
    }




    getUsers() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            var appUserId = this.getApplicationUserIdFromToken();

            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsersWithRoles?userId=" + appUserId + "&hideTenantAdmin=" + true;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    //console.log("users are", result);
                    this.setState({
                        users: result,


                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching users!', "top-center");

                });
        }
    }

    /**
* Disable button
* @param {any} state true to disable button
*/
    async togglPromote(state) {
        var btnSave = document.getElementById("btn-promote");
        btnSave.disabled = state;
        if (state == true) {
            btnSave.innerText = "Processing...";
        }
        else {
            btnSave.innerText = "Yes";
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    /**
     * Create Usr Form (Submit Handler)
     * @param {any} event
     */
    async Request(event) {


        if (this.state.email === "" || this.state.email === undefined) {
            displayWarningMessage('Please fill Email required field!', "top-center");
            return;
        }

        else {
            var result = isValidPassword(this.state.password);
            if (result === false || result === "false") {
                displayErrorMessage("Password should be between 8 to 15 characters  which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character", "top-center");
                return;
            }
        }

        //Validation Passed

        let inputModel =
        {
            email: this.state.email + "@" + getHostFromEmail(this.currentUsername),


        }
        let bearer = validateAndGetFormatedToken();
        //if (bearer != "" || bearer != null) {
        //    var model = JSON.stringify(inputModel);
        //    let url = process.env.REACT_APP_CORE_API + '/api/tenant/RegisterTenantUser';
        //    axios.post(url, model, {
        //        headers: {
        //            'Authorization': bearer,
        //            'Content-Type': 'application/json',
        //        },
        //    }).then(async (response) => {

        //        //Clear state of variables
        //        this.setState({
        //            email: "",

        //        });
        //        displaySuccessMessage("User Created Successfully", "top-center");
        //        setTimeout(function () {
        //            window.location.replace('/usermanagement');
        //        }, 5000);
        //    }).catch((error) => {
        //        console.log("Error During Tenant User Creation!", error.response.data);
        //        if (typeof error.response.data === 'string') {
        //            displayErrorMessage(error.response.data, "top-center");

        //        }
        //        else {
        //            displayErrorMessage("Error During Tenant User Creation!", "top-center");
        //        }
        //    });


        //}

    }




    render() {

        return (
            <>
                <main id="content" role="main" className="main">
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Tenant Configurations</li>
                                        </ol>
                                    </nav>
                                    <h1 className="page-header-title">Tenant Configuration</h1>

                                </div>


                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Tenant Configurations</h4>


                            </div>
                            <div className="card-body">

                                <form>

                                    <div id="promote" className="row mb-4 d-none alert alert-danger">
                                        <div class="col-sm-12">
                                            <p class="font-bold text-white">This user already register as <span id="user-type"></span>, do you want to promote it as tenant user?
                                            </p>
                                        </div>
                                    </div>



                                    <div className="row mb-4">
                                        <label for="email" className="col-sm-3 col-form-label form-label">Email
                                            <span style={{ color: 'red' }}>*</span></label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" name="email" id="email" placeholder="Username" aria-label="Email" onChange={this.handleInputChange}
                                                value={this.state.email}
                                                onKeyUp={(c) => this.validateUsernameHandler(c.target.value)}
                                                onBlur={(e) => { this.verifyUsername(e.target.value, e, getHostFromEmail(this.currentUsername)) }}

                                            />
                                            <span className="alert alert-danger" style={{ display: this.state.displayValidationMessage }}>{this.state.usernameValidationMessage}</span>
                                        </div>
                                        <div className="col-sm-4">
                                            <p className="fs-2">@{getHostFromEmail(this.currentUsername)}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label for="email" className="col-sm-3 col-form-label form-label">Status
                                        </label>

                                        <div className="col-sm-4">
                                            <p className="fs-2">{this.state.status}</p>
                                        </div>
                                    </div>




                                    <div className="d-flex justify-content-end">
                                        <div className="d-flex gap-3">
                                            <Link type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" to="/usermanagement">Cancel</Link>
                                            <button type="button" className="btn btn-primary" onClick={(e) => this.Request(e)}>Request </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default TenantConfigurations;
