import React, { useEffect } from 'react';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

var currentPath = null;
var currentTargetControl = null;

/** this function sets the active class of navigation link
 * it get the pathName and the find href element that have same path then 
 * set the active class
 * */


export default function SettingsTabNavigations() {
    const [viewUserGroupPermission, setviewUserGroupPermission] = React.useState(true);
    const [viewBillingPermission, setviewBillingPermission] = React.useState(true);
    const [viewCompanyProfilePermission, setviewCompanyProfilePermission] = React.useState(true);
    const [permissionTenantConfigurationView, setPermissionTenantConfigurationView] = React.useState(true);
    const [permissionTenantIndustryView, setPermissionTenantIndustryView] = React.useState(true);
    const [permissionTenantBonusView, setPermissionTenantBonusView] = React.useState(true);
    const [viewUsersPermission, setviewUserPermission] = React.useState(true);

    useEffect(() => {
        var viewUserGroupPermission = false;
        var viewBillingPermission = false;
        var viewCompanyProfilePermission = false;
        var permissionTenantConfigurationView = false;
        var permissionTenantIndustryView = false;
        var permissionTenantBonusView = false;
        var viewUsersPermission = false;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {
                setviewUserGroupPermission(CheckPermission(parseToken(bearer).roles, "Permissions.Groups.View", bearer));

                setviewBillingPermission(CheckPermission(parseToken(bearer).roles, "Permissions.Billing.ActivityList", bearer));

                setviewCompanyProfilePermission(CheckPermission(parseToken(bearer).roles, "Permissions.Settings.TenantProfile", bearer));

                setPermissionTenantConfigurationView(CheckPermission(parseToken(bearer).roles, "Permissions.Settings.TenantConfigurations.View", bearer));

                setPermissionTenantIndustryView(CheckPermission(parseToken(bearer).roles, "Permissions.Settings.TenantIndustries.View", bearer));

                setPermissionTenantBonusView(CheckPermission(parseToken(bearer).roles, "Permissions.Settings.TenantIndustries.View", bearer));

                setviewUserPermission(CheckPermission(parseToken(bearer).roles, "Permissions.Users.View", bearer));
            }
        }
    }, [])
    //setActiveTab();
    return (
        <ul className="settingsNavTabs nav nav-tabs page-header-tabs mb-1 mt-2" role="tablist">
            {/*<span className="hs-nav-scroller-arrow-prev" >*/}
            {/*    <a className="hs-nav-scroller-arrow-link" href="javascript:;">*/}
            {/*        <i className="bi-chevron-left"></i>*/}
            {/*    </a>*/}
            {/*</span>*/}
            <li className="nav-item">
                <NavLink className="nav-link" to="/userprofile" activeClassName="active">Profile</NavLink>
            </li>
            
            {viewCompanyProfilePermission &&
                <li className="nav-item">
                <NavLink className="nav-link" to="/tenantprofile" activeClassName="active">Company</NavLink>
                 </li>
                
            }

            {viewUsersPermission &&
                <li className="nav-item">
                    <NavLink className="nav-link "
                        isActive={(match, location) =>
                            location.pathname === "/usermanagement" || location.pathname === "/createuser" || location.pathname.startsWith("/edituser/")
                                }
                        to="/usermanagement">User Management</NavLink>
                </li>
            }
            {viewUserGroupPermission &&
                <li className="nav-item">
                    <NavLink className="nav-link" to="/usergroup"
                        isActive={(match, location) =>
                            location.pathname === "/usergroup" || location.pathname === "/creategroup" || location.pathname.startsWith("/viewgroupmembers/") || location.pathname.startsWith("/editgroup/")  
                        }
                        activeClassName="active">Groups</NavLink>
                </li>
            }
            {viewBillingPermission && <li className="nav-item">
                <NavLink className="nav-link" to="/userplan" activeClassName="active">Billing Plans</NavLink>
            </li>
            }
            {permissionTenantIndustryView && <li className="nav-item">
                <NavLink className="nav-link" to="/tenantindustrysettings" activeClassName="active">Industry</NavLink>
            </li>}
            {permissionTenantBonusView && <li className="nav-item">
                <NavLink className="nav-link" to="/bonussettings" activeClassName="active">Bonus</NavLink>
            </li>}
            <li className="nav-item">
                <NavLink className="nav-link" to="/skills"
                    isActive={(match, location) =>
                        location.pathname === "/skills" || location.pathname === "/createskill" 
                    }
                    activeClassName="active">Skills</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/jobtitles"
                    isActive={(match, location) =>
                        location.pathname === "/jobtitles" || location.pathname === "/createjobtitle"
                    }
                    activeClassName="active">Job Titles</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" id="rejectionReasons" to="/rejectionreasons" activeClassName="active">Rejections</NavLink>
            </li>
            {/*{permissionTenantConfigurationView &&*/}
            {/*    <li className="nav-item">*/}
            {/*        <Link className="nav-link l1" id="tenantconfigurations" to="/tenantconfigurations">Tenant Configuration</Link>*/}
            {/*    </li>*/}
            {/*}*/}


            {/*<span className="hs-nav-scroller-arrow-next" >*/}
            {/*    <a className="hs-nav-scroller-arrow-link" href="javascript:;">*/}
            {/*        <i className="bi-chevron-right"></i>*/}
            {/*    </a>*/}
            {/*</span>*/}
        </ul>
    );

}

//function setActiveTab() {
//    var btns = document.getElementsByClassName("l1");
//    currentPath = window.location.pathname;
//    for (var i = 0; i < btns.length; i++) {
//        var current = document.getElementsByClassName("nav-link");
//        if (current.length > 0) {
//            current[0].className = current[0].className.replace(" active", "");

//        }

//        if (btns[i].getAttribute('href') != null) {
//            var pathName = btns[i].getAttribute('href');

//            if (currentPath == pathName) {

//                //console.info("========================================== SUcess ======================================", btns[i]);
//                currentTargetControl = btns[i];

//            }
//            else {
//                console.log("Path not macthed");
//            }
//        }
//    }

//    if (currentTargetControl != null || currentTargetControl != undefined) {
//        // console.info("Current target control is not null" + currentTargetControl.getAttribute('href'));
//        currentTargetControl.classList.add("active");

//    }
//    else {
//        console.error("currentTargetControl is" + currentTargetControl);
//    }
//}



