/**
 * Check Permission wheter user can view/create/update/delete some entity*/

function CheckPermission(role, claimType, bearer)
{
  
    var url = process.env.REACT_APP_IDENTITY_API + "/Account/VerifyRoleClaim?roleName=" + role + "&claimType=Permission&claimValue=" + claimType;
    //console.log("Check Permission ------------------------------------>"+url);
    //console.log(role, claimType);
    fetch(url,
        {
            method: 'GET',
            headers:
            {
                'Authorization': bearer,
                'Content-Type': 'application/json'
            }
        }).then((result) => result.json()).then((response) => {
            //console.log(" permission response", response);
            return response;
        })
        .catch(err => {
            console.error("Unable to get permission == >" + err);
            return false;
        });
    return false;
}




module.exports = { CheckPermission };




