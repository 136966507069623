import BaseComponent from "../Base/BaseComponent";

class SplashScreen extends BaseComponent {
    constructor()
    {
        super();
    }


    render()
    {     
        return (<h1>Loading...</h1>);
    }
}
export default SplashScreen;