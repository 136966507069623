import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const generateYearsRange = (start, end) => {
    const years = [];
    for (let year = start; year <= end; year++) {
        years.push(year);
    }
    return years;
};

class DatePickerInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
        };
    }

    handleDecreaseMonth = (decreaseMonth) => {
        decreaseMonth();
    };

    handleIncreaseMonth = (increaseMonth) => {
        increaseMonth();
    };
    handleDecreaseYear = (decreaseYear) => {
        decreaseYear();
    };

    handleIncreaseYear = (increaseYear) => {
        increaseYear();
    };
   

    render() {
        const years = generateYearsRange(1990, getYear(new Date()) + 6);
        const { selected, onChange,inline,placeholder} = this.props;

        return (
            <DatePicker
            inline={inline}
            placeholderText={placeholder}
                dateFormat="yyyy-dd-MM"
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    decreaseYear,
                    increaseYear,
                }) => (
                    <div
                        className="RemoveBorder"
                        style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <button style={{paddingRight:"15px"}} type="button" onClick={decreaseYear}>
                            {"<<"}
                        </button>

                        <button type="button" onClick={decreaseMonth}>
                            {"<"}
                        </button>

                        <select
                            className="custom-select"
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            className="custom-select"
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <button type="button" onClick={increaseMonth}>
                            {">"}
                        </button>

                        <button style={{paddingLeft:"20px"}} type="button" onClick={increaseYear}>
                            {">>"}
                        </button>
                    </div>
                )}
                selected={selected}
                onChange={onChange}
            />
        );
    }
}

export default DatePickerInput;
