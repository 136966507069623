import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseJwt, parseToken } from '../../modules/TokenParser';
import axios from "axios";
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import CompanyLogo from '../../assets/svg/brands/codingkey-icon.svg';
import { CheckPermission } from '../../modules/CheckPermission';
import StarRatingComponent from 'react-star-rating-component';
import ReactTooltip from 'react-tooltip';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import ReactPaginate from 'react-paginate';

class InterviewDetailsComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state =
        {
            interviewDetails: {},
            candidatesDetails: [],
            OverallCandidates: [],
            filteredData: {},
            activeTab: "details-tab-pane",
            candidatesFilterModal: false,
            filteredStatus: '',
            showFilteredStatus: '',
            searchedCandidate: '',
            itemOffset: 0,
        };



    }
    componentDidMount() {
        document.title = "Tenant App - Interview Details";
        this.getInterviewDetails(this.props.interviewId);
    }

    /**
     * Get Lead details
     * */
    getInterviewDetails(interviewId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewDetails/" + interviewId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    console.log("Interview details", result);
                    this.setState({
                        interviewDetails: result,
                        candidatesDetails: result.interviewInvites,
                        OverallCandidates: result.interviewInvites

                    })

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Lead details!', "top-center")

                });
        }
    }

    /*
   *Copy text invitation link
   * */
    invitationLinkCopy(e, id) {
        /* Get the text field */
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        displaySuccessMessage('Link Copied :' + copyText.value, "top-center");

    }

    handleTabClick = (tab) => {
        this.setState({ activeTab: tab })

    }



    render() {
        const filteration = () => {
            const { interviewDetails, searchedCandidate, filteredStatus } = this.state;
            let filteredInvites = interviewDetails.interviewInvites;

            if (searchedCandidate.trim() || filteredStatus.trim()) {
                const searchedNames = searchedCandidate.trim().toLowerCase().split(" ");
                const filteredStatusLowerCase = filteredStatus.trim().toLowerCase();

                filteredInvites = filteredInvites.filter(invite => {
                    const candidateName = (invite.firstName + " " + invite.lastName).toLowerCase();
                    const matchesName = searchedNames.every(name => candidateName.includes(name));
                    const matchesStatus = !filteredStatusLowerCase || invite.inviteStatus.toLowerCase() === filteredStatusLowerCase;
                    return matchesName && matchesStatus;
                });
            }

            this.setState({ candidatesDetails: filteredInvites });
        }

        //for pagination
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.candidatesDetails.slice(this.state.itemOffset, endOffset);

        console.log("currentItems:", currentItems)
        const pageCount = Math.ceil(this.state.candidatesDetails.length / 10);


        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.candidatesDetails.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            console.log("azeem", currentItems)
            this.setState({ itemOffset: newOffset, });
        };

        return (
            <>

                {/* <!-- Page Header --> */}
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">

                            <div className=" d-none d-lg-block">

                                <div className="card-body">

                                    <div className="">

                                        <div className="flex-grow-1 ">
                                            <div className="col-lg-12 mb-3 mb-lg-5 p-0">
                                                <div className="card detail-box-area job-detial-area">
                                                    <div className="detail-header pt-3 pb-3 ps-3">
                                                        <h1 className="f-size-14 f-weight-600 mb-0 f-dark-color">
                                                            Interview Detail
                                                        </h1>
                                                    </div>
                                                    <div className="detail-body pt-3 pb-3 ps-3 pe-3">

                                                        <div className="detail-inner-box pt-3 pb-0">

                                                            <div className="row d-md-flex  g-0 p-0">
                                                                <div className="col-xl-6 col-lg-6 col-md-6 mb-0 mb-lg-0 ps-0 pe-8 pt-0 pb-0 border-col">
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-briefcase me-1"></i> Job Title
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {this.state.interviewDetails.jobName}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-file-person me-1"></i> Job ID
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                {this.state.interviewDetails.jobId}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-building me-1"></i> Interview ID
                                                                            </h1>
                                                                        </div>

                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0">
                                                                                {this.state.interviewDetails.interviewId}
                                                                            </p>
                                                                        </div>



                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-calendar-week me-1"></i> Created Date
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {convertUTCDateToBrowserLocale(this.state.interviewDetails.createdDate)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-calendar-week me-1"></i> Expiry Date
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {convertUTCDateToBrowserLocale(this.state.interviewDetails.expiryDate)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6 col-lg-6 col-md-6 mb-0 mb-lg-0 ps-8 pe-0 pt-0 pb-0">
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-person-plus me-1"></i> Created by
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {this.state.interviewDetails.createdBy == "" ? "-" : this.state.interviewDetails.createdBy}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-clock me-1"></i> Time Zone
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {this.state.interviewDetails.timeZoneName}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-door-closed me-1"></i> Status
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {this.state.interviewDetails.isActive ? <><i class="bi bi-circle-fill text-success me-2 f-size-10"></i>Active</> : <><i class="bi bi-circle-fill text-danger me-2 f-size-10"></i>Inactive</>}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-bar-chart-steps me-1"></i> Interview Level
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {this.state.interviewDetails.interviewLevel}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-md-flex align-items-center mb-3 p-0 me-1 ms-0">
                                                                        <div className="col-md-6 p-0">
                                                                            <h1 className="f-dark-color f-size-13 f-weight-600 mb-0">
                                                                                <i class="f-size-15 bi bi-tags me-1"></i> Tags
                                                                            </h1>
                                                                        </div>
                                                                        <div className="col-md-6 p-0 text-end">
                                                                            <p className="f-gray f-size-13 f-weight-600 mb-0 text-overflow-details">
                                                                                {this.state.interviewDetails.tagsCSV}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.interviewDetails.interviewInvites != undefined && this.state.interviewDetails.interviewInvites.length > 0 ?
                                                <div className="card mt-3 ">

                                                    <div className="card-body p-0 ">


                                                        <div className="p-3">
                                                            <div className="row  g-0 mb-2" >
                                                                <div className="col-12 col-md-auto">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="f-size-18 f-weight-600">Applicants </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row  g-0 mb-2" >
                                                                <div className="col-md-4">
                                                                    <div className="input-group input-group-merge">
                                                                        <div className="input-group-prepend input-group-text">
                                                                            <i className="bi-search"></i>
                                                                        </div>
                                                                        <input
                                                                            type="search"
                                                                            className="form-control"
                                                                            placeholder="Search applicants by name..."
                                                                            onChange={(e) => {
                                                                                if (e.target.value === "") {

                                                                                    this.setState({ searchedCandidate: "" },
                                                                                        () => { filteration(); });
                                                                                }

                                                                            }}

                                                                            onKeyDown={(event) => {
                                                                                if (event.key === 'Enter') {
                                                                                    this.setState({ searchedCandidate: event.target.value },
                                                                                        () => { filteration(); })
                                                                                    event.preventDefault();

                                                                                }
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>

                                                                <div className="col d-flex justify-content-end">
                                                                    {this.state.showFilteredStatus && (
                                                                        <div className="filtered-div f-size-14 mx-1">
                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                <span className="filter-overflow " >
                                                                                    {this.state.showFilteredStatus}
                                                                                </span>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-close f-size-12"
                                                                                    aria-label="Close"
                                                                                    style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                                    onClick={(e) => {
                                                                                        this.setState({ showFilteredStatus: '', filteredStatus: "" },
                                                                                            () => {
                                                                                                filteration()
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                ></button>
                                                                            </div>
                                                                        </div>


                                                                    )}



                                                                    <div class="icon-wrapper me-2 " >

                                                                        <i className="bi bi-funnel-fill filter-icon-hover" style={{ fontSize: '1.5rem', color: '#3FB5C3', cursor: 'pointer', padding: "7px" }}
                                                                            onClick={() => {
                                                                                this.setState({ candidatesFilterModal: true })
                                                                                document.body.classList.add('modal-open');

                                                                            }}
                                                                        >

                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<!-- Table --> */}
                                                        <div className="table-responsive datatable-custom ">

                                                            <table id="datatable" className="table table-hover table-borderless mb-3 table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                                <thead className="thead-light">
                                                                    <tr>

                                                                        <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} > <strong>Applicant </strong></th>
                                                                        <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Invite Date </strong></th>
                                                                        <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Expiry Date </strong></th>
                                                                        <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Invite Status </strong></th>
                                                                        <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} ><strong>Invitation Link </strong></th>

                                                                    </tr>

                                                                </thead>

                                                                <tbody>


                                                                    {currentItems != undefined && currentItems.map((interviewInvite, index) => (
                                                                        <tr role="row" className="odd" key={index}>
                                                                            <td >
                                                                                <span className="h5 inter-ff"> {interviewInvite.firstName} {interviewInvite.lastName}</span>
                                                                                <p className="f-gray inter-ff "> {interviewInvite.candidateEmail} </p>

                                                                            </td>

                                                                            <td >
                                                                                <span className="f-gray inter-ff ">{convertUTCDateToBrowserLocale(interviewInvite.requestedDate)}</span>
                                                                            </td>
                                                                            <td >
                                                                                <span className="f-gray inter-ff ">{convertUTCDateToBrowserLocale(interviewInvite.expiryDate)}</span>
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Accepted" &&

                                                                                    <span class="review-interviews-status status-accepted f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Reviewed" &&

                                                                                    <span class="review-interviews-status status-reviewed f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Requested" &&

                                                                                    <span class="review-interviews-status status-requested f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Evaluated" &&

                                                                                    <span class="review-interviews-status status-evaluated f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Completed" &&

                                                                                    <span class="review-interviews-status status-completed f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Rescheduled" &&

                                                                                    <span class="review-interviews-status status-rescheduled f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                                {
                                                                                    interviewInvite.inviteStatus == "Expired" &&

                                                                                    <span class="review-interviews-status status-expired f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <div id={"copyLink" + index} data-tip data-for={"copyLink" + index} className=" flex-nowrap btn btn-outline-primary btn-icon btn-sm me-2 ms-2">
                                                                                    {interviewInvite.invitationLink != null || interviewInvite.invitationLink != undefined ?
                                                                                        <>
                                                                                            <span class="material-symbols-outlined" style={{ cursor: 'pointer' }} onClick={(e) => this.invitationLinkCopy(e, interviewInvite.interviewId)}>
                                                                                                link
                                                                                            </span>
                                                                                            <input type="text" className="form-control" value={interviewInvite.invitationLink} id={interviewInvite.interviewId} hidden />
                                                                                            <ReactTooltip id={"copyLink" + index} place="top" type="dark" effect="float">
                                                                                                <span className="d-block fs-5">Copy open interview link</span>
                                                                                            </ReactTooltip>
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        {/*<!-- Footer --> */}
                                                        {this.state.candidatesDetails.length === 0 ? (
                                                            <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                                                <div className="card-body py-10">
                                                                    <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />
                                                                    <div className="text-center">
                                                                        <h1>No search results were found </h1>
                                                                        <p>Please refine your search</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="card-footer m-3 mt-0">
                                                                <div className="d-flex justify-content-center">
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={5}
                                                                        pageCount={pageCount}
                                                                        previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                        renderOnZeroPageCount={null}
                                                                        breakClassName={'page-item'}
                                                                        breakLinkClassName={'page-link'}
                                                                        containerClassName={'pagination'}
                                                                        pageClassName={'page-item'}
                                                                        pageLinkClassName={'page-link'}
                                                                        previousClassName={'page-item'}
                                                                        previousLinkClassName={'page-link'}
                                                                        nextClassName={'page-item'}
                                                                        nextLinkClassName={'page-link'}
                                                                        activeClassName={'active'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/*<!-- End Footer --> */}

                                                    </div>

                                                </div>
                                                : null}

                                            <div className="card mt-3">

                                                <div className="card-body p-0">

                                                    <div className="d-flex">

                                                        <div className="flex-grow-1">
                                                            <h3 className=" m-3">Interview Reviewers</h3>
                                                            {/*<!-- Table --> */}
                                                            <div className="table-responsive datatable-custom">

                                                                <table id="datatable" className="table table-hover table-borderless mb-3 table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                                    <thead className="thead-light">
                                                                        <tr>

                                                                            <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} > <strong>User Name </strong></th>

                                                                        </tr>

                                                                    </thead>

                                                                    <tbody>


                                                                        {this.state.interviewDetails.interviewReviewers != undefined && this.state.interviewDetails.interviewReviewers.map((interviewReviewer, index) => (
                                                                            <tr role="row" className="odd" key={index}>

                                                                                <td>
                                                                                            <span className="d-block h5 text-wrap mb-0">{interviewReviewer.userName} </span>

                                                                                </td>

                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/* Mobile Start here */}
                            <div className="row justify-content-center align-items-center g-0 d-lg-none d-block">


                                <div className="col-md-12">
                                    <div className="card border-0 jc-radius mb-3">
                                        <div className="row g-0 p-3 pb-0">
                                            <div className="col-12 d-flex">
                                                <div className="col-12 ">
                                                    <div className="d-flex">
                                                        <h1
                                                            className="f-size-18 f-weight-700 "
                                                        >
                                                            {this.state.interviewDetails.jobName}
                                                        </h1>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row g-0 p-4 pt-0">
                                            <div className="col-12">
                                                <ul
                                                    className="nav nav-tabs col-12 nav-tabs-bordered"
                                                    id="myTab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item col-4" role="presentation">
                                                        <button
                                                            className={`nav-link  f-size-14 f-weight-500 ${this.state.activeTab === "details-tab-pane" ? "active" : ""}`}

                                                            id="details-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#details-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="details-tab-pane"
                                                            aria-selected="true"

                                                            onClick={() => this.handleTabClick('details-tab-pane')}

                                                        >
                                                            Interview details
                                                        </button>

                                                    </li>
                                                    <li className="nav-item  col-4" role="presentation">
                                                        <button
                                                            className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "description-tab-pane" ? "active" : ""}`}
                                                            id="description-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#description-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="description-tab-pane"
                                                            aria-selected="false"
                                                            onClick={() => this.handleTabClick('description-tab-pane')}


                                                        >
                                                            Candidates
                                                        </button>
                                                    </li>

                                                    <li className="nav-item  col-4" role="presentation">
                                                        <button
                                                            className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "reviewers-tab-pane" ? "active" : ""}`}
                                                            id="reviewers-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#reviewers-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="reviewers-tab-pane"
                                                            aria-selected="false"
                                                            onClick={() => this.handleTabClick('reviewers-tab-pane')}


                                                        >
                                                            Reviewers
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="myTabContent">
                                                    <div
                                                        className={`tab-pane fade pt-3 ${this.state.activeTab === "details-tab-pane" ? "show active" : ""}`}
                                                        id="details-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="details-tab"
                                                        tabIndex={0}

                                                    >
                                                        <div className="row pt-3 ">
                                                            <div className="col-6 ">
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        JOB TITLE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color  overflow-text">
                                                                        {this.state.interviewDetails.jobName}
                                                                    </p>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        JOB ID
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.interviewDetails.jobId}
                                                                    </p>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                        INTERVIEW ID
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color">
                                                                        {this.state.interviewDetails.interviewId}

                                                                    </p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="mb-4">
                                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1 overflow-text">
                                                                            CREATED DATE
                                                                        </h1>
                                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                                            {convertUTCDateToBrowserLocale(this.state.interviewDetails.createdDate)}
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-6 ">
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        EXPIRY DATE
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                        {convertUTCDateToBrowserLocale(this.state.interviewDetails.expiryDate)}
                                                                    </p>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">

                                                                        CREATED BY
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color  overflow-text">
                                                                        {this.state.interviewDetails.createdBy == "" ? "-" : this.state.interviewDetails.createdBy}
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        TIME ZONE

                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color  overflow-text">
                                                                        {this.state.interviewDetails.timeZoneName}

                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                        STATUS
                                                                    </h1>
                                                                    <p className="f-size-13 f-weight-600 f-dark-color overflow-text">
                                                                        {this.state.interviewDetails.isActive ? "Active" : "Inactive"}
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`tab-pane fade pt-3 ${this.state.activeTab === "description-tab-pane" ? "show active" : ""}`}

                                                        id="description-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="description-tab"
                                                        tabIndex={0}
                                                    >
                                                        <div className="col-md-12 d-flex d-lg-none mb-2" style={{ paddingRight: '8px' }}>
                                                            <div className="col" style={{ marginRight: '.6rem' }}>
                                                                <div className="col">
                                                                    <div className="input-group input-group-merge">
                                                                        <div className="input-group-prepend input-group-text">
                                                                            <i className="bi-search"></i>
                                                                        </div>
                                                                        <input type="search" className="form-control" placeholder="Search applicants..."
                                                                            onChange={(e) => {
                                                                                if (e.target.value === "") {

                                                                                    this.setState({ searchedCandidate: "" },
                                                                                        () => { filteration(); });
                                                                                }

                                                                            }}

                                                                            onKeyDown={(event) => {
                                                                                if (event.key === 'Enter') {
                                                                                    this.setState({ searchedCandidate: event.target.value },
                                                                                        () => { filteration(); })
                                                                                    event.preventDefault();

                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="btn  no-border py-2 me-2 " style={{ backgroundColor: '#3FB5C3' }}
                                                                onClick={() => {
                                                                    this.setState({ candidatesFilterModal: true })
                                                                    document.body.classList.add('modal-open');

                                                                }}
                                                            >
                                                                <i className="bi bi-funnel" style={{ fontSize: '0.8rem', color: 'white' }}></i>
                                                            </button>

                                                        </div>
                                                        <div class="d-flex  mb-1 filter-chips-mb d-lg-none">
                                                            {this.state.showFilteredStatus && (
                                                                <div className="filtered-div-mb  f-size-14 mx-1">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="filter-overflow " >
                                                                            {this.state.showFilteredStatus}
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState({ showFilteredStatus: '', filteredStatus: "" },
                                                                                    () => {
                                                                                        filteration()
                                                                                    }
                                                                                )
                                                                            }}

                                                                        ></button>
                                                                    </div>
                                                                </div>


                                                            )}

                                                        </div>
                                                        <table className="table jobs-overview-table">
                                                            <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                                                <tr>
                                                                    <th scope="col " className="p-3" colSpan={3}>
                                                                        <span className="h5 f-weight-600">
                                                                            Candidates

                                                                        </span>

                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="">
                                                                {currentItems != undefined && currentItems.map((interviewInvite, index) => (
                                                                    <tr key={index}>
                                                                        <td className="">
                                                                            <div className="d-flex align-items-center mb-0">
                                                                                <div className="col d-flex">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h2 className="inter-ff f-size-14 f-weight-500 ">{interviewInvite.firstName} {interviewInvite.lastName}</h2>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3  align-middle d-flex  justify-content-end mb-1">
                                                                                    <div className="d-flex">
                                                                                        <div id={"copyLink" + index} data-tip data-for={"copyLink" + index} className=" flex-nowrap btn btn-outline-primary btn-icon btn-sm me-2 ms-2">
                                                                                            {interviewInvite.invitationLink != null || interviewInvite.invitationLink != undefined ?
                                                                                                <>
                                                                                                    <span class="material-symbols-outlined" style={{ cursor: 'pointer' }} onClick={(e) => this.invitationLinkCopy(e, interviewInvite.interviewId)}>
                                                                                                        link
                                                                                                    </span>
                                                                                                    <input type="text" className="form-control" value={interviewInvite.invitationLink} id={interviewInvite.interviewId} hidden />
                                                                                                    <ReactTooltip id={"copyLink" + index} place="top" type="dark" effect="float">
                                                                                                        <span className="d-block fs-5">Copy open interview link</span>
                                                                                                    </ReactTooltip>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" mb-1 ">
                                                                                <div className="row">
                                                                                    <div className="col-12 d-flex align-items-center ">
                                                                                        <span className="material-symbols-outlined f-size-12">mail</span>
                                                                                        <p className="f-size-11 col-4 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                                            Email:
                                                                                        </p>
                                                                                        <p className="f-size-11 col-8 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                                            {interviewInvite.candidateEmail}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" mb-1 ">
                                                                                <div className="row">
                                                                                    <div className="col-12 d-flex align-items-center ">
                                                                                        <span class="material-symbols-outlined f-size-12">
                                                                                            calendar_clock
                                                                                        </span>
                                                                                        <p className="f-size-11 mb-0 col-4 f-gray-2 f-weight-400 f-inter text-overflow-leads mx-1">
                                                                                            Invitation Date:
                                                                                        </p>
                                                                                        <p className="f-size-11 mb-0 col-8 f-gray-2 f-weight-400 f-inter text-overflow-leads mx-1">
                                                                                            {convertUTCDateToBrowserLocale(interviewInvite.requestedDate)}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" mb-1">
                                                                                <div className="row">
                                                                                    <div className="col-12 d-flex align-items-center ">
                                                                                        <span class="material-symbols-outlined f-size-12">
                                                                                            event_busy
                                                                                        </span>
                                                                                        <p className="f-size-11 col-4 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                                            Expiry Date:
                                                                                        </p>
                                                                                        <p className="f-size-11 col-8 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                                            Expiry Date: {convertUTCDateToBrowserLocale(interviewInvite.expiryDate)}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" mb-1">
                                                                                <div className="row">
                                                                                    <div className="col-12 d-flex align-items-center ">
                                                                                        <p className="f-size-11 mb-0 f-gray-2  col-4 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                                            Invite Status:
                                                                                        </p>
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Accepted" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-accepted  inter-ff  ">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Reviewed" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-reviewed  inter-ff">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Requested" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-requested  inter-ff">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Evaluated" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-evaluated  inter-ff">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Completed" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-completed  inter-ff">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Rescheduled" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-rescheduled  inter-ff">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                        {
                                                                                            interviewInvite.inviteStatus == "Expired" &&
                                                                                            <span class="review-interviews-status-mb mx-3 f-size-10 f-weight-500 status-expired  inter-ff">{interviewInvite.inviteStatus}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>

                                                        </table>
                                                        {/*<!-- Footer --> */}
                                                        {this.state.candidatesDetails.length === 0 ? (
                                                            <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                                                <div className="card-body py-10">
                                                                    <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />
                                                                    <div className="text-center">
                                                                        <h1>No search results were found </h1>
                                                                        <p>Please refine your search</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="card-footer ">
                                                                <div className="d-flex justify-content-center">
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={5}
                                                                        pageCount={pageCount}
                                                                        previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                        renderOnZeroPageCount={null}
                                                                        breakClassName={'page-item'}
                                                                        breakLinkClassName={'page-link'}
                                                                        containerClassName={'pagination'}
                                                                        pageClassName={'page-item'}
                                                                        pageLinkClassName={'page-link'}
                                                                        previousClassName={'page-item'}
                                                                        previousLinkClassName={'page-link'}
                                                                        nextClassName={'page-item'}
                                                                        nextLinkClassName={'page-link'}
                                                                        activeClassName={'active'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/*<!-- End Footer --> */}

                                                    </div>

                                                    <div
                                                        className={`tab-pane fade pt-3 ${this.state.activeTab === "reviewers-tab-pane" ? "show active" : ""}`}
                                                        id="reviewers-tab-pane"
                                                        role="tabpanel"
                                                        aria-labelledby="reviewers-tab"
                                                        tabIndex={0}

                                                    >
                                                        <div className="card mt-3">

                                                            <div className="card-body p-0">

                                                                <div className="d-flex">

                                                                    <div className="flex-grow-1">
                                                                        <h3 className=" m-3">Interview Reviewers</h3>
                                                                        {/*<!-- Table --> */}
                                                                        <div className="table-responsive datatable-custom">

                                                                            <table id="datatable" className="table table-hover table-borderless mb-3 table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                                                <thead className="thead-light">
                                                                                    <tr>

                                                                                        <th style={{ cursor: 'pointer', width: '0px', paddingRight: '0px' }} > <strong>User Name </strong></th>

                                                                                    </tr>

                                                                                </thead>

                                                                                <tbody>


                                                                                    {this.state.interviewDetails.interviewReviewers != undefined && this.state.interviewDetails.interviewReviewers.map((interviewReviewer, index) => (
                                                                                        <tr role="row" className="odd" key={index}>

                                                                                            <td>
                                                                                                        <span className="d-block h5 text-wrap mb-0">{interviewReviewer.userName} </span>

                                                                                            </td>

                                                                                        </tr>
                                                                                    ))}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>                                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mobile end here */}
                        </div>
                        {/* <!-- End Row --> */}
                    </div>

                </div>
                {/* <!-- End Content --> */}






                {/*Modal for Applicants Filter*/}

                {this.state.candidatesFilterModal &&
                    <div className="d-block modal right " id="AddIntroVideo" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog" aria-hidden="true">
                        <div className="modal-dialog filter-modal-width jobs-filter-modal-dialog">
                            <div className="modal-content jobs-filter-modal no-border">
                                {/* Header */}
                                <div className="modal-header no-border p-3 pt-4 pb-4 d-flex align-items-center" style={{ backgroundColor: '#F0F2F7' }}>
                                    <h1 className="f-size-24 f-weight-700">Applicants Filters</h1>
                                    <button type="button" className="btn-close" aria-label="Close " onClick={() => {
                                        this.setState({
                                            candidatesFilterModal: false,
                                            filteredStatus: this.state.showFilteredStatus

                                        });
                                        document.body.classList.remove('modal-open');


                                    }}></button>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="modal-body">
                                    <div className="row mb-5">
                                        <div className="swft-form-input mb-2">
                                            <label htmlFor="jobs" className="f-gray f-weight-600 f-size-14 mb-3">
                                                INVITE STATUS
                                            </label>
                                            <select
                                                name="selectjobs"
                                                className="form-select"
                                                placeholder="Job Status"
                                                value={this.state.filteredStatus}
                                                onChange={(e) => {
                                                    this.setState({ filteredStatus: e.target.value })
                                                }}>
                                                <option value="" >Select status</option>
                                                <option value="Requested" >Requested</option>
                                                <option value="Rescheduled" >Rescheduled</option>
                                                <option value="Accepted" >Accepted</option>
                                                <option value="Completed" >Completed</option>
                                                <option value="Reviewed" >Reviewed</option>
                                                <option value="Evaluated" >Evaluated</option>
                                                <option value="Expired" >Expired</option>

                                            </select>
                                        </div>
                                    </div>


                                    <div className="d-flex justify-content-end gap-4 mb-3">
                                        <button type="button" className="btn filter-clear-btn" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={(event) => {
                                                event.preventDefault();

                                                this.setState({
                                                    filteredStatus: "",
                                                    showFilteredStatus: "",
                                                },
                                                    () => {
                                                        //this.setState({
                                                        //    candidatesDetails: this.state.OverallCandidates,
                                                        //});
                                                        filteration();
                                                    }
                                                );
                                            }}


                                        >
                                            Clear
                                        </button>
                                        <button type="button" className="btn btn-primary f-size-14 f-weight-700" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    candidatesFilterModal: false,
                                                    showFilteredStatus: this.state.filteredStatus,
                                                }, () => {
                                                    filteration();

                                                });

                                                document.body.classList.remove('modal-open');
                                            }}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                {/* End Body */}
                            </div>
                        </div>
                    </div>

                }
                {/*applicants filter modal end here*/}




            </>
        )
    }
}

export default InterviewDetailsComponent;

