import React, { useState } from 'react';

class VideoDownloadButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloading: false,
            progress: 0
        };
    }

    handleDownload = async (videoUrl) => {
        try {
            console.log("Downloading file...");
            this.setState({ downloading: true, progress: 0 }); // Set downloading state to true and progress to 0

            // Call your backend server to initiate the download
            let url = process.env.REACT_APP_CORE_API + "/api/cloudstorage?videoUrl=" + videoUrl;
            const response = await fetch(url, { method: 'Get'});

            if (response.ok) {

                // Generate a random sequence (GUID) as the filename
                const filename = this.generateRandomFileName();

                // If the response is successful, initiate the file download
                const reader = response.body.getReader();
                const contentLength = Number(response.headers.get('Content-Length'));
                let receivedLength = 0;
                const chunks = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }

                    chunks.push(value);
                    receivedLength += value.length;

                    // Update preloader progress
                    const progress = (receivedLength / contentLength) * 100;
                    this.setState({ progress });
                }

                // Combine chunks into a single blob
                const blob = new Blob(chunks);

                // Create object URL and initiate file download
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename+'.webm'; // You can specify the filename here
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                console.log("File downloaded");
            } else {
                console.error('Failed to download the file');
            }
        } catch (error) {
            console.error('Error occurred while downloading the file:', error);
        } finally {
            this.setState({ downloading: false, progress: 0 }); // Set downloading state to false and progress to 0 after download completion
        }
    };


    // Function to generate a random filename (GUID)
    generateRandomFileName = () => {
        return 'file_' + Math.random().toString(36).substr(2, 9); // Generates a random string
    };

    render() {
        const { videoUrl } = this.props;
        const { downloading, progress } = this.state;

        return (
            <div>
                <button className="btn btn-primary d-flex" onClick={() => this.handleDownload(videoUrl)}>
                    Download
                    <i className="bi bi-download ms-2" ></i>
                </button>
                {downloading &&
                    <div className="progress mt-2" style={{ width: '100px' }}>
                        <div className="progress-bar mt-2" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                }
            </div>
        );
    }
}

export default VideoDownloadButton;
