import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import BaseComponent from "../Base/BaseComponent";
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';


class SearchApplicants extends BaseComponent {

    constructor() {
        super();
        this.state =
        {
            atsLink: ""
        }
    }



    async componentDidMount()
    {
        if (verifyTokenOnLocal()) {
            await isActiveAccessToken();

            document.title = "Tenant App - Search Applicants";
            this.getAtsLink();
        }
    }


    getAtsLink() {

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null)
            var tenantId = parseJwt(bearer).tenantid;

        this.setState({
            atsLink: process.env.REACT_APP_ATS + "?tenantId=" + tenantId,
        });
    }

    render() {

        
        
        return (

            <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                {/* <!-- Content --> */}
                <div className="">

                    {/*<!--Card--> */}
                        
                    {(this.state.atsLink!='') &&

                        <div className="my-2">

                            <div className="col-md-12">

                                <div className="card">
                                    {/*<!-- Header --> */}
                                    <div className="card-header card-header-content-between">
                                        <div className="row justify-content-between align-items-center flex-grow-1">
                                            <div className="col-12 col-md-auto">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="card-header-title">Search Applicants</h5>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="d-flex ustify-content-end">
                                            <a href={this.state.atsLink} target="_blank" title="Open In new window"><i className="bi bi-arrow-up-right-square" style={{ fontSize: '2em' }} ></i></a>
                                        </div>
                                    </div>
                                    {/*<!-- End Header --> */}
                                    <div className="table-responsive datatable-custom">
                                        <iframe src={this.state.atsLink} width="100%" height="700" title=""></iframe>

                                    </div>
                                </div>

                            </div>

                        </div>}
                 </div>

            </main>
        );           
    }
} 
export default SearchApplicants;