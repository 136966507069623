import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { stringToBoolean } from '../../../modules/Utilities';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'


class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            editFirstName: "",
            editLastName: "",
            editEmail: "",
            editSelectedRole: "",
            editPhoneNumber: "",
            editStatus: true,
            createuserPermission: true,
            edituserPermission: true,
            disableSaveBtn: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleStatusInputChange = this.handleStatusInputChange.bind(this);
    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";

        await this.getUser(this.props.match.params.userId)
    }

    /** Get the Application User id from token
 * return {string} : applicationUserId | null */
    getApplicationUserIdFromToken() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var appUserId = null;

            appUserId = parseJwt(bearer).sub;
            return appUserId;
        }
        return null;
    }


    /**
 * Verify that username not already exist in system
 * @param {any} userName
 */
    verifyUsername(userName, e) {
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        tenantId = parseJwt(bearer).tenantid;

        let url = process.env.REACT_APP_IDENTITY_API + "/Account/VerifyUser?userName=" + encodeURIComponent(userName) + "&tenantId=" + tenantId;

        fetch(url,
            {
                method: 'GET',
                headers:
                {
                    'Content-Type': 'application/json',
                }
            }).then(response => response.json())
            .then(result => {

                if (result == "candidate user") {
                    document.getElementById("user-type").innerHTML = "candidate";
                    var element = document.getElementById("promote");
                    element.classList.remove("hidden");
                }
                else if (result == "tenant user") {
                    document.getElementById("user-type").innerHTML = "tenant user";
                    var element = document.getElementById("promote");
                    element.classList.remove("hidden");
                }
                else if (result == "success") {
                    e.target.value = "";
                    displayErrorMessage("Validation Error:" + userName + " username already exist", 'top-center');
                    var element = document.getElementById("promote");
                    element.classList.add("hidden");
                }
                else {
                    var element = document.getElementById("promote");
                    element.classList.add("hidden");
                }
            })
            .catch(err => {
                console.log(err);
                displayErrorMessage('Error while Fetching users!', "top-center");

            });
    }
    async getUser(userId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_IDENTITY_API + "/Account/GetApplicationUserProfileData?applicationUserId=" + userId;
            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        editFirstName: result.firstName,
                        editLastName: result.lastName,
                        editEmail: result.email,
                        editSelectedRole: result.roleName,
                        editStatus: result.status,
                        editPhoneNumber: result.phoneNumber
                    })
                })
                .catch(err => {
                    console.log("get user", err);
                    displayErrorMessage('Error while Fetching specific User !', "top-center");

                });
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleStatusInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        value = JSON.parse(value);
        this.setState({
            [name]: Boolean(value)
        });
    }

    async handleUpdate(event) {
        if (this.state.editFirstName.length < 3 || this.state.editLastName.length < 3) {
            displayWarningMessage('First Name/Last Name should be greater than 3 chars', "top-center");
            return;
        }
        if (this.state.editFirstName === "" || this.state.editLastName === undefined || this.state.editEmail === "" || this.state.editEmail === undefined || this.state.editSelectedRole === "" || this.state.editSelectedRole === undefined) {
            displayWarningMessage('Please fill all required fields!', "top-center");

        }
        
        if (!this.state.editPhoneNumber || !isValidPhoneNumber(this.state.editPhoneNumber)) {
            displayErrorMessage("Phone no is not valid", 'top-center');
            return;
        }
        else {
            let inputModel = {
                email: this.state.editEmail,
                firstName: this.state.editFirstName,
                lastName: this.state.editLastName,
                role: this.state.editSelectedRole,
                status: this.state.editStatus,
                phoneNumber: this.state.editPhoneNumber
            }
            let bearer = validateAndGetFormatedToken();
            if (bearer != "" || bearer != null) {
                this.setState({
                    disableSaveBtn: true
                });
                var model = JSON.stringify(inputModel);


                let url = process.env.REACT_APP_CORE_API + '/api/tenant/UpdateTenantUser';

                const requestOptions = {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: model
                };
                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        displaySuccessMessage("User succesfully updated");
                        this.setState({
                            disableSaveBtn: false
                        });
                        setTimeout(function () {
                            window.location.replace('/usermanagement');
                        }, 5000);

                    })
                    .catch((err) => {

                        console.log("Error During Tenant User Updation!", err);
                        this.setState({
                            disableSaveBtn: false
                        });
                        displayErrorMessage('Error During Tenant User Updation!', "top-center");
                    });
            }
        }
    }



    render() {

        return (
            <>


                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>

                           
                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Edit User</h4>


                            </div>
                            <div className="card-body">
                                <form>

                                    <div className="row mb-4">
                                        <label for="editFirstNameModalLabel" className="col-sm-3 col-form-label form-label">Full Name <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className="form-control" name="editFirstName" id="editFirstNameModalLabel" placeholder="First name" aria-label="Your first name" value={this.state.editFirstName} onChange={this.handleInputChange} />
                                                <input type="text" className="form-control" name="editLastName" id="editLastNameModalLabel" placeholder="Last name" aria-label="Your last name" value={this.state.editLastName} onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label for="email" className="col-sm-3 col-form-label form-label">Email                                                     <span style={{ color: 'red' }}>*</span></label>
                                        <div className="col-sm-9">
                                            <input type="email" className="form-control" name="editEmail" id="email" placeholder="Email" aria-label="Email" onChange={this.handleInputChange}
                                                value={this.state.editEmail}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label for="editPhoneNumber" className="col-sm-3 col-form-label form-label">Phone Number </label>

                                        <div className="col-sm-9">
                                            <div className="input-group input-group-sm-vertical">
                                                <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="US"
                                                    placeholder="Enter phone number"
                                                    name="editPhoneNumber"
                                                    id="editPhoneNumber"
                                                    value={this.state.editPhoneNumber}
                                                    onChange={(value) => { this.setState({ editPhoneNumber: value }) }}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <label className="col-sm-3 col-form-label form-label">Role <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                <input type="radio" value="TenantAdmin" name="editSelectedRole" checked={this.state.editSelectedRole === "TenantAdmin"} /> <span className="form-check-label mx-2">Tenant Admin</span>
                                                <input type="radio" value="TenantUser" name="editSelectedRole" checked={this.state.editSelectedRole === "TenantUser"} /> <span className="form-check-label mx-2">Tenant User</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-sm-3 col-form-label form-label">Status <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="col-lg form-check" onChange={this.handleStatusInputChange}>

                                                <input type="radio" value={true} name="editStatus" checked={this.state.editStatus == true} /> <span className="form-check-label mx-2">Active</span>
                                                <input type="radio" value={false} name="editStatus" checked={this.state.editStatus == false} /> <span className="form-check-label mx-2">Inactive</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="d-flex gap-3">
                                            <Link type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" to="/usermanagement">Cancel</Link>
                                            <button type="button" className="btn btn-primary" disabled={this.state.disableSaveBtn} onClick={(e) => this.handleUpdate(e)}>Update </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default EditUser;
