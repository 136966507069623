window.Buffer = window.Buffer || require("buffer").Buffer;

/**
 * Parse JWT TOKEN
 * @param {string} token
 */
function parseJwt(token)
{
    if (token != null && token != undefined && token != '')
    {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var result= JSON.parse(jsonPayload);
        //console.info(result);
        return result;
    }
    else
    {
        console.error('Unable to parse token');
        return {};
    }  
}

/**
 * Parse JWT Token
 * @param {string} token
 */
function parseToken(token) {
    if (token != null && token != undefined && token != '') {
        let base64Url = token.split('.')[1]; // token you get
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
        return decodedData;
    }
    else
    {
        console.error('Unable to parse token');
        return '{}'
    }

}

module.exports = { parseJwt, parseToken };




