import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';

import { Link } from 'react-router-dom';
import { ApiCall } from '../../modules/ApiCall';
import axios from 'axios';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../Loader/BeatLoader";
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';

import DatePicker from "../DataPicker/index"
import "react-datepicker/dist/react-datepicker.css";
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';

class Candidates extends Component {

    constructor() {
        super();
        this.state = {
            candidates: [],
            isloadedgetCandidates: true,
            filter: "",
            order: "DSC",
            filteredData: [],
            resultedData:[],
            publicJobCount: 0,
            privateJobCount: 0,
            itemOffset: 0,
            startFilterDate: null,
            endFilterDate: null,
            filterCandidateName: "",
            candidatesFilterModal: false,
            showFilteredEmail: "",
            showFilteredName: "",
            showStartDate: null,
            showEndDate: null,
            searchedCandidate:"",
        }

        this.handleInputChange = this.handleInputChange.bind(this);

    }


    //Page_load

    async componentDidMount() {
        document.title = "Tenant App - Candidates";

        this.getCandidates();

    }



    /** Get all Candidates of tenant */
    getCandidates() {
        let bearer = validateAndGetFormatedToken();
        var tenantId = null;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_CORE_API + "/api/TenantCandidates/" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (response.data.length != undefined && response.data.length == 0) {

                        this.setState({
                            isloadedgetCandidates: false,
                            filteredData: []
                        });
                    }
                    else {

                        let tempCandidates = [];
                        (response.data.interviewSummary != [] || response.data.interviewSummary != undefined) && response.data.interviewSummary.forEach((candidate) => {
                            candidate.createdDate = convertUTCDateToBrowserLocale(candidate.createdDate);
                            tempCandidates.push(candidate);
                        })
                        this.setState({
                            candidates: tempCandidates,
                            filteredData: tempCandidates,
                            isloadedgetCandidates: false,

                            publicJobCount: response.data.publicJobCount,
                            privateJobCount: response.data.privateJobCount,
                        });
                    }
                })
                .catch(err => {

                    console.log(err);
                    displayErrorMessage('Error while fetching Candidates!', "top-center");
                });
        }
    }





    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    render() {

        const { candidates,filteredData } = this.state;

        const filteration = () => {
            let filteredData = candidates.length > 0 && candidates.filter((candidate) => {
                let candidateName = candidate.candidateFirstName + " " + candidate.candidateLastName;
                if (this.state.startFilterDate == null && this.state.endFilterDate == null) {
                    return (
                        candidate.candidateEmail.toLowerCase().indexOf(this.state.filter.trim().toLowerCase()) > -1 &&
                        candidateName.toLowerCase().indexOf(this.state.filterCandidateName.trim().toLowerCase()) > -1 &&
                        (
                            candidateName.toLowerCase().indexOf(this.state.searchedCandidate.trim().toLowerCase()) > -1 ||
                            candidate.candidateEmail.toLowerCase().indexOf(this.state.searchedCandidate.trim().toLowerCase()) > -1
                        )
                    )
                }

                else {
                    return candidate.candidateEmail.toLowerCase().indexOf(this.state.filter.trim().toLowerCase()) > -1
                        && candidateName.toLowerCase().indexOf(this.state.filterCandidateName.trim()) > -1
                        && new Date(this.state.startFilterDate) <= new Date(candidate.createdDate)
                        && new Date(new Date(this.state.endFilterDate).setDate(new Date(this.state.endFilterDate).getDate() + 1)) >= new Date(candidate.createdDate)
                        &&
                        (
                        candidateName.toLowerCase().indexOf(this.state.searchedCandidate.trim().toLowerCase()) > -1 ||
                        candidate.candidateEmail.toLowerCase().indexOf(this.state.searchedCandidate.trim().toLowerCase()) > -1
                        )

                }
            }
            );
            this.setState({
                filteredData: filteredData,
                resultedData: filteredData
            });
        }

        const sorting = (col) => {
            if (this.state.order == "ASC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "DSC"
                })
            }
            if (this.state.order == "DSC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "ASC"
                })
            }
        }
        //for pagination
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset} `);
        const currentItems = this.state.filteredData != null ? this.state.filteredData.slice(this.state.itemOffset, endOffset) : [];
        const pageCount = this.state.filteredData != null ? Math.ceil(this.state.filteredData.length / 10) : 0;

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.filteredData.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            this.setState({ itemOffset: newOffset });
        };
        return (

            <>

                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/*<!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    {/*<!-- Breadcrumbs --> */}
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Candidates</li>
                                        </ol>
                                    </nav>
                                    {/*<!-- End Breadcrumbs --> */}
                                    <h1 className="page-header-title">Candidates</h1>
                                </div>
                                {/*<!-- End Col --> */}

                                <div className="col-auto">

                                </div>
                                {/*<!-- End Col --> */}
                            </div>
                            {/*<!-- End Row --> */}
                        </div>
                        {/*<!-- End Page Header --> */}
                        {/*<!--Card--> */}
                        {this.state.isloadedgetCandidates ? <BeatLoaderComponent />
                            :
                            <>
                                {candidates == null ? <div className="card card-centered mb-3 mb-lg-5">
                                    <div className="card-body py-10">
                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                        <div className="text-center">

                                            <h1>No candidate found.</h1>
                                            <p>Once candidate applied then they'll show up here.</p>
                                        </div>

                                    </div>
                                </div>
                                    :
                                    <>

                                        {/*<!-- Start Jobs Overview below this line -->*/}
                                        <div className="row d-none d-lg-flex">
                                            <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className="card card-color-border">
                                                    <div className="card-body text-center">
                                                        <small className="text-cap">Total Candidates</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2" data-value="150">{this.state.candidates.length}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}

                                            <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className="card card-color-border">
                                                    <div className="card-body text-center">
                                                        <small className="text-cap"><span className="legend-indicator bg-success"></span> Public Jobs</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2" data-value="25">{this.state.publicJobCount}</span>

                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}

                                            <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className="card card-color-border">
                                                    <div className="card-body text-center">
                                                        <small className="text-cap"><span className="legend-indicator bg-danger"></span> Private Jobs</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2" data-value="4">{this.state.privateJobCount}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}
                                        </div>
                                        {/*<!-- End Jobs Overview below this line -->*/}


                                        {/*<!-- Start Job Data table-->*/}

                                       


                                        <div className="row my-2 d-none d-lg-block">

                                            <div className="col-md-12">

                                                <div className="card">
                                                    {/*<!-- Header --> */}
                                                    <div className="p-3">

                                                    <div className="row  g-0 " >
                                                        <div className="col-12 col-md-auto">
                                                            <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                                                                <h5 className="f-size-18 fs-2">Candidate</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div className="row  g-0 mb-2" >
                                                            <div className="col-md-4">
                                                                <div className="input-group input-group-merge">
                                                                    <div className="input-group-prepend input-group-text">
                                                                        <i className="bi-search"></i>
                                                                    </div>
                                                                    <input type="search" className="form-control" placeholder="Search candidates by name or email..."
                                                                        onChange={(e) => {
                                                                            if (e.target.value === "") {
                                                                                this.setState({ filteredData: candidates, searchedCandidate: "" },
                                                                                    () => { filteration(); });
                                                                            }

                                                                        }}

                                                                        onKeyDown={(event) => {
                                                                            if (event.key === 'Enter') {
                                                                                this.setState({ searchedCandidate: event.target.value },
                                                                                    () => { filteration(); })
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col d-flex justify-content-end">
                                                              
                                                                
                                                                {this.state.showFilteredEmail && (
                                                                    <div className="filtered-div f-size-14 mx-1">
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            <span className="filter-overflow " >
                                                                                {this.state.showFilteredEmail}                                                                            </span>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close f-size-12"
                                                                                aria-label="Close"
                                                                                style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                                onClick={(e) => {
                                                                                    this.setState({
                                                                                        showFilteredEmail: "",
                                                                                        filter: "",
                                                                                       
                                                                                    }, () => {
                                                                                        filteration();
                                                                                    });
                                                                                }}

                                                                            ></button>
                                                                        </div>
                                                                    </div>


                                                                )}
                                                                {this.state.showFilteredName && (
                                                                    <div className="filtered-div f-size-14 mx-1">
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            <span className="filter-overflow " >
                                                                                {this.state.showFilteredName}                                                                            </span>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close f-size-12"
                                                                                aria-label="Close"
                                                                                style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                                onClick={(e) => {
                                                                                    this.setState({
                                                                                        filterCandidateName: "",
                                                                                        showFilteredName: "",

                                                                                    }, () => {
                                                                                        filteration();
                                                                                    });
                                                                                }}

                                                                            ></button>
                                                                        </div>
                                                                    </div>


                                                                )}
                                                                
                                                                {this.state.showStartDate && (
                                                                    <div className="filtered-div f-size-14 mx-1">
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            <span className="filter-overflow " >
                                                                                {this.state.showStartDate.toDateString()}, {this.state.showEndDate.toDateString()}                                                                            </span>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close f-size-12"
                                                                                aria-label="Close"
                                                                                style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                                onClick={(e) => {
                                                                                    this.setState({
                                                                                        startFilterDate: null,
                                                                                        showStartDate: null,
                                                                                        endFilterDate: null,
                                                                                        showEndDate: null
                                                                                    }, () => {
                                                                                        filteration();
                                                                                    });
                                                                                }}

                                                                            ></button>
                                                                        </div>
                                                                    </div>


                                                                )}
                                                                
                                                              

                                                                <button className="btn btn-primary no-border py-2 mx-2 me-2" style={{ backgroundColor: '#3FB5C3', border: "none" }}
                                                                    onClick={() => {
                                                                        this.setState({ candidatesFilterModal: true })
                                                                        document.body.classList.add('modal-open');


                                                                    }}
                                                                >
                                                                    <i className="bi bi-funnel " style={{ fontSize: '0.8rem', color: 'white' }}></i>
                                                                </button>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    {/*<!-- End Header --> */}

                                                    {/*<!-- Table --> */}
                                                    <div className="table-responsive datatable-custom">

                                                        <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                            <thead className="thead-light">
                                                                <tr>


                                                                    <th className="table-column-ps-1" onClick={() => sorting("candidateEmail")} style={{ cursor: 'pointer', }} > <strong>Candidate <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                    <th style={{ width: '0px' }}><strong>Candidate Details </strong></th>
                                                                    <th><strong>Created Date </strong></th>
                                                                    <th><strong>Interviews </strong></th>
                                                                    <th style={{ width: '0px' }}><strong>Candidate CV </strong></th>
                                                                    <th style={{ width: '0px' }}><strong>Candidate Docs </strong></th>
                                                                    <th><strong>Action</strong></th>
                                                                </tr>

                                                            </thead>

                                                            <tbody>


                                                                {currentItems != [] && currentItems.map((candidate, index) => (
                                                                    <tr role="row" className="odd" key={index}>

                                                                        <td>
                                                                            <a className="d-flex align-items-center" >
                                                                                <div className="ms-0">
                                                                                    <span className="d-block h5 text-inherit mb-0 inter-ff">{candidate.candidateFirstName} {candidate.candidateLastName} </span>
                                                                                    <span className="d-block fs-5 text-body f-gray inter-ff">{candidate.candidateEmail}</span>

                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a className="d-flex align-items-center" >
                                                                                <div className="ms-0">  
                                                                                    <span className="d-block fs-5 text-body inter-ff">
                                                                                        {candidate.candidatePhone ? candidate.candidatePhone : "-"}

                                                                                    </span>

                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td className="inter-ff">
                                                                            {candidate.createdDate}
                                                                        </td>
                                                                        <td className="inter-ff" >
                                                                            {candidate.totalInterviewInvites}
                                                                        </td>
                                                                        <td>  
                                                                            {candidate.candidateCV != null && candidate.candidateCV !== "" ? (
                                                                                <>
                                                                                    <a className="btn-link" type="button" style={{ marginLeft: '1%' }}
                                                                                        href={candidate.candidateCV} target="_blank"><span class="material-symbols-outlined f-size-26" data-tip data-for='candidateCV'>
                                                                                            cloud_download
                                                                                        </span>
                                                                                        <ReactTooltip id="candidateCV" place="right" type="dark" effect="float">
                                                                                            <span> Candidate CV </span>
                                                                                        </ReactTooltip>
                                                                                    </a>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                        <span className="material-symbols-outlined f-size-30" data-tip data-for='candidateNoCV' style={{ color: "#EAECF0" }} aria-hidden="true">
                                                                                        cloud_download
                                                                                    </span>
                                                                                        <ReactTooltip id="candidateNoCV" place="right" type="dark" effect="float">
                                                                                        <span> Candidate CV not attached </span>
                                                                                    </ReactTooltip>
                                                                                </>
                                                                            )}

                                                                        </td>
                                                                        <td >

                                                                            {candidate.isDocUploadRequested ? (
                                                                                <Link to={`/candidatedetails/${candidate.applicationUserId}`} className="btn-icon btn-sm me-2">
                                                                                    <span className="material-symbols-outlined f-size-28" data-tip data-for='candidateDoc'>
                                                                                        file_save
                                                                                    </span>
                                                                                    <ReactTooltip id="candidateDoc" place="right" type="dark" effect="float">
                                                                                        <span> Candidate documents </span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                            ) : (
                                                                                <span className="material-symbols-outlined f-size-28" data-tip data-for='candidateDocNotUploaded' style={{ color: "#EAECF0" }}>
                                                                                    file_save
                                                                                </span>
                                                                            )}
                                                                            <ReactTooltip id="candidateDocNotUploaded" place="right" type="dark" effect="float">
                                                                                <span> Candidate Docs not attached</span>
                                                                            </ReactTooltip>


                                                                        </td>
                                                                        <td>
                                                                            {candidate.applicationUserId == null ? '' :
                                                                                <Link className="btn btn-outline-primary btn-icon btn-sm me-2" type="button" style={{ marginLeft: '1%' }} to={"/candidateinterviews/" + candidate.applicationUserId} data-tip data-for='interviewsbutton' ><span class="material-symbols-outlined  f-size-18">
                                                                                    note_alt
                                                                                </span>
                                                                                    <ReactTooltip id="interviewsbutton" place="right" type="dark" effect="float">
                                                                                        <span> Interviews</span>
                                                                                    </ReactTooltip>
                                                                                </Link>

                                                                            }
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    {/*<!-- End Table --> */}

                                                    {/*<!-- Footer --> */}
                                                    {filteredData.length === 0 ? (
                   <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                   <div className="card-body py-10">
                   <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                       <div className="text-center">

                      <h1>No search results were found </h1>
                           <p>Please refine your search</p>
            
                       </div>

                   </div>
            </div>
            ) : (
                                                    <div className="card-footer">
                                                        <div className="d-flex justify-content-center">

                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                renderOnZeroPageCount={null}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                containerClassName={'pagination'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                            />
                                                        </div>
                                                    </div>
                                                     )}
                                                    {/*<!-- End Footer --> */}
                                                </div>

                                            </div>
                                        </div>


                                        {/*Mobile start here*/}
                                        <div className="row  justify-content-center align-items-center g-0 p-0 mt-1 d-lg-none">
                                            <div className="col-md-12 d-flex d-lg-none mb-2" style={{ paddingRight: '8px' }}>
                                                <div className="col" style={{ marginRight: '.6rem' }}>
                                                    <div className="col">
                                                        <div className="input-group input-group-merge">
                                                            <div className="input-group-prepend input-group-text">
                                                                <i className="bi-search"></i>
                                                            </div>
                                                            <input type="search" className="form-control" placeholder="Search candidates..."
                                                                onChange={(e) => {
                                                                    if (e.target.value === "") {
                                                                        this.setState({ filteredData: candidates, searchedCandidate: "" },
                                                                            () => { filteration(); });
                                                                    }

                                                                }}

                                                                onKeyDown={(event) => {
                                                                    if (event.key === 'Enter') {
                                                                        this.setState({ searchedCandidate: event.target.value },
                                                                            () => { filteration(); })
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn  no-border py-2 me-2 " style={{ backgroundColor: '#3FB5C3' }}
                                                    onClick={() => {
                                                        this.setState({ candidatesFilterModal: true })
                                                        document.body.classList.add('modal-open');

                                                    }}
                                                >
                                                    <i className="bi 
                                                    " style={{ fontSize: '1rem', color: 'white' }}></i>
                                                </button>
                                               
                                            </div>
                                            <div className="row g-0 d-flex filter-chips-mb mt-0 mb-2 b d-lg-none" style={{ overflowX: 'auto' }}>
                                                <div className="col-12">
                                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                                                            {this.state.showFilteredEmail && (
                                                                <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="">{this.state.showFilteredEmail}</span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState({ showFilteredEmail: "", filter: "" }, () => { filteration(); });
                                                                            }}
                                                                        ></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {this.state.showFilteredName && (
                                                                <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="">{this.state.showFilteredName}</span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState({ filterCandidateName: "", showFilteredName: "" }, () => { filteration(); });
                                                                            }}
                                                                        ></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {this.state.showStartDate && (
                                                                <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="">{this.state.showStartDate.toDateString()}, {this.state.showEndDate.toDateString()}</span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState({ startFilterDate: null, showStartDate: null, endFilterDate: null, showEndDate: null }, () => { filteration(); });
                                                                            }}
                                                                        ></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                           
                                              
                                           

                                            <div className=" col-md-12">
                                                <div className="card border-0 jc-radius pb-3">
                                                    <table className="table jobs-overview-table">
                                                        <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                                            <tr>
                                                                <th scope="col " className="p-3" colSpan={3}>
                                                                    Candidates

                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="">
                                                            {currentItems != [] && currentItems.map((candidate, index) => (
                                                                <tr >
                                                                    <td className="p-3">
                                                                        <div className="row  mb-0">

                                                                            <div className="col d-flex">


                                                                                <div className="d-flex align-items-center">

                                                                                    <div>
                                                                                        <h2 className="inter-ff f-size-14 f-weight-500 ">{candidate.candidateFirstName} {candidate.candidateLastName} </h2>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={candidate.applicationUserId ? "col-5 d-flex justify-content-end" : "col-3 d-flex justify-content-end"}>
                                                                                <div>
                                                                                    {candidate.isDocUploadRequested == true ? (
                                                                                        <div className="btn btn-outline-primary btn-icon btn-sm me-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                            <Link to={"/candidatedetails/" + candidate.applicationUserId} className="btn-icon btn-sm w">
                                                                                                <span className="material-symbols-outlined f-size-18" data-tip data-for='candidateDoc'>
                                                                                                    file_save
                                                                                                </span>
                                                                                                <ReactTooltip id="candidateDoc" place="right" type="dark" effect="float">
                                                                                                    <span>Candidate documents</span>
                                                                                                </ReactTooltip>
                                                                                            </Link>
                                                                                        </div>
                                                                                    ) : ""}
                                                                                </div>
                                                                                <div className="d-flex ">
                                                                                    <div className="btn btn-outline-primary btn-icon btn-sm me-2" style={{ display: 'flex', alignItems: 'center' }}>

                                                                                        <a className="btn-link" type="button" style={{ marginLeft: '1%', display: 'flex', alignItems: 'center' }} href={candidate.candidateCV} target="_blank">
                                                                                            <span className="material-symbols-outlined f-size-18">
                                                                                                cloud_download
                                                                                            </span>
                                                                                        </a>

                                                                                    </div>
                                                                                    {candidate.applicationUserId == null ? '' :
                                                                                        <Link className="btn btn-outline-primary btn-icon btn-sm me-2" type="button" style={{ marginLeft: '1%' }} to={"/candidateinterviews/" + candidate.applicationUserId}  ><span class="material-symbols-outlined  f-size-18 ">
                                                                                            note_alt
                                                                                        </span></Link>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className=" mb-1 ">
                                                                            <div className="row g-0">
                                                                                <div className="col-5 d-flex align-items-center ">
                                                                                    <span class="material-symbols-outlined f-size-12">
                                                                                        call
                                                                                    </span>
                                                                                    <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                        Phone:
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-7 align-items-center ">
                                                                                    <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                        {candidate.candidatePhone ? candidate.candidatePhone : "-"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" mb-1 ">
                                                                            <div className="row g-0">
                                                                                <div className="col-5 d-flex align-items-center ">
                                                                                    <span className="material-symbols-outlined f-size-12">
                                                                                        pending_actions
                                                                                    </span>
                                                                                    <p className="f-size-11 mx-1 mb-0 f-gray-2 f-weight-400 f-inter  ">
                                                                                        Created Date:
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-7 d-flex align-items-center ">
                                                                                    <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                        {candidate.createdDate}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" mb-1 ">
                                                                            <div className="row g-0 ">
                                                                                <div className="col-5 d-flex align-items-center ">
                                                                                    <span className="material-symbols-outlined f-size-12">mail</span>
                                                                                    <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                                        Email:
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-7 align-items-center ">
                                                                                    <p className="f-size-11 col-8 mb-0 f-gray-2 f-weight-400 f-inter text-overflow-leads">
                                                                                        {candidate.candidateEmail}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" mb-1 ">
                                                                            <div className="row g-0">
                                                                                <div className="col-5 d-flex align-items-center ">
                                                                                    <span class="material-symbols-outlined f-size-12">
                                                                                        note_alt
                                                                                    </span>
                                                                                    <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                        Interviews:
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-7 d-flex align-items-center ">

                                                                                    <p className="f-size-11  mb-0 f-gray-2  f-weight-400 f-inter  text-overflow-leads">
                                                                                        {candidate.totalInterviewInvites} Interviews
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                       
                                                                    </td>
                                                                </tr>
                                                     ))}
                                                        </tbody>
                                                    </table>
                                                    {/*<!-- Footer --> */}
                                                    {filteredData.length === 0 ? (
                                                        <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                                            <div className="card-body py-10">
                                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                <div className="text-center">

                                                                    <h1>No search results were found </h1>
                                                                    <p>Please refine your search</p>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="card-footer">
                                                            <div className="d-flex justify-content-center">

                                                                <ReactPaginate
                                                                    breakLabel="..."
                                                                    nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                    onPageChange={handlePageClick}
                                                                    pageRangeDisplayed={5}
                                                                    pageCount={pageCount}
                                                                    previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                    renderOnZeroPageCount={null}
                                                                    breakClassName={'page-item'}
                                                                    breakLinkClassName={'page-link'}
                                                                    containerClassName={'pagination'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    activeClassName={'active'}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/*<!-- End Footer --> */}
                                                </div>

                                            </div>
                                        </div>

                                        {/*Mobile end here*/}


                                    </>
                                }
                            </>
                        }

                        {/*<!--End Card--> */}
                        {/*<!-- End Job Data table--> */}

                    </div>
                    {/* <!-- End Content --> */}


                </main>


                {/*Modal for Candidates Filter*/}

                {this.state.candidatesFilterModal &&
                    <div className="d-block modal right " id="AddIntroVideo" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog" aria-hidden="true">
                        <div className="modal-dialog filter-modal-width jobs-filter-modal-dialog">
                            <div className="modal-content jobs-filter-modal no-border">
                                {/* Header */}
                                <div className="modal-header no-border p-3 pt-4 pb-4 d-flex align-items-center" style={{ backgroundColor: '#F0F2F7' }}>
                                    <h1 className="f-size-24 f-weight-700">Candidates Filters</h1>
                                    <button type="button" className="btn-close" aria-label="Close " onClick={() => {
                                        this.setState({ candidatesFilterModal: false, filterCandidateName: this.state.showFilteredName, filter: this.state.showFilteredEmail, startFilterDate: this.state.showStartDate, endFilterDate: this.state.showEndDate })
                                        document.body.classList.remove('modal-open');


                                    }}></button>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="modal-body">
                                   


                                    <div className="row mb-4 g-0">
                                        <label htmlFor="created-date" className="f-gray f-weight-600 f-size-14 mb-3">
                                            CANDIDATE EMAIL
                                        </label>
                                    <input type="text" className="form-control" value={this.state.filter}
                                        onChange={(e) => { this.setState({ filter: e.target.value }) }}
                                        name="candidateEmail" id="candidateEmail" placeholder="Search Candidate Email"
                                        />
                                    </div>

                                    <div className="row mb-4 g-0">
                                        <label htmlFor="created-date" className="f-gray f-weight-600 f-size-14 mb-3">
                                            CANDIDATE NAME
                                        </label>
                                        <input type="text" className="form-control" value={this.state.filterCandidateName}
                                            onChange={(e) => { this.setState({ filterCandidateName: e.target.value }) }}
                                            name="candidateName" id="candidateName" placeholder="Search Candidate Name"
                                            />
                                    </div>

                                  
                                    <div className="row mb-4 g-0">
                                        <label htmlFor="created-date" className="f-gray f-weight-600 f-size-14 mb-3">
                                            CREATED DATE
                                        </label>
                                        <div className="d-flex gap-2">
                                            <div className="col">
                                                <label htmlFor="from-date" className="f-gray f-weight-600 f-size-12 mb-1">
                                                    FROM
                                                </label>
                                                <DatePicker
                                                    selected={this.state.startFilterDate}
                                                    onChange={(date) => this.setState({ startFilterDate: date, endFilterDate: date })}
                                                    selectsStart
                                                    startDate={this.state.startFilterDate}
                                                    endDate={this.state.endFilterDate}
                                                    customInput={<input className="form-control"></input>}
                                                    placeholderText="From Create Date"
                                                    placeholder="From Create Date"
                                                    autoFocus={false} // Apply focus
                                                />

                                            </div>
                                            <div className="col">
                                                <label htmlFor="to-date" className="f-gray f-weight-600 f-size-12 mb-1">
                                                    TO
                                                </label>
                                                <DatePicker
                                                    selected={this.state.endFilterDate}
                                                    onChange={(date) => this.setState({ endFilterDate: date })}
                                                    selectsEnd
                                                    startDate={this.state.startFilterDate}
                                                    endDate={this.state.endFilterDate}
                                                    minDate={this.state.startFilterDate}
                                                    customInput={<input className="form-control"></input>}
                                                    placeholder="To Create Date"

                                                />
                                            </div>
                                        </div>
                                    </div>
                                   
                                    
                                   

                                    <div className="d-flex justify-content-end gap-4 mb-3">
                                        <button type="button" className="btn filter-clear-btn" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    filter: "",
                                                    startFilterDate: null,
                                                    endFilterDate: null,
                                                    filterCandidateName: "",
                                                    showFilteredEmail: "",
                                                    showFilteredName: "",
                                                    showStartDate: null,
                                                    showEndDate:null,

                                                }, () => {
                                                    this.setState({
                                                        filteredData: this.state.resultedData
                                                    });
                                                    filteration();
                                                }
                                                   
                                                );
                                            }}

                                        >
                                            Clear
                                        </button>
                                        <button type="button" className="btn btn-primary f-size-14 f-weight-700" data-bs-dismiss="modal" aria-label="Close"
                                          
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (this.state.startFilterDate != null) {
                                                    if (this.state.endFilterDate == null) {
                                                        displayWarningMessage('Please select end date !', "top-center");
                                                        return;
                                                    }
                                                }
                                                if (this.state.endFilterDate != null) {
                                                    if (this.state.startFilterDate == null) {
                                                        displayWarningMessage('Please select start date !', "top-center");
                                                        return;
                                                    }
                                                }
                                                if (this.state.startFilterDate > this.state.endFilterDate) {
                                                    displayErrorMessage("To date should be equal or greater than From date", "top-center");
                                                    return
                                                }

                                                this.setState({
                                                    candidatesFilterModal: false, showFilteredEmail: this.state.filter,
                                                    showFilteredName: this.state.filterCandidateName,
                                                    showStartDate: this.state.startFilterDate,
                                                    showEndDate: this.state.endFilterDate,
                                                })

                                                document.body.classList.remove('modal-open');
                                                if (this.state.startFilterDate != null) {
                                                    if (this.state.endFilterDate == null) {
                                                        displayWarningMessage('Please select end date !', "top-center");
                                                        return;
                                                    }
                                                }
                                                filteration();
                                            }}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                {/* End Body */}
                            </div>
                        </div>
                    </div>

                }
                {/*Candidates filter modal end here*/}

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />

            </>

        );
    }
} export default Candidates;