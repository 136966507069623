import  { Component } from 'react';
import { logout} from './oidc'
import Cookies from 'js-cookie';

export class Logout extends Component {
    static displayName = Logout.name;


    constructor(props)
    {
        super(props);    
        this.initData();
    }
    render() {
        return (logout());
    }

    initData = () =>
    {

        localStorage.removeItem("id_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("sidebarToggle");
        Cookies.remove('activeLink');
        
    }
}








