import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { parseToken } from '../../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';

import { Link } from 'react-router-dom';
import { ApiCall } from '../../../modules/ApiCall';
import axios from 'axios';
import { parseJwt } from '../../../modules/TokenParser';
import { ToastContainer } from 'react-toastify';
import SuccessQuestionSet from "../../../assets/svg/illustrations/oc-hi-five.svg";
import ReactTooltip from 'react-tooltip';
import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

class CreateJobTitle extends Component {

    constructor() {
        super();
        this.state = {
            industries: [],
            selectedIndustry: "",
            jobTitle: "",
            questionssets: [],
            isloadedgetQuestionSets: true,
            selectedIndustryId: -1,
            selectedQuestionSetId: -1,
            SuccessMessage: false,
            disableSaveBtn: false,
            AllJobTitles: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Tenant App - Create Job Title";

        this.getIndustrieslookup();
        this.getQuestionSets();
    }

    /**
     * Industries from look up*/
    getIndustrieslookup() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let industryId = null;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data != null) {
                        this.setState({
                            selectedIndustry: response.data[0].title,
                            selectedIndustryId: response.data[0].defaultIndustryId,
                            industries: response.data,
                        });
                        industryId = response.data[0].defaultIndustryId;
                    }
                    else
                        console.error("Unable to get industries");


                }).then(() => {
                    this.getAllJobTitles(industryId);
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Industy lookup!', "top-center");

                });
        }

    }

    /**
     * Get All Question Sets */
    getQuestionSets() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({
                        questionsets: response.data,
                        selectedQuestionSetId: response.data[0].questionSetId,
                        isloadedgetQuestionSets: false
                    })

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Question Sets!', "top-center");

                })
        }
    }

    /**
  * Get All job titles default and tenant*/
    getAllJobTitles(industryId) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobTitles/" + industryId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length != undefined && response.data.length == 0) {
                        this.setState({
                            AllJobTitles: [],

                        });
                    }
                    else {
                        this.setState({
                            AllJobTitles: response.data,

                        });

                    }
                }).catch(err => {
                    console.log(err);
                    if (err.response.data == "No data found") {
                        this.setState({
                            AllJobTitles: [],

                        });
                    }
                    else {
                        this.setState({
                            AllJobTitles: [],

                        });
                        displayErrorMessage('Error while Fetching  Job Titles!', "top-center");
                    }
                });
        }

    }


    /** input values event onchange
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /** Save Job Title*/

    handleSubmit(event) {
        let bearer = validateAndGetFormatedToken();
        let tenantId = null;
        let count = 0;
        if (this.state.selectedIndustryId == -1) {
            displayErrorMessage('Please select industry!', "top-center");
            return;
        }
        if (this.state.selectedQuestionSetId == -1) {
            displayErrorMessage('Please select question set!', "top-center");
            return;
        }
        if (this.state.jobTitle == "") {
            displayErrorMessage('Please add job title!', "top-center");
            return;
        }
        this.state.AllJobTitles.forEach((item, index) => {
            if (item.title == this.state.jobTitle) {
                count++;
            }
        });
        if (count > 0) {
            displayErrorMessage('Job title is already present!', "top-center");
            return;
        }
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let data = {
                Title: this.state.jobTitle,
                ShortDescription: this.state.jobTitle,
                IsDefault: false,
                TenantId: tenantId,
                IsActive: true,
                DefaultIndustryId: this.state.selectedIndustryId,
            }
            let url = process.env.REACT_APP_CORE_API + '/api/JobTitles';

            var model = JSON.stringify(data);

            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result != null && result != undefined) {
                        let data1 = {
                            JobTitleId: result,
                            QuestionSetId: this.state.selectedQuestionSetId,

                            IsActive: true,
                        }
                        let url = process.env.REACT_APP_CORE_API + '/api/JobTitleDefaultTemplates';

                        var model1 = JSON.stringify(data1);

                        const requestOptions = {
                            method: 'POST',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            },
                            body: model1
                        };
                        fetch(url, requestOptions)
                            .then(() => {

                                displaySuccessMessage('Job Title Default Template Saved Successfullt!', "top-center");
                                this.setState({ jobTitle: "" })
                            }).catch(err => {
                                console.log(err);
                                displayErrorMessage('Error while Saving Job Title Default Template!', "top-center");
                            });


                    }
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Saving Job Title!', "top-center");
                });

        }
    }



    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>
                                {/* <!-- End Col --> */}
                                <div className="col-auto">
                                    <button className="btn btn-outline-primary" onClick={() => this.props.history.goBack()}>
                                        <i className="bi bi-arrow-left me-1"></i> Back
                                    </button>
                                </div>
                                {/* <!-- End Col --> */}
                            </div>
                            {/* <!-- End Row --> */}
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />

                            </div>
                        </div>
                        {/* <!-- End Page Header --> */}
                        <>
                            {this.state.SuccessMessage ?

                                <div id="validationFormSuccessMessage" className="js-success-message">
                                    <div className="text-center">
                                        <img className="img-fluid mb-3" src={SuccessQuestionSet} alt="Image Description" style={{ maxWidth: '15rem' }} />

                                        <div className="mb-4">
                                            <h2>Successful!</h2>
                                            <p>Your Job Title have been successfully saved.</p>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-white me-3" onClick={() => this.props.history.goBack()}>
                                                <i className="bi-chevron-left small ms-1"></i> Back
                                            </button>
                                            <button className="btn btn-primary" onClick={() => { window.location.reload(true); }}>
                                                <i className="tio-city me-1"></i> Create New Job Title
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>



                                    <div className="row">
                                        {/* <!--Find me--> */}

                                        <div className="col-lg-12">
                                            <div className="d-grid gap-3 gap-lg-5">

                                                {/* <!-- Card --> */}
                                                <div id="descriptionSection" className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Create Job Title</h4>
                                                    </div>

                                                    {/* <!-- Body --> */}
                                                    <div className="card-body">
                                                        <div className="row mb-4">
                                                            <label for="industries" className="col-sm-3 col-form-label form-label">Industries <span className="form-label-secondary text-danger">*</span></label>

                                                            <div className="col-sm-9">
                                                                {/* <!-- Select --> */}
                                                                <div className="tom-select-custom mb-4">
                                                                    <select className="form-select"
                                                                        name="industries" onChange={(e) => {

                                                                            this.setState({ selectedIndustryId: e.target.value });
                                                                            this.getAllJobTitles(e.target.value);
                                                                        }}>


                                                                        {this.state.industries != [] && this.state.industries.map((industry, index) =>
                                                                            <option key={index} value={industry.defaultIndustryId}>{industry.title}</option>


                                                                        )}

                                                                    </select>

                                                                </div>
                                                                {/* <!-- End Select --> */}
                                                            </div>
                                                        </div>

                                                        <div className="row mb-4">
                                                            <label for="questionsets" className="col-sm-3 col-form-label form-label">Question Sets <span className="form-label-secondary text-danger">*</span></label>

                                                            <div className="col-sm-9">
                                                                {/* <!-- Select --> */}
                                                                <div className="tom-select-custom mb-4">
                                                                    <select className="form-select"
                                                                        name="questionsets" onChange={(e) => {

                                                                            this.setState({ selectedQuestionSetId: e.target.value });
                                                                        }}>


                                                                        {(!this.state.isloadedgetQuestionSets && this.state.questionsets.length > 0) && this.state.questionsets.map((questionset, index) =>
                                                                            <option key={index} value={questionset.questionSetId}>{questionset.questionSetName}</option>


                                                                        )}

                                                                    </select>

                                                                </div>
                                                                {/* <!-- End Select --> */}
                                                            </div>
                                                        </div>


                                                        <div className="row mb-4">
                                                            <label for="jobTitle" className="col-sm-3 col-form-label form-label">Job Title <span className="form-label-secondary text-danger">*</span></label>

                                                            <div className="col-sm-9">
                                                                <input type="text" className="form-control" onChange={this.handleInputChange} value={this.state.jobTitle} name="jobTitle" id="jobTitle" placeholder="Job Title" />


                                                            </div>

                                                        </div>

                                                    </div>
                                                    {/* <!-- End Body --> */}
                                                </div>
                                                {/* <!-- End Card --> */}


                                            </div>
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-end gap-3">
                                                    <button type="button" className="btn btn-white" onClick={() => this.props.history.goBack()}>Cancel</button>
                                                    <button type="button" id="btnSave" className="btn btn-primary" onClick={this.handleSubmit}>Save</button>
                                                </div>
                                            </div>
                                            {/* <!-- Sticky Block End Point --> */}
                                            <div id="stickyBlockEndPoint"></div>
                                        </div>
                                    </div>
                                    {/* <!-- End Row --> */}
                                </>}
                        </>



                        {/* <!-- End Step Form --> */}
                        {/* <!--End Stepper--> */}

                    </div>

                    {/* <!-- End Content --> */}

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}
export default CreateJobTitle;