import Oidc from 'oidc-client'

var config =
{
    authority: process.env.REACT_APP_IDENTITY_API,
    client_id: "spa",
    redirect_uri: process.env.REACT_APP_TENANT+"/oidc_callback",
    response_type: "id_token token",
    scope: "openid profile core_api roles",
   // post_logout_redirect_uri: process.env.REACT_APP_TENANT +"/index.html",
    loadUserInfo: true, 
};

//console.log(" Config si  =================== >" + config);

var mgr = new Oidc.UserManager(config);

console.info(mgr);

export function login()
{
    mgr.signinRedirect();
}

export function logout() {
    mgr.signoutRedirect();
} 










