import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage } from '../../modules/ToastMessage';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import { parseToken } from '../../modules/TokenParser';
import SrQuestionlisting from "./../../assets/img/new-design/sr-questions-listing.png";
class ViewQuestionSet extends React.Component {

    constructor() {
        super();
        this.state = {
            questions: [],
            questionsetname: "",
            questiontitle: "",
            description: "",
            questiontype: "",
            answertype: "",
            removedQuestions: [],
            tags: "",
            expandTableRow: false,
            rowId: "",
            //Dummy Question set for
            questionsset: [
            ],



            //Selected Question Set
            selectedquestionset: {
                questions: []
            },
            selectedquestionsetcopy: [

            ],
            isloadedgetQuestions: true,
            isloadedgetQuestionSets: true,
            editQuestionSetPermission: true




        }
    }

    componentDidMount() {
        document.title = "Tenant App - QuestionSets";

        this.getPermissions();
        this.getQuestionSet(this.props.match.params.questionSetId);
    }

    getQuestionSet(questionSetId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet/" + questionSetId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    console.log("get specific question set", result);
                    this.setState({ selectedquestionset: result });
                    this.setState({ selectedquestionsetcopy: result.questions })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Question Set!', "top-center");

                });
        }
    }

    getPermissions() {
        let bearer = validateAndGetFormatedToken();
        var editQuestionSetPermission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                editQuestionSetPermission = CheckPermission(parseToken(bearer).roles, "Permissions.QuestionSet.Create", bearer);
                this.setState({ editQuestionSetPermission: editQuestionSetPermission });
            }

        }
    }

    render() {
        return (
            <>
                <main id="content" role="main"
                    className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}
                // className="main"
                >
                    {/* <!-- Content --> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="offset-lg-2 col-lg-9 col-md-12 pb-4">
                                <>
                                    <div className="swift-bar m-auto mb-swift pb-2 mt-2 col-12">

                                        <div className="swift-bar m-auto mb-swift pb-2 mt-2 col-12">
                                            
                                        </div>

                                        <div className="card h-swift-130 jc-radius border-0 mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-12">
                                                    <div className="job-card-img jc-pad">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="card-title jc-title mb-0">
                                                                Question Set
                                                            </h5>
                                                            <img
                                                                src={SrQuestionlisting}
                                                                alt="Image"
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-lg-12">
                                                <div className="d-grid gap-2 gap-lg-2">

                                                    <div className="card border-0 jc-radius mb-3">
                                                        {/* <!-- Card --> */}
                                                        <div id="descriptionSection" className="box-questSet scrolling-container">
                                                            {/* <div className="card-header card-header-content-between">
                                                <h4 className="card-header-title">Description</h4>*/}


                                                            {/* <!-- End Dropdown --> */}
                                                            {/*   </div> */}
                                                            {/* <!-- Body --> */}


                                                            <div className="d-flex align-items-center">
                                                                <div className="col-md-12 mb-4">
                                                                    <label for="questionTitle" className="col-form-label form-label">Name Question Set<i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Displayed on public forums, such as Front."></i></label>
                                                                    <div className="input-group ">
                                                                        <input type="text" className="form-control marg-r-20 bg-white" disabled value={this.state.selectedquestionset.questionSetName} name="questionsetname" onChange={this.handleInputChange} id="questionTitle" placeholder="Type your question set name" aria-label="Your department" />

                                                                        <Link className="btn btn-primary" to={"/updatequestionset/" + this.props.match.params.questionSetId}>
                                                                            Edit
                                                                        </Link>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <!-- End Body --> */}
                                                        </div>
                                                        {/* <!-- End Card --> */}
                                                    </div>

                                                    <div className="card border-0 jc-radius mb-3">
                                                        {/* <!-- Card --> */}
                                                        <div id="connectedAccountsSection" className="box-questSet  box-questionSet scrolling-container navbar-vertical-content">
                                                            <div className="card-header card-header-content-between">
                                                                <h4 className="card-header-title fw-semibold ">Questions</h4>

                                                                {/* <!-- Dropdown --> */}
                                                                {this.state.editQuestionSetPermission &&

                                                                    <Link className="btn btn-white btn-sm b-none" to={"/updatequestionset/" + this.props.match.params.questionSetId}>
                                                                        <i className="bi bi-pencil-square me-1"></i>
                                                                    </Link>
                                                                }
                                                                {/* <!-- End Dropdown --> */}
                                                            </div>


                                                            {/* <!-- Body --> */}
                                                            <div className="card-body">

                                                                {/* <!-- Form --> */}
                                                                <form>
                                                                    <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
                                                                        {/* <!-- List Item --> */}
                                                                        {this.state.selectedquestionset.questions.length > 0 && this.state.selectedquestionset.questions.map((question, index) => {
                                                                            return <div className="list-group-item" key={index}>

                                                                                {(question.questionContentType.toLowerCase() === "grammar" || question.questionContentType.toLowerCase() === "comprehension") && (
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col">
                                                                                            <h5 className="card-title jc-title f-size-16" style={{ textTransform: 'capitalize' }}>
                                                                                                {question.questionContentType} Question
                                                                                            </h5>
                                                                                            <p className="fs-5 text-body mb-0 w-description" dangerouslySetInnerHTML={{ __html: question.title }}></p>
                                                                                        </div> 

                                                                                    </div>
                                                                                )}

                                                                                {(question.questionContentType.toLowerCase() !== "grammar" && question.questionContentType.toLowerCase() !== "comprehension") && (
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col">
                                                                                            <h4 className="mb-0" dangerouslySetInnerHTML={{ __html: question.title }}></h4>
                                                                                            <p className="fs-5 text-body mb-0" dangerouslySetInnerHTML={{ __html: question.description }}></p>
                                                                                        </div>
                                                                                        {/* <!-- End Col --> */}

                                                                                    </div>
                                                                                )} 


                                                                            </div>
                                                                        })
                                                                        }




                                                                    </div>
                                                                </form>
                                                                {/* <!-- End Form --> */}

                                                            </div>
                                                            {/* <!-- End Body --> */}

                                                        </div>
                                                        {/* <!-- End Card --> */}
                                                    </div>

                                                </div>

                                                <div className="card border-0 jc-radius  jc-t-pad h-swift-92 mb-3">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="d-md-flex d-flex gap-3 justify-content-between">
                                                                <Link
                                                                    type="button"
                                                                    className="border-swift btn-swift-text btn btn-light swift-shadow b-d-size text-right"
                                                                    to="/questionsets"
                                                                >
                                                                    Cancel
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <!-- Sticky Block End Point --> */}
                                                <div id="stickyBlockEndPoint"></div>
                                            </div>
                                        </div>
                                    </div>
                                </>


                                {/* <!-- End Step Form --> */}
                                {/* <!--End Stepper--> */}

                            </div>
                        </div>
                    </div>

                    {/* <!-- End Content --> */}

                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        );
    }
}

export default ViewQuestionSet;
