import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseJwt, parseToken } from '../../modules/TokenParser';
import axios from "axios";
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'

class OfferLetterDetailComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state =
        {
            candidateDetails: [],
            isloadedgetVideos: false,
            isloadedgetCandidateDetails: true,
            isloadedgetTenantUserGroups: true,
            isloadedgetQuestionSets: true,
            editJobPermission: true,
            createJobPermission: true,
            createInterviewPermission: true,
            offerStatus: "",
            offerLetterUrl: "",
            offerLetterResponseUrl: "",
            comments: "",
            name: "",
            candidateEmail: "",
            phoneNumber: "",
            offerLetterData:[]
        };



    }
    componentDidMount() {

        this.getCandidateOfferLetter(parseInt(this.props.interviewInviteId));
    }

    /**
     * Fetch Candidate offer letter
     * @param {any} interviewInviteId
     */
    async getCandidateOfferLetter(interviewInviteId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/OfferLetter/" + interviewInviteId;
            await axios(
                url,

                {
                    method: 'GET',
                    headers: {
                        'Authorization': bearer,
                        'accept': '*/*',
                        'Content-Type': 'application/json-patch+json'
                    }
                }
            )
                .then(response => {
                    this.setState({
                        offerLetterData: response.data,
                        offerStatus: response.data.offerStatus,
                        offerLetterUrl: response.data.offerLetterUrl,
                        offerLetterResponseUrl: response.data.offerLetterResponseUrl,
                        comments: response.data.comments,
                        name: response.data.name,
                        candidateEmail: response.data.candidateEmail,
                        phoneNumber: response.data.phoneNumber,
                    })
                })

                .catch(err => {
                    if (err.response.data !== "No data found") {
                        console.log(err);
                        displayErrorMessage('Error while fetching Candidate Offer Letter!', "top-center");
                    }


                });
        }
    }


    render() {

        return (
            <>
                
                        {/* <!-- Start Job Details--> */}


                {this.state.offerLetterData.length === 0 && (
                    <div className=" card-centered mb-3 mb-lg-5">
                        <div className="card-body py-10">
                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                            <div className="text-center">

                                <h1>No offer letter uploaded.</h1>




                            </div>

                        </div>
                    </div>
                )
                }
                {this.state.offerLetterData.length != 0 && (
                        <div className=" my-0">

                           
                            <div className="">
                                <div className="list-group-item">

                                    <div className="row col-sm-divider">

                                        <div className="col-sm-4">
                                            <div className="d-lg-flex align-items-md-center">
                                                <div className="flex-grow-1 ms-lg-0">
                                                    <span className="d-block fs-6"><strong> Offer Status </strong> </span>
                                                    <div className="d-flex align-items-center mt-1"> 
                                                        {
                                                            this.state.offerStatus == "requested" &&
                                                            <label className='badge badge-base-requested ms-1 fw-bold fs-5'>{this.state.offerStatus ==="requested" ? "Requested" : ""}</label>
                                                        }
                                                        {
                                                            this.state.offerStatus == "accepted" &&
                                                            <label className='badge badge-base-accepted ms-1 fw-bold fs-5'>{this.state.offerStatus === "accepted" ? "Accepted" : ""}</label>
                                                        }
                                                        {
                                                            this.state.offerStatus == "expired" &&
                                                            <label className='badge badge-base-expired ms-1 fw-bold fs-5'>{this.state.offerStatus === "expired" ? "Expired" : ""}</label>
                                                        } 

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="d-lg-flex align-items-md-center">
                                                <div className="flex-grow-1 ms-lg-0">
                                                    <span className="d-block fs-6"><strong>Requested Offer Letter </strong> </span>
                                                    <div className="d-flex align-items-center">
                                                        {(this.state.offerLetterUrl != null && this.state.offerLetterUrl != "") && <a className="btn-link" type="button" style={{ marginLeft: '1%' }}
                                                            href={this.state.offerLetterUrl} target="_blank" > <span class="material-symbols-outlined f-size-30" >
                                                                cloud_download
                                                            </span>

                                                            
                                                        </a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="d-lg-flex align-items-md-center">
                                                <div className="flex-grow-1 ms-lg-0">
                                                    <span className="d-block fs-6"><strong>Signed Offer Letter </strong> </span>
                                                    <div className="d-flex align-items-center">
                                                        {this.state.offerLetterResponseUrl != null && this.state.offerLetterResponseUrl !== "" ? (
                                                            <>
                                                                <a className="btn-link" type="button" style={{ marginLeft: '1%' }} href={this.state.offerLetterResponseUrl} target="_blank" aria-label="Download Offer Letter">
                                                                    <i style={{ fontSize: '2rem' }} className="bi bi-cloud-arrow-down me-1"></i>
                                                                </a>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined f-size-30" data-tip data-for='candidateDoc' style={{ color: "#EAECF0" }} aria-hidden="true">
                                                                    cloud_download
                                                                </span>
                                                                <ReactTooltip id="candidateDoc" place="right" type="dark" effect="float">
                                                                    <span> Signed Offer Letter not attached </span>
                                                                </ReactTooltip>
                                                            </>
                                                        )}



                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 mt-3">
                                            <div className="d-lg-flex align-items-md-center">
                                                <div className="flex-grow-1 ms-lg-0">
                                                    <span className="d-block fs-6"><strong> Comments </strong> </span>
                                                    <div className="d-flex align-items-center">
                                                        <p><small>{this.state.comments}</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/*<!-- End Header --> */}

                          
                        </div>
                )}
                        {/* <!-- Content --> */}


                        {/* <!-- End Job Details --> */}


                    {/* <!-- End Content --> */}
                 

              
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}

export default OfferLetterDetailComponent;

