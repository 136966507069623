import React, { Component } from "react";
import { parseToken } from "../../modules/TokenParser";
import { validateAndGetFormatedToken } from "../../modules/CheckToken";
import { CheckPermission } from "../../modules/CheckPermission";
import {
    displayWarningMessage,
} from "../../modules/ToastMessage";
import { Link } from "react-router-dom";
import axios from "axios";
import Empty from "../../assets/svg/illustrations/oc-browse.svg";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import SrQuestionlisting from "./../../assets/img/new-design/sr-questions-listing.png";

class Questions extends Component {
    constructor() {
        super();
        this.state = {
            QuestionId: "",
            UpdateQuestion: {},
            questions: [],
            createQuestionPermission: true,
            editQuestionPermission: true,
            filter: "",
            searchQuestionTag: "",
            filterSearchQuestionTag: "",
            questionsset: [],
            isloadedgetUserPermissions: true,
            isloadedgetgetQuestions: true,
            questionHeaders: [
                {
                    label: "Question Title",
                    key: "title",
                },
                {
                    label: "Question Description",
                    key: "description",
                },
                {
                    label: "Question Type",
                    key: "questionType",
                },
                {
                    label: "Answer Type",
                    key: "answerType",
                },
                {
                    label: "Tags",
                    key: "tags",
                },
            ],
            order: "DSC",
            filteredData: [],
            hideFilteration: false,
            itemOffset: 0,
            currentPage:"",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        document.title = "Tenant App - Questions";

        this.getUserPermissions();
        this.getQuestions();
    }

    getUserPermissions() {
        let bearer = validateAndGetFormatedToken();
        var createQuestionPermission = false;
        var editQuestionPermission = false;
        if (bearer != "" || bearer != null) {
            console.log("parseToken(bearer).roles", parseToken(bearer).roles);
            if (parseToken(bearer).roles != "TenantAdmin") {
                createQuestionPermission = CheckPermission(
                    parseToken(bearer).roles,
                    "Permissions.Question.Create",
                    bearer
                );
                this.setState({ createQuestionPermission: createQuestionPermission });

                editQuestionPermission = CheckPermission(
                    parseToken(bearer).roles,
                    "Permissions.Question.Edit",
                    bearer
                );
                this.setState({ editQuestionPermission: editQuestionPermission });
            }
        }
        this.setState({ isloadedgetUserPermissions: false });
    }

    getQuestions() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/Questions";
            axios(url, {
                method: "Get",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        questions: response.data,
                        filteredData: response.data,
                        isloadedgetgetQuestions: false,
                    });
                })
                .catch((err) => {
                    console.log("err from getquestions", err);
                });
        }
    }

    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    render() {

        const { questions, filteredData } = this.state;
        const filteration = () => {
            const filteredData = questions.filter(
                (item) =>
                    typeof item.title === "string" &&
                    item.title.toLowerCase().indexOf(this.state.filter.trim().toLowerCase()) >
                    -1 &&
                    typeof item.tags === "string" &&
                    item.tags
                        .toLowerCase()
                        .indexOf(this.state.filterSearchQuestionTag.trim().toLowerCase()) > -1
            );

            this.setState({
                filteredData: filteredData,
                itemOffset: 0, // Reset the itemOffset to the first page when filtering.
            });
        };

        const sorting = (col) => {
            if (this.state.order == "ASC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "DSC",
                });
            }
            if (this.state.order == "DSC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "ASC",
                });
            }
        };
        //for pagination
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.filteredData.slice(
            this.state.itemOffset,
            this.state.itemOffset + 10
        );
        const pageCount = Math.ceil(this.state.filteredData.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = event.selected * 10;
            this.setState({ itemOffset: newOffset, currentPage:event.selected });
        };
        return (
            <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                <section id="main-right">
                    <div className="container-fluid">
                        <div className="row mt-3">
                            {/* <IntroSidebar title="" description="" /> */}

                            {/* Swift Form */}
                            <div className="offset-lg-2 col-lg-9 col-md-12 p-0">
                                <div className="swift-form-job">
                                   
                                    
                                    {/* Job Card With Image */}
                                    <div className="card h-swift-130 jc-radius border-0 mb-2">
                                        <div className="row g-0">
                                            <div className="col-md-12">
                                                <div className="job-card-img jc-pad">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h5 className="card-title jc-title mb-0">
                                                            Questions Listing
                                                        </h5>
                                                        <img
                                                            src={SrQuestionlisting}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card h-swift-80 jc-radius border-0 mb-2">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="jc-pad">
                                                    <div className="mt-3">
                                                        <span className="card-title cc-title mb-0">
                                                            Want to create a new Question?
                                                        </span>
                                                        <Link
                                                            className="border-swift d-none d-md-block btn-swift-text text-right btn btn-primary swift-shadow theme-color f-size-14 btn-create-question"
                                                            to="/createquestion"
                                                        >
                                                            {/* <strong> */}
                                                            <i className="bi bi-plus-circle me-1"></i> Create Question
                                                            {/* </strong> */}
                                                        </Link>
                                                        <Link
                                                            className="border-swift d-block d-md-none btn-swift-text text-right btn btn-primary swift-shadow theme-color f-size-14 btn-create-question"
                                                            to="/createquestion"
                                                        >
                                                            {/* <strong> */}
                                                            <i className="bi bi-plus-circle me-1"></i>
                                                            {/* </strong> */}
                                                        </Link>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* Input Buttons */}
                                    <div class="card border-0 jc-radius mb-3"></div>
                                    <div className="card border-0 jc-radius mb-3">
                                        <div className="row g-0">
                                            <div className="col-md-12">
                                                <div className="card-body jc-t-pad">
                                                    <form id="create-job">
                                                        {!this.state.hideFilteration && (
                                                            <>
                                                                <div class="row g-0 col-input-box">
                                                                    <div className="col-md-6 padd-r-12">
                                                                        <input
                                                                            id="seacrhQuestions"
                                                                            type="text"
                                                                            className="h-swift-44 form-control"
                                                                            name="seacrhQuestions"
                                                                            placeholder="Search Questions"
                                                                            value={this.state.filter}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    filter: e.target.value,
                                                                                });
                                                                            }}
                                                                            onKeyDown={(event) => {
                                                                                if (event.key === "Enter") {
                                                                                    event.preventDefault();
                                                                                    if (
                                                                                        this.state.startFilterDate != null
                                                                                    ) {
                                                                                        if (
                                                                                            this.state.endFilterDate == null
                                                                                        ) {
                                                                                            displayWarningMessage(
                                                                                                "Please select end date !",
                                                                                                "top-center"
                                                                                            );
                                                                                            return;
                                                                                        }
                                                                                    }
                                                                                    filteration();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6 p-w-input">
                                                                        <input
                                                                            id="seacrhTag"
                                                                            type="text"
                                                                            className="h-swift-44 form-control"
                                                                            name="seacrhTag"
                                                                            placeholder="Tags"
                                                                            value={this.state.filterSearchQuestionTag}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    filterSearchQuestionTag:
                                                                                        e.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>

                                                                <div class="row g-0">
                                                                    <div class="col-md-12">
                                                                        <div class="d-md-flex justify-content-end mt-2 marg-r-8">
                                                                            <button
                                                                                className="border-swift btn-swift-text btn btn-primary swift-shadow theme-color text-right f-size-14 btn-create-question"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    filteration();
                                                                                }}
                                                                            >
                                                                                Apply Filters
                                                                            </button>

                                                                            <button
                                                                                className="border-swift btn-swift-text btn btn-primary swift-shadow btn-swift-cancel text-right f-size-14 btn-create-question"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    this.setState(
                                                                                        {
                                                                                            filter: "",
                                                                                            filterSearchQuestionTag: "",
                                                                                        },
                                                                                        () => filteration()
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Clear
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card border-0 jc-radius mb-3">
                                        {currentItems !== [] && currentItems.map((question, index) => (
                                            <div className="row g-0">
                                                <div className="col-md-12">
                                                    <div className="pad-b pad-t pad-l-r">

                                                        <div
                                                            className="card-body border-swift-question p-relative mb-3"
                                                            key={index}
                                                        >
                                                            <Link
                                                                className="bi bi-pencil-square blue-color top-right-icon"
                                                                type="button"
                                                                to={{
                                                                    pathname:
                                                                        "updatequestion/" +
                                                                        question.questionId +
                                                                        "/" +
                                                                        question.questionType +
                                                                        "/" +
                                                                        question.answerType,
                                                                }}
                                                            ></Link>
                                                            {(question.questionContentType.toLowerCase() === "grammar" || question.questionContentType.toLowerCase() === "comprehension") && (
                                                                <div className="mb-2">
                                                                    <h5 className="card-title jc-title f-size-16" style={{ textTransform: 'capitalize' }}>
                                                                        {question.questionContentType} Question
                                                                    </h5>
                                                                    <p className="card-title f-size-16"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: question.title
                                                                        }}
                                                                    >
                                                                    </p>
                                                                </div>
                                                            )}

                                                            {(question.questionContentType.toLowerCase() !== "grammar" && question.questionContentType.toLowerCase() !== "comprehension") && (
                                                                <div>
                                                                    <h5 className="card-title jc-title f-size-16">
                                                                        {question.title}
                                                                    </h5>
                                                                    <p
                                                                        className="card-text f-weight-400 f-size-14"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: question.description,
                                                                        }}
                                                                    ></p>
                                                                </div>
                                                            )}

                                                            <div className="no-border">
                                                                {(question.questionContentType !== null && (question.questionContentType.toLowerCase() === "comprehension" || question.questionContentType.toLowerCase() === "grammar")) && (
                                                                    <div className="click-chip outline f-weight-400 f-size-12 h-swift-26 mb-2 mr-1">
                                                                        Content Type:
                                                                        <span className="f-weight-700">

                                                                            <>
                                                                                {" "}
                                                                                <span> </span>
                                                                                <span>{question.questionContentType}</span>{" "}
                                                                            </>

                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <div className="click-chip outline f-weight-400 f-size-12 h-swift-26 mb-2 mr-1">
                                                                    Question Type:
                                                                    <span className="f-weight-700">
                                                                        {question.questionType.toLowerCase() ===
                                                                            "text" && (
                                                                                <>
                                                                                    {" "}
                                                                                    <i className="bi bi-fonts"></i>
                                                                                    <span> </span>
                                                                                    <span>Text</span>{" "}
                                                                                </>
                                                                            )}
                                                                        {question.questionType.toLowerCase() ===
                                                                            "video" && (
                                                                                <>
                                                                                    {" "}
                                                                                    <i className="bi bi-file-play-fill"></i>
                                                                                    <span> </span>
                                                                                    <span>Video</span>
                                                                                </>
                                                                            )}
                                                                    </span>
                                                                </div>
                                                                <div className="click-chip outline f-weight-400 f-size-12 h-swift-26 mb-2 mr-1">
                                                                    Answer Type:
                                                                    <span className="f-weight-700">
                                                                        {question.answerType.toLowerCase() ==
                                                                            "text" && (
                                                                                <>
                                                                                    {" "}
                                                                                    <i className="bi bi-fonts"></i>
                                                                                    <span> </span>
                                                                                    <span>Text</span>{" "}
                                                                                </>
                                                                            )}
                                                                        {question.answerType.toLowerCase() ==
                                                                            "video" && (
                                                                                <>
                                                                                    {" "}
                                                                                    <i className="bi bi-file-play-fill"></i>
                                                                                    <span> </span>
                                                                                    <span>Video</span>
                                                                                </>
                                                                            )}
                                                                    </span>
                                                                </div>
                                                                <div className="click-chip outline f-weight-400 f-size-12 h-swift-26 mb-2">
                                                                    Tags:
                                                                    <span className="f-weight-700">
                                                                        {" "}
                                                                        {question.tags}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* Cards end here */}
                                    {filteredData.length === 0 ? (
                                        <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                            <div className="card-body py-10">
                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                <div className="text-center">

                                                    <h1>No search results were found </h1>
                                                    <p>Please refine your search</p>

                                                </div>

                                            </div>
                                        </div>
                                    ) : (


                                        <><div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                            <div className="pagination-wrapper ">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={
                                                            <>
                                                                <button
                                                                    className={`btn ${this.state.currentPage < pageCount - 1 ? "btn-primary" : "btn-light"} border-swift btn-swift-text swift-shadow`}
                                                                >
                                                                    Next <i className="bi bi-arrow-right ms-1"></i>
                                                                </button>
                                                            </>
                                                        }
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                        previousLabel={
                                                            <>
                                                                <button
                                                                    className={`btn ${this.state.currentPage > 0 ? "btn-primary" : "btn-light"} border-swift btn-swift-text swift-shadow`}
                                                                >
                                                                    <i className="bi bi-arrow-left"></i> Previous 
                                                                </button>
                                                            </>}
                                                    renderOnZeroPageCount={null}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    containerClassName={"pagination pagination-swift"}
                                                    pageClassName={"page-item mobile-hidden"}
                                                    pageLinkClassName={"page-link mobile-hidden"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    activeClassName={"active"} />
                                            </div>
                                        </div><div className="card border-0 jc-radius  jc-t-pad h-swift-92 mb-3">

                                                <div className="row">
                                                    <div className="col-md-12 button-right">
                                                        <div className="d-md-flex justify-content-between">
                                                            <CSVLink
                                                                data={this.state.filteredData}
                                                                filename={"Questions.csv"}
                                                                headers={this.state.questionHeaders}
                                                                className="border-swift btn-swift-text btn btn-primary swift-shadow theme-color text-right f-size-14 csv-btn"
                                                            >
                                                                <span>
                                                                    Download CSV <i className="bi bi-download"></i>
                                                                </span>
                                                            </CSVLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default Questions;
