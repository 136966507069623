/**
 * This module will take a UTC date and return Browser specific timezone base date time
 * in other words UTC to Lacale
 */

// convert to browser locale
const moment = require('moment');
const timezones = require('moment-timezone');

function convertUTCDateToBrowserLocale(dateTimeInUTC)
{

    return utcToLocaleMomemnt(dateTimeInUTC);

}


function utcToLocaleMomemnt(utcDateTime)
{
    // get the UTC datetime from your database
    const utcDatetimeString = utcDateTime;

    // parse the UTC datetime string using Moment.js
    const utcDatetime = moment.utc(utcDatetimeString);

    // convert the UTC datetime to the browser's local time zone
    const browserDatetime = utcDatetime.local();

    // get the browser's time zone name
    const timeZoneName = moment.tz.guess();

    // convert the browser datetime to a specific time zone, e.g. Pakistan Standard Time (PKT)
    const pktDatetime = browserDatetime.clone().tz(timeZoneName);

    // format the datetimes into strings using Moment.js
    const utcDatetimeStringFormatted = utcDatetime.format('DD/MM/YYYY hh:mm A');
    const localFormat = utcDatetime.format('MMMM D, YYYY ');

    return localFormat;

}



module.exports = { convertUTCDateToBrowserLocale };
