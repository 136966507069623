import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom";

import AuthorizeRoute from './components/Auth/AuthorizeRoute';
import { Logout } from './components/Auth/Logout';
import OidcCallback from './components/Auth/OidcCallback'
import Login from './components/Auth/Login';

import { displayErrorMessage} from './modules/ToastMessage';
import { parseJwt } from './modules/TokenParser';
import { verifyTokenOnLocal } from './modules/TokenHelper';
import { validateAndGetFormatedToken } from './modules/CheckToken';
import CreateQuestion from './components/Questions/CreateQuestion';
import UpdateQuestion from './components/Questions/UpdateQuestion';
import Questions from './components/Questions/Questions';
import CreateInterview from './components/Interviews/CreateInterview';
import CreateJob from './components/Jobs/CreateJob';
import Jobs from './components/Jobs/Jobs';
import ViewJob from './components/Jobs/ViewJob';
import CreateQuestionSet from './components/QuestionSets/CreateQuestionSet';
import QuestionSets from './components/QuestionSets/QuestionSets';
import VideoListing from './components/VideoLibrary/VideoListing';
import VideoRecording from './components/VideoLibrary/VideoListing';
import Dashboard from './components/Dashboard/Dashboard';
import Analytics from './components/Analytics/Analytics';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import SideBar from './components/Layout/SideBar';
import UpdateJob from './components/Jobs/UpdateJob';
import ViewQuestionSet from './components/QuestionSets/ViewQuestionSet';
import UpdateQuestionSet from './components/QuestionSets/UpdateQuestionSet';
import UserProfile from './components/Setting/Profile/UserProfile';
import TenantProfile from './components/Setting/Profile/TenantProfile';
import UserManagement from './components/Setting/UserManagement/Users';
import UserGroup from './components/Setting/UserGroup/Groups';
import PlanManagement from './components/Setting/Plan/index';
import ViewGroupMembers from './components/Setting/UserGroup/ViewGroupMembers';
import CreateUser from './components/Setting/UserManagement/CreateUser';
import EditUser from './components/Setting/UserManagement/EditUser';
import EditGroup from './components/Setting/UserGroup/EditGroup';
import CreateGroup from './components/Setting/UserGroup/CreateGroup';

import Candidates from './components/Candidates/Candidates';
import CandidateDetails from './components/Candidates/CandidateDetails';
import CandidateInterviews from './components/Candidates/CandidateInterviews';
import CandidateOfferLetter from './components/Candidates/OfferLetterDetail';

import SearchApplicants from './components/Leads/SearchApplicants';

import TenantConfigurations from './components/Setting/TenantConfigurations/TenantConfigurations';
import TenantIndustrySettings from './components/Setting/TenantIndustrySettings/TenantIndustrySettings';
import BonusSettings from './components/Setting/BonusSettings/BonusSettings';
import EditTenantIndustry from './components/Setting/TenantIndustrySettings/EditTenantIndustry';
import RejectionReasons from './components/Setting/RejectionReasons/RejectionReasons';

import Skills from './components/Setting/Skills/Skills';
import CreateSkill from './components/Setting/Skills/CreateSkill';

import JobTitles from './components/Setting/JobTitle/JobTitles';
import CreateJobTitle from './components/Setting/JobTitle/CreateJobTitle';
import SplashScreen from '../src/components/Splash/SplashScreen';
import { login } from './components/Auth/oidc';
import Cookies from 'js-cookie';


export default class App extends Component {
    static displayName = App.name;
    // constructor()
    // {
    //     super();
    //     if (!verifyTokenOnLocal())
    //     {
    //         return login();            
    //     }
    //     this.state = {
    //         isCollapsed:false,
    //     }

    // }
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
            activeLink: 'dashboard',
            appLoggedUserEmail: ""

        }
        if (!verifyTokenOnLocal())
        {
            return login();
        }

    }
    componentDidMount()
    {
        const currentURL = window.location.pathname;
        const cleanedURL = currentURL.startsWith('/') ? currentURL.substring(1) : currentURL;
        const activeLinkValue = cleanedURL !== '' ? cleanedURL : 'dashboard';
        Cookies.set('activeLink', activeLinkValue, { expires: 7 });
        const activeLinkFromCookie = Cookies.get('activeLink');

        this.setState({ activeLink: activeLinkFromCookie });

        if (verifyTokenOnLocal()) {
            this.fetchTenantDetails();
        }
        //localStorage.removeItem("Tenant");

        // Sidebar Toggle
        const togglePreference = localStorage.getItem('sidebarToggle');
        if (togglePreference !== null) {
            this.setState({ isCollapsed: togglePreference === 'true' });
        }
    }
    componentDidUpdate() {
        localStorage.setItem('sidebarToggle', this.state.isCollapsed);
    }
    /**
     * Logo fetch and set 
     * */
    fetchTenantDetails()
    {
        let url = null;
        let tenantId = null;
        let bearer = null;

        if (verifyTokenOnLocal())
        {
            bearer = validateAndGetFormatedToken();

            if (bearer != "" || bearer != null)
            {
                tenantId = parseJwt(bearer).tenantid;
                url = process.env.REACT_APP_IDENTITY_API + "/Tenant/GetTenant?tenantId=" + tenantId;

                fetch(url,
                    {
                        method: 'GET',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => response.json())
                    .then(result => {

                        var loggedUserData = JSON.stringify(result);
                        var parsedUserData = JSON.parse(loggedUserData);

                        this.setState({ appLoggedUserEmail: parsedUserData.tenantEmail })

                        localStorage.setItem('Tenant', JSON.stringify(result));
                    })
                    .catch(err => {
                        console.log(err);
                        displayErrorMessage('Error while setting tenant details!', "top-center")
                    });


            }
        }



    }

    toggleMenu = () => {
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed,
        }));
    };


    render() {

        //  const isCollapsedCondition = this.state.isCollapsed;
        // const isCollapsedCondition = false;

        return (
            <div className="has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset">
                <BrowserRouter>
                    <Header isCollapsed={this.state.isCollapsed} toggleMenu={this.toggleMenu} />
                    <SideBar isCollapsed={this.state.isCollapsed} activeLink={this.state.activeLink} toggleMenu={this.toggleMenu} />
                    <Switch>


                        {/*Auth*/}
                        <Route exact path='/oidc_callback' component={OidcCallback} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/logout' component={Logout} />
                        <Route path="/login" component={Login} />

                        {/* Dashboard Routes*/}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/' component={Dashboard} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/dashboard' component={Dashboard} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/analytics' component={Analytics} />

                        {/* Question Routes */}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createquestion' component={CreateQuestion} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/updatequestion/:questionId/:questionType/:answerType' component={UpdateQuestion} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/questions' component={Questions} />

                        {/* QuestionSets Routes */}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/questionsets' component={QuestionSets} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createquestionset' component={CreateQuestionSet} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/viewquestionset/:questionSetId' component={ViewQuestionSet} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/updatequestionset/:questionSetId' component={UpdateQuestionSet} />

                        {/* Job Routes */}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} appLoggedUserEmail={this.state.appLoggedUserEmail} exact path='/jobs' component={Jobs} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createjob' component={CreateJob} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/viewjob/:jobId' component={ViewJob} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/updatejob/:jobId' component={UpdateJob} />

                        {/* Interview Routes */}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createinterview/:JobId' component={CreateInterview} />
                        


                        {/* Setting Routes */}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/userprofile' component={UserProfile} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/tenantprofile' component={TenantProfile} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/usermanagement' component={UserManagement} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createuser' component={CreateUser} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/edituser/:userId' component={EditUser} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/usergroup' component={UserGroup} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/editgroup/:userGroupId' component={EditGroup} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/userplan' component={PlanManagement} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/viewgroupmembers/:userGroupId' component={ViewGroupMembers} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/creategroup' component={CreateGroup} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/tenantconfigurations' component={TenantConfigurations} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/tenantindustrysettings' component={TenantIndustrySettings} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/bonussettings' component={BonusSettings} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/edittenantindustry/:tenantindustryId' component={EditTenantIndustry} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/skills' component={Skills} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createskill' component={CreateSkill} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/jobtitles' component={JobTitles} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/createjobtitle' component={CreateJobTitle} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/rejectionreasons' component={RejectionReasons} />

                        {/*Video Libraray*/}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/videolisting' component={VideoListing} />
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/videorecording' component={VideoRecording} />

                        {/*Leads*/}
                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} exact path='/searchapplicants' component={SearchApplicants} />

                        <AuthorizeRoute isCollapsed={this.state.isCollapsed} excat path="/splash" component={SplashScreen} />

                    </Switch>
                </BrowserRouter>
                <Footer isCollapsed={this.state.isCollapsed } />

            </div>
        );
    }
}
