import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { getHostFromEmail, isValidUsername } from '../../../modules/Utilities';
import { generatePassword, isValidPassword } from '../../../modules/PasswordHelper';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';

class RejectionReasons extends React.Component {
    currentUsername = "";

    constructor(props) {
        super(props);

        this.state = {

            rejectionTitle: "",
            rejectionShortDesc: "",
            rejectionIsActive: true,

            reasonOfRejections: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.AddRejectionReason = this.AddRejectionReason.bind(this);
    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";

        this.getReasonsofRejection();

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * Industries from look up*/
    getReasonsofRejection() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/ReasonOfRejection/";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        reasonOfRejections: response.data,
                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Industy lookup!', "top-center");

                });
        }

    }
    /**
     * Add Reason of rejection
     * 
     * @param {any} event
     */
    AddRejectionReason(event) {

        let bearer = validateAndGetFormatedToken();


        if (this.state.rejectionTitle.trim() == "") {
            displayWarningMessage('Please fill title field!', "top-center");

            return;
        }
        if (this.state.rejectionShortDesc.trim() == "") {
            displayWarningMessage('Please fill description field!', "top-center");

            return;
        }

        let inputModel = {};
        inputModel.Title = this.state.rejectionTitle;
        inputModel.Description = this.state.rejectionShortDesc;

        inputModel.IsActive = this.state.rejectionIsActive;
        if (bearer != "" || bearer != null) {
            var model = JSON.stringify(inputModel);

            let url = process.env.REACT_APP_CORE_API + '/api/ReasonOfRejection/';

            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };

            fetch(url, requestOptions)
                .then((response) => {
                    console.log("Reason of Rejection created", response);
                    this.setState({
                        rejectionTitle: "",
                        rejectionShortDesc: ""
                    })
                    displaySuccessMessage("Rejection Saved Successfully", "top-center");
                    this.getReasonsofRejection();
                })


                .catch(() => {
                    displayErrorMessage('Error During Reason of Rejection Creation!', "top-center");

                });



        }


    }



    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                    <h1 className="page-header-title">Settings</h1>

                                </div>


                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">


                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Reasons of Rejection </h4>


                            </div>
                            <div className="card-body">

                                <div className="row mb-4">
                                    <label for="rejectionTitle" className="col-sm-3 col-form-label form-label">Title <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" name="rejectionTitle" id="rejectionTitle" value={this.state.rejectionTitle} placeholder="Title of Rejection Reason " onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="rejectionShortDesc" className="col-sm-3 col-form-label form-label">Description <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" name="rejectionShortDesc" id="rejectionShortDesc" value={this.state.rejectionShortDesc} placeholder=" Description of Rejection Reason" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label form-label">Is Active  <span style={{ color: 'red' }}>*</span></label>

                                    <div className="col-sm-9">
                                        <div className="col-lg form-check" onChange={this.handleInputChange}>

                                            <input type="radio" value={true} name="rejectionIsActive" defaultChecked /> <span className="form-check-label mx-2">Active</span>
                                            <input type="radio" value={false} name="rejectionIsActive" /> <span className="form-check-label mx-2">InActive</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex gap-3">
                                        <button type="button" className="btn btn-primary" onClick={this.AddRejectionReason
                                        }>Save </button>
                                    </div>
                                </div>


                                <div className="card my-4">

                                    {/*<!-- Header --> */}
                                    <div className="card-header card-header-content-between">
                                        <div className="row justify-content-between align-items-center flex-grow-1">
                                            <div className="col-12 col-md-auto">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="card-header-title">Resons of Rejection</h5>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <ul className="list-group">
                                        {this.state.reasonOfRejections.map((reasonOfRejection, index) => (
                                            <li key={index} className="list-group-item">



                                                <div className="row col-sm-divider">
                                                    <div className="col-sm-12">
                                                        {/*<!-- Stats --> */}
                                                        <div className="d-lg-flex align-items-md-center">


                                                            <div className="flex-grow-1 ms-lg-0">
                                                                <span className="fs-6 fw-bold">{reasonOfRejection.title}</span>


                                                                <div className="d-flex align-items-center">
                                                                    <p><small>{reasonOfRejection.shorrtDesc}</small></p>
                                                                </div>



                                                            </div>
                                                        </div>
                                                        {/*<!-- End Stats --> */}
                                                    </div>



                                                </div>



                                            </li>

                                        ))}


                                    </ul>
                                </div>


                            </div>
                        </div>


                    </div>

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default RejectionReasons;
