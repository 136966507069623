import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import Select from 'react-select';
import axios from "axios";
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { Link } from 'react-router-dom';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { CheckPermission } from '../../../modules/CheckPermission';
import { getHostFromEmail, isValidUsername } from '../../../modules/Utilities';
import { generatePassword, isValidPassword } from '../../../modules/PasswordHelper';
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'

class CreateUser extends React.Component {
    currentUsername = "";

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            firstName: "",
            lastName: "",
            email: "",
            selectedrole: "TenantAdmin",
            PhoneNumber: "",
            status: true,
            password: "",
            createuserPermission: true,
            edituserPermission: true,
            usernameValidationMessage: '',
            displayValidationMessage: 'none',
            tenantSubscriptionId: 0,
            sfc_AllowedUsersCount: 0,
            disableSaveBtn: false

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.createUserSubmitHandler = this.createUserSubmitHandler.bind(this);
        this.validateUsernameHandler = this.validateUsernameHandler.bind(this);
    }


    async componentDidMount() {
        document.title = "Tenant App - Settings";

        this.getTenantSubscriptionid();
        var createuserPermission = false;
        var edituserPermission = false;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {
                createuserPermission = await CheckPermission(parseToken(bearer).roles, "Permissions.Users.Create", bearer);
                this.setState({ createuserPermission: createuserPermission });

                edituserPermission = await CheckPermission(parseToken(bearer).roles, "Permissions.Users.Edit", bearer);
                this.setState({ edituserPermission: edituserPermission });

            }
        }

        this.getUsers();
        this.setState({
        //    password: generatePassword(8)
        });
        this.getCurrentUsername();
    }


    /** get current user username */
    async getCurrentUsername() {
        var url = null;
        var currentUserId = null;
        let bearer = validateAndGetFormatedToken();

        try {

            currentUserId = this.getApplicationUserIdFromToken();
            if (currentUserId == null || currentUserId == undefined || currentUserId == '') {
                console.error("Unablet o get current user Id from token");
                return;
            }


            //Get current user name
            url = process.env.REACT_APP_IDENTITY_API + "/Account/GetUsername?userId=" + currentUserId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.currentUsername = response.data;
                })
                .catch(err => {
                    console.error("Unable to get current user name" + err);
                });

        }
        catch (e) {
            console.error("An error occured when geeting username ofc urrent logged in user => " + e);
        }
    }


    /** Get the Application User id from token
 * return {string} : applicationUserId | null */
    getApplicationUserIdFromToken() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var appUserId = null;

            appUserId = parseJwt(bearer).sub;
            return appUserId;
        }
        return null;
    }


    /**
     * Validate userName
     * @param {any} userName
     */
    validateUsernameHandler(userName) {
        console.log('userName pass to function=>' + userName);
        var result = isValidUsername(userName);
        if (result == false) {
            this.setState({ displayValidationMessage: 'block', usernameValidationMessage: 'Invalid username, only [A-Z], [a-z], [0-9], _. are allowed' });
            console.error("Invalid username");

            return false;
        }
        else {
            this.setState({ displayValidationMessage: 'none', usernameValidationMessage: '' });
            console.log("Valid username");
            return true;
        }
    }



    /**
     * Verify that username not already exist in system
     * @param {any} userName
     * @param {any} e
     * @param {any} hostAddress
     */
    verifyUsername(userName, e, hostAddress) {

        var targetUsername = encodeURIComponent(userName) + "@" + hostAddress;
        console.info("targetUsername ===> " + targetUsername);
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        tenantId = parseJwt(bearer).tenantid;

        let url = process.env.REACT_APP_IDENTITY_API + "/Account/VerifyUser?userName=" + targetUsername + "&tenantId=" + tenantId;

        fetch(url,
            {
                method: 'GET',
                headers:
                {
                    'Content-Type': 'application/json',
                }
            }).then(response => response.json())
            .then(result => {

                if (typeof result === "string") {
                    if (result === "success") {
                        e.target.value = "";
                        displayErrorMessage("Validation Error:" + userName + " username already exist", 'top-center');
                        var element = document.getElementById("promote");
                        element.classList.add("d-none");
                    }
                    else if (result.TenantId != undefined && result.TenantId !== tenantId) {
                        document.getElementById("user-type").innerHTML = "tenant user";
                        var element = document.getElementById("promote");
                        element.classList.remove("d-none");
                    }
                    else {
                        var element = document.getElementById("promote");
                        element.classList.add("d-none");
                    }
                }
                else {
                    this.setState({
                        firstName: result.firstName,
                        lastName: result.lastName,
                        PhoneNumber: result.phoneNumber
                    })
                    document.getElementById("user-type").innerHTML = "candidate";
                    var element = document.getElementById("promote");
                    element.classList.remove("d-none");
                }
            })
            .catch(err => {
                console.log(err);
                displayErrorMessage('Error while Fetching users!', "top-center");

            });
    }




    getUsers() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            var appUserId = this.getApplicationUserIdFromToken();

            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsersWithRoles?userId=" + appUserId + "&hideTenantAdmin=" + true;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    //console.log("users are", result);
                    this.setState({
                        users: result,


                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching users!', "top-center");

                });
        }
    }

    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }

    /**
     * 
     * @param {any} tenantSubscriptionId
     * @param {any} featureKey
     * @param {any} operationType
     */
    subscriptionDataDecrement(tenantSubscriptionId, featureKey, operationType) {
        let tenantSubscriptionModel = {
            tenantSubscriptionId: tenantSubscriptionId,
            featureKey: featureKey,
            operationType: operationType
        };
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData";
            axios.put(url, tenantSubscriptionModel, {
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                console.log("response from decrementing TenantSubscriptionData", response)
            })
                .catch(error => {
                    console.log("error during decrementing TenantSubscriptionData", error);
                    displayErrorMessage('Error During decrementing TenantSubscriptionData!', "top-center");

                })
        }
    }



    /**
* Disable button
* @param {any} state true to disable button
*/
    async togglPromote(state) {
        var btnSave = document.getElementById("btn-promote");
        btnSave.disabled = state;
        if (state == true) {
            btnSave.innerText = "Processing...";
        }
        else {
            btnSave.innerText = "Yes";
        }
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    /**
     * Create Usr Form (Submit Handler)
     * @param {any} event
     */
    async createUserSubmitHandler(event) {

        if (this.state.firstName.length < 2 || this.state.lastName.length < 2) {
            displayWarningMessage('First Name/Last Name should be at least 2 chars', "top-center");
            return;
        }

        if (this.state.firstName === "" || this.state.lastName === undefined || this.state.email === "" || this.state.email === undefined || this.state.selectedrole === "" || this.state.selectedrole === undefined) {
            displayWarningMessage('Please fill all required fields!', "top-center");
            return;
        }
        if (this.state.password == '' || this.state.password == undefined || this.state.password == null) {
            displayWarningMessage('Please enter password', "top-center");
            return;
        }
        if (!isValidPhoneNumber(this.state.PhoneNumber)) {
            displayErrorMessage("Phone no is not valid", 'top-center');
            return;
        }
        else if (this.state.password.length < 8) {
            displayErrorMessage('Password should be atleast 8 character', "top-center");
            return;
        }
        else {
            var result = isValidPassword(this.state.password);
            if (result === false || result === "false") {
                displayErrorMessage("Password should be between 8 to 15 characters  which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character", "top-center");
                return;
            }
        }

        //Validation Passed

        let inputModel =
        {
            email: this.state.email + "@" + getHostFromEmail(this.currentUsername),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            role: this.state.selectedrole,
            status: this.state.status,
            password: this.state.password,
            PhoneNumber: this.state.PhoneNumber

        }
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            this.setState({
                disableSaveBtn: true
            });
            var model = JSON.stringify(inputModel);
            let url = process.env.REACT_APP_CORE_API + '/api/tenant/RegisterTenantUser';
            axios.post(url, model, {
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {

                //Clear state of variables
                this.setState({
                    email: "",
                    firstName: "",
                    lastName: "",
                    selectedrole: "TenantAdmin",
                    password: "",
                    PhoneNumber: "",
                    status: true,
                });
                displaySuccessMessage("User Created Successfully", "top-center");

            }).then(() => {
                this.subscriptionDataDecrement(this.state.tenantSubscriptionId, "Users", "decrement");
                this.setState({
                    disableSaveBtn: false
                });
                setTimeout(function () {
                    window.location.replace('/usermanagement');
                }, 5000);
            }).catch((error) => {
                console.log("Error During Tenant User Creation!", error.response.data);
                if (typeof error.response.data === 'string') {
                    displayErrorMessage(error.response.data, "top-center");

                }
                else {
                    displayErrorMessage("Error During Tenant User Creation!", "top-center");
                }
                this.setState({
                    disableSaveBtn: false
                });
            });


        }

    }




    render() {

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                   
                                    <h1 className="page-header-title">Settings</h1>

                                </div>

                           
                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Create User</h4>


                            </div>
                            <div className="card-body">

                                <form>

                                    <div id="promote" className="row mb-4 d-none alert alert-danger">
                                        <div class="col-sm-12">
                                            <p class="font-bold text-white">This user already register as <span id="user-type"></span>, do you want to promote it as tenant user?
                                                <button id="btn-promote" className=" btn btn-primary float-end" type="button"

                                                    onClick={() => {

                                                        if (this.state.firstName.length < 3 || this.state.lastName.length < 3) {
                                                            displayWarningMessage('First Name/Last Name should be greater than 3 chars', "top-center");
                                                            return;
                                                        }
                                                        if (this.state.firstName === "" || this.state.lastName === undefined || this.state.email === "" || this.state.email === undefined || this.state.selectedrole === "" || this.state.selectedrole === undefined) {
                                                            displayWarningMessage('Please fill all required fields!', "top-center");

                                                        }
                                                        else {
                                                            let inputModel = {
                                                                email: this.state.email +'@'+ getHostFromEmail(this.currentUsername),
                                                                firstName: this.state.firstName,
                                                                lastName: this.state.lastName,
                                                                Role: this.state.selectedrole,
                                                                Status: this.state.status ? true : false,
                                                                password: this.state.password,
                                                                PhoneNumber: this.state.PhoneNumber
                                                            }
                                                            this.togglPromote(true);
                                                            let bearer = validateAndGetFormatedToken();
                                                            if (bearer != "" || bearer != null) {
                                                                var model = JSON.stringify(inputModel);
                                                                let url = process.env.REACT_APP_CORE_API + '/api/tenant/PromoteTenantUser';
                                                                axios.post(url, model, {
                                                                    headers: {
                                                                        'Authorization': bearer,
                                                                        'Content-Type': 'application/json',
                                                                    },
                                                                }).then(async (response) => {

                                                                    var element = document.getElementById("promote");
                                                                    element.classList.add("d-none");

                                                                    displaySuccessMessage("User Promoted Successfully", "top-center");
                                                                    this.togglPromote(false);
                                                                    window.location.replace('/usermanagement');

                                                                }).catch((error) => {
                                                                    console.log("Error During Tenant User Promotion!", error.response.data);
                                                                    if (typeof error.response.data === 'string') {
                                                                        displayErrorMessage(error.response.data, "top-center");

                                                                    }
                                                                    else {
                                                                        displayErrorMessage("Error During Tenant User Promotion!", "top-center");
                                                                    }
                                                                    this.togglPromote(false);
                                                                });


                                                            }
                                                        }
                                                    }}

                                                >Yes</button>
                                            </p>
                                        </div>
                                    </div>


                                    <div className="row mb-4">
                                        <label for="editFirstNameModalLabel" className="col-sm-3 col-form-label form-label">Full Name <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className="form-control" name="firstName" id="editFirstNameModalLabel" placeholder="First name" aria-label="Your first name" value={this.state.firstName} onChange={this.handleInputChange} />
                                                <input type="text" className="form-control" name="lastName" id="editLastNameModalLabel" placeholder="Last name" aria-label="Your last name" value={this.state.lastName} onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label for="email" className="col-sm-3 col-form-label form-label">Username
                                            <span style={{ color: 'red' }}>*</span></label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" name="email" id="email" placeholder="Username" aria-label="Email" onChange={this.handleInputChange}
                                                value={this.state.email}
                                                onKeyUp={(c) => this.validateUsernameHandler(c.target.value)}
                                                onBlur={(e) => { this.verifyUsername(e.target.value, e, getHostFromEmail(this.currentUsername)) }}

                                            />
                                            <span className="alert alert-danger" style={{ display: this.state.displayValidationMessage }}>{this.state.usernameValidationMessage}</span>
                                        </div>
                                        <div className="col-sm-4">
                                            <p className="fs-2">@{getHostFromEmail(this.currentUsername)}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label for="PhoneNumber" className="col-sm-3 col-form-label form-label">Phone Number </label>

                                        <div className="col-sm-9">
                                            <div className="input-group input-group-sm-vertical">

                                                <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="US"
                                                    placeholder="Enter phone number"
                                                    name="PhoneNumber"
                                                    id="PhoneNumber"
                                                    value={this.state.PhoneNumber} onChange={(value) => { this.setState({ PhoneNumber: value }) }}
                                                    
                                                />


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <label className="col-sm-3 col-form-label form-label">Role <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                <input type="radio" value="TenantAdmin" name="selectedrole" defaultChecked /> <span className="form-check-label mx-2">Tenant Admin</span>
                                                <input type="radio" value="TenantUser" name="selectedrole" /> <span className="form-check-label mx-2">Tenant User</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-sm-3 col-form-label form-label">Status <span style={{ color: 'red' }}>*</span></label>

                                        <div className="col-sm-9">
                                            <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                <input type="radio" value={true} name="status" defaultChecked /> <span className="form-check-label mx-2">Active</span>
                                                <input type="radio" value={false} name="status" /> <span className="form-check-label mx-2">Inactive</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <label for="password" className="col-sm-3 col-form-label form-label">Password                                                     <span style={{ color: 'red' }}>*</span></label>
                                        <div className="col-sm-9">
                                            <input type="password"
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                aria-label="Password"
                                                pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/"
                                                title="Password should be between 8 to 15 characters  which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character"
                                                onChange={this.handleInputChange}
                                                value={this.state.password}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <div className="d-flex gap-3">
                                            <Link type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" to="/usermanagement">Cancel</Link>
                                            <button type="button" className="btn btn-primary" disabled={this.state.disableSaveBtn} onClick={(e) => this.createUserSubmitHandler(e)}>Save </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

        );
    }

}
export default CreateUser;
