import React, { useEffect } from 'react';
import { UserManager, WebStorageStateStore } from 'oidc-client';

const Login = () =>
{
    useEffect(() =>
    {
        const userManager = new UserManager({
            authority: process.env.REACT_APP_IDENTITY_API,
            client_id: 'spa',
            redirect_uri: process.env.REACT_APP_TENANT + "/oidc_callback", // Set your React app callback URL
            response_type: 'id_token token',
            scope: 'openid profile core_api roles',
            //post_logout_redirect_uri: 'http://localhost:3000', // Set your React app post-logout redirect URL
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            loadUserInfo: true, 
        });


        const body = document.body;

        // Remove all child elements from the body if not authenticated
        
        while (body.firstChild)
        {
                body.removeChild(body.firstChild);
        }

            // Add a new text node with "redirecting..."
        const redirectingText = document.createTextNode('Please wait...');


        // Add an <h2> element with "Redirecting..."
        const heading = document.createElement('h4');
        heading.textContent = 'Redirecting...';
        body.appendChild(heading);

        body.appendChild(redirectingText);
        

        userManager.signinRedirect();
    }, []);

    return (
        <div>
            <p>Redirecting to Identity Server 4 login page...</p>
        </div>
    );
};

export default Login;








